import { Component, NgModule, OnInit, importProvidersFrom } from '@angular/core';
import { ContactFormService } from '../../services/contact-form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CompanyservService } from '../../services/companyserv.service';
import { LeadDialogComponent } from '../lead-dialog/lead-dialog.component';
// import { Options } from 'ng5-slider';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule,DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxPaginationModule } from 'ngx-pagination';

declare var Options :any



@Component({
  selector: 'app-lead',
  standalone: true,
  imports: [ReactiveFormsModule,MatIconModule,MatMenuModule,FormsModule,NgSelectModule,MatTableModule,MatDividerModule,DatePipe,CommonModule,RouterModule,NgxSliderModule,NgxPaginationModule],
  providers : [ContactFormService,LoginService,CompanyservService],
  templateUrl: './lead.component.html',
  styleUrl: './lead.component.scss'
})


export class LeadComponent {
  constructor(private contactservice: ContactFormService,private router:Router,private companyserv:CompanyservService,private dialog: MatDialog,private loginservice:LoginService,private route:ActivatedRoute) { }
  leadList:any;
  cid:any;
  user:any;
  searchControl=""
  contactlist:any;
  organizationlist:any
  customerFilter = null
  contactFilter= null;
  leadStageFilter= null
  advfilter : any 
  rangeFilter1=""
  rangeFilter2="";
  leadStageArr = [{name : 'New',value : 'new' },{name : 'Open',value : 'open'},{name : 'Closed',value : 'closed'}]
  p : number = 1

  minValue: number = 10;
  maxValue: number = 40;
  options: any = {
    floor: 10,
    ceil: 40,
    step: 10,
    showTicks: true
  };

  minVal: number = 6;
  maxVal: number = 12;
  Foroptions: any = {
    floor: 6,
    ceil: 12,
    step: 1,
    showTicks: true
  };

  // minValue: number = 10;
  // maxValue: number = 40;
  // options: Options = {
  //   floor: 10, // Start the slider at 10
  //   ceil: 40, // End the slider at 40
  //   step: 10,
  //   tickStep: 1, // Show a tick for every step
  //   ticksArray: [10, 20, 30, 40], // Array of tick values
  //   showTicks: true, // Show ticks on the slider
  //   showTicksValues: true,
   
  // }
  // minVal: number = 6;
  // maxVal: number = 12;
  // opt: Options = {
  //   floor: 6, // Start the slider at 1
  //   ceil: 12, // End the slider at 12
  //   step: 1,
  //   tickStep: 1, // Show a tick for every step
  //   ticksArray: [6,7,8,9,10,11,12], // Array of tick values
  //   showTicks: true, // Show ticks on the slider
  //   showTicksValues: true,
   
  // }

 

  displayedColumns: string[] = ['lead_unique_id','name','customer_name','hot_lead','type','forecast_probability','forecast_period', 'contact','creationDate', 'createdBy', 'revenue','stage','action' ];


  ngOnInit(): void {

    this.user = this.loginservice.getUserdata()
    this.getLeadlist('')
    // this.getcontactwithoutCustomer(this.user.data.id,'')
    this.getOrganisationList()
  }

  getLeadlist(filterdata:any){   
    this.contactservice.getLead(this.user.data.id,filterdata).subscribe(
      
      (leadlist: any) => 
      {
        console.log(leadlist,'leadlisttttttt')
        this.leadList = leadlist.data.tasks
        console.log(this.leadList,'leadlisttttttt')
      }
      );   
  }

  searchCall(){
    if(this.searchControl != ""){
    this.advfilter={
      customer : this.customerFilter,
      contact : this.contactFilter,
      status : this.leadStageFilter,
      search : this.searchControl,
      forcast_percentage_max :  Number(this.maxValue),
      forcast_percentage_min : Number(this.minValue),
      forcast_period_max : Number(this.maxVal),
      forcast_period_min : Number(this.minVal)
    }
    this.getLeadlist(this.advfilter)
    }else{
      this.clearfilter()
    }
  }

  getcontactwithoutCustomer(userid: number,search:any){
    this.contactlist = []
    this.contactservice.getContactwithoutCus(userid,search).subscribe(
      (contactlist: any) => {
        contactlist.data.forEach((item:any)=>{
          item.name = item?.first_name + ' ' + item?.last_name
        })
        this.contactlist = contactlist.data
      });   
  }


  getOrganisationList(){
    this.companyserv.getCompany(this.user.data.id,'').subscribe(
      (organizationlist: any) =>{ 
        this.organizationlist = organizationlist.company_list
       });    
  }

  filter(){
     this.advfilter={
      customer : this.customerFilter,
      contact : this.contactFilter,
      status : this.leadStageFilter,
      search : this.searchControl,
      forcast_percentage_max :  Number(this.maxValue),
      forcast_percentage_min : Number(this.minValue),
      forcast_period_max : Number(this.maxVal),
      forcast_period_min : Number(this.minVal)
    }
    this.getLeadlist(this.advfilter)
  }
  clearfilter(){
    this.customerFilter=null
    this.contactFilter=null
    this.leadStageFilter=null
    // this.searchControl =""
    this.minValue = 10
    this.maxValue = 40
    this.minVal = 1
    this.maxVal = 12
   
    this.advfilter={
      customer:"",
      contact:"",
      status:"",
      forcast_percentage_max:"",
      forcast_percentage_min : "",
      forcast_period_max : "",
      forcast_period_min : ""
    }
    this.getLeadlist(this.advfilter)
  }

  deleteLead(list:any){

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Remove Lead',
        message: 'Are you sure, you want to remove a Lead: ' + list.name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
		this.contactservice.dellead(list.id).subscribe((data:any)=>{
      this.getLeadlist('')
    
		})
	}
});

}

addlead(id:any){
		const addLead = this.dialog.open(LeadDialogComponent, {
		  data: {
			id:id,
      status:'Lead'
		  },
		});
		addLead.afterClosed().subscribe(result => {
     this.getLeadlist('')
	});
}

  onProbabilitySliderChange(event: any) {
    // The event object contains the new values of the slider
    this.minValue = event.value;
    this.maxValue = event.highValue;
    // Now you can use this.minValue and this.maxValue as needed
    
  }

  onPeriodSliderChange(event:any){
    this.minVal = event.value;
    this.maxVal = event.highValue;
    // Now you can use this.minValue and this.maxValue as needed
  }

  customSearchFn(term: string, item:any) {
    item.name = item.name.replace(',','');
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  contactSearchFn(term: string, item:any) {
    item.first_name = item.first_name.replace(',','');
    term = term.toLocaleLowerCase();
    return item.first_name.toLocaleLowerCase().indexOf(term) > -1;
  }

  

  onCustomerFilterChange(value: any) {
  //  this.customerFilter = value // Output the selected value to the console
	 this.getcontactlist(this.user?.data?.id,value)

  }

  getcontactlist(uid : any,cuid :any){
    this.contactlist = []
    this.contactservice.getContact(uid,cuid).subscribe(
      (contactdetails: any) => {
        contactdetails.data.forEach((item:any)=>{
          item.name = item?.first_name + ' ' + item?.last_name
        })
        this.contactlist = contactdetails.data
      }
      );  

  }

  customLabelFn(item: any) {
    return item ? `${item.first_name} ${item.last_name}` : '';
  }

  // getStatusClass(status: string): string[] {
  //   // switch (status) {
  //   //   case 'new':
  //   //     return ['bg-blue-100', 'text-blue-800'];
  //   //   case 'open':
  //   //     return ['bg-green-100', 'text-green-800'];
  //   //   case 'closed':
  //   //     return ['bg-red-100', 'text-red-800'];
  //   //   default:
  //   //     return ['bg-gray-200', 'text-gray-800']; // or any default classes
  //   // }
  // }

  editLead(customer_id:any,id:any){
    const addLead = this.dialog.open(LeadDialogComponent, {
		  data: {
        customer_id:customer_id,
        id : id
		  },
		});
		addLead.afterClosed().subscribe(result => {
     this.getLeadlist('')
	});

  }
 

}
