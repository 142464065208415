<nav class="hidden md:flex justify-between items-center bg-white p-4 shadow-md h-16">
	<div class="navbar ">
		<div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
			<div class="">
				<svg xmlns="http://www.w3.org/2000/svg" (click)="openmenu()" [ngClass]="menuclass" class="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" style="margin-top: 2vh !important; margin-right:1vh !important;">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
				</svg>
			</div>
				<div class="progress-outer">
					<div class="progress-inner" [style.width]="whichProgress(progress) + '%'" [style.background-color]="'#2a303c'">
					  <ng-container *ngIf="!disabledP" > 
						{{whichProgress(progress)}}%
					  </ng-container>
					  <ng-container *ngIf="disabledP"> 
						{{whichProgress(progress)}}
					  </ng-container>
					</div>
				  </div>
				<div title="Target" style="background-color: #2a303c;color: white;font-weight: bold;border-radius: 22px;margin-top: 2vh !important;margin-bottom: 1vh !important;margin-left: 7vh !important; 
    height: 5vh !important;min-width: 15vh !important;" class="progressStyle">
					<div class="flex p-1 mb-1" id="target">
						<svg class="ml-1 mr-2  " xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14"><path fill="currentColor" fill-rule="evenodd" d="M11.485.379a.5.5 0 0 0-.839-.233l-2 2a.5.5 0 0 0-.131.475l.36 1.444a.725.725 0 0 0-.014.014L6.47 6.47a.75.75 0 0 0 1.06 1.06l2.393-2.39a.789.789 0 0 0 .014-.015l1.443.361a.5.5 0 0 0 .475-.131l2-2a.5.5 0 0 0-.233-.84l-1.709-.426zm-4.168.38a.75.75 0 0 1-.696.8a5.47 5.47 0 1 0 5.831 5.89a.75.75 0 1 1 1.496.119A6.97 6.97 0 1 1 6.517.062a.75.75 0 0 1 .8.696Zm-.672 3.299a.75.75 0 0 1-.425.972a2.12 2.12 0 1 0 2.75 2.742a.75.75 0 1 1 1.398.544a3.62 3.62 0 1 1-4.694-4.683a.75.75 0 0 1 .971.425" clip-rule="evenodd"/></svg>
						<p class="ml-1  mr-2 text-sm" >₹ {{totaltarget}}</p>
					</div>
				</div>
				<mat-card title="Achived"  style="background-color: #2a303c;color: white;font-weight: bold;border-radius: 22px;margin-top: 2vh !important;margin-bottom: 1vh !important;margin-left: 2vh !important; height: 5vh !important;min-width: 15vh !important;">
					<div class="flex p-1 mb-1">
					<svg class="ml-1 mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 15 15"><path fill="none" stroke="currentColor" d="M4 7.5L7 10l4-5m-3.5 9.5a7 7 0 1 1 0-14a7 7 0 0 1 0 14Z"/></svg>
					<p class="ml-1 mr-2 text-sm">₹ {{achived}}</p>
					</div>
				</mat-card>
			</div>
			<div class="flex-none gap-2 relative">
		<!-- <button class="btn btn-ghost btn-circle" > -->
			<div class="indicator cursor-pointer" (click)="getNotification()">
			<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 " fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" /></svg>
			<span class="badge badge-xs badge-primary">{{notificationCount}}</span>
			</div>

		<!-- </button> -->
		<app-notification *ngIf="notifyFlag" class="absolute notify-div" (notifyCloseEvent)="notifyCloseEvent()" (notifySingleCloseEvent)="notifySingleCloseEvent()"></app-notification>

		
		<h1 class="font-semibold userlogin ml-5">{{uname|titlecase}}<br>
			<span class="colourchange">{{role|titlecase}}</span>
		</h1>
		
		<div class="dropdown dropdown-end">

			<label (click) ="onProfileClick()" tabindex="0" class="btn btn-ghost btn-circle avatar">
			<div class="w-10 rounded-full">
				<img src="../../../assets/img/user.png" />
			</div>
			</label>
			
			<ul  tabindex="0"  *ngIf="isDropdownVisible" class="mt-3 p-2 shadow menu menu-compact dropdown-content bg-white rounded-box w-52">
			
			<li><a routerLink="/teamleader/change-password/{{uid}}"  (click)="onChangePassword()">Change Password</a></li> 
			<li><a (click)="logout()">Logout</a></li>
			</ul>
		</div>
	
		</div>
	</div>
	
</nav>
