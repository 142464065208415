<div class="max-w w-full h-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 height">
  <div class="grid  gap-8 grid-cols-1 ">
    <form class="" [formGroup]="addteamMemberForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-col ">
              <div class="flex flex-col sm:flex-row items-center mb-2">
                  
                  <h2 *ngIf="!isEdit" class="font-semibold text-lg mr-auto">Add Team Members / New</h2>
                  <h2 *ngIf="isEdit" class="font-semibold text-lg mr-auto">Team Member Target / Edit</h2>
                  <div class="w-full sm:w-auto sm:ml-auto sm:mt-0"></div>
                  <div class=" text-right md:space-x-3 md:block flex flex-col-reverse mb-2" *ngIf="!isEdit">
                    <a routerLink="/admin/team-members-details/{{cid}}"class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                      <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg color">Add</button>
                  </div>
                  <div class=" text-right md:space-x-3 md:block flex flex-col-reverse  mb-2" *ngIf="isEdit">
                    <a routerLink="/admin/team-members-details/{{cid}}" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                      <a  class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg  cursor-pointer color" (click)="edit()">Save</a>
                  </div>

              </div>
              <mat-divider></mat-divider>
              <div class="mt-3 bg-white rounded border border-gray-100  px-20">
                  <div class="form mt-3">
                    <div class="flex flex-wrap -mx-3 mb-6">

                      <div *ngIf="isEdit" class="w-full  md:w-1/2 p-3 mb-6 md:mb-0">
                        <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                        Member
                        </label>
                        <input type="text" [disabled]="false" formControlName="member" [ngClass]="{ 'is-invalid': submitted && f['member'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " readonly />
                        <div *ngIf="submitted && f['member'].errors" class="invalid-feedback text-sm">
                        <div *ngIf="f['member'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                     
                      <div *ngIf="!isEdit" class="w-full md:w-1/2 p-3 mb-6 md:mb-0">
                          <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                         Members
                          </label>
                          <select formControlName="member" [ngClass]="{ 'is-invalid': submitted && f['member'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                            <option value="">Select</option>
                            <option *ngFor="let rl of exc;" [value]="rl.id" >{{rl.username}}-{{rl.groups[0].name}}</option>
                            
                            
                          </select>
                          <div *ngIf="submitted && f['member'].errors" class="invalid-feedback text-sm">
                            <div *ngIf="f['member'].errors['required']">Please fill out this field.</div>
                          </div>
                      </div>

                      <div class="w-full md:w-1/2 p-3 mb-6 md:mb-0">
                        <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">Duration</label>
                          <select formControlName="duration" [ngClass]="{ 'is-invalid': submitted && f['duration'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " [(ngModel)]="typeModel" (change)="onFilterChange($event,'from_create')">
                            <option >Select</option>
                            <option *ngFor="let item of typeArr" [value]="item">{{ item }}</option></select>
                       <div *ngIf="submitted && f['duration'].errors" class="invalid-feedback text-sm">
                          <div *ngIf="f['duration'].errors['required']">Please fill out this field.</div>
                       </div>
                      </div>
                      <div class="w-full md:w-1/2 p-3 mb-6 md:mb-0">
                        <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">Duration Type</label>
                          <select formControlName="durationType" [ngClass]="{ 'is-invalid': submitted && f['durationType'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " [(ngModel)]="subType" (change)="onSubFilterChange($event)">
<option >Select</option>
                            <option *ngFor="let item of subTypeArr" [value]="item?.id">{{ item?.month }}</option>
                          </select>
                       <div *ngIf="submitted && f['durationType'].errors" class="invalid-feedback text-sm">
                          <div *ngIf="f['durationType'].errors['required']">Please fill out this field.</div>
                       </div>
                      </div>


                      <div class="w-full md:w-1/2 p-3 mb-6 md:mb-0">
                          <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                          Target
                          </label>
                          <input type="text" formControlName="target" [ngClass]="{ 'is-invalid': submitted && f['target'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " placeholder="Enter Team Target"/>
                          <div *ngIf="submitted && f['target'].errors" class="invalid-feedback text-sm">
                          <div *ngIf="f['target'].errors['required']">Please fill out this field.</div>
                          </div>
                      </div> </div>
                     </div> </div>
      </div>
    </form>
  </div>
</div>

