
  <!-- Main modal -->
  <div id="crypto-modal" tabindex="-1" class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex" aria-modal="true" role="dialog">
    <div class="relative p-4 w-full max-w-md h-full md:h-auto">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-toggle="crypto-modal" [mat-dialog-close]="false">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" ></path></svg>  
                <span class="sr-only">Close modal</span>
            </button>
            <!-- Modal header -->
            <div class="py-4 px-6 rounded-t border-b dark:border-gray-600" >
                <h3 class="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                 Schedule Details
                </h3>
            </div>
            <!-- Modal body -->
            <div class="p-6" *ngFor='let list of data;'>
               
                <ul class="my-4 space-y-3">
                    <li class="bg-gray-100 border border-gray-200 ">
                        <a href="#" class="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg ">
                        
                            <span class="flex-1 ml-3 whitespace-nowrap">{{list.eventname}}<br><small class="text-xs text-gray-500">{{list.activity_type}}<br>Date: {{list.date_and_time}}<br>Duration: {{list.hour}}:{{list.minute}}</small></span> 
                         
                            <!-- <span class="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium  ">{{list.task.name}}</span><br> -->
                           
                        </a>
                        <span *ngIf="list.description.length>=150"> <p  class="flex items-center ml-3 p-3 text-base  text-gray-900  rounded-lg " [innerHTML]="list.description | slice:0:150"></p><a  class="flex items-center ml-3 p-3 text-base  text-blue-900 " routerLink="">view more</a></span>
                        <span *ngIf="list.description.length<150"><p class="flex items-center ml-3 p-3 text-base  text-gray-900  rounded-lg " [innerHTML]="list.description"></p></span>
                    </li>
                    
                   
                </ul>
             
            </div>
        </div>
    </div>
</div>
