<div class="max-w w-full space-y-8 h-screen p-10 bg-white rounded-xl shadow-lg z-10 mt-3 "
  style="height: 85vh !important;">
  <div class="flex flex-col">
    <div class="flex flex-col sm:flex-row items-center mb-4">
      <h2 class="font-semibold text-lg mr-auto">Quotation</h2>
      <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>

      <div class="block  mr-4">
        <input placeholder="Search" id="name" [(ngModel)]="searchControl" (input)="filter()"
          class="appearance-none rounded-full  border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm
                   placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" autocomplete="off">
      </div>

      <div> <mat-icon [matMenuTriggerFor]="animals" class="icon pr-8 icon-pointer">filter_list</mat-icon> </div>
      <mat-menu class="custom-menu" #animals="matMenu">
        <div class="p-2">
          <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
            Customer</label>

          <ng-select [items]="organizationlist" [searchFn]="customSearchFn" bindLabel="name"
            (click)="$event.stopPropagation()" bindValue="id" placeholder="Select"
            class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm"
            (change)="onCustomerFilterChange($event.id)">
          </ng-select>
        </div>
        <div class="p-2">
          <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
            Contact</label>
          <!-- <select [(ngModel)]="contactFilter" (click)="$event.stopPropagation()"
            class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
            <option value="">Select</option>
            <option *ngFor="let list of contactlist" [value]="list.id">{{list.first_name}} {{list.last_name}}</option>
          </select> -->
          <ng-select [items]="contactlist" [(ngModel)]="contactFilter" [clearable]="true"
          [searchFn]="customSearchFn"
          bindLabel="name"
         (click)="$event.stopPropagation()"
          bindValue="id"
          placeholder="Select"  class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
        </ng-select>
        </div>

        <div class="p-2">
          <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
            Type</label>
          <!-- <select [(ngModel)]="typeFilter" (click)="$event.stopPropagation()"
            class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
            <option value="">Select</option>
            <option value="HCM" selected>HCM</option>
            <option value="ERP">ERP</option>
            <option value="OCI">OCI</option>
          </select> -->
          <ng-select [items]="typeArr" [(ngModel)]="typeFilter" [clearable]="true"
          [searchFn]="customSearchFn"
          bindLabel="name"
         (click)="$event.stopPropagation()"
          bindValue="id"
          placeholder="Select"  class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
        </ng-select>
        </div>

        <div class="p-2 flex justify-between ">
          <button (click)="clearfilter()"
            class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">Clear</button>
          <button (click)="filter()"
            class="mb-2 md:mb-0 bg-blue-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-blue-600">Filter</button>
        </div>
      </mat-menu>

      <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
        <a [routerLink]="['/executive/quotation-generate']"
          class="mb-2 md:mb-0 px-5 bg-green-400 text-white py-2 text-sm shadow-sm font-medium tracking-wider border rounded-full hover:shadow-lg "
          style="background-color: #2a303c;"> Create </a>
      </div>

    </div>
  </div>

    <mat-divider></mat-divider>
    <div class="mt-4 w-full overflow-x-auto">
      <table class="min-w-full  table-auto border-collapse border border-gray-300  ">
        <thead>
            <tr class="bg-gray-200 text-gray-600 text-sm">
                <th class="py-3 px-6 text-center border border-gray-300 ">Quotation unique id  </th>
                <th class="py-3 px-6 text-center border border-gray-300">Quotation Name </th>
                <th class="py-3 px-6 text-center border border-gray-300">Customer</th>
                <th class="py-3 px-6 text-center border border-gray-300">Type</th>
                <th class="py-3 px-6 text-center border border-gray-300">Quote Revenue</th>
                <th class="py-3 px-6 text-center border border-gray-300">Approved Amount</th>
                <th class="py-3 px-6 text-center border border-gray-300">Contact</th>
                <th class="py-3 px-6 text-center border border-gray-300">Quote Stage</th>
                <th class="py-3 px-6 text-center border border-gray-300">Sales Rep</th>
                <!-- <th class="py-3 px-6 text-center border border-gray-300">Partner Sales Rep</th> -->
                <th class="py-3 px-6 text-center border border-gray-300">Action</th>
            </tr>
        </thead>
        <tbody class="text-gray-600 text-sm font-light" *ngIf="quotationlist && quotationlist.length > 0; else noData">
            <tr *ngFor="let element of quotationlist | paginate: { itemsPerPage: 5, currentPage: p }" class="border-b border-gray-300 hover:bg-gray-100">
                <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                  {{element.quote_unique_id}}
                </td>
                <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                  {{element.oppertunity_name_label}}
                </td>
                <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                  {{element.customer_name}} 
                </td>
                <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                  {{element.type}}
                </td>
                <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                  {{element.quotation_revenue}}
                </td>
               
                <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                  {{element.approved_amount}}
                </td>
                 <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                    
                  {{element.contact_name}}</td>
                  <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                    
                    {{element.quotation_stage}}</td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                    
                      {{element.cloudvice_sales_rep_label}}</td>
                      <!-- <td class="py-3 px-6 text-center border border-gray-300">
                    
                        {{element.partner_sales_rep_label}}</td> -->
                <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                    <div class="flex justify-center space-x-2">
                        <span class="bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer">
                            <a [routerLink]="['/executive/quotation-generate',element.id]">
                                <i class="fa fa-pencil"></i>
                            </a>
                        </span>
                        <span class="bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer">
                            <a (click)="deleteQuotation(element)">
                                <i class="fa fa-trash"></i>
                            </a>
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
      <!-- <div class="flex justify-center py-20">
        <h1 *ngIf="!isLoading && noDataMessageShown" class="font-semibold text-lg">No Data Found</h1>
      </div> -->
      <ng-template #noData><tr><td colspan="11" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
    </div>
    <pagination-controls (pageChange)="p = $event" class="float-right mt-1 pagination"></pagination-controls>

</div>