import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  FormControl, Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { ContactFormService } from '../../services/contact-form.service';
import { EditLeadService } from '../../services/edit-lead.service';
import { CompanyservService } from '../../services/companyserv.service';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { ToastrService } from 'ngx-toastr';
import { MenutoggleService } from '../../services/menutoggle.service';
import { ReportService } from '../../services/report.service';
import { Console } from 'console';
import { PipelineService } from '../../teamleader/drag-drops/pipeline.service';




@Component({
	selector: 'app-lead-dialog',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule, RouterModule, MatSelectModule, NgxFileDragDropModule],
	providers: [LoginService, EditLeadService],
	templateUrl: './lead-dialog.component.html',
	styleUrl: './lead-dialog.component.scss'
})
export class LeadDialogComponent {
	public user: any;
	// leadForm!: FormGroup;
	submitted = false
	cid: any;
	lid: any;
	contactdetails: any;
	leadDetails: any;
	cstDetails: any;
	organizationlist: any;
	customerDetails: any;
	salesReplist: any;
	partnerSalesReplist: any;
	searchControl: any
	contactsearchControl: any;
	salesRepsearchControl: any;
	partnersalessearchControl: any;
	filter: FormControl = new FormControl('');
	id: any;
	selectedImages: any;
	fileName: any
	teamid: any;
	email: any;
	phone: any;
	productShowFlag = false;
	typeArr = ['Software Sales','Software Development','Implementation Services','Managed Services','Assessment Services','Others'];
	productArr : any = [];
	


	constructor(private leadserv: EditLeadService, private rservice:ReportService,  private preservice:PipelineService, private menuservice:ReportService,private dialog: MatDialog, private companyserv: CompanyservService, private contactservice: ContactFormService, private formBuilder: FormBuilder, private toaster:ToastrService, private loginservice: LoginService,
		public dialogRef: MatDialogRef<LeadDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) { }

		leadForm  : FormGroup = this.formBuilder.group({
			customer: ['', [Validators.required]],
			contact: ['', [Validators.required]],
			lead_name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
			lead_description: ['',],
			budget: ['', [Validators.required,Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
			currency: ['usd', [Validators.required]],
			type: ['', [Validators.required]],
			products_id: [[]],
            forecast_period: ['',Validators.required],
			forecast_percentage: ['',[Validators.required]],
			hot_lead: [false],
			cloudvice_sales_rep: [''],
			partner_sales_rep: [''],
			comments: ['',],
			referal: ['',],
			file: [null],
			status: ['',],
		},);
	


	ngOnInit() {
        this.user = this.loginservice.getUserdata()
		this.leadForm.get('cloudvice_sales_rep')?.setValue(this.user?.data?.first_name)
		this.getProductList()
		this.id = this.data?.id
		this.teamid=this.user.teamid;
		if (this.id !== '') {
			this.getLeadDetails(this.id)
		}
		if(this.cid != undefined){
		this.getcontactlist(this.user.data.id, this.cid);
		this.getOrg()

	}
		this.getOrganisationList("")
		this.salesrepList()
		this.getpartnerSalesrepList()
	}

	getProductList(){
       this.leadserv.getProductList().subscribe((res :any)=>{
		if(res?.data){
			this.productArr = res?.data;
		}
	   })
	}

	

	getcontactlist(userid: number, cid: number) {
		this.contactservice.getContact(userid, cid).subscribe(
			(contactdetails: any) => {
				this.contactdetails = contactdetails.data
				
	    }
		);
	}
	

	getOrg() {
		this.companyserv.getorg(this.cid).subscribe((response: any) => {
		this.cstDetails = response?.company_data
	})
	}

	onFileChange(event: any) {
		if(event != null && event.length > 0){
		const files: FileList = event;
		const file: File = files[0]; // Access the file object directly
		const reader = new FileReader();
		reader.onload = (e: any) => {
		  this.selectedImages = e.target.result;
		  
		};
		reader.readAsDataURL(file);
	
		if(this.leadDetails?.file != undefined && this.leadDetails?.file !== null){
		  // Log the file name
		  this.fileName = '';
		  this.leadDetails.file = null;
		}
		}
	  }

	getOrganisationList(search: any) {
		this.companyserv.getCompany(this.user.data.id, search).subscribe(
			(organizationlist: any) => {
				this.organizationlist = organizationlist.company_list;
				
				
				
			});
	}

	getCustomerData(customerId: any) {
		console.log(customerId,'cstm iddd')
		this.getcontactlist(this.user?.data?.id, customerId)
		this.companyserv.getorg(customerId).subscribe((response: any) => {
			this.customerDetails = response?.company_data
			
		})
	}

	salesrepList() {
		this.leadserv.salesRepList().subscribe(
			(salesReplist: any) => {
				this.salesReplist = salesReplist.data
				
			});
	}

	getpartnerSalesrepList() {
		this.leadserv.partnersalesRepList().subscribe(
			(partnerSalesReplist: any) => {
				this.partnerSalesReplist = partnerSalesReplist.data
				
				
			});
	}

	searchCall() {
	 this.getOrganisationList(this.searchControl)
	}
	searchcontactCall() {
		this.contactdetails = this.contactdetails.filter((item: any) => {
			// Assuming item is the property you want to search/filter
			return item.first_name.toLowerCase().includes(this.contactsearchControl.toLowerCase());
		});
	}

	searchsalesrepCall() {
		this.salesReplist = this.salesReplist.filter((item: any) => {
			// Assuming item is the property you want to search/filter
			return item.label.toLowerCase().includes(this.salesRepsearchControl.toLowerCase());
		});
	}
	partnersalesCall() {
		this.partnerSalesReplist = this.partnerSalesReplist.filter((item: any) => {
			// Assuming item is the property you want to search/filter
			return item.label.toLowerCase().includes(this.partnersalessearchControl.toLowerCase());
		});
	}
    
	onSubmit() {
		// console.log(this.leadForm?.controls,'dataasssasswwwww')
		this.leadForm.markAllAsTouched();
		const postData = {
			customer: this.leadForm.value.customer,
			contact: this.leadForm.value.contact,
			name: this.leadForm.value.lead_name,
			lead_description: this.leadForm.value.lead_description,
			budget: this.leadForm.value.budget,
			currency: this.leadForm.value.currency,
			type: this.leadForm.value.type,
			forecast_period: this.leadForm.value.forecast_period,
			forecast_percentage: this.leadForm.value.forecast_percentage,
			hot_lead: this.leadForm.value.hot_lead,
			status: this.data?.status,
			task_stage:this.data?.substatus,
			cloudvice_sales_rep: this.user?.data?.id,
			partner_sales_rep: this.leadForm.value.partner_sales_rep,
			comments: this.leadForm.value.comments,
			referal: this.leadForm.value.referal,
			file: this.selectedImages,
			team:this.teamid,
			creator_id: this.user.data.id,
			email:this.email,
			phone:this.phone,
			products_id : this.leadForm?.value?.products_id
		}
		if (this.leadForm.valid) {
			this.leadserv.addLead(postData).subscribe((response: any) => {
				if(response){
					this.preservice.ContactleadCountCreateView(response.data?.Lead?.contact,response?.data?.Lead?.id).subscribe((result:any)=>{
					})
				}
				
				
			if (response.status_code === 201) {
					this.toaster.success(response.message,'success')
				this.dialogRef.close('from_create');
				this.rservice.setupdate(true)
				}
			})
		}else{
			return
		}
	}

	getLeadDetails(lid: any) {
		this.contactservice.getleaddetails(lid).subscribe((response: any) => {
			this.leadDetails = response?.data?.lead
			if (this.leadDetails?.file != null) {
				const parts = this.leadDetails?.file?.split('/');
				this.fileName = parts[parts?.length - 1]
			}
			this.getCustomerData(this.leadDetails?.customer)
			this.editForm()
		})
	}

	editForm() {
		this.getProductList()
		console.log(this.leadDetails,'leaddetddd')
	 // Ensure `products_id` is an array
		// let selectedProducts = Array.isArray(this.leadDetails?.products) ? this.leadDetails?.products : [];
		// console.log(selectedProducts,'selectedfff')
		// this.leadDetails.products.forEach((item:any)=>{
		// 	this.leadForm.get('products_id')?.setValue(item);

		// })
		this.leadForm.reset({
			customer: this.cstDetails?.id || this.leadDetails?.customer,
			contact: this.leadDetails?.contact,
			lead_name: this.leadDetails?.name,
			lead_description: this.leadDetails?.lead_description,
			budget: this.leadDetails?.budget,
			currency: this.leadDetails?.currency,
			type: this.leadDetails?.type,
			forecast_period: this.leadDetails?.forecast_period,
			forecast_percentage: this.leadDetails?.forecast_percentage,
			hot_lead: this.leadDetails?.hot_lead,
			cloudvice_sales_rep: this.user?.data?.first_name,
			partner_sales_rep: this.leadDetails?.partner_sales_rep,
			comments: this.leadDetails?.comments,
			referal: this.leadDetails?.referal,
			status: this.leadDetails?.status,
			// products_id: selectedProducts,


		})
		// console.log(this.leadForm.controls,'lead fromm')
		if(this.leadDetails?.type){
			this.productShowFlag = true;
			this.leadForm.get('products_id')?.setValue(this.leadDetails?.products)
		}else{
			this.productShowFlag = false;
			this.leadForm.get('products_id')?.setValue('')

		}

	}

	changehotlead() {
		if (this.leadForm.value.forecast_percentage == '30') {
			this.leadForm.get('hot_lead')?.setValue(true);
		}
		else {
			this.leadForm.get('hot_lead')?.setValue(false);
		}
	}


	editLead() {
		this.leadForm.markAllAsTouched();
		const postData = {
			customer: +this.cid || this.leadDetails?.customer,
			contact: this.leadForm.value.contact,
			name: this.leadForm.value.lead_name,
			lead_description: this.leadForm.value.lead_description,
		    budget: this.leadForm.value.budget,
			currency: this.leadForm.value.currency,
			type: this.leadForm.value.type,
		    forecast_period: this.leadForm.value.forecast_period,
			forecast_percentage: this.leadForm.value.forecast_percentage,
			hot_lead: this.leadForm.value.hot_lead,
			status: this.leadForm.value.status,
			cloudvice_sales_rep: this.user?.data?.id,
			partner_sales_rep: this.leadForm.value.partner_sales_rep,
			comments: this.leadForm.value.comments,
			referal: this.leadForm.value.referal,
			file: this.selectedImages,
			creator_id: this.user.data.id,
			email:this.email,
			phone:this.phone,
			products_id: this.leadForm.value.products_id,
		}
		this.contactservice.editlead(this.id, postData).subscribe(
			(response: any) => {
				
			
				if (response.status_code === 200) {
					this.toaster.success(response.message, 'success');
					this.menuservice.setupdate(true);
					this.dialogRef.close('from_create');

					
				}
			},
			(error) => {
				this.toaster.error('An error occurred', 'Error');
			}
		);
		
		
	}

	close() {
		this.dialogRef.close('from_cancel');
	}

	downloadFile() {
		if (this.leadDetails?.file != null) {
			const fileUrl = this.leadDetails?.file 
			const a = document.createElement('a');
			a.href = fileUrl;
			a.download = fileUrl; 
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	}


	changecontact(event:any){
	this.contactdetails?.forEach((item:any)=>{
	if(item.id==event)
	{
		
		this.email = item?.email
		this.phone= item?.mobile_phone
		
	}})}

	onTypeChange(event:any){
		if(this.leadForm?.value?.type != ''){
		  this.productShowFlag = true;
		  this.leadForm.get('products_id')?.setValidators(Validators?.required)
		  this.leadForm.get('products_id')?.updateValueAndValidity()

	   }else{
		this.productShowFlag = false;
		this.leadForm.get('products_id')?.clearValidators();
		this.leadForm.get('products_id')?.updateValueAndValidity();

	   }
	}
}
