import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { MenutoggleService } from '../../services/menutoggle.service';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from '../../success/success.component';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { TeamComponent } from '../team/team.component';
import { TeamReportComponent } from '../team-report/team-report.component';
import { TeamMembersDetailsComponent } from '../team-members-details/team-members-details.component';
import { LoginService } from '../../services/login.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationAdminComponent } from '../notification-admin/notification-admin.component';
import { ReportService } from '../../teamleader/reports/report.service';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule,RouterModule,MatCardModule,TeamComponent,RouterOutlet,NotificationAdminComponent],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  
  public menuclass:string = "hidden";
  user: any;
  uid: any;
  uname: any;
  role: any;
  isDropdownVisible: boolean = false
  notifyFlag = false;
	notificationCount = 0

  constructor(
      private breakpointObserver: BreakpointObserver,
      private menuservice:MenutoggleService,
      private router:Router,private dialog: MatDialog,
      private loginservice:LoginService,
      private toaster:ToastrService,
      private rservice:ReportService,
    ) {}
  
    ngOnInit(): void {
      this.user = this.loginservice.getUserdata()
	    this.getNotificationList();
      this.rservice.notificationUpdate$.subscribe((data:any) => {
        if(data){
          this.notificationCount = data?.notification_count
        }else{
          this.notificationCount = 0;
        }
         // Update the UI automatically
      });
      this.uid=this.user.data.id
      this.uname=this.user.data.first_name;
      this.role=this.user.data.groups[0].name
      this.menuservice.onGetnav.subscribe((data:any)=>{
		    if(data==true){
				 this.menuclass = 'hidden';
			
		    } else{
				
			  this.menuclass = 'block';
		    }
		});
    }
    openmenu(){
      this.menuservice.onSetnav(true);
    }
   
		

  logout()
    {
    localStorage.removeItem("usertoken")
    localStorage.removeItem("userdata")
    this.toaster.success('Logout Sucessfully','success')
    this.router.navigate(['/'])
  
    }
  onChangePassword(){
    this.isDropdownVisible = false;
    }
  
    onProfileClick(){
    this.isDropdownVisible = !this.isDropdownVisible
    }

  notifyCloseEvent(){
    this.notifyFlag = false;
    }

  getNotification(){
    this.notifyFlag = !this.notifyFlag
    }
    
  notifySingleCloseEvent(){
    this.getNotificationList()
  }
        
  getNotificationList(){
    this.rservice.connect(this.user?.data?.id)
  //   .subscribe({
  //     next: (data: any) => {
  //       if(data?.message?.length){
  //           this.notificationCount = data?.notification_count;
  //       }else{
  //         this.notificationCount = 0;
  //       }
  //     },
  // });
    }
  }
  

