<div class=" w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10"style="min-height: 85vh !important; max-height: 600px; overflow-y: auto;">
    <div class="flex flex-col sm:flex-row items-center mb-2">
        <h2 class="font-semibold text-lg mr-auto">Email Signatures</h2>
        <div class="w-full sm:w-auto sm:ml-auto mt-2 sm:mt-0"></div>
        <div class="text-right md:space-x-3 md:block flex flex-col-reverse">
            <a routerLink="/teamleader/add-email-signature" 
               class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 border-blue-800"> 
               Create
            </a>
            <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg " style="background-color: #2a303c;" 
                    (click)="ExportEmailSignatureDataView()">
                Export
            </button>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="mt-6  w-full overflow-x-auto">
        <table class="min-w-max w-full table-auto border-collapse border border-gray-300">
            <thead>
                <tr class="bg-gray-200 text-gray-600 text-sm">
                    <th class="py-3 px-6 text-center border border-gray-300">Name</th>
                    <th class="py-3 px-6 text-center border border-gray-300">Signature</th>
                    <th class="py-3 px-6 text-center border border-gray-300">Manage</th>
                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm font-light" *ngIf="data && data.length > 0; else noData">
                <tr *ngFor="let item of data | paginate: { itemsPerPage: 5, currentPage: p }" 
                    class="border-b border-gray-300 hover:bg-gray-100">
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        {{ item.name }}
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        {{ item.signature }}
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        <div class="flex justify-center space-x-2">
                            <span class="bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer">
                                <a (click)="Editemailsig(item.id)">
                                    <i class="fa fa-pencil"></i>
                                </a>
                            </span>
                            <span class="bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer">
                                <a (click)="delEmailsig(item)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </span>
                        </div>
                    </td>
                </tr>
                <!-- <tr *ngIf="data?.length === 0">
                    <td colspan="3" class="text-center  font-semibold text-lg pt-9">No Data Found</td>
                  </tr> -->
            </tbody>
        </table>
        <ng-template #noData><tr><td colspan="3" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
    </div>
    <pagination-controls (pageChange)="p = $event" class="float-right pagination mt-1"></pagination-controls>

</div>
