import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ContactFormService } from '../../services/contact-form.service';
import { MatDividerModule } from '@angular/material/divider';
import { ToastrService } from 'ngx-toastr';
import { PipelineService } from '../drag-drops/pipeline.service';
declare var XLSX: any;

@Component({
  selector: 'app-contact-form-view',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule,RouterModule,MatDividerModule],
  templateUrl: './contact-form-view.component.html',
  styleUrl: './contact-form-view.component.scss'
})
export class ContactFormViewComponent implements OnInit {
  singlecontact:any;

  id:any;
  lists: any;
  constructor(private contactservice: ContactFormService, private preservice:PipelineService, private route: ActivatedRoute,private toaster:ToastrService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    
     this.getsinglecontact(this.id)
    });
  }
  getsinglecontact(cid: number){
  
    this.contactservice.getOneContact(cid).subscribe((singlecontact: any)=>{
      this.singlecontact=singlecontact;
      if (singlecontact){
this.preservice.ContactleadcountFetchApi(cid).subscribe((result:any)=>{
  this.lists=result.message;

})
      }
    }
      
      );

  }
  exportcontact(){
    this.contactservice.ExportContactsDataView(this.id).subscribe((result:any)=>{
      console.log(result,'resultisss')
      const worksheet = XLSX.utils.json_to_sheet(result.message);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, 'table-data.xlsx');
    
    if(result?.status==false){
      this.toaster.error(result.message,'error')
    }
    })
  }
}
