import { Component, OnInit ,ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { GetEventsService } from './get-events.service';
import { LoginService } from '../../services/login.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { EventDialogComponent } from '../event-dialog/event-dialog.component';
import {CommonModule, DatePipe, formatDate} from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { AddEventComponent } from '../add-event/add-event.component';

@Component({
  selector: 'app-full-calenders',
  standalone: true,
  imports: [CommonModule,MatDividerModule,RouterModule,MatDialogModule,AddEventComponent],
  templateUrl: './full-calenders.component.html',
  styleUrl: './full-calenders.component.scss',
  
})
export class FullCalendersComponent {
  
 public currentDay:number = new Date().getDate();
 public currentMonth:number = new Date().getMonth();
 public currentYear:number = new Date().getFullYear()
//  public EventsArray:any = [];
 public narray:any =[];
 public position:number = 0;
 public date:any = new Date();
 e_details:any[]=[]
currentDate:any
fulldate_c:any
public user:any
public count_type:any=1
eventslist:any;
public isShow:boolean = false;
 private lastday:number = new Date(this.date.getFullYear(),this.date.getMonth()+1,0).getDate();
 private prevMonthlastdate:number = new Date(this.date.getFullYear(),this.date.getMonth(),0).getDate();
 private prevMonthlastday:number = new Date(this.date.getFullYear(),this.date.getMonth(),0).getDay();
 constructor(private dialog: MatDialog,private geteventservice:GetEventsService,private loginservice:LoginService) { 
  
      
}
 ngOnInit(): void {
  this.counter(this.lastday);
this.user=this.loginservice.getUserdata()
this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en')
//console.log(formatDate(new Date(), 'yyyy-MM-dd', 'en'))
this.geteventservice.getActivities(this.user.data.id,this.currentDate).subscribe(
  (data: any) =>{ this.eventslist = data
    //var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
 
});


//this.getevent();
}
  // public date:any = new Date;
 
  prevMonth(){
    this.narray=[]
    this.incrementMonth(-1);
    let testdate=this.date.toLocaleDateString('en-US')
    let day:number = new Date(this.date.getFullYear(),this.date.getMonth()+1,0).getDate();;
    
    this.counter(day);
    let predate=formatDate(testdate, 'YYYY-MM-dd', 'en-US')
   
    this.geteventservice.getActivities(this.user.data.id,predate).subscribe(
      (data: any) =>{ this.eventslist = data
       
    });
    if(this.currentDate<=predate)
    {
      //console.log('trueeeeee')
      this.isShow=false
    }
    else{
      this.isShow=true
    }
    
  }
  nextMonth(){
    this.narray=[]
    this.incrementMonth(1);
    let testdate=this.date.toLocaleDateString('en-US')
    let day:number = new Date(this.date.getFullYear(),this.date.getMonth()+1,0).getDate();;
    
    this.counter(day);
    let predate=formatDate(testdate, 'YYYY-MM-dd', 'en-US')
    this.geteventservice.getActivities(this.user.data.id,predate).subscribe(
      (data: any) =>{ this.eventslist = data
       
    }); 
    // //console.log(this.currentDate,'nowdate') 
    // //console.log(predate,'nextdate') 
    if(this.currentDate<=predate)
    {
      //console.log('trueeeeee')
      this.isShow=false
    }
    else{
      this.isShow=true
    }
  
  }
  eventData(event:any){

  }
  addEvent(eobj:any){
    let eobj_day
    let e_month
   if(eobj.pos<10)
   {
      eobj_day='0' + eobj.pos
   }
   else{
    eobj_day=eobj.pos
   }
   
    let testdate=this.date.toLocaleDateString('en-US')
    let predate=formatDate(testdate, 'YYYY-MM', 'en-US')
    let ydate=this.date.getFullYear()
    let mdate= this.date.getMonth()
   // console.log(predate)
   if(mdate<10)
   {
e_month='0' + mdate
   }
   else{
    e_month= mdate
   }
 //  console.log(e_month)
    let compare_date=(predate+"-"+eobj_day)
    
    console.log(compare_date,'posdate')
    console.log(this.currentDate,'cudate')
    if(compare_date>=this.currentDate)
    {
console.log('hoii')
    var nindex = this.narray.indexOf(eobj)
   
    this.narray.map((item:any)=>{
      item.mstat = false;
      return item;
    })
    this.narray[nindex].mstat = !this.narray[nindex].mstat  ;
  
    //console.log("addeventFn");
  }
}
  prevMonthDates(){
    var dates: number[] = [];
    for(var i = this.prevMonthlastday; i >= 0; i--){
      dates.push(this.prevMonthlastdate-i);
      
    }
  
    return dates; 

  }
  mouseEnter(edate:any,cat:any)
  {
    let a = edate.split("T");
    let c_date = a[0];
    let time = a[1];

    this.geteventservice.geteventdetails(this.user.data.id,c_date,cat).subscribe((response:any)=>{
      this.e_details=response
    
    const confirmDialog = this.dialog.open(EventDialogComponent, {
      data: this.e_details
      });
    })
  }
  menuclose(obj:any){
   //  this.position = obj.pos;
  this.narray.map((item:any)=>{
  item.mstat = false;
  return item;
  })  
  }

 

 
  counter(i: number) {
  
    for (var j:number =1;j<=i;j++){
      //console.log(j)
      this.narray.push({"pos":j,"mstat":false})
    }
  }

  public months:string[] =[
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
 


 
  private incrementMonth(delta: number): void {
   
    this.date = new Date(this.date.getFullYear(),this.date.getMonth()+delta),0;
    this.lastday= new Date(this.date.getFullYear(),this.date.getMonth()+1,0).getDate();
    this.prevMonthlastdate= new Date(this.date.getFullYear(),this.date.getMonth(),0).getDate();
    this.prevMonthlastday= new Date(this.date.getFullYear(),this.date.getMonth(),0).getDay();
    //this.fulldate_c=
  }

  
  


}
