import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiurl } from '../global';

@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {

  constructor(private http: HttpClient) { }

  getCountry(id:any){
    return this.http.get(apiurl+"drop-country/list/"+id+"/")
  }

   getState(id:any){
       return this.http.get(apiurl+"state/list/"+id+"/")
   }

   getCity(id:any){
    return this.http.get(apiurl+"city/list/"+id+"/")
  }
}
