import { Component, NgModule, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { MainService } from '../../services/main-service';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-configuration',
  standalone: true,
  imports: [CommonModule,RouterModule,MatDialogModule,MatDividerModule],
  providers:[DatePipe],
  templateUrl: './configuration.component.html',
  styleUrl: './configuration.component.scss'
})
export class ConfigurationComponent implements OnInit {
  list:any[]=[]
  user:any
  public isEdit:boolean = false;
  loader = true;
  constructor(private loginservice:LoginService,private mservice:MainService,) { }

  ngOnInit(): void {
    this.user=this.loginservice.getUserdata()
    this.configdetails(this.user.data.id)
  }
  configdetails(id:any)
  {
    this.mservice.configurationlist(id).subscribe((response:any)=>{
      this.list=response
      if(this.list[0].name!="")
      {
        this.isEdit = true;
       
      // }else{
      //   this.isEdit = false;
      //   this.loader = false;
      // }
      }
    })
  }
}
