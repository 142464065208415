<div class="container">
  <div mat-icon-button (click)="onClose()" class="alignment cursor-pointer" aria-label="Close">
    <mat-icon class="position">close</mat-icon>
  </div>
  <h1 mat-dialog-title>{{ data.title }}</h1>

  <form [formGroup]="registerForm" >
    <div mat-dialog-content>
      <textarea
        formControlName="extranote"
        name="extranote"
        id="extranote"
        class="w-full min-h-[100px] max-h-[300px] h-28 outline-none border border-gray-400 rounded py-1 px-2 bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline"
        spellcheck="false"
        placeholder="Enter your note"
      ></textarea>
      <div *ngIf="submitted && f['extranote'].errors" class="invalid-feedback text-sm">
        <div *ngIf="f['extranote'].errors['required']">Please fill out this field.</div>
    </div>
    </div>

    <div style="display: flex; margin: 20px; justify-content: center;">
      <button
        type="submit"
        class="bg-blue-500 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow" (click)="editTask(data.id, data.colid, data.cid)"
      >
        Save
      </button>
      <button
        type="button"
        class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
        (click)="onClose()"
        style="margin-left: 5px;"
      >
        Cancel
      </button>
    </div>
  </form>
</div>
