import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SuccessComponent } from '../../success/success.component';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [MatMenuModule,RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {

//   constructor(private router:Router, private dialog: MatDialog) { }

//   ngOnInit(): void {
//   }

//   logout()
//   {
//     console.log('dbd')
//     localStorage.removeItem("usertoken")
//     localStorage.removeItem("userdata")
//     const confirmDialog = this.dialog.open(SuccessComponent, {
//       data: {
//         title: 'Success',
//         message: 'Logout Successfully!'
//       }
//       });
//       confirmDialog.afterClosed().subscribe(result => {
//     window.location.href=""
//     });

//   }
// }

constructor(private router:Router,private dialog: MatDialog,private route:ActivatedRoute) { }
activeLink: string = '';
ngOnInit(): void {
    if(this.router.url == '/admin'){
      this.activeLink = 'dashboard' 

    }
    else if(this.router.url=='/admin/configuration'){
      this.activeLink = 'logs'
    }
    else if(this.router.url=='/admin/team-members'){
      this.activeLink = 'member'
    }
    else if(this.router.url=='/admin/team'){
      this.activeLink = 'team'
    }
    else if(this.router.url== '/admin/team-report')
  {
  this.activeLink='stats'
  }  
  
}
logout()
{
  localStorage.removeItem("usertoken")
  localStorage.removeItem("userdata")

const confirmDialog = this.dialog.open(SuccessComponent, {
  data: {
    title: 'Success',
    message: 'Logout Successfully!'
  }
  });
  confirmDialog.afterClosed().subscribe(result => {
  window.location.href="login"
});
}

showMenu: boolean = false;
toggleMenu() {
  
  this.showMenu = !this.showMenu;
}


setActiveLink(link: string) {
  this.activeLink = link;
}

}
