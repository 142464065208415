import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EmailtemplateService } from './email-template.service';
import { LoginService } from '../../services/login.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SuccessComponent } from '../../success/success.component';
import { FailComponent } from '../../fail/fail.component';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-add-email-templates',
  standalone: true,
  imports: [CommonModule,RouterModule,MatDividerModule,ReactiveFormsModule],
  templateUrl: './add-email-templates.component.html',
  styleUrl: './add-email-templates.component.scss'
})
export class AddEmailTemplatesComponent implements OnInit {

  //   registerForm!: FormGroup;
    public user: any;
    submitted = false;
    users:any=[];
    Acivity:any=[];
    isEdit: boolean=false;
    cid:any
    emailtemplateForm!: FormGroup;
    constructor(private dialog: MatDialog,private formBuilder: FormBuilder,
      private eservice: EmailtemplateService,
      private router: Router,private loginservice: LoginService,private route: ActivatedRoute
      ) { }
  
    ngOnInit() {
        this.emailtemplateForm = this.formBuilder.group({
          name: ['', [Validators.required,Validators.pattern(/^[a-zA-Z\s]*$/)]],                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
          subject:['', [Validators.required,Validators.pattern(/^[a-zA-Z\s]*$/)]],
          message:['',[Validators.required,Validators.pattern(/^[a-zA-Z\s]*$/)]]
        }, );
        this.user = this.loginservice.getUserdata()
        this.getuser()
        this.route.paramMap.subscribe(params => {
          this.cid = params.get('id')
         
          if(this.cid != null){
            
              this.isEdit = true;
          this.eservice.getOneEmailtemplate(this.cid).subscribe((data:any)=>{
                      
              console.log(data)
            
              this.emailtemplateForm.setValue({
                  name:data.name,
                  subject:data.subject,
                  message:data.message
                  
              
              })
          })
        
      }
      else
      {
          this.isEdit = false;
      this.emailtemplateForm.reset();
      }
        });
    }
    getuser(){
    
      this.eservice.getUser().subscribe(
        (users: any) => this.users = users
        )
      }
      editemailtemplate(cid:number){
        this.emailtemplateForm.value.creator_id=this.user.data.id
    this.eservice.editEmailtemplate(this.emailtemplateForm.value,cid).subscribe((reponse:any) => {
      //console.log(reponse);
      this.router.navigate(['teamleader/email-template/']);
    });
    }
    // convenience getter for easy access to form fields
    get f() { return this.emailtemplateForm.controls; }
  
    onSubmit() {
      this.submitted = true;
      if (this.emailtemplateForm.invalid) {
        console.log('gggggg')
          return;
      }
        //console.log(this.registerForm.value);
        this.emailtemplateForm.value.creator_id=this.user.data.id
        this.eservice.addEmailtemplate(this.emailtemplateForm.value).subscribe((reponse)=>{
          if(reponse)
      {
       const confirmDialog = this.dialog.open(SuccessComponent, {
  
         data: {
           title: 'Success',
           message: 'Added Succesfully!'
         }
       })
       confirmDialog.afterClosed().subscribe(result => {
   
        if (result == true) {
          this.router.navigate(['teamleader/email-template/']);
       }
  
  
     });
  }
  else{
     const confirmDialog = this.dialog.open(FailComponent, {
  
         data: {
           title: 'Failed',
           message: 'Added Failed!'
         }
       })
       confirmDialog.afterClosed().subscribe(result => {
   
        if (result == true) {
            this.router.navigate(['teamleader/add-email-template'])
       }
  
  
     });
  }
        
       });  
    }
  
    onReset() {
        this.submitted = false;
        this.emailtemplateForm.reset();
    }
  }
  