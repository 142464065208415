import { Component, OnInit } from '@angular/core';
import { MenutoggleService } from '../services/menutoggle.service';
import { RouterModule, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from "./navbar/navbar.component";


@Component({
    selector: 'app-executive',
    standalone: true,
    providers: [MenutoggleService],
    templateUrl: './executive.component.html',
    styleUrls: ['./executive.component.scss'],
    imports: [RouterOutlet, RouterModule, CommonModule, SidebarComponent, NavbarComponent]
})
export class ExecutiveComponent implements OnInit {
	public col2width:string = "col-span-2"
	public col10width:string = "col-span-10"

	constructor(
		private menuservice:MenutoggleService,
		private router:Router
	) { }

    ngOnInit(): void {
	  this.menuservice.onGetnav.subscribe((data:any)=>{
		
		  if (data == false){
			  this.col2width = "hidden"
			  this.col10width = "col-span-12 animate__fadeOutLeftBig"

		  }else{
			  this.col2width = "col-span-2"
			  this.col10width = "col-span-10 animate__fadeInLeftBig"
		  }
		});
  }

	closemenu(){
		
		this.menuservice.onSetnav(false);
	}

	refresh() {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(['/executive']);
		});
	 
	}
}
