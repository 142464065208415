import { Injectable,EventEmitter  } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { apiurl } from '../global';
import { LoginService } from './login.service';
@Injectable({
	providedIn: 'root'
})
export class PipelineService {

	constructor(
		private http:HttpClient,
		private login:LoginService
	) { }

	public taskEditEmitter:EventEmitter<any> = new EventEmitter();

	getBoard(userid:number){
		let access_token = this.login.getUserToken()
		const headers = new HttpHeaders();
		headers.set('Authorization',`Bearer ${access_token['access']}`);
		return this.http.get(apiurl+'pipeline/lc_board/'+userid+"/",{headers:headers})
	}

	addColumns(boardid:number,cname:any){
		let access_token = this.login.getUserToken()
		const headers = new HttpHeaders();
		headers.append('Content-Type','enctype/form-data');
		headers.append('Authorization','Bearer '+access_token['access']);
		const fdata:FormData = new FormData();
		fdata.append('board_id',boardid.toString())
		fdata.append('name',cname)
		return this.http.post(apiurl+'pipeline/lc_column/'+boardid+"/",fdata,{headers:headers})
	}

	getColumns(boardid:number){
		return this.http.get(apiurl+'pipeline/lc_column/'+boardid+"/")
	}

	addTask(data:any,colid:number){
	
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl+"pipeline/lc_task/"+colid+"/",data,{headers:headers})
	}
	editTask(data:any,taskid:number){
		
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.put(apiurl+"pipeline/rud_task/"+taskid+"/",data,{headers:headers})
	}
	editColumn(data:any,cid:number){
		
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.put(apiurl+"pipeline/rud_column/"+cid+"/",data,{headers:headers})
	}
	getOneTask(taskid:number){
		return this.http.get(apiurl+"pipeline/rud_task/"+taskid+"/")
	}
	delOnetask(taskid:number){
		return this.http.delete(apiurl+"pipeline/rud_task/"+taskid+"/")
	}
	delOneColumn(colid:number){
		return this.http.delete(apiurl+"pipeline/rud_column/"+colid+"/")
	}
	getOneColumn(colid:number){
		return this.http.get(apiurl+"pipeline/rud_column/"+colid+"/")
	}
	getTask(colid :number){
		return this.http.get(apiurl+'pipeline/lc_task/'+colid+"/")
	}



	getTotalList(id:number,data:any){
		const params =data
		return this.http.get(apiurl + 'pipeline/kanban-board-api-list/'+id+'/',{params});             
	}

	getNewLeadsList(id:number){
		return this.http.get(apiurl + 'pipeline/new-leads/list/'+id+'/');             
	}

	getOpenLeadsList(id:number){
		return this.http.get(apiurl + 'pipeline/open-leads/list/'+id+'/');             
	}

	getClosedLeadsList(id:number){
		return this.http.get(apiurl + 'pipeline/close-leads/list/'+id+'/');             
	}


	getOpportunityList(id:number){
		return this.http.get(apiurl + 'pipeline/new-oppertunity/list/'+id+'/');             
	}

	getOpenOpportunityList(id:number){
		return this.http.get(apiurl + 'pipeline/open-oppertunity/list/'+id+'/');             
	}

	getClosedOpportunityList(id:number){
		return this.http.get(apiurl + 'pipeline/close-oppertunity/list/'+id+'/');             
	}

	getQuotationList(id:number){
		return this.http.get(apiurl + 'pipeline/new-quotation/list/'+id+'/');             
	}

	getOpenQuotationList(id:number){
		return this.http.get(apiurl + 'pipeline/open-quotation/list/'+id+'/');             
	}

	getClosedQuotationList(id:number){
		return this.http.get(apiurl + 'pipeline/close-quotation/list/'+id+'/');             
	}

    ToOpenLead(id:number){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl + 'pipeline/leads-toggling-open-status/'+id+'/',{headers:headers});             
	}

	ToCloseLead(id:number){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl + 'pipeline/leads-toggling-close-status/'+id+'/',{headers:headers});             
	}

	leadToOpportunity(id:number){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl + 'pipeline/leads-to-oppertunity/'+id+'/',{headers:headers});             
	}

	ToOpenOpportunity(id:number){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl + 'pipeline/oppertunity-toggling-open-status/'+id+'/',{headers:headers});             
	}

	ToCloseOpportunity(id:number){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl + 'pipeline/oppertunity-toggling-close-status/'+id+'/',{headers:headers});             
	}

	OpportunityToQuotation(id:number){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl + 'pipeline/oppertunity-to-quotation/'+id+'/',{headers:headers});             
	}

	ToOpenQuotation(id:number){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl + 'pipeline/quotation-toggling-open-status/'+id+'/',{headers:headers});             
	}

	ToCloseQuotation(id:number){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl + 'pipeline/quotation-toggling-close-status/'+id+'/',{headers:headers});             
	}


	colorChange(color:any,type:any,id:any){
		const data={color_identifier:type,
			           color:color
					  }
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl + 'pipeline/color-conversion/'+id+'/',data,{headers:headers});    
	}

	
}


