<div  class="flex flex-col  bg-white z-50" style="min-width: 350px;" >
    <form class="" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <div class="flex flex-col px-4 pt-2" >
        <div class="flex justify-end">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 p-1 rounded-full hover:bg-gray-400 cursor-pointer" (click)="menuClose($event);$event.stopPropagation()" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </div>
        <div class="flex-grow border-b-4 border-gray-700 ">
            
            <input type="text"  formControlName="eventname" [ngClass]="{ 'is-invalid': submitted && f['eventname'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                   
            <div *ngIf="submitted && f['eventname'].errors" class="invalid-feedback text-sm">
             <div *ngIf="f['eventname'].errors['required']">Please fill out this field.</div>
         </div>
            
        </div>
    </div>
    <!-- <div class="flex flex-row px-4 py-2 justify-center items-center ">
        <div class="w-6/12 text-center">
           
            {{date | date:'MMMM'}}-{{ selectedDay.pos }}-{{date | date:'YYYY'}} -->
            <!-- <select  formControlName="edate" >
              
                <option selected value="{{date | date:'YYYY'}}-{{date | date:'MM'}}-{{ selectedDay.pos }}  {{date | date:'h:mm:ss a'}}">{{date | date:'YYYY'}}-{{date | date:'MM'}}-{{ selectedDay.pos }}  {{date | date:'h:mm:ss a'}}</option>
             </select> -->
        <!-- </div>
        </div> -->
    <div class="flex flex-row px-4 py-2 justify-center items-center ">
        
      
        <div class="w-6/12 pr-1">
            <select   formControlName="activitytype"  [ngClass]="{ 'is-invalid': submitted && f['activitytype'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                <option  value="">Select Type</option>
                <option  value="Events">Events</option>
                <option  value="Meetings">Meetings</option>
              </select>
            <div *ngIf="submitted && f['activitytype'].errors" class="invalid-feedback text-sm">
                <div *ngIf="f['activitytype'].errors['required']">Please fill out this field.</div>
            </div>
        </div>
        <div class="w-6/12 text-center pr-1">
            <select  formControlName="contacts" [ngClass]="{ 'is-invalid': submitted && f['contacts'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                <option value="" >Customer</option>
                <option *ngFor="let list of contact" value="{{list.id}}">{{list.name}}</option>
             </select>
             <div *ngIf="submitted && f['contacts'].errors" class="invalid-feedback text-sm">
              <div *ngIf="f['contacts'].errors['required']">Please fill out this field.</div>
          </div>
        </div>
    
    </div>
    <div class="flex flex-row px-4 py-2 justify-center items-center ">
        
      
        <div class="w-6/12 pr-1">
            <input type="datetime-local" formControlName="edate" [ngClass]="{ 'is-invalid': submitted && f['edate'].errors }"  class="focus:ring-white w-full focus:border-white  border-gray-200  sm:text-sm " placeholder="Hr"> 
            <div *ngIf="submitted && f['edate'].errors" class="invalid-feedback text-sm">
                <div *ngIf="f['edate'].errors['required']">Please fill out this field.</div>
            </div>
        </div>
        <div class="w-3/12 ">
            <input type="text" #hour formControlName="hour"  [ngClass]="{ 'is-invalid': submitted && f['hour'].errors }" name="minute" class="focus:ring-white w-full focus:border-white  border-gray-200  sm:text-sm " placeholder="MM"> 
            <div *ngIf="submitted && f['hour'].errors" class="invalid-feedback text-sm">
                <div *ngIf="f['hour'].errors['required']">Please fill out this field.</div>
            </div>
        </div>
        <div class="w-3/12 ">
            <input type="text" #minute formControlName="minute"  [ngClass]="{ 'is-invalid': submitted && f['minute'].errors }" name="minute" class="focus:ring-white w-full focus:border-white  border-gray-200  sm:text-sm " placeholder="MM"> 
            <div *ngIf="submitted && f['minute'].errors" class="invalid-feedback text-sm">
                <div *ngIf="f['minute'].errors['required']">Please fill out this field.</div>
            </div>
        </div>
    
    </div>
    <div class="flex justify-center items-center text-lg ">
        <button type="submit" (click)="$event.stopPropagation()" class="border-2 rounded-lg border-gray-100 focus:outline-none focus:border-gray-500 px-2 m-2"> save</button>
    </div>
      
</form>
</div>
