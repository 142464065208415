
<div class="custom-dialog ">
	<h2 mat-dialog-title class="text-red-700 text-align-center">{{data.title}}</h2>
	<mat-dialog-content>
		<p>
			{{data.message}}
		</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		
		<button mat-raised-button class="focus:outline-none text-white bg-red-700 hover:bg-red-800  font-medium rounded-lg text-sm px-4 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" [mat-dialog-close]="true">Ok</button>
	</mat-dialog-actions>
</div>
