<div>
	<h2 class="font-semibold text-lg mr-auto mt-5 ml-5">
		Lead / {{id?'Edit':'New'}}</h2>
		<form class="form-control" [formGroup]="leadForm">
		  <div class="mt-3 bg-white overflow-y-auto h-96 rounded border border-gray-100 mx-5">
			<div class="form mt-3">
			  <div>
				<div class="mt-4">
				  <div>
					<div class="flex justify-between">
					  <div class="px-2">
						<label class="block tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
						<span class="text-red-600">*</span>  Customer :</label>
						<button mat-button style="opacity: 0; height: 0; width: 0;" cdkFocusInitial></button>
						<mat-form-field appearance="fill"  class="custom-width"  
						>
						  <mat-select formControlName="customer"  (ngModelChange)="getCustomerData($event)">
							<input matInput [formControl]="filter" [(ngModel)]="searchControl" (input)="searchCall()"  placeholder="Search...">
							<mat-option *ngFor="let list of organizationlist" [value]="list.id">
							  {{ list.name }}
							</mat-option>
						  </mat-select>
						</mat-form-field> 
						<div 
						*ngIf="leadForm.get('customer')?.touched && leadForm.get('customer')?.hasError('required')"
						class="error-msg">
						Customer is Required
					  </div>
					 
					  </div>

					  <div class="">
						<label class="block tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
							<span class="text-red-600">*</span> Contact :</label>
						<mat-form-field appearance="fill" class="custom-width">
						  <mat-select formControlName="contact" (ngModelChange)="changecontact($event)">
							<div class="bg-green-700 text-white text-center cursor-pointer font-bold m-2 rounded w-10" (click)="close()"></div>
							<input matInput [formControl]="filter" [(ngModel)]="contactsearchControl" (input)="searchcontactCall()"  placeholder="Search...">
							<mat-option *ngFor="let list of contactdetails" [value]="list.id">
							  {{ list.first_name }} {{list.last_name}}
							</mat-option>
						  </mat-select>
						</mat-form-field> 
						<div 
						  *ngIf="leadForm.get('contact')?.touched && leadForm.get('contact')?.hasError('required')"
						  class="error-msg">
						  Contact is Required
						</div>
						
					  </div>
					  	<div class="">
						<label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
						  File :</label>
						 
						<ngx-file-drag-drop
						formControlName="file"
					  activeBorderColor="#3F51B5" class="focus:ring-white w-64 file-uploader"
					  [multiple]="false"
					  (valueChanged)="onFileChange($event)"></ngx-file-drag-drop>
						<div class="mt-2 cursor-pointer" *ngIf="this.leadDetails?.file != null" (click)="downloadFile()"> <mat-icon class="icon " >insert_drive_file</mat-icon>{{fileName}}</div>
					</div>
					</div>
  
					<div class="flex justify-between">
						<div class="p-2">
							<label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
								<span class="text-red-600">*</span> Lead Name :</label>
							<input type="text" formControlName="lead_name"
							
							  class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
							<div
							  *ngIf="leadForm.get('lead_name')?.touched && leadForm.get('lead_name')?.hasError('required')"
							  class="error-msg">
							  Lead Name is Required
							</div>
							<div *ngIf="leadForm.get('lead_name')?.hasError('pattern')" class="error-msg">
							  Invalid format
							</div>
						  </div>

					  <div class="p-2">
						<label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
						  Lead Short Description :</label>
						<input type="text" formControlName="lead_description"
						  
						  class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
						</div>				
  
					  <div class="p-2">
						<label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
						<span class="text-red-600">*</span>   Lead Revenue :</label>
						<input type="text" formControlName="budget"
						 
						  class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
						  
						<div
						  *ngIf="leadForm.get('budget')?.touched && leadForm.get('budget')?.hasError('required')"
						  class="error-msg">
						  Lead Revenue is Required
						</div>
						<div *ngIf="leadForm.get('budget')?.hasError('pattern')" class="error-msg">
						  Invalid format
						</div>
					  </div>
  
					  
  
					</div>
  
					<div class="flex justify-between">
						<div class="p-2">
							<label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
								<span class="text-red-600">*</span> Currency :</label>
							
							  <select formControlName="currency"
							  class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
							  <option value="">Select</option>
							  <option value="usd">USD</option>
							  <option value="inr">INR</option>
							  <option value="aed">AED</option>
							</select>
							<div
							  *ngIf="leadForm.get('currency')?.touched && leadForm.get('currency')?.hasError('required')"
							  class="error-msg">
							  Currency is Required
							</div>
							
						  </div>
					 	<div class="p-2">
						<label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
							<span class="text-red-600">*</span> Forecast Period :</label>
						  <select formControlName="forecast_period"
						  class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
						  <option value="">Select</option>
						  <option value="6">6 Months</option>
						  <option value="7">7 Months</option>
						  <option value="8">8 Months</option>
						  <option value="9">9 Months</option>
						  <option value="10">10 Months</option>
						  <option value="11">11 Months</option>
						  <option value="12">12 Months</option>
						</select>
						<div
							  *ngIf="leadForm.get('forecast_period')?.touched && leadForm.get('forecast_period')?.hasError('required')"
							  class="error-msg">
							  Forecast Period is Required
							</div>
						</div>

					  <div class="p-2">
						<label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
							<span class="text-red-600">*</span> Forecast Probability (Percentage) :</label>
						  <select formControlName="forecast_percentage" (change)="changehotlead()"
						  class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
						  <option value="">Select</option>
						  <option value="10">10%</option>
						  <option value="20">20%</option>
						  <option value="30">30%</option>
						  </select>
						  <div
						  *ngIf="leadForm.get('forecast_percentage')?.touched && leadForm.get('forecast_percentage')?.hasError('required')"
						  class="error-msg">
						  Forecast Probability  is Required
						</div>
						</div>
					</div>
  
					 
					 <div class="flex justify-between">
  
					  <div class="p-2">
						<label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
						  Hot Lead :</label>
						<select formControlName="hot_lead" 
						  class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" [disabled]="true">
						  <option value="" disabled="true">Select</option>
						  <option value="true">Yes</option>
						  <option value="false">No</option>
						</select>
						
					  </div>

					  <div class="p-2">
						<label class="block tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
					   	Cloudvice Sales Rep :</label>
					    <!-- <select formControlName="cloudvice_sales_rep"
						class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
						<option value="" disabled="true">Select</option>
						<option *ngFor="let list of salesReplist" [value]="list.value">{{list.label}}</option>
					  </select> -->
					  <input type="text" formControlName="cloudvice_sales_rep"
						  
						  class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
						<!-- <div *ngIf="leadForm.get('cloudvice_sales_rep')?.touched && leadForm.get('cloudvice_sales_rep')?.hasError('required')"
						  class="error-msg">
						  Cloudvice Sales Rep is required
						</div> -->
						
					  </div>
  
					 

					  <div class="p-2">
						<label class="block tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
							Partner Company Name :</label>
						<!-- <select formControlName="partner_sales_rep"
						class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
						<option value="" disabled="true">Select</option>
						<option *ngFor="let list of partnerSalesReplist" [value]="list.value">{{list.label}}</option>
					  </select>
						 -->
						 <input type="text" formControlName="partner_sales_rep"
						  
						 class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
					  </div>
					</div>
  
				
  
					<div class="flex">

						<!-- <div class="p-2">
							<label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
							  Comments :</label>
							  <textarea formControlName="comments" rows="4" cols="50" 
							  class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm"></textarea>
							
						  </div> -->


						<div class="p-2">
							<label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
							  Referal / Campaign :</label>
							  <select formControlName="referal"
							  class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
							  <option value="" disabled="true">Select</option>
							  <option value="web">Web</option>
							  <option value="email">Email</option>
							</select>
							
						  </div>
						  <div class="p-2">
						<label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
							<span class="text-red-600">*</span> Type :</label>
						<select formControlName="type"
						  class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" (change)="onTypeChange($event)">
						  <option value="" >Select</option>
						  <option [value]="item" *ngFor="let item of typeArr">{{item}}</option>
						  <!-- <option value="Software Development">Software Development</option>
						  <option value="OCI">OCI</option> -->
						</select>
						<div *ngIf="leadForm.get('type')?.touched && leadForm.get('type')?.hasError('required')"
						  class="error-msg">
						  Type is Required
						</div>
						
					  </div>
					  <div class="p-2" *ngIf="productShowFlag">
						<label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
							<span class="text-red-600">*</span> Product :</label>
						<!-- <select formControlName="type_product"
						  class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
						  <option value="" disabled="true">Select</option>
						  <option [value]="item" *ngFor="let item of productArr">{{item}}</option>
						  
						</select> -->
						<mat-select #cat placeholder="Select" formControlName="products_id"   class="multiple-select focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" multiple>
							<mat-option class="select-checkbox">select</mat-option>
							<mat-option class="select-option" *ngFor="let item of productArr" 
							[value]="item.id">{{item.product_name}}</mat-option>
						  </mat-select>
						<div *ngIf="leadForm.get('products_id')?.touched && leadForm.get('products_id')?.hasError('required')"
						  class="error-msg">
						  Product is Required
						</div>
						
					  </div>
						</div>
						<div class="flex w-full">
							<div class="p-2 w-full">
							  <label class="block tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
								Comments :
							  </label>
							  <textarea formControlName="comments" rows="5" 
								class="focus:ring-white w-full rounded-md focus:border-gray-200 border-gray-300 sm:text-sm"></textarea>
							</div>
						  </div>
					</div>
				</div>
			  </div>
			</div>
		  </div>
	  </form>
	  <div class="flex flex-col sm:flex-row items-center mr-5">
		<div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>
		<div class=" text-right md:space-x-3 md:block mt-4 flex flex-col-reverse">
		  <a (click)="close()" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 cursor-pointer">
			Cancel </a>
		  <button
			class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg color"
		   (click)="id? editLead() :onSubmit()"  type="submit">Save</button>
		</div>
	  </div>
	</div>

