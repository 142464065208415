<div class="custom-dialog ">
	<h2 mat-dialog-title class="text-green-700 text-align-center position">{{data.title}}</h2>
	<mat-dialog-content>
		<p>
			{{data.message}}
		</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		
		<button mat-raised-button class="focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800" [mat-dialog-close]="true">Ok</button>
	</mat-dialog-actions>
</div>
