import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-admin-report',
  standalone: true,
  imports: [CommonModule,RouterModule,NgxPaginationModule,MatDividerModule],
  templateUrl: './admin-report.component.html',
  styleUrl: './admin-report.component.scss'
})
export class AdminReportComponent implements OnInit {
  private cid:any
  teamList:any[]=[];
  p: number = 1;
    constructor(private route: ActivatedRoute,private loginservice:LoginService) { }
  
    ngOnInit(): void {
      this.route.paramMap.subscribe(params => {
        this.cid = params.get("id")
      })
      this.listTeam()
    }
    listTeam(){
      this.loginservice.listTeams(this.cid).subscribe((response:any)=>{
      
        this.teamList=response
        
      })
    }
  
  }
  