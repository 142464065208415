import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from '../global';
@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  constructor(private http: HttpClient) { }
  
  getActivities(userid:number){
    return this.http.get(apiurl + 'pipeline/events/'+userid+"/");             
    }

delOneactivity(cid:number){
    return this.http.delete(apiurl+"pipeline/rudevents/"+cid+"/")
  }

  activityReport(){
    return this.http.get(apiurl + 'open-activity-log/list/');             
  }

  hotleadReport(userid:any){
    return this.http.get(apiurl + 'pipeline/hot-leads/list/'+userid+"/");             
  }

  revenueReport(userid:any,filter:any){
    return this.http.get(`${apiurl}pipeline/revenue/list/${userid}/?search=${filter}`);             
  }

  lastupdatedReport(id:any,filter:any){
    const params = filter;
    return this.http.get(`${apiurl}report-log/list/${id}`,{params});             
  }

  addActivity(data:any){
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json'); 
    return this.http.post(apiurl + 'create_activity_log/',data,{headers:headers});
  }

  activityDetails(id:any){
    return this.http.get(apiurl + 'activity/detail/'+id+"/");             
  }

  updateActivity(data:any,id:any){
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json'); 
   return this.http.put(apiurl + 'update_activity_log/'+id+"/",data,{headers:headers});
  }

  addActivities(activityName: any,activityId : any, data:any){
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json'); 
    
   return this.http.post(`${apiurl}create_activity_log_by_id/${activityName}/${activityId}/`,data,{headers:headers});
  }

  getActivityType(id:any,value:any){
    return this.http.get(`${apiurl}activity-type/${id}/${value}/`);             
  }


        
        
}
