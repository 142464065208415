 
<div class=" w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10" style="min-height: 85vh !important; max-height: 600px; overflow-y: auto;">
    <div class="flex flex-col sm:flex-row items-center mb-4">
        <h2 class="font-semibold text-lg mr-auto">contact group</h2>
        <div class="w-full sm:w-auto sm:ml-auto mt-2 sm:mt-0">
            <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
                <a routerLink="/teamleader/add-contactgroup"  class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border border-blue-800 text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Create </a>
                <button 
                class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm 
                shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg " style="background-color: #2a303c;;" (click)="exportContactGroupDataView()">
                    Export</button>
            </div>
        </div>
    </div>
    
    <mat-divider></mat-divider>

    <div class="mt-6 w-full overflow-x-auto">
        <table class="min-w-max w-full table-auto border-collapse border border-gray-300">
            <thead>
                <tr class="bg-gray-200 text-gray-600 text-sm">
                   <th class="py-3 px-6 text-center border border-gray-300">Name</th>
                    <th class="py-3 px-6 text-center border border-gray-300">Members</th>
                    <th class="py-3 px-6 text-center border border-gray-300">Manage</th>
                   
                   </tr>
            </thead>
            <tbody class="text-gray-600 text-sm" *ngIf="data && data.length > 0; else noData">
                <tr *ngFor="let item of data | paginate: { itemsPerPage: 5, currentPage: p }" class="border-b border-gray-200 hover:bg-gray-100">
                    <td class="py-2 px-6 border border-gray-300 text-center">  {{item.name}}</td>
                    
                    
                    <td class="py-2 px-6 border border-gray-300 text-center">
                         <div class="flex justify-center">
                           <span class="bg-red-200 mr-2 text-purple-600 py-1 px-3 rounded-full text-xs"><a routerLink="/teamleader/contact-group-member/{{item.id}}">Add Members</a></span>
                           </div>
                    </td>
                    <td class="py-2 px-6 text-center whitespace-nowrap">
                        <div class="" style="justify-content: center;">
                      
                            <span class="bg-red-200 mr-2 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer"><a (click)="EditContactgroup(item.id)"><i class="fa fa-pencil"></i></a></span>
                            <span class="bg-red-200 mr-2 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer"><a (click)="delContact(item)"><i class="fa fa-trash"></i></a></span>
                        </div>
                    </td>
                </tr>
                <!-- <tr *ngIf="data?.length === 0">
                    <td colspan="3" class="text-center  font-semibold text-lg pt-9">No Data Found</td>
                  </tr> -->
            </tbody>
        </table>
        <ng-template #noData><tr><td colspan="3" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>

       
    </div>

    <pagination-controls 
    class="float-right pagnation"
    (pageChange)="p = $event">
</pagination-controls>
</div>
