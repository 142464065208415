import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { LoginService } from './services/login.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: LoginService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if (!this.authenticationService.loggedIn()) {
        this.router.navigate(['']); // go to login if not authenticated
        return false;
    }
     
      const usergrp= localStorage.getItem('usergroup');
        if (usergrp) {
            // check if route is restricted by role
            // console.log(route?.data?.['role'])
            if (route.data['role']!= usergrp) {
                // role not authorised so redirect to home page
                this.router.navigate(['']);
                return false;
            }
            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}