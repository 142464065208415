<div class="flex flex-nowrap pr-2" cdkDropListGroup style="width:100% !important;height:85vh !important;overflow-y: auto !important; overflow-x: auto !important;">
    <div class="flex" *ngFor="let item of statusArr" >
    <div class="flex-2 mb-5" style="padding-left: 2vh !important; margin-top: 4vh;">
        <div class="text-lg text-left   p-1  border-gray-400 mainDiv"  style="height: auto !important;">
            <div class="mt-1">
                <div class="flex justify-center">
                    <p class="bold-style">{{item?.name}}</p>
                </div>
                <div class="addIcon float-right cursor-pointer" title="Add SubCategory" (click)="addSubStatus(item?.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><path fill="currentColor" d="M11.5 12.5H6v-1h5.5V6h1v5.5H18v1h-5.5V18h-1z"/></svg>
                </div>
            </div>
            <div class="flex w-full mt-2" (click)="addlead('',item?.name,'')">
                <button style="border-radius: 6px !important;background-color: #e5e7eb;"    class="flex justify-center w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M11.5 12.5H6v-1h5.5V6h1v5.5H18v1h-5.5V18h-1z"/></svg>
                    <span class=" task">Add task</span>
                </button>
            </div>
           
            <div   cdkDropList [cdkDropListData]="salestask"   [cdkDropListConnectedTo]="getConnectedLists(item)" (cdkDropListDropped)="onDrop($event, item)"  [id]="'drop-' + item?.id + '-'"  class="drop-container"  
             >
             <div  cdkDrag [cdkDragData]="items" class="cust-style"  *ngFor="let items of salestask">
                    <app-cust-card cdkDragHandle (ontaskcomplete)="reloadboard($event)"   [salestask]="items"   *ngIf="items?.status == item?.name && items?.task_stage == '' || items?.status == item?.name && items?.task_stage == null" [editStatus]="true"> </app-cust-card></div>
            </div>
           
    </div>
   
</div>
<div #boxContainer class="flex-2 "  style="padding-left: 2vh !important;  margin-top: 4vh;"  *ngIf="subStatusAddId == item?.id  ">
    <div  class="text-lg text-left  p-1  border-gray-400 mainDiv" style="height: auto !important;">
        <div class="flex justify-center mt-2">
            <div class="flex" >
                 <input  [formControl]="subStatus" id="name" class="rounded-md h-8 flex-grow w-64" style="display: flex; text-align:center !important;"   name="boardname" #boardname type="text" placeholder="Enter Column Name" />
            </div>
        </div>
  <hr  class="mt-5">
  <div  class="flex justify-center items-center space-x-2 mt-2 mb-2">
    <div class="flex cursor-pointer" (click)="createSubStatus(item?.id)">
      <button
        style="border-radius: 6px !important; background-color: #e5e7eb;"
        class="flex justify-center w-14 h-8 task"
      >
        Save
      </button>
    </div>
    <div class="flex cursor-pointer" (click)="close(boxContainer)">
      <button
        style="border-radius: 6px !important; background-color: #e5e7eb;"
        class="flex justify-center w-14 h-8 task"
      >
        Cancel
      </button>
    </div>
  </div>
  
</div>
</div>
<div class="flex" *ngFor="let subItem of substatusArr">
<ng-container *ngIf="subItem?.task_status == item?.name">
<div class="flex-2 mb-5"  style="padding-left: 2vh !important; margin-top: 4vh !important;" *ngFor="let column of board?.columns">
    <div class="text-lg text-left p-1 border-gray-400 mainDiv" style="height: auto !important;">
        <div class="mt-1">
            <div class="flex justify-center">
                <p class="bold-style">{{subItem?.sub_status}}</p>
            </div>
            <div class="addIcon float-right cursor-pointer" title="Remove SubCategory" >
                <svg (click)="RemoveSubStatus(subItem?.id)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"/></svg>    </div>
        </div>
        <div class="flex w-full mt-2" (click)="addlead('',item?.name,subItem?.sub_status)">
            <button style="border-radius: 6px !important;background-color: #e5e7eb;"    class="flex justify-center w-full">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M11.5 12.5H6v-1h5.5V6h1v5.5H18v1h-5.5V18h-1z"/></svg>
                <span class=" task">Add task</span>
            </button>
        </div>
       
            <div cdkDropList [cdkDropListData]="salestask"   [cdkDropListConnectedTo]="getConnectedLists(item)" (cdkDropListDropped)="onDrop($event, subItem)"  [id]="'drop-' + item?.id + '-'"  class="drop-container"  
             >
            <div *ngFor="let items of salestask" cdkDrag [cdkDragData]="items" class="cust-style" >
                <app-cust-card cdkDragHandle (ontaskcomplete)="reloadboard($event)"  [salestask]="items"      *ngIf="items?.status == item?.name  && items?.task_stage == subItem?.sub_status" [editStatus]="true" > </app-cust-card></div>
        </div>
        </div>
</div>
</ng-container>
</div>
</div>
</div>

 
