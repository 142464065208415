<div class="max-w w-full h-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 mt-5 mb-5">
    <div class="grid  gap-8 grid-cols-1 ">
        <form class="" [formGroup]="targetForm" (ngSubmit)="onSubmit()">
        <div class="flex flex-col ">
                <div class="flex flex-col sm:flex-row items-center mb-2">
                    <h2 class="font-semibold text-lg mr-auto">Target / New</h2>
                    <div class="w-full sm:w-auto sm:ml-auto sm:mt-0"></div>

                    <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
                      <a routerLink="/admin/target" class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                        <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-green-500">Save</button>
                    </div>
                </div>
                <mat-divider></mat-divider>
             
                <div class="mt-3 bg-white rounded border border-gray-100  px-20">
                    <div class="form mt-3">
                      <!-- <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                               Name</label>
                           <input type="text" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" class="relative w-full bg-white text-sm text-gray-700 placeholder-gray-400 border-0  border-b-2 border-gray-400" placeholder="eg. Activity type name"/>
                           <div *ngIf="submitted && f.name.errors" class="invalid-feedback text-sm">
                            <div *ngIf="f.name.errors.required">Please fill out this field.</div>
                        </div>
                        </div>
                      </div> -->
                     
                      <div class="flex flex-wrap -mx-3 mb-6">
                       
                     
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                           Team lead
                            </label>
                            <select   formControlName="role" [ngClass]="{ 'is-invalid': submitted && f['role'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                              <option value="">Select</option>
                              <option value="tesmlead">Team Lead</option>
                              <option value="executive">Executive</option>
                              
                          </select>
                          <div *ngIf="submitted && f['role'].errors" class="invalid-feedback text-sm">
                            <div *ngIf="f['role'].errors['required']">Please fill out this field.</div>
                        </div>
                          </div>
                          <div class="w-full md:w-1/2 px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                              Duration</label>
                          <select formControlName="duration" [ngClass]="{ 'is-invalid': submitted && f['duration'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " >

                            <option >Select</option>
                              <option value="monthly">Monthly</option>
                              <option value="yearly">Yearly</option>
                              
                          </select>
                           <div *ngIf="submitted && f['duration'].errors" class="invalid-feedback text-sm">
                           <div *ngIf="f['duration'].errors['required']">Please fill out this field.</div>
                       </div>
                          </div>
                          <div class="w-full md:w-1/2 px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                              Target(₹)</label>
                          <input type="text" formControlName="amount" [ngClass]="{ 'is-invalid': submitted && f['amount'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " placeholder="Expected Target"/>
                          <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback text-sm">
                           <div *ngIf="f['amount'].errors['required']">Please fill out this field.</div>
                       </div>
                          </div>
                        </div>
                       
                               
                    </div>      
                    
                  
                </div>
        </div>
        </form>
    </div>
</div>

