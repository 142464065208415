<div class="max-w w-full space-y-8  p-10 bg-white rounded-xl shadow-lg z-10 mb-15">
    <div class="flex flex-col sm:flex-row items-center mb-2">
        <h2 class="font-semibold uppercase text-lg mr-auto">Target</h2>
        <div class="w-full sm:w-auto sm:ml-auto mt-2 sm:mt-0"></div>

        <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
            <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
                <a routerLink="/teamleader/add-target"  class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Create </a>
                <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-green-500">Export</button>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class='overflow-x-auto overflow-y-auto  w-full'>
        <table class="min-w-max w-full table-auto">
            <thead>
                <tr class="bg-gray-200 text-gray-600 text-sm">
                    <th class="py-3 px-6 text-left">Teamlead</th>
                    <th class="py-3 px-6 text-left">Target(₹)</th>
                    <th class="py-3 px-6 text-left">Manage</th>
                   
                </tr>
            </thead>
         
            <tbody class="text-gray-600 text-sm font-light">
                <tr  *ngFor='let item of data  | paginate: { itemsPerPage: 10, currentPage: p }' class="border-b border-gray-200 hover:bg-gray-100" >
                    <td class="py-3 px-6 text-left whitespace-nowrap">
                        <div class="flex items-center">
                      
                            <span class="text-base">{{item.name}}</span>
                        </div>
                    </td>
                    <td class="py-3 px-6 text-left">
                        <div class="flex items-center">
                           
                            <span class="text-base">{{item.defaultsummary}}</span>
                        </div>
                    </td>
                  
                </tr>
            
            </tbody>
        </table>
        <pagination-controls (pageChange)="p = $event" class="float-right mt-5"></pagination-controls>
    </div>        
    
</div>