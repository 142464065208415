
<div class="row mb-3">
	<label *ngIf="!isEdit" style="justify-content: center !important;display: flex !important;"><b>Add Task</b></label>
	<label *ngIf="isEdit" style="justify-content: center !important;display: flex !important;"><b>Edit Task</b></label>
	<div class="float-right cursor-pointer " style="margin-top:-5vh !important" (click)="discard(column)">
		<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 21 21"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="m7.5 7.5l6 6m0-6l-6 6"/></svg>
	</div>
</div>
<form class="" [formGroup]="taskForm"  style="height: auto !important; overflow-y: auto ; width: 90vh !important; padding-left: 3vh;padding-right: 3vh !important;" >
	<div class="flex flex-wrap px-2 pb-2">
	<div class="w-1/2 px-1">
        <div class="pb-1 pl-1">
            <label for="email" class="block text-xs font-medium text-gray-700">
                Task Name
            </label>
        </div>
        <div class="flex-grow">
            <input type="text" #name formControlName="name" 
                   [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" 
                   class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300" 
                   placeholder="Name">
            <div *ngIf="submitted && f['name'].errors" class="invalid-feedback text-sm">
                <div *ngIf="f['name'].errors['required']">Please fill out this field.</div>
            </div>
        </div>
    </div>
	<div class="w-1/2 px-1">
		<div class="pb-1 pl-1">
			<label for="taskname" class="block text-xs font-medium text-gray-700">
				Organization/ Contact
			</label>
		</div>
		<select #taskname  formControlName="contact_id" [ngClass]="{ 'is-invalid': submitted && f['contact_id'].errors }" class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300" (change)="onContactChange($event)">
			<option [value]= null  selected disabled >Select</option>
			<option *ngFor="let list of contact" value="{{list.id}}">{{list.first_name}}</option>
		</select>
		<div *ngIf="submitted && f['contact_id'].errors" class="invalid-feedback text-sm">
			<div *ngIf="f['contact_id'].errors['required']">Please fill out this field.</div>
		</div>
	</div>
</div>
<div class="flex flex-wrap px-2 pb-2">
<div class="w-1/2 px-1">
	<div class="pb-1 pl-1">
		<label for="email" class="block text-xs font-medium text-gray-700">
			Email
		</label>
	</div>
	<div class="flex-grow ">
		<input type="text" #email formControlName="email"  name="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300" placeholder="">
		<div *ngIf="submitted && f['email'].errors" class="invalid-feedback text-sm">
			<div *ngIf="f['email'].errors['required']">Please fill out this field.</div>
			<div *ngIf="f['email'].errors['email']">Email must be a valid email address</div>
		</div>
	</div>
</div>

<div class="w-1/2 px-1">
	<div class="pb-1 pl-1">
		<label for="AppName" class="block text-xs font-medium text-gray-700">
			Phone
		</label>
	</div>
	<div class="flex-grow ">
		<input type="text" #phone formControlName="phone" name="phone" [ngClass]="{ 'is-invalid': submitted && f['phone'].errors }" class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300" placeholder="">

		<div *ngIf="submitted && f['phone'].errors" class="invalid-feedback text-sm">
			<div *ngIf="f['phone'].errors['required']">Please fill out this field.</div>
			<div *ngIf="f['phone'].errors['pattern']">Mobile must be a valid mobile address</div>
		</div>	</div>
</div>
</div>
<div class="flex flex-wrap px-2 pb-2">
<div class="w-1/2 px-1">
	<div class="pb-1 pl-1">
		<label for="AppName" class="block text-xs font-medium text-gray-700">
			Expecting Revenue
		</label>
	</div>
	<div class="flex-grow ">
		<input type="text" #budget formControlName="budget"  [ngClass]="{ 'is-invalid': submitted && f['budget'].errors }" class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300" placeholder="₹ 0.00">
		<div *ngIf="submitted && f['budget']?.errors" class="invalid-feedback text-sm">
			<div *ngIf="f['budget']?.errors?.['required']">Please fill out the budget.</div>

		</div>
	</div>
</div>
<div class="w-1/2 px-1">
	<div class="pb-1 pl-1">
		<label for="email" class="block text-xs font-medium text-gray-700">
			Category
		</label>
	</div>
	<div class="flex-grow ">

		<select formControlName="category"  #cat  [ngClass]="{ 'is-invalid': submitted && f['category'].errors }" (change)="Onchangecat(cat.value)"class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300">
			<option [value]= null  selected disabled>Select</option>
			<option value="service">Service</option>
			<option value="product">Product</option>
			
		</select>
		<div *ngIf="submitted && f['category'].errors" class="invalid-feedback text-sm">
			<div *ngIf="f['category'].errors['required']">Please fill out this field.</div>
		</div>
	</div>
</div>
</div>
<div class="flex flex-wrap px-2 pb-2">
	<div class="w-full px-1" *ngIf="isCatpro && !isCatser">
		<div class="pb-1 pl-1">
			<label for="email" class="block text-xs font-medium text-gray-700">
				Product
			</label>
		</div>
		<div class="flex-grow ">
			<!-- <select multiple formControlName="product"  #cat  (change)="Onchangecat(cat.value)"  class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300" >
				<option [value]= null  selected disabled>Select</option>
				<option *ngFor="let list of productlist" value="{{list?.id}}">{{list?.product_name}}</option>
				
			</select> -->
			<!-- <mat-form-field > -->
				<mat-select #cat placeholder="Select" formControlName="product"    (change)="Onchangecat(cat.value)"   class="multiple-select focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300" multiple>
				  <mat-option class="select-option" *ngFor="let item of productlist" 
				  [value]="item.id">{{item.product_name}}</mat-option>
				</mat-select>
			  <!-- </mat-form-field> -->
			  
		</div>
	</div>
</div>
	<div class="flex flex-col px-2 pb-2" *ngIf="isCatser && !isCatpro">
		<div class="pb-1 pl-1">
			<label for="email" class="block text-xs font-medium text-gray-700">
				Service title
			</label>
		</div>
		<div class="flex-grow ">
			<input type="text" #name formControlName="servicetitle" [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300" >
                                    <div *ngIf="submitted && f['servicetitle'].errors" class="invalid-feedback text-sm">
                                     <div *ngIf="f['servicetitle'].errors['required']">Please fill out this field.</div>
                                 </div>
			</div>
	</div>

	
	<div class="flex flex-col px-2 pb-2" *ngIf="isCatser && !isCatpro">
		<div class="pb-1 pl-1">
			<label for="email" class="block text-xs font-medium text-gray-700">
				Service description
			</label>
		</div>
		<div class="flex-grow ">
			<textarea formControlName="servicedes"  class="relative outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline" ></textarea>
			
		</div>
	</div>
	
	<div class="flex justify-center">
		<div *ngIf="!isEdit"  class="flex border rounded-md mt-2 border-blue-800 bg-blue-600 hover:bg-blue-400 content-center mx-1" style="width: 15vh !important;display: flex;justify-content: center;">
			<button style="width: 15vh !important;" class="px-2 py-1 text-xs text-gray-100" type="submit" (click)="onSubmit()">Add</button>
		</div>
		<div  *ngIf="isEdit" class="flex border rounded-md mt-2 border-blue-800 bg-blue-600 hover:bg-blue-400 content-center mx-1" style="width: 15vh !important;display: flex;justify-content: center;">
			<button style="width: 15vh !important;" class="px-2 py-1 text-xs text-gray-100"type="button" (click)="edittask(task.id)">Save</button>
		</div>
		<!-- <div class="flex border rounded-md mt-2 border-gray-800 bg-gray-600 hover:bg-gray-400 content-center mx-1" style="display: flex;justify-content: center;">
			<button style="width: 15vh !important;" class="px-2 py-1 text-xs text-gray-100" type="button" (click)="discard(column)" >Discard</button>
		</div> -->
	</div><br>
</form>