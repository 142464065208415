<div 
  class="max-w h-content w-11/12 size space-y-8 p-10 bg-white rounded-xl shadow-lg z-10  mb-5" 
  style="margin-left: 6vh  !important;"
>
  <div class="grid gap-8 grid-cols-1">
    <div class="flex flex-col" style="padding-top: 2vh !important; padding-bottom: 3vh !important;">
      <div class="flex flex-col sm:flex-row items-center mb-2">
        <h2 class="font-semibold text-lg mr-auto " style="margin-left: 5vh !important;">Configuration</h2>
        <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>
        <div class="text-right md:space-x-3 md:block flex flex-col-reverse" style="margin-right: 5vh !important;">
          <a 
                *ngIf="!isEdit" 
                routerLink="/admin/add-configuration/" 
                class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-white-600 rounded-full hover:shadow-lg color" 
              >
                Add
              </a>&nbsp;&nbsp;
              <a 
            *ngIf="isEdit" 
            routerLink="/admin/add-configuration/{{list[0].id}}/" 
            class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border  rounded-full hover:shadow-lg color"
          >
            Edit
          </a>
        </div>
      </div>      
      <br>
      <mat-divider  style="margin-left: 4vh !important;margin-right: 4vh !important;"></mat-divider>
      <div class="mt-2 bg-white rounded gray-100 px-20">
        <div class="form mt-3">
          <div class="flex w-full justify-left">
            <div class="w-full top" *ngIf="isEdit">
                <div class="row flex py-5">
                    <div class="flex col-lg-4" style="padding-top: 2.5vh !important;">
                      <img class="w-32 h-32" src="{{list[0].logo}}" alt="John Doe">
                    </div>
                    <div class="flex col-lg-4" style="padding-left:10vh !important;">
                        <table class="text-xs">
                          <tbody>
                            <tr>
                              <td class="px-2 py-2 text-gray-500 font-semibold">Name</td>
                              <td class="px-2 py-2">{{list[0].name}}</td>
                            </tr>
                            <tr>
                                <td class="px-2 py-2 text-gray-500 font-semibold">Created By</td>
                                <td class="px-2 py-2">{{list[0].creator.first_name}}</td>
                                    </tr>
                                
                                <tr>
                                    <td class="px-2 py-2 text-gray-500 font-semibold">Phone</td>
                                    <td class="px-2 py-2">{{list[0].phone}}</td>
                                </tr>
                
                                <tr>
                                    <td class="px-2 py-2 text-gray-500 font-semibold">Email</td>
                                    <td class="px-2 py-2">{{list[0].email}}</td>
                                </tr>
                                <tr>
                                    <td class="px-2 py-2 text-gray-500 font-semibold">Address</td>
                                    <td class="px-2 py-2">{{list[0].address}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="flex col-lg-4" style="padding-left: 10vh !important;" >
                        <table class="text-xs">
                            <tbody>
                            <tr *ngIf="list[0].city!=''">
                                <td class="px-2 py-2 text-gray-500 font-semibold">Bank name</td>
                                <td class="px-2 py-2">{{list[0].bank_name}}</td>
                            </tr>
                            <tr *ngIf="list[0].state!=''">
                                <td class="px-2 py-2 text-gray-500 font-semibold">Account Name</td>
                                <td class="px-2 py-2">{{list[0].account_name}}</td>
                            </tr>
                            <tr *ngIf="list[0].country!=''">
                                <td class="px-2 py-2 text-gray-500 font-semibold">Account Number</td>
                                <td class="px-2 py-2">{{list[0].account_number}}</td>
                            </tr>
                            <tr *ngIf="list[0].zipcode!=''">
                                <td class="px-2 py-2 text-gray-500 font-semibold">IFSC Code</td>
                                <td class="px-2 py-2">{{list[0].ifsc_code}}</td>
                            </tr>
                            <tr *ngIf="list[0].zipcode!=''">
                                <td class="px-2 py-2 text-gray-500 font-semibold">GST No</td>
                                <td class="px-2 py-2">{{list[0].gst_no}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
