            <div class="flex flex-col">
       
        <div class="grid grid-cols-3 gap-4 mt-4">
        <div class="bg-white border-solid border-2 px-2 py-2 border-light-blue-500 h-48 w-100 shadow-xl rounded-xl " *ngFor="let list of organizationlist">
          
            <img src="../assets/img/image.png"
                class="w-1/1 lg:w-1/3  rounded-xl">
           
            <div class="ml-3">
               
                <p class="text-sm text-black">{{list?.name}}</p>
                <p class="text-gray-300  text-sm">{{list?.email}}</p>
                
            <div class="flex mt-3 ">
              
            <a  [routerLink]="['/executive/customer-edit',list.id]" class="text-gray-500   menu-btn p-0 m-0 hover:text-gray-900 focus:text-gray-900 focus:outline-none transition-all ease-in-out duration-300" title="
            Edit">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24"><path fill="currentColor" d="m21.561 5.318l-2.879-2.879A1.495 1.495 0 0 0 17.621 2c-.385 0-.768.146-1.061.439L13 6H4a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1v-9l3.561-3.561c.293-.293.439-.677.439-1.061s-.146-.767-.439-1.06zM11.5 14.672L9.328 12.5l6.293-6.293l2.172 2.172l-6.293 6.293zm-2.561-1.339l1.756 1.728L9 15l-.061-1.667zM16 19H5V8h6l-3.18 3.18c-.293.293-.478.812-.629 1.289c-.16.5-.191 1.056-.191 1.47V17h3.061c.414 0 1.108-.1 1.571-.29c.464-.19.896-.347 1.188-.64L16 13v6zm2.5-11.328L16.328 5.5l1.293-1.293l2.171 2.172L18.5 7.672z"/></svg>           
            </a>
    
              <a (click)="deleteOrganization(list)" class="text-gray-500 pl-4  menu-btn p-0 m-0 hover:text-gray-900 focus:text-gray-900 focus:outline-none transition-all ease-in-out duration-300 icon-pointer" title="
              Delete">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path   stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>               
        </a>
    
       
       
        <a  [routerLink]="['/executive/add-edit-lead',list.id,'']" [matBadge]="list?.lead_open_count" matBadgeColor="warn" class="text-gray-500 pl-4  menu-btn p-0 m-0 hover:text-gray-900 focus:text-gray-900 focus:outline-none transition-all ease-in-out duration-300" title="
          leads">
        <svg xmlns="http://www.w3.org/2000/svg"class="h-6 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12.522,10.4l-3.559,3.562c-0.172,0.173-0.451,0.176-0.625,0c-0.173-0.173-0.173-0.451,0-0.624l3.248-3.25L8.161,6.662c-0.173-0.173-0.173-0.452,0-0.624c0.172-0.175,0.451-0.175,0.624,0l3.738,3.736C12.695,9.947,12.695,10.228,12.522,10.4 M18.406,10c0,4.644-3.764,8.406-8.406,8.406c-4.644,0-8.406-3.763-8.406-8.406S5.356,1.594,10,1.594C14.643,1.594,18.406,5.356,18.406,10M17.521,10c0-4.148-3.374-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.147,17.521,17.521,14.147,17.521,10"></path>
        </svg>               
        </a>
    
        <a [routerLink]="['/executive/customer-contact',list.id]" [matBadge]="list?.contact_count" matBadgeColor="warn" class="text-gray-500 pl-4  menu-btn p-0 m-0 hover:text-gray-900 focus:text-gray-900 focus:outline-none transition-all ease-in-out duration-300" title="
        Contact List">
        <svg xmlns="http://www.w3.org/2000/svg"  class="h-5 w-5" viewBox="0 0 24 24"><g transform="translate(24 0) scale(-1 1)"><path fill="currentColor" d="M19 19H5V5h14m0-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-2.5 13.25c0-1.5-3-2.25-4.5-2.25s-4.5.75-4.5 2.25V17h9M12 12.25A2.25 2.25 0 0 0 14.25 10A2.25 2.25 0 0 0 12 7.75A2.25 2.25 0 0 0 9.75 10A2.25 2.25 0 0 0 12 12.25Z"/></g></svg></a>
        
    
    
    </div>
            </div>
        </div>
        
        
        </div>
        <div class="flex justify-center pt-9" *ngIf="organizationlist.length === 0">
            <h1 class="font-semibold text-lg">No Data Found</h1>
        </div>
        </div>
       
