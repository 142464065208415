import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-team-members-report',
  standalone: true,
  imports: [NgxPaginationModule,CommonModule,RouterModule,MatDividerModule],
  templateUrl: './team-members-report.component.html',
  styleUrl: './team-members-report.component.scss'
})
export class TeamMembersReportComponent  implements OnInit {
   cid:any
  members:any[]=[]
  creatid:any
  p:number=1;
  id: any;

  constructor(private route: ActivatedRoute,private loginservice:LoginService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params =>{
      this.cid = params.get("id")
      this.id= params.get('cid')
    })
    this.listSingTeamMems(this.id)
  }

  listSingTeamMems(cid:number){
    this.loginservice.singleTeamMem(cid).subscribe((response:any)=>{
      this.members=response
      
      
    })
  }

}
