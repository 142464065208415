import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AddContactgroupService } from '../add-contactgroups/add-contactgroups.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-add-contact-group-member',
  standalone: true,
  imports: [CommonModule,RouterModule,ReactiveFormsModule,MatDividerModule],
  providers:[LoginService],
  templateUrl: './add-contact-group-member.component.html',
  styleUrl: './add-contact-group-member.component.scss'
})
export class AddContactGroupMemberComponent implements OnInit {
  isDisabled: boolean = false;
  public contactgroup_list:any[]=[];
  public user: any;
  submitted = false;
  users:any=[];
  public clist:any[]=[]
  cgroupMForm!: FormGroup;
  isEdit: boolean=false;
  cid:any
  resp:any=[]
  gid:any
  contacts_g: any[]=[];
  constructor(private formBuilder: FormBuilder,
    private c_groupservice: AddContactgroupService,
    private router: Router,private loginservice: LoginService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    
    this.cgroupMForm = this.formBuilder.group({
      contact: ['', Validators.required],
      contact_group: ['', Validators.required],
     
     
    }, );
    this.user = this.loginservice.getUserdata()
    this.getcontactgroup()
    this.getcontactgrouplist()
    this.route.paramMap.subscribe(params => {
      this.cid = params.get('id')
      this.gid= params.get('gid')
      if(this.cid != null){
        
        this.cgroupMForm.controls['contact_group'].setValue(this.gid)
        
    
  }
  
    });
  
}
getcontactgrouplist()
{
  this.c_groupservice.getContactmember(this.user.data.id).subscribe((result: any) => {
      this.contactgroup_list = result;
      console.log('resultis',this.contactgroup_list);
});
}

getcontactgroup(){

  this.c_groupservice.getContactgroup(this.user.data.id).subscribe(
    (result: any) => this.contacts_g = result
    )
  }
// convenience getter for easy access to form fields
get f() { return this.cgroupMForm.controls; }

onSubmit() {
 
  let data_val={
    "cid":this.cgroupMForm.value.contact,
    "cgmid":this.cid

  }

    this.c_groupservice.addgroupmember(data_val).subscribe((reponse:any)=>{
  //  console.log(reponse)
  this.router.navigate(['teamleader/contact-group-member/',this.cgroupMForm.value.contact_group]);
   });
}






onReset() {
    this.submitted = false;
    this.cgroupMForm.reset();
}
 
}
