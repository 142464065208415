<div class=" w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10" style="min-height: 85vh !important; max-height: 600px; overflow-y: auto;">
    <div class="flex flex-col sm:flex-row items-center mb-4">
        <h2 class="font-semibold text-lg mr-auto">Activity</h2>
        <div class="w-full sm:w-auto sm:ml-auto mt-2 sm:mt-0">
             <div class="text-right md:space-x-3 flex flex-col-reverse sm:flex-row sm:block">
            <a routerLink="/teamleader/schedule" class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 border-blue-800">Activity</a>
            <a routerLink="/teamleader/scheduleemail" class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 border-blue-800" title="Schedule Email">Email</a>
        </div>
        </div>
    </div>
    
    <mat-divider></mat-divider>

    <div class="mt-6 w-full overflow-x-auto">
        <table class="min-w-max w-full table-auto border-collapse border border-gray-300">
            <thead>
                <tr class="bg-gray-200 text-gray-600 text-sm">
                    <th class="py-3 px-6 border border-gray-300 text-center">Name</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Activity</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Assigned To</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Date and Time</th>
                   <th class="py-3 px-6 border border-gray-300 text-center"> Duration</th>
                    <th class="py-3 px-6 border border-gray-300  text-center">Manage</th>
                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm" *ngIf="data && data.length > 0; else noData">
                <tr *ngFor="let item of data | paginate: { itemsPerPage: 5, currentPage: p }" class="border-b border-gray-200 hover:bg-gray-100">
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">{{item.eventname}}</td>
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">{{item.activity_type }}</td>
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">{{item.task.name }}</td>
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">{{item.date_and_time}}</td>
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">{{item.hour}}hr:{{item.minute}} minutes</td>

                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">
                         <div class="flex justify-center">
                            <span class="bg-red-200 mr-2 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer" *ngIf="item.activity_type === 'Emails'">
                                <a (click)="EditEmailactivity(item.id)"><i class="fa fa-pencil"></i></a>
                            </span>
                            <span class="bg-red-200 mr-2 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer" *ngIf="item.activity_type !== 'Emails'">
                                <a (click)="Editactivity(item.id)"><i class="fa fa-pencil"></i></a>
                            </span>
                            <span class="bg-red-200 mr-2 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer">
                                <a (click)="deleteactivity(item)"><i class="fa fa-trash"></i></a>
                            </span>
                        </div>
                    </td>
                </tr>
                <!-- <tr *ngIf="data?.length === 0">
                    <td colspan="6" class="text-center  font-semibold text-lg pt-9">No Data Found</td>
                  </tr> -->
            </tbody>
        </table>
        <ng-template #noData><tr><td colspan="6" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
       
    </div>
    <pagination-controls (pageChange)="p = $event" class="float-right pagination mt-1"></pagination-controls>

</div>
