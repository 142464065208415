import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoginService } from '../../services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { MainService } from '../../services/main-service';
import { SuccessComponent } from '../../success/success.component';
import { BrowserModule } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
declare var XLSX: any;

@Component({
  selector: 'app-team',
  standalone: true,
  imports: [CommonModule,NgxPaginationModule,MatDivider,RouterModule,RouterOutlet],
  templateUrl: './team.component.html',
  styleUrl: './team.component.scss'
})
export class TeamComponent implements OnInit {
  public user:any
  TeamsList:any[]=[]
  p: number = 1;
  teams: any=[];
  constructor(private loginservice:LoginService,private dialog: MatDialog, private router: Router,private mservice:MainService,private toaster:ToastrService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
  this.listTeam()
  
  }
  listTeam(){
    this.loginservice.listTeam(this.user.data.id).subscribe((response:any)=>{
     this.TeamsList=response
    })

  }
  
  exportTeams(){
    this.loginservice.exportTeams(this.user.data.id).subscribe((response:any)=>{
      this.teams=response;

      const worksheet = XLSX.utils.json_to_sheet(response.message);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, 'table-data.xlsx');
    
    if(response?.status==false){
      this.toaster.error(response.message,'error')
    }

})}
  
removeteam(id:any,res:any)
  {

    this.mservice.removeteammember(id,res).subscribe((response:any)=>{
      this.listTeam()
    })
  }
  addteam(id:any,res:any)
  {
    
    this.mservice.removeteammember(id,res).subscribe((response:any)=>{
      this.listTeam()
    })
  }
  delete(id:any){
    this.loginservice.DeleteTeam(id).subscribe((response:any)=>{
      
      
       const confirmDialog = this.dialog.open(SuccessComponent, {

				data: {
				  title: 'Success',
				  message: 'Delete Succesfully!'
				}
			  })
			  confirmDialog.afterClosed().subscribe(result => {
          if (result == true) {
            this.ngOnInit()
          }


		    });
       
       
     })
 
  }

}

