import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import {NgxPaginationModule} from 'ngx-pagination';
@Component({
  selector: 'app-target',
  standalone: true,
  imports: [CommonModule,NgxPaginationModule,MatDivider],
  templateUrl: './target.component.html',
  styleUrl: './target.component.scss'
})
export class TargetComponent {
data:any;
p: number = 1;
}
