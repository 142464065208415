//local
//export var apiurl='http://192.168.1.68:8002/';
//export var imgurl = 'http://192.168.1.68:8002';

//test
// export var apiurl = 'http://65.109.99.178:8020/';
// export var imgurl = 'http://65.109.99.178:8020';

//live
export var apiurl = 'https://crmbackend.senscript.ai/';
export var imgurl = 'https://crmbackend.senscript.ai';

//dev
// export var apiurl = 'http://65.109.99.178:9004/';
// export var imgurl = 'http://65.109.99.178:9004';



