import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from '../global';
@Injectable({
  providedIn: 'root'
})
export class MainService {


  
  constructor(private http: HttpClient) { }
  
  getTarget(userid:number){
 
  return this.http.get(apiurl + 'configuration/'+userid+"/");
               
  }
  getRole(userid:number){
 
    return this.http.get(apiurl + 'configuration/'+userid+"/");
                 
    }
    userexist(res:any){
 
      return this.http.get(apiurl + 'user/userexists/'+res+"/");
                   
      }
  addTargets(result: any){
   
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');   
		return this.http.post(apiurl + 'target/targetassign/',result,{headers:headers});
      
    }
    addConfig(result: any){
   
      const headers = new HttpHeaders();
      headers.append('Content-Type','application/json');   
      return this.http.post(apiurl + 'pipeline/configurations/0/admin/',result,{headers:headers});
        
      }
      configurationlist(id:any)
      {
        return this.http.get(apiurl + 'pipeline/configurations/'+id+"/"+"admin"+"/"); 
      }
      getOneconfig(id:any)
      {
        return this.http.get(apiurl + 'pipeline/rud_configurations/'+id+"/"); 
      }
      getperformance(id:any)
      {
        return this.http.get(apiurl + 'overallteamperformance/'+id+"/"); 
      }
      removemember(id:any)
      {
        return this.http.get(apiurl + 'user/removemember/'+id+"/"); 
      }
      removeteammember(id:any,res:any)
      {
        return this.http.get(apiurl + 'user/remove_allteammembers/'+id+"/"); 
      }
      editConfig(data:any,cid:number){
        //console.log(data);
        const headers = new HttpHeaders();
        headers.append('Content-Type','application/json');
        return this.http.put(apiurl+"pipeline/rud_configurations/"+cid+"/",data,{headers:headers})
      }
    listrRole(id:any){
      return this.http.get(apiurl + 'user/getrole/'+id);
      
    }
    listLeads(id:any,mid:any){
      return this.http.get(apiurl + 'pipeline/tasklistby_tmid/'+id+"/"+mid+"/");
    }
    
 
  
}
