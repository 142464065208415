<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/ng5-slider@1.0.0/bundles/ng5-slider.min.css">
<script src="https://cdn.jsdelivr.net/npm/ng5-slider@1.0.0/bundles/ng5-slider.min.js"></script>

 
<ul>
    <li id="dashboard"
  class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer"
  [class.active]="activeLink === 'dashboard'"
  routerLink="/executive/"
  (click)="setActiveLink('dashboard')">
  <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 icons" viewBox="0 0 24 24">
    <path fill="currentColor" d="M4 13h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1m-1 7a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1zm10 0a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1zm1-10h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1"/>
  </svg>
  <a class="text-white">Kanban Board</a>
</li>
 
   
    <li
  id="Activities"
  class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer"
  [class.active]="activeLink === 'Activities'"
  (click)="setActiveLink('Activities')"
  routerLink="/executive/activities"
>
<svg xmlns="
http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 icons"
 viewBox="0 0 24 24"><path fill="currentColor" d="M2 2h20v9h-4.554l-2.021 3.233l-5.865-7.82L5.546 11H2z"/><path fill="currentColor" d="M2 13v9h20v-9h-3.446l-2.979 4.767l-6.135-8.18L6.454 13z"/></svg>
  <a class="text-white">Activity</a>
</li>
 
<li
  id="Quotation"
  class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer"
  [class.active]="activeLink === 'Quotation'"
  (click)="setActiveLink('Quotation')"
  routerLink="/executive/quotation"
>
  <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 icons" viewBox="0 0 24 24">
    <path fill="currentColor" d="M2 21V9h5.5v12zm7.25 0V3h5.5v18zm7.25 0V11H22v10z" />
  </svg>
  <a class="text-white">Quotation</a>
</li>
 
    
    <li
    id="Customer"
    class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer"
    [class.active]="activeLink === 'Customer'"
    routerLink="/executive/customerlist"
    (click)="setActiveLink('Customer')"
  >
    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 icons" viewBox="0 0 14 14">
      <path fill="currentColor" fill-rule="evenodd" d="M12.918 1.623a1.623 1.623 0 1 1-3.246 0a1.623 1.623 0 0 1 3.246 0m1.024 5.037a.75.75 0 0 1-.732.586H7.098a.75.75 0 1 1 0-1.5h1.596A2.706 2.706 0 0 1 14 6.493a.27.27 0 0 1-.058.167M1.359 3.324a1.811 1.811 0 1 0 3.622 0a1.811 1.811 0 0 0-3.622 0M0 9.019a3.17 3.17 0 1 1 6.34 0v.858a.5.5 0 0 1-.5.5h-.86l-.398 3.185a.5.5 0 0 1-.496.438H2.253a.5.5 0 0 1-.496-.438l-.399-3.185H.5a.5.5 0 0 1-.5-.5z" clip-rule="evenodd"/>
    </svg> -->
    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 icons" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M10 4a4 4 0 0 0-4 4a4 4 0 0 0 4 4a4 4 0 0 0 4-4a4 4 0 0 0-4-4m7 8a.26.26 0 0 0-.26.21l-.19 1.32c-.3.13-.59.29-.85.47l-1.24-.5c-.11 0-.24 0-.31.13l-1 1.73c-.06.11-.04.24.06.32l1.06.82a4.2 4.2 0 0 0 0 1l-1.06.82a.26.26 0 0 0-.06.32l1 1.73c.06.13.19.13.31.13l1.24-.5c.26.18.54.35.85.47l.19 1.32c.02.12.12.21.26.21h2c.11 0 .22-.09.24-.21l.19-1.32c.3-.13.57-.29.84-.47l1.23.5c.13 0 .26 0 .33-.13l1-1.73a.26.26 0 0 0-.06-.32l-1.07-.82c.02-.17.04-.33.04-.5s-.01-.33-.04-.5l1.06-.82a.26.26 0 0 0 .06-.32l-1-1.73c-.06-.13-.19-.13-.32-.13l-1.23.5c-.27-.18-.54-.35-.85-.47l-.19-1.32A.236.236 0 0 0 19 12zm-7 2c-4.42 0-8 1.79-8 4v2h9.68a7 7 0 0 1-.68-3a7 7 0 0 1 .64-2.91c-.53-.06-1.08-.09-1.64-.09m8 1.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5c-.84 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5"/></svg>
    <a class="text-white">Accounts</a>
  </li>
 
  <li
    id="Contact"
    class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer"
    [class.active]="activeLink === 'Contact'"
    routerLink="/executive/contact"
    (click)="setActiveLink('Contact')"
  >
    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 icons" viewBox="0 0 24 24">
      <path fill="currentColor" d="M21 2H6a2 2 0 0 0-2 2v3H2v2h2v2H2v2h2v2H2v2h2v3a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1m-8 2.999c1.648 0 3 1.351 3 3A3.012 3.012 0 0 1 13 11c-1.647 0-3-1.353-3-3.001c0-1.649 1.353-3 3-3M19 18H7v-.75c0-2.219 2.705-4.5 6-4.5s6 2.281 6 4.5z"/>
    </svg>
    <a class="text-white">Contacts</a>
  </li>
  <li
  id="leads"
  class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer"
  [class.active]="activeLink === 'leads'"
  routerLink="/executive/lead"
(click)="setActiveLink('leads')"
>
  <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 icons" viewBox="0 0 24 24">
    <path fill="currentColor" d="M10 4c2.2 0 4 1.8 4 4s-1.8 4-4 4s-4-1.8-4-4s1.8-4 4-4m7 17l1.8 1.77c.5.5 1.2.1 1.2-.49V18l2.8-3.4A1 1 0 0 0 22 13h-7c-.8 0-1.3 1-.8 1.6L17 18zm-2-2.3l-2.3-2.8c-.4-.5-.6-1.1-.6-1.7c-.7-.2-1.4-.2-2.1-.2c-4.4 0-8 1.8-8 4v2h13z"/>
  </svg>
  <a class="text-white">Leads</a>
</li>
 
<li
  id="Opportunity"
  class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer"
  [class.active]="activeLink === 'Opportunity'"
  routerLink="/executive/opportunity_list"
  (click)="setActiveLink('Opportunity')"
>
  <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 icons" viewBox="0 0 1024 1024">
    <path fill="currentColor" d="M384 960v-64h192.064v64zm448-544a350.66 350.66 0 0 1-128.32 271.424C665.344 719.04 640 763.776 640 813.504V832H320v-14.336c0-48-19.392-95.36-57.216-124.992a351.55 351.55 0 0 1-128.448-344.256c25.344-136.448 133.888-248.128 269.76-276.48A352.384 352.384 0 0 1 832 416m-544 32c0-132.288 75.904-224 192-224v-64c-154.432 0-256 122.752-256 288z"/>
  </svg>
  <a class="text-white">Opportunity</a>
</li>
<li id="Report" style="cursor: pointer;" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm relative"
[class.active]="activeLink === 'Report'"
(click)="setActiveLink('Report')"
   >
  <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 icons" viewBox="0 0 24 24">
    <g fill="none">
      <path d="M24 0v24H0V0z"/>
      <path fill="currentColor" d="M10 21h9a2 2 0 0 0 2-2v-2H10zm0-6h11v-5H10zm-2-5v5H3v-5zm2-2h11V6a2 2 0 0 0-2-2h-9zM8 4v4H3V6a2 2 0 0 1 2-2zm0 13v4H5a2 2 0 0 1-2-2v-2z"/>
    </g>
  </svg>
  <span class="text-white">Reports</span>
  <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 ml-auto h-4 w-4 text-white" viewBox="0 0 24 24" style="margin-right: 13vh;">
    <path fill="currentColor" d="M7 10l5 5 5-5H7z"/>
  </svg>
 
 
  <ul *ngIf="isSubMenuVisible" class="absolute left-6 pl-4" style="margin-top: 32vh !important;">
    <li class="flex items-center" (click)="onSubMenuClick($event)">
        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-2 h-3 w-3 text-white" viewBox="0 0 24 24">
          <path fill="currentColor" d="M4 2h12v2H4zm0 4h12v2H4zm0 4h12v2H4zm0 4h12v2H4zm0 4h12v2H4z"/>
        </svg>
        <p routerLink="/executive/hot-lead-report" class="text-sm text-white hover:bg-gray-200 p-2 rounded">Hot Lead Report</p>
      </li>
     
      <li class="flex items-center" (click)="onSubMenuClick($event)">
        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-2 h-4 w-4 text-white" viewBox="0 0 24 24">
          <path fill="currentColor" d="M3 18h2V6H3zm4 0h2V9H7zm4 0h2v-4h-2zm4 0h2V4h-2z"/>
        </svg>
        <p routerLink="/executive/revenue-report" class="text-sm text-white hover:bg-gray-200 p-2 rounded">Revenue Report</p>
      </li>
     
      <li class="flex items-center" (click)="onSubMenuClick($event)">
        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-2 h-3 w-3 text-white" viewBox="0 0 24 24">
          <path fill="currentColor" d="M12 2v10h10C22 7.58 17.42 2 12 2zm0 20c-5.52 0-10-4.48-10-10h10v10z"/>
        </svg>
        <p routerLink="/executive/open-activity-report" class="text-sm text-white hover:bg-gray-200 p-1 rounded">Open Activity</p>
      </li>
     
      <li class="flex items-center" (click)="onSubMenuClick($event)">
        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-2 h-3 w-3 text-white" viewBox="0 0 24 24">
          <path fill="currentColor" d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM12 6v6l4 2"/>
        </svg>
        <p routerLink="/executive/last-updated-report" class="text-sm text-white hover:bg-gray-200 p-2 rounded">Last Updated Report</p>
      </li>
     
  </ul>
</li></ul>
 
 
 
