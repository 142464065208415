import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContactFormService } from '../../services/contact-form.service';
import { QuotationListService } from '../quotation-lists/quotation-list.service';
import { LoginService } from '../../services/login.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { SuccessComponent } from '../../success/success.component';
import { FailComponent } from '../../fail/fail.component';
import { ReportService } from '../reports/report.service';
@Component({
  selector: 'app-invoices',
  standalone: true,
  imports: [CommonModule,RouterModule,ReactiveFormsModule,MatDividerModule,FormsModule],
  templateUrl: './invoices.component.html',
  styleUrl: './invoices.component.scss',
  
})
export class InvoicesComponent implements OnInit {
  res:any=[];
  isDisabled: boolean = false;
  public qid:any;
  users:any=[];
  registerForm!: FormGroup;
  submitted = false;
  Quotes:any=[];
  salesteam: any[] = [];
  contactlist:any[] =[];
  id:any;
  cid:any
  public user: any;
  tasklist:any[]=[];
  public isEdit:boolean = false;
  now: any 
quatationlistss: any []=[];
customer: any;
  constructor(private dialog: MatDialog,private formBuilder: FormBuilder,private reportservice:ReportService,
      private contactservice: ContactFormService,private loginservice: LoginService,
      private router: Router,private qservice:QuotationListService,private route: ActivatedRoute) { }

  ngOnInit() {
   
    // console.log(this.user.data.id,this.user.teamid,'idssssssteamssss');
  //  this. getquatation();
      this.registerForm = this.formBuilder.group({
          contact: ['', Validators.required],
          expiration: ['', Validators.required],
          gst_no: ['', Validators.required],
          gst_amount: ['', Validators.required],
          paymentterms: ['', Validators.required],
          date:['', Validators.required],
          product_details:['', Validators.required],
          task:['', Validators.required],
          amount:['', Validators.required],
         // totalamount:['', Validators.required]
         customer:['']
      }, );
      
      this.user = this.loginservice.getUserdata()
      console.log(this.user.data.id,this.user.teamid,'idssssssteamssss');
       this.getquatation();
      //this.getsalesteam(this.user.data.id)
      this.getcontacts(this.user.data.id)
      this.getuser()
      this.route.paramMap.subscribe(params => {
        this.cid = params.get('id')
       
        if(this.cid != null){
          
            this.isEdit = true;
        this.qservice.getSingleQuotation(this.cid).subscribe((data:any)=>{
                console.log(data,'oiuuuuuuu')    
            console.log(data.total_amount)
            let expiration = data?.expiration; 
            expiration = new Date(expiration).toISOString().split('T')[0];
            console.log("expiration:", expiration);

            let date = data?.date; 
            date = new Date(date).toISOString().split('T')[0];
            console.log("date:", date);

            this.registerForm.reset({
              contact: data.contact,
              expiration: expiration ,
              gst_no: data?.gst_no,
              gst_amount: data.gst_amount,
              paymentterms: data.paymentterms,
              date:date,
              product_details:data.product_details,
              task:data?.task,
              amount:data.amount,
            // totalamount:data.total_amount
                
            
            })
           
        })
       
    }
    else
    {
        this.isEdit = false;
    this.registerForm.reset();
    }
    
      });
      const datePipe = new DatePipe('en-US');
      // const formattedDate = datePipe.transform(this.registerForm.value.date, 'yyyy-MM-dd');
      
    
      //this.gettaklist(this.user.data.id)
}

quantities() : FormArray {  
  return this.registerForm.get("quantities") as FormArray  
}  


getlead(id:any) 
{
  
  this.qservice.gettasklist(id).subscribe((res:any)=>{
    this.tasklist=res;
    console.log('tasklist',this.tasklist);
  })
}
getuser(){

  this.loginservice.getUser().subscribe(
    (users: any) => this.users = users
    )
  }
// getsalesteam(userid: number){
//     this.salesteamservice.getSales(userid).subscribe(
//       (salesteam: any) => this.salesteam = salesteam
//       );
//   }
getcontacts(userid: number){

      this.contactservice.getContacts(userid).subscribe(
        (contactlist: any) =>{
          this.contactlist = contactlist?.data;
          console.log(this.contactlist,'contactlistsssss')
        } 
       
        );
    }
    editinvoice(){
      console.log(this.registerForm.value.contact,'hiiiiii')
      let s_amount=parseInt(this.registerForm.value.amount)
      let g_amount=parseInt(this.registerForm.value.gst_amount)
     // this.registerForm.value.totalamount=s_amount+g_amount
      let data_val={
        "contact":this.registerForm.value.contact,
        "expiration":this.registerForm.value.expiration,
        "gst_no":this.registerForm.value.gst_no,
        "gst_mount":this.registerForm.value.gst_amount,
        "date":this.registerForm.value.date,
        "product_details":this.registerForm.value.product_details,
        "paymentterms":this.registerForm.value.paymentterms,
        "amount":this.registerForm.value.amount,
        "total_amount":s_amount+g_amount,



      }
      let uid=this.user.data.id
  this.qservice.Editquotation(data_val,this.cid).subscribe((response:any) => {
    if(response.length!=0){

    const confirmDialog = this.dialog.open(SuccessComponent, {

      data: {
        title: 'Success',
        message: 'Edited Succesfully!'
      }
    })
    confirmDialog.afterClosed().subscribe(result => {

     if (result == true) {
      this.router.navigate(['teamleader/quotation/']);
    }


  });
}
else{
  const confirmDialog = this.dialog.open(FailComponent, {

    data: {
      title: 'Failed',
      message: 'Added Failed!'
    }
  })
  confirmDialog.afterClosed().subscribe(result => {

   if (result == true) {
     this.router.navigate(['teamleader/quotation'])
  }


})}
  });
  }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
      this.submitted = true;
      if (this.registerForm.invalid) {
        return;
        
    } 
    console.log(this.registerForm.value)
    let s_amount=parseInt(this.registerForm.value.amount)
    let g_amount=parseInt(this.registerForm.value.gst_amount)
    this.registerForm.value.total_amount=s_amount+g_amount
    this.registerForm.value.status='Generate'
    this.registerForm.value.customer=this.customer;
  this.qservice.addInvoice(this.registerForm.value,this.user.data.id).subscribe((response:any)=>{
    console.log(response,'resssss')
  this.qid=response.id;
  if(response.length!=0)
  {
   const confirmDialog = this.dialog.open(SuccessComponent, {

     data: {
       title: 'Success',
       message: 'Added Succesfully!'
     }
   })
   confirmDialog.afterClosed().subscribe(result => {

    if (result == true) {
      this.router.navigate(['teamleader/invoice-generate/',response.id]);
        
   }


 });
}
else{
 const confirmDialog = this.dialog.open(FailComponent, {

     data: {
       title: 'Failed',
       message: 'Added Failed!'
     }
   })
   confirmDialog.afterClosed().subscribe(result => {

    if (result == true) {
      this.router.navigate(['teamleader/quotation'])
   }


 });
}
// for (let i = 0; i < this.registerForm.value.quantities.length; i++) {

//  let product = {
//   "quotation" :this.qid, 
//   "productname":this.registerForm.value.quantities[i].productname,
//   "description":this.registerForm.value.quantities[i].description,
//   "quantity":this.registerForm.value.quantities[i].quantity,
//   "unitprice":this.registerForm.value.quantities[i].unitprice,
//   "taxes":this.registerForm.value.quantities[i].taxes,
//   "subtotal":this.registerForm.value.quantities[i].subtotal,
//   "user":this.user.data.id
//  }
//         this.contactservice.addProduct(product,this.user.data.id).subscribe((reponse:any)=>{
// //console.log(reponse);
   
//            }); 
//     }
}); 
}

  onReset() {
      this.submitted = false;
      this.registerForm.reset();
  }

  private selectedLink: string="Individual";        

  setContactType(e: string): void   
  {  
      this.selectedLink = e;     
  }  
  
  isSelected(name: string): boolean   
  {  
      if (!this.selectedLink) {   
          return false;  
      }  
          return (this.selectedLink === name);  
  }  

  name = '!!!';
  viewMode = 'tab1';   



    getquatation(){
      console.log('hai quatttt')
      this.loginservice.listLeads(this.user.data.id).subscribe(
        (result: any) => {
          console.log(result,'resultis');
          this.quatationlistss = result;
          console.log(this.quatationlistss,'quatationlistsssss')
        }
      );

    }

 onContactChange(event:any){
  console.log(event.target.value,'tarhgettttttt')
  this.contactlist.forEach((item:any)=>{
    console.log(item,'itemssssssssss')
    if(item.id==event.target.value){
    
      this.customer = item?.customer
      
    }
    
  })

}


}