import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from '../../services/login.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TargetService } from '../targetss/target.service';
import { SuccessComponent } from '../../success/success.component';
import { FailComponent } from '../../fail/fail.component';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-adding-target',
  standalone: true,
  imports: [CommonModule,RouterModule,ReactiveFormsModule,FormsModule,MatDividerModule,NgxPaginationModule],
  templateUrl: './adding-target.component.html',
  styleUrl: './adding-target.component.scss'
})
export class AddingTargetComponent  implements OnInit {
  targetForm!: FormGroup;
  submitted = false;
  public user: any;
  roles:any[]=[]
  teams:any[]=[]
  p:number=1;
  isEdit: boolean=false;
  cid:any
  mid: any;
  team_id:any;
  constructor(private dialog: MatDialog,private formBuilder: FormBuilder,private loginservice: LoginService, private router: Router,private route: ActivatedRoute,private tservice:TargetService, private toaster:ToastrService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.targetForm = this.formBuilder.group({ 
      duration:['', Validators.required],
      amount:['', Validators.required]
    }, );
    this.getteammembers()
    this.route.paramMap.subscribe(params => {
      this.cid = params.get('id')
      this.mid = params.get('mid')
      if(this.cid != null){
        
          this.isEdit = true;
      this.tservice.getoneteamembers(this.cid).subscribe((data:any)=>{
        console.log(data,'targettttt')
        this.team_id=data.team_id;
          if(this.user.data.id==data.created_by_id){

            this.targetForm.enable();
          }
          else{
            this.targetForm.disable();
          }
        
          this.targetForm.setValue({
            duration:data?.duration,
             amount:data.target
           
              
          
          })
      })
    
  }
  else
  {
      this.isEdit = false;
  this.targetForm.reset();
  }
    });
  }
  get f() { return this.targetForm.controls; }
  onSubmit() {
    this.submitted = true;
      let data_tr={
        "team":this.team_id,
        "team_member":this.mid,
        "target":this.targetForm.value.amount,
        "duration":this.targetForm.value.duration,
        "is_teamlead": false

      }
    //   this.tservice.edittarget(data_tr,this.cid).subscribe((reponse)=>{
      
    //       if(reponse)
    //       {
    //        const confirmDialog = this.dialog.open(SuccessComponent, {
      
    //          data: {
    //            title: 'Success',
    //            message: 'Added Succesfully!'
    //          }
    //        })
    //        confirmDialog.afterClosed().subscribe(result => {
       
    //         if (result == true) {
    //           this.router.navigate(['teamleader/members/']);
    //        }
      
      
    //      });
    //   }
    //   else{
    //      const confirmDialog = this.dialog.open(FailComponent, {
      
    //          data: {
    //            title: 'Failed',
    //            message: 'Added Failed!'
    //          }
    //        })
    //        confirmDialog.afterClosed().subscribe(result => {
       
    //         if (result == true) {
    //             this.router.navigate(['executive/add-contactgroup'])
    //        }
      
      
    //      });
    //   }
        
      
    //  });  
    this.loginservice.addsubTeamMemberview(data_tr).subscribe((result:any)=>{
      if(result.message =="Updating this member would exceed the team's target."){
        this.toaster.error(result.message,'error')
      }
      else{
        const confirmDialog = this.dialog.open(SuccessComponent, {

          data: {
            title: 'Success',
            message: 'Added Succesfully!'
          }
          })
          confirmDialog.afterClosed().subscribe(result => {
            if (result == true) {
              this.router.navigate(['/teamleader/members'])
            }
  
  
          });
      }
     
     
      
    })
   
  }

  getteammembers(){
    this.tservice.getteamembers(this.user.teamid).subscribe((response:any)=>{
      this.teams=response 
  })
  }

}
