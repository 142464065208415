<div class="max-w w-full space-y-8  mt-3 h-screen p-10 bg-white rounded-xl shadow-lg z-10 "style="height:85vh !important">
    <div class="flex flex-col sm:flex-row items-center mb-4">
        <h2 class="font-semibold text-lg mr-auto">Opportunity</h2>

         <div class="block  mr-4">
                <input placeholder="Search"  id="name" [(ngModel)]="searchControl"  (input)="filter()"
                    class="appearance-none rounded-full  border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm
                     placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" autocomplete="off" />
        </div>

        <div> <mat-icon [matMenuTriggerFor]="animals" class="icon cursor-pointer ">filter_list</mat-icon> </div>
        <mat-menu class="custom-menu" #animals="matMenu">
          <div class="p-2">
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              Customer</label>
          

        <ng-select [items]="organizationlist"  [(ngModel)]="customerFilter" 
        [searchFn]="customSearchFn"
        bindLabel="name"
       (click)="$event.stopPropagation()"
        bindValue="id"
        placeholder="Select"  class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm" (change)="onCustomerFilterChange($event.id)">
      </ng-select>
        </div>
        <div class="p-2">
          <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
            Contact</label>
        <!-- <select [(ngModel)]="contactFilter" (click) = "$event.stopPropagation()"
        class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
        <option value="">Select</option>
        <option *ngFor="let list of contactlist" [value]="list.id">{{list.first_name}} {{list?.last_name}}</option>
      </select> -->
      <ng-select [items]="contactlist"
        [searchFn]="customSearchFn"
        [(ngModel)]="contactFilter"
        bindLabel="name"
       (click)="$event.stopPropagation()"
        bindValue="id"
        placeholder="Select"  class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
      </ng-select>
      </div>
      <div class="p-2 custom-slider">
        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
          Forecast Probability</label>
         
          <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options" (click) = "$event.stopPropagation()" (userChangeEnd)="onProbabilitySliderChange($event)"></ngx-slider>
    </div>
    <div class="p-2 custom-slider">
      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
        Forecast Period</label>
       
        <ngx-slider [(value)]="minVal" [(highValue)]="maxVal" [options]="Foroptions" (click) = "$event.stopPropagation()" (userChangeEnd)="onPeriodSliderChange($event)"></ngx-slider>
       
   
  </div>
      
    <div class="p-2 flex justify-between mt-4">
      <button (click)="clearfilter()" class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">Clear</button>         
       <button (click)="filter()" class="mb-2 md:mb-0 bg-blue-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-blue-600">Filter</button>   
      </div>
        </mat-menu>

        
  
    </div>
    <mat-divider></mat-divider>
<div class="mt-4 w-full overflow-x-auto">
<table class="min-w-max w-full  table-auto border-collapse border border-gray-300">
    <thead>
        <tr class="bg-gray-200 text-gray-600 text-sm">
            <th class="py-3 px-6 text-center border border-gray-300">Opportunity Unique Id </th>
            <th class="py-3 px-6 text-center border border-gray-300"> Opportunity Name  </th>
            <th class="py-3 px-6 text-center border border-gray-300">Customer</th>
            <th class="py-3 px-6 text-center border border-gray-300">Type</th>
            <th class="py-3 px-6 text-center border border-gray-300"> Forecast Probability</th>
            <th class="py-3 px-6 text-center border border-gray-300">Forecast Period</th>
            <th class="py-3 px-6 text-center border border-gray-300">Contact</th>
            <th class="py-3 px-6 text-center border border-gray-300">Created Date</th>
            <th class="py-3 px-6 text-center border border-gray-300"> Created By</th>
            <th class="py-3 px-6 text-center border border-gray-300">Opportunity Revenue</th>
            <th class="py-3 px-6 text-center border border-gray-300"> Opportunity Stage</th>
            <th class="py-3 px-6 text-center border border-gray-300">Action</th>
        </tr>
    </thead>
    <tbody class="text-gray-600 text-sm" *ngIf="opportunitylist && opportunitylist.length > 0; else noData">
        <tr *ngFor="let element of opportunitylist | paginate: { itemsPerPage: 5, currentPage: p }" class="border-b border-gray-300 hover:bg-gray-100">
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
              {{element.oppertunity_unique_id}} 
            </td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
              {{element.oppertunity_name_label}} 
            </td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
              {{element.customer_name}} 
            </td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
              {{element.type}}
            </td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
              {{element.forecast_probablity}}
            </td>
           
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
              {{element.forecast_period}}
            </td>
             <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                
              {{element.contact_name}}</td>
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                
                {{element.created_date | date :'dd/MM/yyyy'}}</td>
                <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                
                  {{element.created_user_name}}</td>
                  <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                
                    {{element.oppertunity_revenue}}</td>


                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                      {{element.oppertunity_stage}}</td>
                      
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                <div class="flex justify-center space-x-2">
                    <span class="bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer">
                        <a  [routerLink]="['/executive/add-edit-opportunity','',element.id]">
                            <i class="fa fa-pencil"></i>
                        </a>
                    </span>
                    <span class="bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer">
                        <a (click)="deleteOpportunity(element)">
                            <i class="fa fa-trash"></i>
                        </a>
                    </span>
                </div>
            </td>
        </tr>
    </tbody>
</table>
<ng-template #noData><tr><td colspan="7" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>

</div>
<pagination-controls (pageChange)="p = $event" class="float-right mt-1 pagination"></pagination-controls>

</div>

