import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { SuccessComponent } from '../../success/success.component';
import { CommonModule } from '@angular/common';
import { TargetComponent } from '../target/target.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-team-members',
  standalone: true,
  imports: [ReactiveFormsModule,CommonModule,FormsModule,MatDividerModule,RouterModule,MatFormFieldModule,MatDatepickerModule,MatNativeDateModule,MatInputModule,MatIconModule],
  templateUrl: './add-team-members.component.html',
  styleUrl: './add-team-members.component.scss'
})
export class AddTeamMembersComponent implements OnInit {

  addteamMemberForm!: FormGroup;
  submitted = false;
  public user:any
  Tl:any[]=[]
  exc:any[]=[]
  membersList:any[]=[]
  members:any[]=[]
  public isEdit:boolean = false;
public cid:any
  private cidd:any
  private usid:any;
  typeArr : any =  ['Monthly','Yearly','Quaterly'];
  subTypeArr : any[] = [{id:1,month:'January'},{id:2,month:'February'},{id:3,month:'March'},{id:4,month:'April'},{id:5,month:'May'},{id:6,month:'June'},{id:7,month:'July'},{id:8,month:'August'},{id:9,month:'September'},{id:10,month:'October'},{id:11,month:'November'},{id:12,month:'December'}]
  typeModel : any = 'Monthly';
  subType : any = null;
  originalSubArr :any =[];

  constructor(private formBuilder: FormBuilder,private dialog: MatDialog,private router: Router,private loginservice:LoginService,private route: ActivatedRoute,private toaster:ToastrService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.form();
    this.originalSubArr = this.subTypeArr
    this.route.paramMap.subscribe(params => {
      this.cid = params.get('id')
      this.cidd=params.get('idd')
      this.usid = params.get('uid')
      if(this.cidd != null){
        
        this.isEdit = true;
    this.loginservice.singleMem(this.cidd).subscribe((data:any)=>{
        const duration :any = data?.duration.charAt(0).toUpperCase() + data?.duration.slice(1);
        this.onFilterChange(duration,'from_edit')

        this.addteamMemberForm.reset({
          member:data.team_member.first_name,
          target:data.target,
          duration: duration,
          durationType: this.getTaskPeriod(data?.duration, data?.started_date, data?.end_date)
          // this.addteamMemberForm.value.durationType
        })

        // this.typeModel=data?.duration
    })
    
  
  }else
  {
      this.isEdit = false;
      this.addteamMemberForm.reset();
  }
    })

   
    this.listExc()
    
  }
  getTaskPeriod(taskDurationType:any, startDate:any, endDate:any) {
    const start = new Date(startDate);
    const end = new Date(endDate);
   
    switch (taskDurationType) {
      case 'Monthly':
        const month = start.toLocaleString('default', { month: 'long' });
        var monthId : any = 0
        this.subTypeArr.forEach((item:any)=>{
          if(item?.month == month){
            monthId = item?.id;
          }
        })
        return monthId;
      case 'Quaterly':
        const startMonth = start.getMonth() + 1;
        const endMonth = end.getMonth() + 1;
        return `${startMonth}-${endMonth}`;
      case 'Yearly':
        return start.getFullYear();
      default:
        return 'Invalid task duration type';
    }
  }
 

  form() {
	  this.addteamMemberForm = this.formBuilder.group({
		  member: ['', Validators.required],
		  target: ['', Validators.required],
      duration:['', Validators.required],
      durationType : ['', Validators.required],
  }
	   );
	  
  }
  get f() { return this.addteamMemberForm.controls; }
  onSubmit(){
    this.submitted=true
    if(this.addteamMemberForm.invalid){
      return;
    }
    var datas
    this.exc.forEach((item:any)=>{
      if(item.id==this.addteamMemberForm.value.member){
        datas= item.groups[0].name
      }
    })
    let data_cr={
      "team":this.cid,
      "team_member":this.addteamMemberForm.value.member,
      "target":this.addteamMemberForm.value.target,
      "duration":this.addteamMemberForm.value.duration,
      "is_teamlead": datas == 'salesperson' ? false : true,
      "durationType":this.addteamMemberForm.value.durationType,

    }

    this.loginservice.addTeamMemberview(data_cr).subscribe((response:any)=>{
      if(response.message =="Adding this member would exceed the team's target."){
        this.toaster.error(response.message,'error')
      }
      else{
        const confirmDialog = this.dialog.open(SuccessComponent, {

          data: {
            title: 'Success',
            message: 'Added Succesfully!'
          }
          })
          confirmDialog.afterClosed().subscribe(result => {
            if (result == true) {
              this.router.navigate(['/admin/team-members-details/'+this.cid])
            }
  
  
          });
      }
      this.loginservice.statusactivate(this.addteamMemberForm.value.member,'Active').subscribe((result:any)=>{
      })
     
      
    })


  }

  listExc(){
    this.loginservice.listrExc(this.user.data.id).subscribe((response:any)=>{
      this.exc=response
    })
  }

  edit(){
    let data_ed={
      "team_id":this.cid,
      "team_member_id":this.usid,
      "target":this.addteamMemberForm.value.target,
      "duration":this.addteamMemberForm.value.duration,
      "durationType":this.addteamMemberForm.value.durationType,
    }
    
     this.loginservice.editmemberTrgt(data_ed,this.cidd).subscribe((response:any) => {
      const confirmDialog = this.dialog.open(SuccessComponent, {
        data: {
				  title: 'Success',
				  message: 'Edited Succesfully!'
				}
			  })
			  confirmDialog.afterClosed().subscribe(result => {
          if (result == true) {
            this.router.navigate(['admin/team-members-details/'+this.cid])
          }


		    });
          
          
    });

  }

  onFilterChange(event:any,type:any){
    this.subTypeArr = [];
    this.subType = null;
    const dataItem = type == 'from_create' ? event?.target?.value : event
    if(dataItem == 'Yearly'){
      const currentYear = new Date().getFullYear();
      const lastTenYear: any = currentYear - 10
      for(let i = currentYear;i >= lastTenYear; i-- ){
        this.subTypeArr.push({id:i,month:i})
      }
    }else if(dataItem == 'Quaterly'){
      this.subTypeArr = [{id:'1-3',month:'January - March'},{id:'4-6',month:'April - June'},{id:'7-9',month:'July - September'},{id:'10-12',month:'October - December'}]
    }else{
      this.subTypeArr = this.originalSubArr;
    }
  }

  onSubFilterChange(event:any){

  }

}
