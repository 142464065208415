import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { ActivitiesService } from '../../services/activities.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-report',
  standalone: true,
  imports: [CommonModule,FormsModule,MatDividerModule,NgxPaginationModule],
  providers : [LoginService,ActivitiesService],
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss'
})
export class ReportComponent {
  constructor(private activityserve:ActivitiesService,private loginservice: LoginService) { }
  p:number=1;
  revenueReportlist:any[] = [];
  user:any;
  userid:any;
  searchControl : any = '';
  advfilter : any

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.userid=this.user.data.id 
    this.getRevenueReport()
  }

  getRevenueReport(){ 
    this.activityserve.revenueReport(this.userid,this.searchControl).subscribe(
      (revenueReportlist: any) =>{ 
        this.revenueReportlist = revenueReportlist.data
      }
      );    
  }

  onChange(){
    this.getRevenueReport()
  }

}
