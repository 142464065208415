<div class="w-80 notification-div overflow-y-auto">
    <div class="close cursor-pointer" (click)="closeNotification()">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"/></svg></div>
        <p class="notification  ml-4 mt-3">Notifications</p>
        <div class="float-right mt-1 cursor-pointer" (click)="clearAllNotification()">
                <p class="markAll mr-3">Mark all as read</p>
            </div>
            <div *ngIf="notificationListArr?.length && !loading" class="mt-8 ">
                <div *ngFor="let notification of notificationListArr " class="p-2 messageDiv m-2">
                    <div class="closeNotification cursor-pointer flex" (click)="closeSingleNotification(notification?.id)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"/></svg>    </div>
                    <p  class="message mt-1">{{ notification?.message }}</p>
                    <p class="createdTime">{{ notification?.created_at | date: 'MMM d, y, h:mm:ss a' }}</p>
                  </div>
            </div>
            
            <div  *ngIf="loading" class="flex align-center justify-center mt-8">
                <img src="../assets/img/download.png"
                class="noImage">
            </div>
</div>
