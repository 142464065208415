<div class="max-w w-full space-y-8   h-screen p-10 bg-white rounded-xl shadow-lg z-10 " style="height:85vh !important; margin-top: 2vh !important;">
    <div class="flex flex-col sm:flex-row items-center mb-4">
        <h2 class="font-semibold text-lg mr-auto">Hot Lead Report</h2></div>
    <mat-divider></mat-divider>
<div  class="mt-4  w-full overflow-x-auto">

      <table class="min-w-max w-full table-auto border-collapse border border-gray-300">
        <thead class="bg-gray-200 text-gray-600 text-sm">
          <tr class="border-b border-gray-300 ">
            <th class="py-3 px-6 text-center border border-gray-300" >Leads</th>
            <th class="py-3 px-6 text-center border border-gray-300" >Cloudvice Sales Rep</th>
            <th class="py-3 px-6 text-center border border-gray-300" >Customer</th>
            <th class="py-3 px-6 text-center border border-gray-300" >Contact</th>
            <th class="py-3 px-6 text-center border border-gray-300" >comment</th>

          </tr>
        </thead>
        <tbody class="text-gray-600 text-sm " *ngIf="hotLeadReportlist && hotLeadReportlist.length > 0; else noData">
          <tr *ngFor="let element of hotLeadReportlist | paginate: { itemsPerPage: 5, currentPage: p }" class="border-b border-gray-300 hover:bg-gray-100">
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.name }}</td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.cloudvice_sales_rep_name }}</td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.customer_name }}</td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.contact_name }}</td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.comments }}</td>
          </tr>
          <!-- <tr *ngIf="hotLeadReportlist?.length === 0">
            <td colspan="5" class="text-center font-semibold text-lg py-4 pt-9">No Data Found</td>
          </tr> -->
        </tbody>
      </table>
      <ng-template #noData><tr><td colspan="5" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>

</div>
<pagination-controls (pageChange)="p = $event" class="float-right pagination mt-1"></pagination-controls>

</div>



