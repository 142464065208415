import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoginService } from '../../services/login.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-team-member-report',
  standalone: true,
  imports: [NgxPaginationModule,CommonModule,RouterModule,MatDividerModule],
  templateUrl: './team-member-report.component.html',
  styleUrl: './team-member-report.component.scss'
})
export class TeamMemberReportComponent implements OnInit {
  public user:any
  members:any[]=[]
  teamid:any
  teamname:any
  p: number = 1;
  private cid:any

  constructor(private loginservice:LoginService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.route.paramMap.subscribe(params => {
      this.cid = params.get('id')
    })
    
    this.listSigTeam(this.cid)
  }
  listSigTeam(cid:number){
    this.loginservice.singleTeamMem(cid).subscribe((response:any)=>{  
    this.members=response
    this.teamname=response[0].team.teamname
   
    
   
    })

  }

}
