<div class="flex flex-nowrap pr-2" cdkDropListGroup style="width:100% !important;height:85vh !important;overflow-y: auto !important; overflow-x: auto !important;">
	<div class="flex" *ngFor="let item of statusArr" >
	<div class="flex-2 mb-5" style="padding-left: 2vh !important;"   *ngFor="let column of board?.columns">
		<div class="text-lg text-left  p-1 relative border-gray-400 mainDiv"  style="height: auto !important;">
			<div class=" float-right cursor-pointer" matTooltip="Add Subcatagory" (click)="addSubStatus(item?.id,item?.name)">
				<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><path fill="currentColor" d="M11.5 12.5H6v-1h5.5V6h1v5.5H18v1h-5.5V18h-1z"/></svg>
			</div>
			<div class="mt-1">
				<div class="flex justify-center">
					<p class="bold-style">{{item?.name}}</p>
				</div>
				
			</div>
			<div class="flex w-full mt-2" (click)="togglecolmenu(column,item?.id)">
				<button style="border-radius: 6px !important;background-color: #e5e7eb;"    class="flex justify-center w-full">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M11.5 12.5H6v-1h5.5V6h1v5.5H18v1h-5.5V18h-1z"/></svg>
					<span class=" task">Add task</span>
				</button>
			</div>
			<div  >
				<app-task *ngIf="taskActiveId == item?.id" [statustype]="item?.name" [editTaskId]="editTaskId"  [colid]="column?.id" [userid]="user?.data.id"  [column]="column" (ontaskcomplete)="reloadboard($event)" [ngClass]="showcolstatus(column)" class="z-50 origin-top-right absolute py-2 px-1 w-100 bg-white shadow-md rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none" style="margin-left: 2.5vh !important;margin-top: -5vh !important">
				</app-task>
			</div>
			<div   cdkDropList [cdkDropListData]="column?.tasks"    (cdkDropListDropped)="onDrop($event, item, column)"  [id]="'drop-' + item?.id"  class="drop-container"  
			 >
			 <div  cdkDrag [cdkDragData]="items"   class="cust-style"  *ngFor="let items of column?.tasks">
					<app-cust-cards cdkDragHandle  (ontaskcomplete)="reloadboard($event)"  [task]="items" [colid]="column?.id"    *ngIf="items?.status == item?.name && items?.task_stage == '' || items?.status == item?.name && items?.task_stage == null" [editStatus]="true"> </app-cust-cards></div>
			</div>
			
	</div>
	
</div>
<div class="flex-2 "  style="padding-left: 2vh !important;"  *ngIf="subStatusAddId == item?.id">
	<div class="text-lg text-left  p-1 relative border-gray-400 mainDiv" style="height: auto !important;">
		<div class="flex justify-center mt-2">
			<div class="flex" >
				 <input  [formControl]="subStatus" id="name" class="rounded-md h-8 flex-grow w-64" style="display: flex; text-align:center !important;"   name="boardname" #boardname type="text" placeholder="Enter Column Name" />
			</div>
		</div>
  <hr  class="mt-5">
		<div class="flex cursor-pointer justify-center mt-2 mb-2"  (click)="createSubStatus(item?.id)">
			<button style="border-radius: 6px !important;background-color: #e5e7eb;"    class="flex justify-center w-14 h-8 task">
				Save
			</button>

		</div>
</div>
</div>
<div class="flex" *ngFor="let subItem of substatusArr">
<ng-container *ngIf="subItem?.task_status == item?.name">
<div class="flex-2 mb-5"  style="padding-left: 2vh !important;" *ngFor="let column of board?.columns">
	<div class="text-lg text-left  p-1 relative border-gray-400 mainDiv" style="height: auto !important;">
		<div class="mt-1">
			<div class=" float-right cursor-pointer" title="Remove SubCategory" (click)="RemoveSubStatus(subItem?.id)">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"/></svg>    </div>
			<div class="flex justify-center">
				<p class="bold-style">{{subItem?.sub_status}}</p>
			</div>
			
		</div>
		<div class="flex w-full mt-2" (click)="togglecolmenu(column,subItem?.id)">
			<button style="border-radius: 6px !important;background-color: #e5e7eb;"    class="flex justify-center w-full">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M11.5 12.5H6v-1h5.5V6h1v5.5H18v1h-5.5V18h-1z"/></svg>
				<span class=" task">Add task</span>
			</button>
		</div>
		<div>
			<app-task *ngIf="taskActiveId == subItem?.id" [statustype]="item?.name"  [subType]="subItem?.sub_status" [editTaskId]="editTaskId"   [colid]="column?.id" [userid]="user?.data.id"  [column]="column" (ontaskcomplete)="reloadboard($event)" [ngClass]="showcolstatus(column)" class="z-50 origin-top-right absolute py-2 px-1 w-100 bg-white shadow-md rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none" style="margin-left: 2.5vh !important;margin-top: -5vh !important">
			</app-task>
		</div>
			<div cdkDropList [cdkDropListData]="column?.tasks"   (cdkDropListDropped)="onDrop($event, subItem, column)"  [id]="'drop-' + subItem?.id"  class="drop-container"  
			 >
			<div    *ngFor="let items of column?.tasks" cdkDrag [cdkDragData]="items" class="cust-style" >
				<app-cust-cards cdkDragHandle (ontaskcomplete)="reloadboard($event)"  [task]="items" [colid]="column?.id"    *ngIf="items?.status == item?.name  && items?.task_stage == subItem?.sub_status" [editStatus]="true" > </app-cust-cards></div>
		</div>
		</div>
</div>
</ng-container>
</div>
</div>
</div>
