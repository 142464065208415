import { Injectable,EventEmitter  } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { apiurl } from '../../global';
import { LoginService } from '../../services/login.service';

@Injectable({
	providedIn: 'root'
})
export class PipelineService {

	constructor(
		private http:HttpClient,
		private login:LoginService
	) { }

	public taskEditEmitter:EventEmitter<any> = new EventEmitter();

	addBoard(data:any,colid:number){
		//console.log(data);
			const headers = new HttpHeaders();
			headers.append('Content-Type','application/json');
			return this.http.post(apiurl+"pipeline/lc_board/"+colid+"/",data,{headers:headers})
		}
	getBoard(userid:number){
		let access_token = this.login.getUserToken()
		const headers = new HttpHeaders();
		headers.set('Authorization',`Bearer ${access_token['access']}`);
		return this.http.get(apiurl+'pipeline/lc_board/'+userid+"/",{headers:headers})
	}

	addColumns(boardid:number,cname:string){
		let access_token = this.login.getUserToken()
		const headers = new HttpHeaders();
		headers.append('Content-Type','enctype/form-data');
		headers.append('Authorization','Bearer '+access_token['access']);
		const fdata:FormData = new FormData();
		fdata.append('board_id',boardid.toString())
		fdata.append('name',cname)
		return this.http.post(apiurl+'pipeline/lc_column/'+boardid+"/",fdata,{headers:headers})
	}
	getNewLeadsList(id:number){
		return this.http.get(apiurl + 'pipeline/new-leads/list/'+id+'/');             
	}
	getColumns(boardid:number){
		return this.http.get(apiurl+'pipeline/lc_column/'+boardid+"/")
	}

	colorChange(color:any,type:any,id:any){
		const data={color_identifier:type,
			           color:color
					  }
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl + 'pipeline/color-conversion/'+id+'/',data,{headers:headers});    
	}
	ContactleadCountCreateView(contactid:any,taskid:any){
		return this.http.get(apiurl+'pipeline/ContactleadCountCreateView/?contact_id='+contactid+'&task_id='+taskid)
	}
	ContactleadCountUpdateView(contactid:any,taskid:any,status:any,user_id:any){
		return this .http .get(apiurl+'pipeline/ContactleadCountUpdateView/?contact_id='+contactid+'&task_id='+taskid+'&status='+status+'&user_id='+user_id)
	}
	ContactleadcountFetchApi(contactid:any){
		return this.http.get(apiurl+'pipeline/ContactleadcountFetchApi/?contact_id='+contactid)
	}
	TasksToOppertunityView(lead_id:any,user_id:any,sub_status:any=''){
		const params:any={sub_status:sub_status}
		return this.http.post(`${apiurl}pipeline/TasksToOppertunityView/${lead_id}/?user_id=${user_id}`,'', {params})
	}



	addTask(data:any,colid:number){
	//console.log(data);
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl+'pipeline/lc_task/'+colid+"/",data,{headers:headers})
	}
	editTask(data:any,taskid:number){
		//console.log(data);
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.put(apiurl+"pipeline/rud_task/"+taskid+"/",data,{headers:headers})
	}
	editColumn(data:any,cid:number){
		//console.log(data);
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.put(apiurl+"pipeline/rud_column/"+cid+"/",data,{headers:headers})
	}
	getOneTask(taskid:number){
		return this.http.get(apiurl+"pipeline/rud_task/"+taskid+"/")
	}
	editonetask(taskid:number){
		return this.http.get(apiurl+"pipeline/EditTask/?taskid="+taskid)
	}
	delOnetask(taskid:number){
		return this.http.delete(apiurl+"pipeline/rud_task/"+taskid+"/")
	}
	delOneColumn(colid:number){
		// console.log('ggggdeeeee')
		return this.http.delete(apiurl+"pipeline/rud_column/"+colid+"/")
	}
	getOneColumn(colid:number){
		return this.http.get(apiurl+"pipeline/rud_column/"+colid+"/")
	}
	getTask(colid :number,teamid:any=''){
		const params:any={team_id:teamid}
		return this.http.get(apiurl+'pipeline/lc_task/'+colid+"/",{params})
	}
	getTasks(colid :number){
		return this.http.get(apiurl+'pipeline/lc_task/'+colid+"/")
	}
	changestatus(taskid:any,userId:any,status:any,subStage:any){
		return this.http.get(apiurl+'pipeline/UpdateTask/?taskid='+taskid+'&user_id='+userId+'&status='+status+'&task_stage='+subStage)
	}
	change_task_status(taskid:any,task_status:any,user_id:any){
		return this.http.get(apiurl+'pipeline/ContactLeadStageUpdateView/?task_id='+taskid+'&status='+task_status+'&user_id='+user_id)
	}
	changenote(taskid:any,note:any,userId:any){
		return this.http.get(apiurl+'pipeline/UpdateTask/?taskid='+taskid+'&note='+note+'&user_id='+userId)
	}
	changeRating(taskid:any,rating:any){
		return this.http.get(apiurl+'pipeline/UpdateTask/?taskid='+taskid+'&rating='+rating)
	}
	changeColur(taskid:any,color:any){
		return this.http.get(apiurl+'pipeline/UpdateTask/?taskid='+taskid+'&color='+color)
	}

	getActivityCount(userId:any,taskId:any){
		return this.http.get(`${apiurl}pipeline/ActivityCountForTask/?user_id=${userId}&task_id=${taskId}`);             
	}

	getTaskList(){
		return this.http.get(`${apiurl}pipeline/ListTaskStatus/`);             
	}

	createSubStatus(data:any){
		return this.http.post(apiurl+'pipeline/CreateTaskSubStatus/',data)
    }

	getSubStatusList(id:any){
		return this.http.get(`${apiurl}pipeline/ListTaskSubStatus/?user_id=${id}`);             
	}

	OpportunityToQuotation(taskid:number,sub_status:any=''){
		const headers = new HttpHeaders();
		const params:any = {sub_status:sub_status}
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl + 'pipeline/oppertunity-to-quotation/'+taskid+'/',{headers:headers},{params});             
	}

	getsalesTask(id:any){
		return  this.http.get(`${apiurl}pipeline/lcSalesTasks/?user_id= ${id}`);   

	}
	activityCount(userid:any,taskid:any,){
		return  this.http.get(`${apiurl}pipeline/ActivityCountForTask/?user_id=${userid}&task_id=${taskid}`);   

	}
	DeleteSubTask(subid:any){
		return  this.http.get(`${apiurl}pipeline/DeleteTaskSubStatus/?sub_status_id= ${subid}`);   

	}


}


