import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from '../global';

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {
 
  constructor(private http: HttpClient) { }

  public contactEditEmitter:EventEmitter<any> = new EventEmitter();

  token : any = localStorage.getItem('usertoken')
  tokenGet = JSON.parse(this.token)
  httpOption = {
    headers : new HttpHeaders({
      'Authorization': `Bearer ${this.tokenGet}`
      
    })
  }
  
  addContact(result: any,userid:number){
    
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');  
    return this.http.post(apiurl + 'contacts/'+userid+"/",result,{headers:headers});
}

createContact(data: any){
  const headers = new HttpHeaders();
  headers.append('Content-Type','application/json');  
  return this.http.post(apiurl + 'createContacts',data,{headers:headers});
}
getContactdetail(userid:number){
    return this.http.get(apiurl + 'contacts/detail/'+userid);   
}
editcontact(cid:number,data:any){
  const headers = new HttpHeaders();
  headers.append('Content-Type','application/json');
  return this.http.put(apiurl+"api/contacts/"+cid+"/",data,{headers:headers})
}
getSales(){
  return this.http.get(apiurl + 'salesteam');             
  }
  contacts(userid:number){
    return this.http.get(apiurl + 'contacts/'+userid+"/");             
    }
getContacts(userid:number){ 
    return this.http.get(apiurl + 'contact-list/'+userid+"/");             
    }
    getcustomerContacts(userid:number,cid:number,filter:any){ 
  
      
       return this.http.get(`${apiurl}contactList/${userid}/${cid}?search_name=${filter}`); 

        }
    getContact(userid:number,cid:number){
      return this.http.get(apiurl + 'contactList/'+userid+"/"+cid);             
        }

        getContactwithoutCus(userid:any,search:any,customerid:any=''){
       
          const params: any = {search_name: search,customer_id:customerid};
           return this.http.get(apiurl + 'contact-list/'+userid+"/", { params });  
         
        }

        getContactwithCus(userid:any,search:any){
          
          const params: any = {company_name: search};
          return this.http.get(apiurl + 'contact-list/'+userid+"/", { params });  
        }
        
getOneContact(cid:number){
      return this.http.get(apiurl+"rud_contact/"+cid+"/")
    }

    ExportContactsDataView(cid:number){
      return this.http.get(apiurl+'user/ExportContactsDataView/'+cid+"/")
    }
    getContactsAssigned(userid:number){
      return this.http.get(apiurl + 'creatorcontactlist/'+userid+"/");             
       }

editContact(data:any,cid:number){ 
      const headers = new HttpHeaders();
      headers.append('Content-Type','application/json');
      return this.http.put(apiurl+"rud_contact/"+cid+"/",data,{headers:headers})
}
getteammembersContacts(id:any)

{
 return this.http.get(apiurl+"createteammembers/"+id+"/")
}
delOneContact(cid:number){
      return this.http.delete(apiurl+"delete-contact/"+cid+"/")
     }


     getLead(uid:number,filterdata:any){
      
      const params = filterdata;
      return this.http.get(apiurl + 'pipeline/leads/list/'+uid+'/',{params});  
      }


        getleaddetails(lid:number){
          return this.http.get(apiurl +'pipeline/leads/detail/'+lid+"/");  
        }
        
        editlead(lid:number,data:any){
          const headers = new HttpHeaders();
          headers.append('Content-Type','application/json');
          return this.http.put(apiurl+'pipeline/leads/update/'+lid+"/",data,{headers:headers})
        }
        
        dellead(lid:number){
          return this.http.delete(apiurl+"pipeline/leads/delete/"+lid+"/")
        }
        ContactandtheirLead(userid:any){
          return this.http.get(apiurl + 'pipeline/ContactandtheirLead/?user_id='+userid); 
        }
       
      getoppertunitystatus(opportunity:any,user_id:any){
        const params: any = {user_id:user_id};
        return this.http.get(apiurl + 'pipeline/ListTaskOpportunityWonSubStatus/?status='+opportunity,{params}); 

      } 
      getLeadstatus(Lead:any,user_id:any){
        const params: any = {user_id:user_id};
        return this.http.get(apiurl + 'pipeline/ListTaskOpportunityWonSubStatus/?status='+Lead,{params}); 

      }  
      getQuatationstatus(won:any,user_id:any){
        const params: any = {user_id:user_id};
        return this.http.get(apiurl + 'pipeline/ListTaskOpportunityWonSubStatus/?status='+won,{params}); 

      }  
      getcontactlists(userid:any,id:any){
      return this.http.get(apiurl +'contact-list/'+userid+'/?contact_id='+id)
      }

      onBulkUpload(data:any){
        return this.http.post(apiurl + 'contact-bulk-upload/', data,this.httpOption);
      }
}
