<div class="max-w w-full space-y-8  p-10 bg-white rounded-xl shadow-lg z-10 mb-15">
    <div class="flex flex-col sm:flex-row items-center mb-2">
        <h2 class="font-semibold  text-lg mr-auto">Members - Leads</h2>
        <a class="justify-start link-hover" routerLink="/admin/team-report/">Report </a> / <a class="justify-start link-hover" routerLink="/admin/team-member-report/{{cid}}">Team Members </a> / Leads
    </div>
    <mat-divider></mat-divider>
    
    <div class='overflow-x-auto overflow-y-auto  w-full'>
        <table class="min-w-max w-full table-auto">
            <thead>
                <tr class="bg-gray-200 text-gray-600 text-sm">
                    <th class="py-3 px-6 text-center">Lead Name</th>
                    <th class="py-3 px-6 text-center">Budget</th>
                    <th class="py-3 px-6 text-center">Status</th>
                </tr>
            </thead>
         
            <tbody class="text-gray-600 text-sm font-light">
                <tr  class="border-b border-gray-200 hover:bg-gray-100" *ngFor='let item of leads  | paginate: { itemsPerPage: 5, currentPage: p }' >
                    <td class="py-3 px-6 text-center whitespace-nowrap">
                        {{item.name}}
                    </td>
                    <td class="py-3 px-6 text-center">
                        {{item.budget}}
                    </td>
                    <td class="py-3 px-6 text-center">
                        {{item.status}}
                    </td>
                </tr>
            
            </tbody>
        </table>
        <pagination-controls (pageChange)="p= $event" class="float-right mt-5"></pagination-controls>
    </div>        
    
</div>