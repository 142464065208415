import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ReportService } from '../reports/report.service';
import { LoginService } from '../../services/login.service';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-allreport',
  standalone: true,
  imports: [CommonModule,RouterModule,MatDividerModule,NgxPaginationModule],
  templateUrl: './allreport.component.html',
  styleUrl: './allreport.component.scss'
})
export class AllreportComponent implements OnInit {
  public user: any;
  allreportlist:any[]=[]
  id:any
  mid:any;
  p:number=1;
  constructor(private activitiesservice: ReportService,
    private router: Router,private loginservice: LoginService,private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id')
      this.mid=params.get('mid')
      this.getallreport(this.id,this.mid)
    })
  }

  getallreport(id:any,mid:any)
  {
      this.activitiesservice.getalleport(id,mid).subscribe((data: any) =>{
        this.allreportlist=data
      });
  }

  
}
