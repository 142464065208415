import { Injectable } from '@angular/core';
//import { baseurl} from './../global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { apiurl } from '../../global';

@Injectable({
  providedIn: 'root'
})
export class EmailtemplateService {

  
  constructor(
    private http:HttpClient,
  ) { }

  addEmailtemplate(data:any){
  
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
    console.log(data);
    return this.http.post(apiurl+'retrive_emailtemplate/'+0+"/",data,{headers:headers})
  }
  addEmailsignature(data:any,userid:number){
  
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
    console.log(data);
    return this.http.post(apiurl+'retrive_emailsignature/'+userid+"/",data,{headers:headers})
  }
  getUser(){
 
    return this.http.get(apiurl + 'user/alluser/');
                 
    }
  getEmailtemplate(userid:any){
 
        return this.http.get(apiurl + 'retrive_emailtemplate/'+userid+"/");
                     
    }
    delOneEmailtemplate(cid:number){
      return this.http.delete(apiurl+"rud_emailtemplate/"+cid+"/")
     }
    getEmailsignature(userid:any){
 
    return this.http.get(apiurl + 'retrive_emailsignature/'+userid+"/");
                       
     }
     ExportEmailSignatureDataView(userid:any){
      return this.http.get(apiurl + 'user/ExportEmailSignatureDataView/'+userid+"/");
     }
     ExportEmailTemplateDataView(userid:any){
      return this.http.get(apiurl + 'user/ExportEmailTemplateDataView/'+userid+"/");
     }
     
     getOneEmailtemplate(cid:number){
      return this.http.get(apiurl+"rud_emailtemplate/"+cid+"/")
}
    editEmailtemplate(data:any,cid:number){
    //console.log(data);
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
    return this.http.put(apiurl+"rud_emailtemplate/"+cid+"/",data,{headers:headers})
    }
    getOneEmailsignature(cid:number){
      return this.http.get(apiurl+"rud_emailsignature/"+cid+"/")
}
    editEmailsignature(data:any,cid:number){
    //console.log(data);
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
    return this.http.put(apiurl+"rud_emailsignature/"+cid+"/",data,{headers:headers})
    }
    delOneEmailsignature(cid:number){
      return this.http.delete(apiurl+"rud_emailsignature/"+cid+"/")
     }
}
