import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ReportService } from './report.service';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrService } from 'ngx-toastr';
import { TargetService } from '../targetss/target.service';
import { MainService } from '../../main-service';
declare var XLSX: any;

@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [CommonModule,RouterModule,MatDividerModule,FormsModule,MatTableModule,NgxPaginationModule],
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.scss',
  // encapsulation: ViewEncapsulation.None
})
export class ReportsComponent implements OnInit {
  data: any[] = [];
  public user: any;
  SelectedIDs:any[]=[];
  showMembersTable: boolean = false;
  teamprogress:any
  teamtarget:any
  p:number=1;
  reports: any;
  allreportlist:any[]=[];
  monthArr : any =  ['Monthly','Yearly','Quaterly'];
  subTypeArr : any[] = [{id:1,month:'January'},{id:2,month:'February'},{id:3,month:'March'},{id:4,month:'April'},{id:5,month:'May'},{id:6,month:'June'},{id:7,month:'July'},{id:8,month:'August'},{id:9,month:'September'},{id:10,month:'October'},{id:11,month:'November'},{id:12,month:'December'}]
  typeModel : any = 'Monthly';
  subType : any = null;
  originalSubArr :any =[];
  isLoading: boolean = true; 
 noDataMessageShown: boolean = false; 
  teams: any;
  constructor(private activitiesservice: ReportService,
    private router: Router,private loginservice: LoginService,private toaster:ToastrService, private TargetService : TargetService,private mservice: MainService,) { }
 @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('secondTable') secondTable!: ElementRef;
  
  ngOnInit() {
   
    this.user = this.loginservice.getUserdata();
    console.log(this.user,'useriddddddddddddd')
    this.getteamprog(this.user.teamid)
    const currentMonthIndex = new Date().getMonth() + 1; // 0-indexed
    this.subType = currentMonthIndex;
    this.originalSubArr = this.subTypeArr;
    this.getreport();

  }

  getreport(){
    this.mservice.getdurationlists(this.user?.teamid).subscribe(
    (data: any) => {
      // console.log(data)
      this.data = data?.data
      this.teams=data?.total_info;
      let teamProgress = this.teams?.team_progress;
      let formattedProgress = teamProgress.toFixed(2); 
      // console.log(formattedProgress + '%'); 
    }
    
   
  )
  
  };

  exportreports(){
    this.TargetService.ExportTeamMembersDataView(this.user.teamid).subscribe((response:any)=>{
      const worksheet = XLSX.utils.json_to_sheet(response.message);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, 'table-data.xlsx');
        if(response?.status==false){
          this.toaster.error(response.message,'error')
        }
      })
  }

  getteamprog(userid: number){
    this.activitiesservice.getprog(userid).subscribe(
    (data: any) =>{ 
      this.teamprogress = data.progress,
      this.teamtarget=data.target
    });
  }
  getallreport(id:any,mid:any)
  {
    this.showMembersTable=true;
    setTimeout(() => {
      const container = this.scrollContainer.nativeElement; 
      const target = this.secondTable.nativeElement; 

      
      const targetPosition = target.offsetTop - container.offsetTop;

      container.scrollTo({
        top: targetPosition, // Scroll position
        behavior: 'smooth'
      });
    }, 100);
      this.activitiesservice.getalleport(id,mid).subscribe(
    (response: any) => {
      this.allreportlist=response;
      console.log(this.allreportlist,"yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy")
      this.isLoading = false; 
      if (this.allreportlist.length === 0) {
        this.noDataMessageShown = true;
      } else {
        this.noDataMessageShown = false; 
      }
    },
    (error) => {
      console.error('Error fetching activity reports', error);
      this.isLoading = false; 
      this.noDataMessageShown = true; 
    }
  );
  
  }
   

  
  onFilterChange(event:any){
    this.showMembersTable=false;
    
    this.subTypeArr = [];
    this.subType = null;
    if(event?.target?.value == 'Yearly'){
      const currentYear = new Date().getFullYear();
      const lastTenYear: any = currentYear - 10
      for(let i = currentYear;i >= lastTenYear; i-- ){
        this.subTypeArr.push({id:i,month:i})
      }
    }else if(event?.target?.value == 'Quaterly'){
      this.subTypeArr = [{id:'1-3',month:'January - March'},{id:'4-6',month:'April - June'},{id:'7-9',month:'July - September'},{id:'10-12',month:'October - December'}]
    }else{
      this.subTypeArr = this.originalSubArr;
    }
  }

getdurationlists(){
  this.showMembersTable=false;
this.mservice.getdurationlist(this.typeModel,this.subType,this.user.teamid).subscribe((result:any)=>{
 console.log(result,'ttttttttttttttttttttewqqqqqqqqqqqqqqqqqqqqqq')
  if(result?.data){
    this.data = result?.data;
    this.teams=result?.total_info;
    console.log("jjjjjjjjjjj", result?.total_info)

  }
})
}
}
