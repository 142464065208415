<div class="max-w w-full space-y-8  h-screen p-10 bg-white rounded-xl shadow-lg z-10 "
  style="height: 85vh !important; margin-top: 2vh !important;">
  <div class="flex flex-col sm:flex-row items-center mb-4">
    <h2 class="font-semibold text-lg mr-auto">Revenue Report</h2>

    <div class="block  mr-4">
      <input placeholder="Search" id="name" [(ngModel)]="searchControl" (input)="onChange()"
        class="appearance-none rounded-full  border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm
                     placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" autocomplete="off" />
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="mt-4 w-full overflow-x-auto">
  <table class="min-w-max w-full table-auto border-collapse border border-gray-300">
      <thead class="bg-gray-200 text-gray-600 text-sm">
        <tr class="border-b border-gray-300 ">
          <th class="py-3 px-6 text-center border border-gray-300" >Customer</th>
          <th class="py-3 px-6 text-center border border-gray-300" >Leads</th>
          <th class="py-3 px-6 text-center border border-gray-300" >Opportunities</th>
          <th class="py-3 px-6 text-center border border-gray-300" >Opportunity Revenue</th>
          <th class="py-3 px-6 text-center border border-gray-300" >Type</th>
          <th class="py-3 px-6 text-center border border-gray-300" >Cloudvice Sales Rep</th></tr>
      </thead>
      <tbody class="text-gray-600 text-sm font-light" *ngIf="revenueReportlist && revenueReportlist.length > 0; else noData">
        <tr *ngFor="let element of revenueReportlist | paginate: { itemsPerPage: 5, currentPage: p }" class="border-b border-gray-300 hover:bg-gray-100">
          <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.customer_name }}</td>
          <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.lead_name }}</td>
          <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.oppertunity_name_label }}</td>
          <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.oppertunity_revenue }}</td>
          <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.type }}</td>
          <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.cloudvice_sales_rep_name }}</td>
        </tr>
       
      </tbody>
    </table>
    <ng-template #noData><tr><td colspan="6" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
  </div>
  <pagination-controls (pageChange)="p = $event" class="float-right pagination mt-1"></pagination-controls>

</div>