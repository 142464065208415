<div class="max-w w-full  space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 height" >
  <div class="grid gap-8 grid-cols-1 ">
      <form class="" [formGroup]="registerForm" >
      <div class="flex flex-col ">
              <div class="flex flex-col sm:flex-row items-center mb-6">
                  <h2 class="font-semibold text-lg mr-auto">Send Mail</h2>
                 
                  <div class=" text-right md:space-x-3 md:block flex flex-col-reverse" *ngIf="!isEdit">
                    <a routerLink="/teamleader/activities" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                    <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg color" (click)="onSubmit()">Save</button>
                </div>
                <div class=" text-right md:space-x-3 md:block flex flex-col-reverse" *ngIf="isEdit">
                  <a routerLink="/teamleader/activities" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                    <a (click)="editemailactivity(cid,emailid)" class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg color">Save</a>
                </div>
              </div>
              <mat-divider></mat-divider>
                <div class="mt-3 bg-white rounded border border-gray-100  px-20">
            
                  <div class="flex flex-wrap -mx-3 mb-6">
                            
                    <div class="w-full md:w-1/2 p-3  md:mb-0">
                      <label class="flex  tracking-wide text-gray-700 text-sm font-bold mb-2" for="grid-first-name">
                      <input type="radio"  value="Individual" (change)="groupsind()" formControlName="mailtype" checked class="form-radio focus:outline-none focus:shadow-outline" />
                      <div class="select-none text-gray-700 px-2"> Individual</div> </label>
                    </div>
                    <div class="w-full md:w-1/2 p-3">
                        <label class="flex  tracking-wide text-gray-700 text-sm font-bold mb-2" for="grid-first-name">

                            <input type="radio" value="Group" (change)="groups()" formControlName="mailtype" class="form-radio focus:outline-none focus:shadow-outline" />
                            <div class="select-none text-gray-700 px-2"> Group</div> </label>
                    </div>
                   
                    <div class="w-full md:w-1/2 p-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                       Due Date
                      </label>
                      <input type="datetime-local" formControlName="edate"  [ngClass]="{ 'is-invalid': submitted && f['edate'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm "/>
                      
                    <div *ngIf="submitted && f['edate'].errors" class="invalid-feedback text-sm">
                      <div *ngIf="f['edate'].errors['required']">Please fill out this field.</div>
                  </div>
                </div>
                <div class="w-full md:w-1/4 p-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                  Hour
                  </label>
                  <input type="text" #hour formControlName="hour" [ngClass]="{ 'is-invalid': submitted && f['hour'].errors }" name="hour" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " placeholder="Hr"> 
                  <div *ngIf="submitted && f['hour'].errors" class="invalid-feedback text-sm">
                      <div *ngIf="f['hour'].errors['required']">Please fill out this field.</div>
                  </div>
                
                </div>
                <div class="w-full md:w-1/4 p-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                  Minute
                  </label>
                  <input type="text" #minute formControlName="minute"  [ngClass]="{ 'is-invalid': submitted && f['minute'].errors }" name="minute" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " placeholder="MM"> 
                  <div *ngIf="submitted && f['minute'].errors" class="invalid-feedback text-sm">
                      <div *ngIf="f['minute'].errors['required']">Please fill out this field.</div>
                  </div>
                
                </div>
                <div class="w-full md:w-full p-3 md:mb-0" *ngIf="isGroup">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                    Group
                      </label>
                     
                      <select  #group (change)="groupmembers(group.value)"  formControlName="groupid" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                     <option >Select Group</option>
                     <option *ngFor="let t of group_list" value="{{t.id}}">{{t.name}}</option>
                   </select>
               </div></div>
               <div class="flex flex-wrap -mx-3 mb-6">
                      
                <div class="w-full md:w-1/2 p-3 md:mb-0">
             
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                    Template
                      </label>
                       <select  #emailtemp (change)="emailtemplate(emailtemp.value)" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                      <option >Select Template</option>
                      <option *ngFor="let t of template" value="{{t.id}}">{{t.name}}</option>
                    </select>
                </div>
                <div class="w-full md:w-1/2 p-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                    Signature
                      </label>
                  <select  #emailsign (change)="emailsignature(emailsign.value)" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                    <option >Select Signature</option>
                    <option *ngFor="let s of esignature" value="{{s.id}}">{{s.name}}</option>
                  </select>
                </div>
               
              </div>
                 
                <div class="flex flex-wrap -mx-3 mb-6">
               
                  <div class="w-full  p-3  md:mb-0"  *ngIf="isGroup">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                  Recipients
                    </label>
                   <input type="text"  formControlName="contacts"  [ngClass]="{ 'is-invalid': submitted && f['contacts'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm" >
                    
               
             <div *ngIf="submitted && f['contacts'].errors" class="invalid-feedback text-sm">
              <div *ngIf="f['contacts'].errors['required']">Please fill out this field.</div>
          </div>
                  </div>
               
                  <div class="w-full  px-3 mb-6 md:mb-0"  *ngIf="!isGroup">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                  Recipients
                    </label>
                    <select  formControlName="contacts"  [ngClass]="{ 'is-invalid': submitted && f['contacts'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm" (change)="onContactChange($event)">
                      <option [value]=null selected disabled>Select</option>
                      <option *ngFor="let list of contact" value="{{list?.email}}">{{list?.email}}-{{list?.name}}</option>
               </select>
                    
               
             <div *ngIf="submitted && f['contacts'].errors" class="invalid-feedback text-sm">
              <div *ngIf="f['contacts'].errors['required']">Please fill out this field.</div>
          </div>
                  </div>
                  <div class="w-full  p-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                cc
                    </label>
                    <input type="text"  formControlName="cc" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                  
                  </div>
                  <div class="w-full  p-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                bcc
                    </label>
                    <input type="text"  formControlName="bcc" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                  
                  </div>    <div class="w-full  p-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                Subject
                    </label>
                    <input type="text"  formControlName="subject" [ngClass]="{ 'is-invalid': submitted && f['subject'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                    <div *ngIf="submitted && f['subject'].errors" class="invalid-feedback text-sm">
                      <div *ngIf="f['subject'].errors['required']">Please fill out this field.</div>
                  </div>
                  
                  </div>
                 
                </div> 
            
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full p-3 mb-6 md:mb-0">
                    <div class=" justify-center">
                      <!-- <angular-editor formControlName="message" [ngClass]="{ 'is-invalid': submitted && f['message'].errors }" class="h-10 justify-center" [(ngModel)]="htmlContent" [config]="config"></angular-editor> -->
                      <!-- <textarea 
                      formControlName="message"
                      [ngClass]="{ 'is-invalid': submitted && f['message'].errors }" 
                      id="editor" 
                      #editor 
                     >
                    </textarea> -->
                    <textarea id="editor" class="editor" formControlName="message"  [(ngModel)]="htmlContent"
                    [ngClass]="{ 'is-invalid': submitted && f['message'].errors }" ></textarea>
                  </div>
                  <div *ngIf="submitted && f['message'].errors" class="invalid-feedback text-sm">
                    <div *ngIf="f['message'].errors['required']">Please fill out this field.</div>
                </div>
                  </div>
                  <div class="w-full px-3 mb-6">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                Signature
                    </label>
                    <input type="text"  formControlName="signature" [ngClass]="{ 'is-invalid': submitted && f['signature'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                    <div *ngIf="submitted && f['signature'].errors" class="invalid-feedback text-sm">
                      <div *ngIf="f['signature'].errors['required']">Please fill out this field.</div>
                  </div>
                  </div>
                 </div>
              
                 
              </div>
           </div>
        </form>
    </div>
</div>