import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiurl } from '../global';

@Injectable({
  providedIn: 'root'
})
export class CompanyservService {
 
  constructor(private http: HttpClient) {    
  }

  companyAdd(data:any){
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');  
    return this.http.post(apiurl+"create-company",data,{headers:headers})
   }

   getOrganization(userid:any,filter:any){
    const params = filter;
    return this.http.get(apiurl+"company-list/"+ userid,{params})
   }
   organizationlist(teamid:any){
    return this.http.get(apiurl+'user/TeamOrganisationList/'+teamid+"/")
   }
   contactviwes(data:any){
    const params = data
    return this.http.get(apiurl+'user/TeamLeaderContactCreate_View/',{params})
   }

   getCompany(userid:any,search:any){
    const params: any = {search_name: search};
    return this.http.get(apiurl+"company-list/"+ userid,{params})
  }

   getorg(id:any){
    return this.http.get(apiurl+"company/detail/"+id+"/")
   }

   getaddress(id:any){
    return this.http.get(apiurl+"list-address/"+id+"/")
   }

  
   delCustomer(id:any){
    return this.http.delete(apiurl+"delete-company/"+id+"/")
   }

   delAddress(id:any){
    return this.http.delete(apiurl+"delete-address/"+id+"/")
   }

   
  companyEdit(data:any,id:any){
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');  
    return this.http.put(apiurl+"update-company/"+id+"/",data,{headers:headers})
   }

   addressEdit(data:any,id:any){
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');  
    return this.http.put(apiurl+"update-address/"+id+"/",data,{headers:headers})
   }

   getRandomRegisteryId(){
    return this.http.get(apiurl+"GetRandomRegistryId/")
   }


  
}
