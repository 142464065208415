import { Component, OnInit } from '@angular/core';
import { ContactFormService } from '../../services/contact-form.service';
import { LoginService } from '../../services/login.service';
import { QuotationListService } from '../../services/quotation-list.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivitiesService } from '../../services/activities.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule, DatePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-add-edit-activity',
  standalone: true,
  imports: [ReactiveFormsModule,MatDividerModule,CommonModule,MatFormFieldModule,MatDatepickerModule,MatNativeDateModule,RouterModule,MatInputModule,MatIconModule],
  providers : [ContactFormService,LoginService,QuotationListService,ActivitiesService],
  templateUrl: './add-edit-activity.component.html',
  styleUrl: './add-edit-activity.component.scss'
})
export class AddEditActivityComponent {

  // leadlist:any;
  activityForm!: FormGroup;
  user:any;
  // quotationlist:any;
  // opportunitylist:any;
  id = 0;
  activityDetails:any;
  userdata:any = JSON.parse(localStorage.getItem('userdata')!);
  typeList : any = []
 
  constructor(private contactservice: ContactFormService,private router:Router,private route: ActivatedRoute,private activityserve:ActivitiesService,private formBuilder: FormBuilder,private quotationserv:QuotationListService,private loginservice:LoginService, private toaster:ToastrService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.route.params.subscribe((params:any)=>{
      if (params['id']!=undefined){
        this.id=params['id']
        // console.log(this.id,'iddd')
      }
    })
    if(this.id){
      this.getActivityDetails(this.id)
    }
    this.setForm()
    
  }


  setForm(){
    this.activityForm = this.formBuilder.group({
      eventname: [this.activityDetails?.eventname||'', [Validators.required]],
      activity_type: [this.activityDetails?.activity_type||'', [Validators.required]],
      hour: [this.activityDetails?.hour||'', [Validators.required]],
      minute: [this.activityDetails?.minute||'', [Validators.required]],
      edate: [this.activityDetails?.edate||'', [Validators.required]],
      type: [this.activityDetails?.type||'', [Validators.required]],
      task: ['', [Validators.required]],
      description: [this.activityDetails?.description||'', [Validators.required]],
  },);
  }

  onSubmit(){
    this.activityForm.markAllAsTouched();
    const postData=this.activityForm.value
    postData['assignee'] =this.userdata?.data?.id;
    if(this.activityForm?.value?.type == 'lead'){
      postData['task'] = this.activityForm?.value?.task;
      postData['oppertunity_name'] = null;
      postData['quotation_name'] = null;
    }else if(this.activityForm?.value?.type == 'opportunity'){
      postData['oppertunity_name'] = this.activityForm?.value?.task;
      postData['task'] = null;
      postData['quotation_name'] = null;
    }else{
      postData['quotation_name'] = this.activityForm?.value?.task;
      postData['task'] = null;
      postData['oppertunity_name'] = null;
    }
    
    if (this.activityForm.valid){
      if(this.id == 0){
    this.activityserve.addActivity(postData).subscribe((response:any)=>{
    if(response.status == "success"){
      this.toaster.success(response.message,'success')
      this.router.navigate(['/executive/activities'])
    }
    })
  }else{
    this.activityserve.updateActivity(postData,this.id).subscribe((response:any)=>{
        // console.log(response,'resss')
        if(response.status == "success"){
          this.toaster.success(response.message,'success')
          this.router.navigate(['/executive/activities'])
        }
        })
  }
  }
  
}

  getActivityDetails(id:any){
    this.activityserve.activityDetails(id).subscribe((response:any)=>{
      this.activityDetails = response.data;
      this.setForm()
      if(this.activityDetails?.task){
        this.getTypeChange('lead');
        this.activityForm.get('task')?.setValue(this.activityDetails?.task)
      }else if(this.activityDetails?.oppertunity_name){
        this.getTypeChange('opportunity');
        this.activityForm.get('task')?.setValue(this.activityDetails?.oppertunity_name)
      }else if(this.activityDetails?.quotation_name){
        this.getTypeChange('quotation');
        this.activityForm.get('task')?.setValue(this.activityDetails?.quotation_name)
      }
    })
  }


  getTypeChange(event:any){
    this.typeList = [];
    this.activityserve.getActivityType(this.user?.data?.id,event).subscribe((res:any)=>{
      // console.log(res,'res eventttt');
      this.typeList = res?.data;
    })
  }

}
