<div class="max-w h-screen w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 mt-10">
    <div class="grid ">
      <form class="form-control" [formGroup]="opportunityForm">
        <div class="flex flex-col ">
          <div class="flex flex-col sm:flex-row items-center mb-6">
            <h2 class="font-semibold text-lg mr-auto">
              Opportunity / {{oid?'Edit':'New'}}</h2>
            <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>
  
            <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
              <a [routerLink]="['/executive/opportunity_list']"
                class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">
                Cancel </a>
              <button
                class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg" style="background-color: #2a303c;"
               (click)="oid? editOpportunity() :onSubmit()"  type="submit">Save</button>
            </div>
  
          </div>
          <mat-divider></mat-divider>
          <div class="mt-3 bg-white overflow-y-auto h-96 rounded border border-gray-100">
            <div class="form mt-3">
              <div>
  
                <div class="mt-4">
                  <div>
                    <div class="flex justify-between">
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          <span class="text-red-600">*</span> Customer :</label>
                          <select formControlName="customer"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" (change)="customerChange($event,'')">
                          <option value="">Select</option>
                          <option *ngFor="let list of organizationlist" [value]="list.id">{{list.name}}</option>
                        </select>
                        <div 
                          *ngIf="submitted && opportunityForm.get('customer')?.hasError('required')"
                          class="error-msg">
                          Customer is Required
                        </div>
                       
                      </div>
 
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          <span class="text-red-600">*</span> Lead ID :</label>
                        <select formControlName="lead_unique_id" (change)="getLeadData($event,'from_page')"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="">Select</option>
                          <option *ngFor="let list of leadlist"   [value]="list.id" [attr.data-unique-id]="list.unique_id">{{list?.unique_id}} - {{list?.name}}</option>
                        </select>
                        <div
                          *ngIf="submitted && opportunityForm.get('lead_unique_id')?.hasError('required')"
                          class="error-msg">
                          Lead ID is Required
                        </div>
                      
                      </div>

                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          <span class="text-red-600">*</span> Opportunity Name :</label>
                        <select formControlName="opportunity_name" 
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" >
                          <option value="">Select</option>
                          <option *ngFor="let list of leadlist" [value]="list.id">{{list.name}}</option>
                        </select>
                        <div 
                        *ngIf="submitted && opportunityForm.get('opportunity_name')?.hasError('required')"
                        class="error-msg">
                        Opportunity Name is Required
                      </div>
                      
                      </div>
  
                      
                    </div>
  
                    <div class="flex justify-between">
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          <span class="text-red-600">*</span> Contact :</label>
                          <select formControlName="contact"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="">Select</option>
                          <option *ngFor="let list of contactlist" [value]="list.id">{{list.first_name}} {{list?.last_name}}</option>
                        </select>
                        <div
                          *ngIf="submitted && opportunityForm.get('contact')?.hasError('required')"
                          class="error-msg">
                          Contact is Required
                        </div>
                       
                      </div><div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Description :</label>
                        <input type="text" formControlName="description" 
                          
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                        <div
                          *ngIf="submitted && opportunityForm.get('description')?.hasError('required')"
                          class="error-msg">
                          Description is Required
                        </div>
                        
                      </div>

                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          <span class="text-red-600">*</span> Opportunity Revenue :</label>
                        <input type="text" formControlName="oppertunity_revenue"
                          
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                        <div
                          *ngIf="submitted && opportunityForm.get('oppertunity_revenue')?.hasError('required')"
                          class="error-msg">
                          Opportunity Revenue is Required
                        </div>
                       
                      </div>

                    </div>
  
                    <div class="flex justify-between"><div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          <span class="text-red-600">*</span> Currency :</label>
                          <select formControlName="currency"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="">Select</option>
                          <option value="USD">USD</option>
                          <option value="INR">INR</option>
                          <option value="AED">AED</option>
                        </select>
                        <div
                          *ngIf="submitted && opportunityForm.get('currency')?.hasError('required')"
                          class="error-msg">
                          Currency is Required
                        </div>
                       
                      </div><div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          <span class="text-red-600">*</span> Type :</label>
                        <select formControlName="type"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="">Select</option>
                          <!-- <option value="HCM">HCM</option>
                          <option value="ERP">ERP</option>
                          <option value="OCI">OCI</option> -->
						              <option [value]="item" *ngFor="let item of typeArr">{{item}}</option>

                        </select>
                        <div *ngIf="submitted && opportunityForm.get('type')?.hasError('required')"
                          class="error-msg">
                          Type is Required
                        </div>
                       
                      </div>
                      <div class="p-2" >
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          <span class="text-red-600">*</span> Product :</label>
                        <!-- <select formControlName="type_product"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="" disabled="true">Select</option>
                          <option [value]="item" *ngFor="let item of productArr">{{item}}</option>
                          
                        </select> -->
                        <mat-select #cat placeholder="Select" formControlName="products_id"   class="multiple-select focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" multiple>
							          <mat-option class="select-checkbox">select</mat-option>
                        <mat-option class="select-option" *ngFor="let item of productArr" 
                          [value]="item.id">{{item.product_name}}</mat-option>
                          </mat-select>
                        <div *ngIf="submitted && opportunityForm.get('products_id')?.hasError('required')"
                          class="error-msg">
                          Product is Required
                        </div>
                        
                        </div>

                      </div>
                    <div class="flex justify-between">
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                           Opportunity Stage :</label>
                        <select formControlName="oppertunity_stage"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="">Select</option>
                          <option *ngFor="let list of oppertunitystatus" [value]="list?.name">{{list?.name}}</option>
                          
                        </select>
                        <!-- <div
                          *ngIf="submitted && opportunityForm.get('oppertunity_stage')?.hasError('required')"
                          class="error-msg">
                          Opportunity Stage is Required
                        </div> -->
                       
                      </div>
                      <div class="p-2">
                            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                              <span class="text-red-600">*</span> Forecast Probability (Percentage) :</label>
                              <select formControlName="forecast_probablity" (change)="changePeriod()"
                              class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                              <option value="">Select</option>
                              <option value="40" >40%</option>
                              <option value="50">50%</option>
                              <option value="60">60%</option>
                              <option value="70">70%</option>
                              <option value="80">80%</option>
                              <option value="90">90%</option>
                              <option value="100">100%</option>
                            </select>
                            <div *ngIf="submitted && opportunityForm.get('forecast_probablity')?.hasError('required')"
                              class="error-msg">
                              Forecast Probability is Required
                            </div>
                          
                          </div>

                          <div class="p-2">
                            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                              <span class="text-red-600">*</span> Forecast Period :</label>
                              <select formControlName="forecast_period"
                              class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                              <option value="">Select</option>
                              <option  *ngFor="let month of monthArr"  [value]="month">{{month}} Month</option>
                              </select>
                              <div *ngIf="submitted && opportunityForm.get('forecast_period')?.hasError('required')"
                              class="error-msg">
                              Forecast Period is Required
                            </div>
                            
                          </div>

                          

                        
                    </div>
  
                  
                    <div class="flex justify-between">
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Cloudvice Sales Rep :</label>
                         <!-- <select formControlName="cloudvice_sales_rep"
                         class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                         <option value="" disabled="true">Select</option>
                         <option *ngFor="let list of salesReplist" [value]="list.value">{{list.label}}</option>
                       </select> -->
                       <input type="text" formControlName="cloudvice_sales_rep"
          
                       class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                        <!-- <div *ngIf="opportunityForm.get('cloudvice_sales_rep')?.touched && opportunityForm.get('cloudvice_sales_rep')?.hasError('required')"
                          class="error-msg">
                          Cloudvice Sales Rep is required
                        </div> -->
                       
                      </div>

                      

                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                           Partner Company Name :</label>
                          <!-- <select formControlName="partner_sales_rep"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="" disabled="true">Select</option>
                          <option *ngFor="let list of partnerSalesReplist" [value]="list.value">{{list.label}}</option>
                        </select> -->
                        <input type="text" formControlName="partner_sales_rep"
						  
						 class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                        
                        <!-- <div *ngIf="opportunityForm.get('partner_sales_rep')?.touched && opportunityForm.get('partner_sales_rep')?.hasError('required')"
                          class="error-msg">
                          Partner Sales Rep is required
                        </div> -->
                        
                      </div>
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Referal / Campaign :</label>
                          <select formControlName="referal"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="" >Select</option>
                          <option value="web">Web</option>
                          <option value="email">Email</option>
                        </select>
                        <div *ngIf="submitted && opportunityForm.get('referal')?.hasError('required')"
                          class="error-msg">
                          Referal / Campaign is Required
                        </div>
                      
                      </div>

  
                     
                    </div>

                    <div class="flex ">
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          File :</label>
                          <ngx-file-drag-drop
                          formControlName="file"
                        activeBorderColor="#3F51B5" class="focus:ring-white w-64 file-uploader"
                        [multiple]="false"
                        (valueChanged)="onFileChange($event)"></ngx-file-drag-drop>
                          <div class="mt-2 cursor-pointer" *ngIf="this.opportunityDetails?.file != null" (click)="downloadFile()"> <mat-icon class="icon " >insert_drive_file</mat-icon>{{fileName}}</div>
                     </div></div>
                     <div class="flex">
                      <div class="p-2 w-full">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        Comments :</label>
                        <textarea formControlName="comments" rows="5" 
                        class="focus:ring-white w-full rounded-md focus:border-gray-200  border-gray-300  sm:text-sm"></textarea>
                      </div>
                      </div>
                </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
    </div>
  </div>
