<nav class="hidden md:flex justify-between items-center bg-white p-4 shadow-md h-16">
	<div class="navbar ">
		<div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
			
			<div class="flex-1 px-2">
				<svg xmlns="http://www.w3.org/2000/svg" (click)="openmenu()" [ngClass]="menuclass" class="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
				</svg>
			</div>
			
		</div>
		<div class="flex-none gap-2">
			
		<h1 class="font-semibold userlogin">{{uname|titlecase}}<br>
			<span class="colourchange">{{role|titlecase}}</span>
		</h1>
		

		<div class="dropdown dropdown-end">
			<label tabindex="0" (click) ="onProfileClick()" class="btn btn-ghost btn-circle avatar">
			<div class="w-10 rounded-full">
				<img src="../../../assets/img/user.png" />
			</div>
			</label>
			<ul tabindex="0"  *ngIf="isDropdownVisible" class="mt-3 p-2 shadow menu menu-compact dropdown-content bg-white rounded-box w-52">
			<!-- <li>
				<a class="justify-between">
				Profile
				<span class="badge">New</span>
				</a>
			</li>
			<li><a>Settings</a></li> -->
			<li><a routerLink="/superAdmin/change-passwordes/{{uid}}"  (click)="onChangePassword()">Change Password</a></li> 
			<li><a (click)="logout()">Logout</a></li>
			</ul>
		</div>
		</div>
	</div>
	<!-- <div>
		<input class="px-4 py-2 bg-gray-200 border border-gray-300 rounded focus:outline-none" type="text"
			placeholder="Search.."/>
	</div> -->
	<!-- <div>
		<button class="mx-2 text-gray-700 focus:outline-none"
				onclick="event.preventDefault(); document.getElementById('logout-form').submit();">
			<svg class="h-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
				viewBox="0 0 24 24" stroke="currentColor">
				<path
					d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path>
			</svg>
		</button>
	</div> -->
</nav>