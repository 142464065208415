import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { apiurl } from '../global';
import { BehaviorSubject } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class LoginService {
    isLogin = false;
    roleAs: any
	logindata = new EventEmitter<any>();
	public access_token:string = '';
	private progressupdate = new BehaviorSubject<boolean>(false);
	// getprogress= this.progressupdate.asObservable();

	constructor(
	private http:HttpClient
	) { }

	setprogress(data:any) 
	{
		this.progressupdate.next(data);
	}
	getProgress(){
		return this.progressupdate.asObservable()
	}
	setUserToken(data:any){
		localStorage.setItem("usertoken",JSON.stringify(data));
	}
	
	setUserdata(data:any){
		localStorage.setItem("userdata",JSON.stringify(data));
	}
	getUserdata(){
		let data:any = JSON.parse(localStorage.getItem('userdata')!);
	    return data
	}
	getUserToken(){
		let data:any = JSON.parse(localStorage.getItem('usertoken')!);
		return data;
	}
	

	onLogin(data:any){
		const headers = new HttpHeaders();
		headers.append('Content-Type','enctype/form-data');
		const fdata:FormData = new FormData();
		fdata.append('username',data['username'])
		fdata.append('password',data['password'])
		return this.http.post(apiurl+'api/token/',fdata,{headers:headers})
	}

	getUserDetails(data:any){
		const header = new HttpHeaders();
		header.append('Content-Type','application/json');
		const fdata = {
			'username':data['username'],
			'password':data['password']
		}
		return this.http.post(apiurl+'user/details/',fdata,{headers:header})
	}
	addProfile(result: any){
		
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');   
		return this.http.post(apiurl + 'user/register/',result,{headers:headers});
	  
		}
	getUser(){
        return this.http.get(apiurl + 'user/alluser/');
					 
		}
	listrRole(id:any){
		return this.http.get(apiurl + 'user/getrole/'+id+"/");
		
	}
	listMembers(id:any){
		return this.http.get(apiurl + 'user/allmember/'+id+"/");
	}
	exportmembers(id:any){
		return this.http.get(apiurl + 'user/ExportMembersDataView/'+id+"/");
	}
	exportTeams(id:any){
		return this.http.get(apiurl + 'user/ExportTeamsDataView/'+id+"/");
	}
	getOneMember(cid:number){
		return this.http.get(apiurl+"user/singlemember/"+cid+"/")
	  }	
	getOneTeam(cid:number){
	return this.http.get(apiurl+"rud_team/"+cid+"/")
	}
	editmember(result:any,id:any)
	{
	const headers = new HttpHeaders();
	headers.append('Content-Type','application/json');   
	return this.http.put(apiurl+"user/rud_member/"+id+"/",result,{headers:headers})
    }
	changepswd(result:any)
	{
	const headers = new HttpHeaders();
	headers.append('Content-Type','application/json');   
	return this.http.post(apiurl+"user/change_password/",result,{headers:headers})
    }
	forgetpass(result:any)
	{
	const headers = new HttpHeaders();
	headers.append('Content-Type','application/json');   
	return this.http.post(apiurl+"user/forgetpass/",result,{headers:headers})
    }
	listrExc(id:any){
		return this.http.get(apiurl + 'getsalesperson/'+id+"/");
		
	}
	listrTl(id:any){
		return this.http.get(apiurl + 'getteamlead/'+id+"/");
		
	}
	addTeam(result: any,id:any){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');   
		return this.http.post(apiurl + 'createteam/'+id+"/",result,{headers:headers});
	  
		}
	listTeam(id:any){
		return this.http.get(apiurl + 'Teamlist/'+id+"/");
	}
	listDuration(userid:any, durations:any,type:any){
		return this.http.get(`${apiurl}team-report/${userid}/?duration=${durations}&durationType=${type}`)

		}
	listteams(user_id:any){
	return this.http.get(`${apiurl}team-report/${user_id}/`)
	}
	listTeams(id:any){
		return this.http.get(apiurl + 'createteam/'+id+"/");
	}
	DeleteTeam(id:any){
		return this.http.get(apiurl + 'remove_team/'+id+"/");
	}
	singleTeam(id:any){
		return this.http.get(apiurl + 'rud_team/'+id+"/");
	}
	editteam(result:any,id:any)
	  {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');   
		return this.http.put(apiurl+"rud_team/"+id+"/",result,{headers:headers})
    }
	addTeamMemb(result:any,id:any){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');   
		return this.http.post(apiurl + 'createteammembers/'+id+"/",result,{headers:headers});
	  
		}
		statusactivate(userid:any,Status:any){
			return this.http.get(apiurl+`TeamMemberUpdateStatus/?userid=${userid}&status=${Status}`)	
		}
	singleTeamMem(id:any){
		return this.http.get(apiurl + 'createteammembers/'+id+"/");
	}
	addTeamMemberview(data:any){
		return this.http.post(apiurl +'AddTeamMemberView/',data);
	}
	addsubTeamMemberview(data:any){
		return this.http.post(apiurl +'AddSubTeamMemberView/',data);
	}

	singleMem(id:any){
		return this.http.get(apiurl + 'rud_teammembers/'+id+"/");
	}
	
	editmemberTrgt(result:any,id:any)
	  {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');   
		return this.http.put(apiurl+"rud_teammembers/"+id+"/",result,{headers:headers})
    }
	addBoard(data:any,colid:number){
		
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(apiurl+"pipeline/lc_board/"+colid+"/",data,{headers:headers})
	}
	listLeads(id:any){
		return this.http.get(apiurl + 'pipeline/tasklist/'+id+"/"+"role"+"/");
	}
	loggedIn()
	{
		const data  = localStorage.getItem('usertoken')
	    return !!localStorage.getItem('usertoken')
	}
	loggedRole()
	{
	  const data = localStorage.getItem("usergroup")
	  return !!data;

	}
	isLoggedIn() {
        const loggedIn = localStorage.getItem('usertoken');
        if (loggedIn == 'true')
          this.isLogin = true;
        else
          this.isLogin = false;
        return this.isLogin;
      }
    
      getRole() {
		  return this.roleAs = localStorage.getItem('usergroup');
          return this.roleAs;
      }
	  listRoleMembers(id:any){
		return this.http.get(apiurl + 'user/getmembersrole/'+id);
	   }

	   removeteammember(id:any){
		return this.http.get(apiurl + `RemoveTeamMember/?team_member_id=${id}`);
	   }
	   deleteteammember(memberid:any,adminid:any){
		return this.http.get(apiurl+`user/DeleteMemberFromAdmin/?member_id=${memberid}&admin_id=${adminid}`)
	   }
   
	   }
	   
