import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ActivitiesService } from '../../services/activities.service';
import { LoginService } from '../../services/login.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule, DatePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';



@Component({
  selector: 'app-add-activity-modal',
  standalone: true,
  imports: [ReactiveFormsModule,MatDividerModule,CommonModule,MatFormFieldModule,MatDatepickerModule,MatNativeDateModule,RouterModule,MatInputModule,MatIconModule],
  providers : [ActivitiesService,LoginService,MatDatepickerModule],
  templateUrl: './add-activity-modal.component.html',
  styleUrl: './add-activity-modal.component.scss'
})
export class AddActivityModalComponent {
  constructor(private dialog: MatDialog,private formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<AddActivityModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,private ActivitiesService : ActivitiesService,private loginservice : LoginService ) { }

    addactivityForm!: FormGroup;
    user : any 
    activityName : any = ''
    activityId : any ;
    submitted = false;

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.setForm()
  }

  setForm(){
    this.addactivityForm = this.formBuilder.group({
      eventname: ['', Validators.required],
      activity_type: ['',Validators.required],
      hour: ['',Validators.required],
      minute: ['',Validators.required],
      edate: ['',Validators.required],
      description: ['',Validators.required],
    })
  }
  close() {
	 this.dialogRef.close();
  }

  onSubmit(){
    this.addactivityForm.markAllAsTouched();
    if(this.addactivityForm?.valid){
      if(this.data?.type){
        this.activityName = 'task';
        this.activityId   = this.data?.id
      
      }
      const postData = this.addactivityForm.value;
      postData.user =this.user?.data?.id
  
      this.ActivitiesService.addActivities(this.activityName,this.activityId,postData).subscribe((res:any)=>{
        if(res){
          this.close()
          
          // this.toaster.success('Successfully submitted','success')
        }
      })
    }else{
      return;
    }
  
  }

}
