<div class="max-w w-full space-y-8  h-screen p-10 bg-white rounded-xl shadow-lg z-10 "style="height:85vh !important; margin-top: 2vh !important;">
    <div class="flex flex-col sm:flex-row items-center mb-4">
        <h2 class="font-semibold text-lg mr-auto">Activity</h2>


        <div class=" text-right pr-2 md:space-x-3 md:block flex flex-col-reverse">
          <a  [routerLink]="['/executive/add-activities']" class="mb-2 md:mb-0 px-5 bg-green-400 text-white py-2 text-sm shadow-sm font-medium tracking-wider border rounded-full hover:shadow-lg " style="background-color: #2a303c;"> Create </a>
      </div>
    </div>
    <mat-divider></mat-divider>
<div class="mt-6">
  <table class="min-w-max w-full table-auto border-collapse border border-gray-300">
    <thead>
        <tr class="bg-gray-200 text-gray-600 text-sm">
            <th class="py-3 px-6 text-center border border-gray-300">Activity Ref </th>
            <th class="py-3 px-6 text-center border border-gray-300">Customer</th>
            <th class="py-3 px-6 text-center border border-gray-300">Due Date</th>
            <th class="py-3 px-6 text-center border border-gray-300">Status</th>
            <th class="py-3 px-6 text-center border border-gray-300">Description</th>
            <th class="py-3 px-6 text-center border border-gray-300">Action</th>
        </tr>
    </thead>
    <tbody class="text-gray-600 text-sm" *ngIf="activitylist && activitylist.length > 0; else noData">
        <tr *ngFor="let element of activitylist | paginate: { itemsPerPage: 5, currentPage: p } " class="border-b border-gray-300 hover:bg-gray-100">
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                {{element.activity_reference}}
            </td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                {{element.customer_name}}
            </td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
               {{element.edate | date :'dd/MM/yyyy'}}
            </td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                {{element.scheduled_status}}
            </td>
             <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                
            {{element.description}} </td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                <div class="flex justify-center space-x-2">
                    <span class="bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer">
                        <a [routerLink]="['/executive/edit-activities',element.id]">
                            <i class="fa fa-pencil"></i>
                        </a>
                    </span>
                    <span class="bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer">
                        <a (click)="deleteActivity(element)">
                            <i class="fa fa-trash"></i>
                        </a>
                    </span>
                </div>
            </td>
        </tr>
        <!-- <tr *ngIf="activitylist?.length === 0">
            <td colspan="6" class="text-center  font-semibold text-lg pt-9">No Data Found</td>
          </tr> -->
    </tbody>
</table>
<ng-template #noData><tr><td colspan="6" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
<pagination-controls (pageChange)="p = $event" class="float-right mt-1"></pagination-controls>


</div>
</div>

