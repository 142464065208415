import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { QuotationListService } from '../quotation-lists/quotation-list.service';
import { LoginService } from '../../services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { apiurl } from '../../global';
import { SuccessComponent } from '../../success/success.component';
import { FailComponent } from '../../fail/fail.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
declare var jspdf: any;
declare const html2canvas: any;
declare const numberToWords: any; 


@Component({
  selector: 'app-quotation-generations',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule,RouterModule,MatDividerModule],
  templateUrl: './quotation-generations.component.html',
  styleUrl: './quotation-generations.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class QuotationGenerationsComponent implements OnInit {
  showTable:boolean=false
  data:any
  configlist:any
  list:any;
  private cid:any
  public user:any
  public grandtotal:any
  public grandtotal_word:any
  expiration: any;
  date: any;
  pdfBlob :any ;
  downloadFlag = true;
  constructor( private dialog: MatDialog,private router: Router,private route: ActivatedRoute,private qservice:QuotationListService,private loginservice:LoginService) { }

  ngOnInit(): void {
    this.user=this.loginservice.getUserdata()
    this.route.paramMap.subscribe(params => {
      this.cid = params.get('id')
      this.getquotation(this.cid)
    })
    this.configurationlist()
  }
  getquotation(id:any){
    this.qservice.getSingleQuotation(id).subscribe(
      (data: any) =>{
        console.log(data,'data uuuu')
         this.list = data;
         this.expiration=this.list?.expiration
         this.expiration= new Date(this.expiration).toISOString().split('T')[0]
         this.date=this.list?.date
         this.date= new Date(this.date).toISOString().split('T')[0]
         let s_amount= data?.approved_amount ? parseInt(data?.approved_amount) : parseInt(data?.quotation_revenue)
         let g_amount= data?.gst_amount ? parseInt(data?.gst_amount) : 0
        //  console.log(s_amount,g_amount,'hnjjjjjj')
         this.grandtotal= s_amount + g_amount
         this.grandtotal_word= numberToWords.toWords(this.grandtotal); 
    });
  }
  configurationlist(){
    this.qservice.configurationlist(this.user.teamid).subscribe((response:any)=>{
      this.configlist=response[0]
    })
  }

  public captureScreen() {
    this.showTable = true;
    this.downloadFlag  = false;
    this.data = document.getElementById("contentToConvert");
    const manageHeader = document.querySelector('.hide-in-pdf') as HTMLElement;
    const manageColumns = document.querySelectorAll('.hide-in-pdf');

    manageColumns.forEach((col) => col.classList.add('hidden'));
    html2canvas(this.data, { scale: 2 }).then((canvas: any) => {
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
  
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4");
      let position = 0;
  
      // Add the first image
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      // If content overflows, add new pages
      while (heightLeft > 0) {
        position = heightLeft - imgHeight; // Adjust position
        pdf.addPage();
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("quotation.pdf");
      manageColumns.forEach((col) => col.classList.remove('hidden'));
    });
  }

  sendnow(id:any){
    this.showTable = true;
    this.data = document.getElementById('contentToConvert');
    if (!this.data) return;
    html2canvas(this.data, { scale: 2 }).then((canvas: HTMLCanvasElement) => {
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('p', 'mm', 'a4');
      let position = 0;

      // Add the first image
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // If content overflows, add new pages
      while (heightLeft > 0) {
        position = heightLeft - imgHeight; // Adjust position
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Convert PDF to Blob and Upload
      this.pdfBlob = pdf.output('blob');

      // Send the PDF directly as FormData
      // this.uploadPDF(pdfBlob);
      if(this.pdfBlob != undefined){
        let data_val1={
          "status":'Send'
        }
        this.qservice.Editquotation(data_val1,id).subscribe((reponse:any) => {
          this.router.navigate(['teamleader/quotation/']);
         });
         let type='quotation';
        //  console.log(this.pdfBlob,'pdfblob')
         const formdata = new FormData()
         formdata.append('file',this.pdfBlob)
         this.qservice.getquotesend(id,type,formdata).subscribe(
          (data: any) =>{
            if(data?.message == 'Quotation invoice sended successfully'){
              const confirmDialog = this.dialog.open(SuccessComponent, {
                data: {
                  title: 'Success',
                  message: 'Send Succesfully!'
                }
              })
              confirmDialog.afterClosed().subscribe(result => {
                if (result == true) {
                 this.router.navigate(['teamleader/quotation/']);
                }
              });
            }
        });
     
      
      }
    });
    
 
    
  }

  Editproduct(cid:number){
		
    this.router.navigate(['/teamleader/edit-product',cid]);

	}
}
