import { Component, NgModule, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule,Router, RouterOutlet } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var XLSX: any;

@Component({
  selector: 'app-member',
  standalone: true,
  imports: [CommonModule,NgxPaginationModule,MatDialogModule,MatDividerModule,RouterModule,RouterOutlet],
  templateUrl: './member.component.html',
  styleUrl: './member.component.scss'
})
export class MemberComponent implements OnInit {
  public user:any
  adminList:any[]=[]
  p: number = 1;
  constructor(private loginservice:LoginService,private router: Router, private toaster:ToastrService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.listMember()
  }

  listMember(){
    
    this.loginservice.listMembers(this.user.data.id).subscribe((response:any)=>{
      
      // for (let i of response){ 
      //   if(i.role=='admin'){
      //     this.adminList.push(i);
      //   }
      // }
      this.adminList=response;
      
      
    })
   
  }

  removeuser(userid:any,status:any)
  {
    if(status==true){

      status='Active'
    }
    else{
      status='Deactive'
    }
    this.loginservice.statusactivate(userid,status).subscribe((result:any)=>{
     console.log(result,'resultissssss')
    this.listMember();

    })

    // this.mservice.removemember(id).subscribe((response:any)=>{
     
    //   this.listMember();
    // })
  }

  // changeRole(id:any){
  //   let data={
  //     "creator_id":this.user.data.id,
  //     "role":id
  //   }
  //   console.log(data,"%%%%%%%%%%%%%555");
    
  // }
  exportmembers(){
    this.loginservice.exportmembers(this.user.data.id).subscribe((response:any)=>{
     
      const worksheet = XLSX.utils.json_to_sheet(response.message);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, 'table-data.xlsx');
    
    if(response?.status==false){
      this.toaster.error(response.message,'error')
    }

      
    })
  }

}
