<div class="row mb-3">
	<h1 class="font-bold mb-2 text-align-center color mt-2" style="text-align: center;">{{data.title}} </h1>
	<div class="float-right cursor-pointer " style="margin-top:-6vh !important" [mat-dialog-close]="false">
		<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 21 21">
			<path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
				d="m7.5 7.5l6 6m0-6l-6 6" />
		</svg>
	</div>
</div>
<form class="" [formGroup]="registerForm"
	style="height: auto !important; overflow-y: auto ; width: 90vh !important; padding-left: 3vh;padding-right: 3vh !important;">
	<div class="flex flex-wrap px-2 pb-2">
		<div class="w-1/2 px-1">
			<div class="pb-1 pl-1">
				<label for="email" class="block text-xs font-medium text-gray-700">
					Task
				</label>
			</div>
			<div class="flex-grow">
				<input type="text" #name formControlName="name"
					[ngClass]="{ 'is-invalid': submitted && f['name'].errors }"
					class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
					placeholder="Name">
				<div *ngIf="submitted && f['name'].errors" class="invalid-feedback text-sm">
					<div *ngIf="f['name'].errors['required']">Please fill out this field.</div>
				</div>
			</div>
		</div>
		<div class="w-1/2 px-1">
			<div class="pb-1 pl-1">
				<label for="email" class="block text-xs font-medium text-gray-700">
					Email
				</label>
			</div>
			<div class="flex-grow ">
				<input type="text" #email formControlName="email" name="email"
					[ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
					class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
					placeholder="">
				<div *ngIf="submitted && f['email'].errors" class="invalid-feedback text-sm">
					<div *ngIf="f['email'].errors['required']">Please fill out this field.</div>
					<div *ngIf="f['email'].errors['email']">Email must be a valid email address</div>
				</div>
			</div>
		</div>

	</div>
	<div class="flex flex-wrap px-2 pb-2">
		<div class="w-1/2 px-1">
			<div class="pb-1 pl-1">
				<label for="AppName" class="block text-xs font-medium text-gray-700">
					Phone
				</label>
			</div>
			<div class="flex-grow ">
				<input type="text" #phone formControlName="phone" name="phone"
					[ngClass]="{ 'is-invalid': submitted && f['phone'].errors }"
					class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
					placeholder="">

				<div *ngIf="submitted && f['phone'].errors" class="invalid-feedback text-sm">
					<div *ngIf="f['phone'].errors['required']">Please fill out this field.</div>
					<div *ngIf="f['phone'].errors['pattern']">Mobile must be a valid mobile address</div>
				</div>
			</div>
		</div>
		<div class="w-1/2 px-1">
			<div class="pb-1 pl-1">
				<label for="AppName" class="block text-xs font-medium text-gray-700">
					Expecting Revenue
				</label>
			</div>
			<div class="flex-grow ">
				<input type="text" #budget formControlName="budget"
					[ngClass]="{ 'is-invalid': submitted && f['budget'].errors }"
					class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
					placeholder="₹ 0.00">
				<div *ngIf="submitted && f['budget']?.errors" class="invalid-feedback text-sm">
					<div *ngIf="f['budget']?.errors?.['required']">Please fill out the budget.</div>

				</div>
			</div>
		</div>
	</div>
	<div class="flex flex-wrap px-2 pb-2">
		<div class="w-full px-1">
			<div class="pb-1 pl-1">
				<label for="email" class="block text-xs font-medium text-gray-700">
					Category
				</label>
			</div>
			<div class="flex-grow ">

				<select formControlName="category" #cat [ngClass]="{ 'is-invalid': submitted && f['category'].errors }"
					(change)="Onchangecat(cat.value)"
					class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300">
					<option [value]=null selected disabled>Select</option>
					<option value="service">Service</option>
					<option value="product">Product</option>

				</select>
				<div *ngIf="submitted && f['category'].errors" class="invalid-feedback text-sm">
					<div *ngIf="f['category'].errors['required']">Please fill out this field.</div>
				</div>
			</div>
		</div>
	</div>
	<div class="flex flex-wrap px-2 pb-2">
		<div class="w-full px-1" *ngIf="isCatpro && !isCatser">
			<div class="pb-1 pl-1">
				<label for="email" class="block text-xs font-medium text-gray-700">
					Product
				</label>
			</div>
			<div class="flex-grow ">
				<!-- <select multiple formControlName="product" #cat (change)="Onchangecat(cat.value)"
					class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300">
					<option [value]=null selected disabled>Select</option>
					<option *ngFor="let list of productlist" value="{{list?.id}}">{{list?.product_name}}</option>

				</select> -->
				<mat-select #cat placeholder="Select" formControlName="product"    (change)="Onchangecat(cat.value)"   class="multiple-select focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300" multiple>
					<mat-option class="select-option" *ngFor="let item of productlist" 
					[value]="item.id">{{item.product_name}}</mat-option>
				  </mat-select>
			</div>
		</div>
	</div>
	<div class="flex flex-col px-2 pb-2" *ngIf="isCatser && !isCatpro">
		<div class="pb-1 pl-1">
			<label for="email" class="block text-xs font-medium text-gray-700">
				Service title
			</label>
		</div>
		<div class="flex-grow ">
			<input type="text" #name formControlName="servicetitle"
				[ngClass]="{ 'is-invalid': submitted && f['name'].errors }"
				class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300">
			<div *ngIf="submitted && f['servicetitle'].errors" class="invalid-feedback text-sm">
				<div *ngIf="f['servicetitle'].errors['required']">Please fill out this field.</div>
			</div>
		</div>
	</div>
	<div class="flex flex-col px-2 pb-2" *ngIf="isCatser && !isCatpro">
		<div class="pb-1 pl-1">
			<label for="email" class="block text-xs font-medium text-gray-700">
				Service description
			</label>
		</div>
		<div class="flex-grow ">
			<textarea formControlName="servicedes"
				class="relative outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline"></textarea>

		</div>
	</div>
	<div class="flex justify-center ">
		<div class="flex border rounded-md border-blue-800 bg-blue-600 hover:bg-blue-400 content-center mx-1">
			<button class="px-2 py-1 text-xs text-gray-100 w-32 " (click)="onSubmit(registerForm.value)"> Add </button>
		</div>
	</div><br>
</form>