import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "search",
  standalone: true,
})
export class SearchPipe implements PipeTransform {
  transform(contact: any[], value: (string | null)[], key: string[]): any {
    if (!contact || !value || !key) {
      return contact; // Return original if inputs are invalid
    }

    value.forEach((name: string | null, index: number) => {
      if (name) {
        contact = contact.filter((item) => {
          return (item[key[index]]
            ?.toString()
            .toLowerCase()
            .indexOf(name.toString().toLowerCase()) !== -1);
        });
      }
    });

    return contact;
  }
}
