import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { MainService } from '../../main-service';
import { PipelineService } from '../drag-drops/pipeline.service';
import { ConfirmedValidator } from '../../confirmed.validator';
import { SuccessComponent } from '../../success/success.component';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-add-meberss',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule,RouterModule,MatDividerModule],
  templateUrl: './add-meberss.component.html',
  styleUrl: './add-meberss.component.scss'
})
export class AddMeberssComponent implements OnInit {
  registerForm!: FormGroup;
  submitted = false;
  public user:any
  public cid:any
  public isEdit:boolean = false;
  public isUserExist:boolean = false;
  mainservice: any;
  roles:any[]=[]
  constructor(private formBuilder: FormBuilder,private dialog: MatDialog, private router: Router,private route: ActivatedRoute,private mservice:MainService, private loginservice:LoginService,private pservice:PipelineService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.form()
    this.route.paramMap.subscribe(params => {
      this.cid = params.get('id')
     
      if(this.cid != null){
        
          this.isEdit = true;
      this.loginservice.getOneMember(this.cid).subscribe((data:any)=>{
                  
          console.log(data[0].phone,"dataaaa")
        
          this.registerForm.setValue({
             name:data[0].user.username,
              address:data[0].address,
              role:data[0].role,
              phone:data[0].phone,
              email:data[0].user.email,
              password:data[0].user.password,
              confirm_password:data[0].user.password

          })
      })
    
  }
  else
  {
      this.isEdit = false;
  this.registerForm.reset();
  }
    });

  this.listRole()
  }
  form() {
	  this.registerForm = this.formBuilder.group({
		  name: ['', Validators.required],
		  email: ['', [Validators.required, Validators.email]],
		  phone:['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
		  role:[null,Validators.required],
      address:[''],
		  password: ['', [Validators.required]],
		  confirm_password: ['', [Validators.required]]
	  
  }, { 
	validator: ConfirmedValidator('password', 'confirm_password')
  }
	   );
	  
  }
  userexist(e:any)
{
  console.log(e,'sdfdsaf')
  this.mservice.userexist(e).subscribe((reponse:any) => {
  console.log(reponse);
  if(reponse=="User Exists")
  {
    this.isUserExist=true
  }
  else{
    this.isUserExist=false
  }
      
});
}
  get f() { return this.registerForm.controls; }

  onSubmit(colid:any) {
    this.submitted = true;
      if (this.registerForm.invalid) {
          return;
          
      } 
      
      // const formdata = new FormData();
      
     // this.registerForm.value['image'] = apiurl+this.img.name
      // stop here if form is invalid
      // 
     ;

     let data_r={
      "username":this.registerForm.value.email,
      "first_name":this.registerForm.value.name,
      "email":this.registerForm.value.email,
      "password":this.registerForm.value.password,
      "profile": {
        "phone":this.registerForm.value.phone,
        "address":this.registerForm.value.address,
        "role":this.registerForm.value.role,
        "creator_id":this.user.data.id,
      }
      
     }
    
     this.loginservice.addProfile(data_r).subscribe((response:any)=>{

     console.log(response,"add mmbr rsss");
     console.log(data_r,"add mmbr data");
     
      
     const confirmDialog = this.dialog.open(SuccessComponent, {

				data: {
				  title: 'Success',
				  message: 'Added Succesfully!'
				}
			  })
			  confirmDialog.afterClosed().subscribe(result => {
          
          if (result == true) {
            this.router.navigate(['teamleader/members'])
          }


		    });

        let data_board={
          "name":this.registerForm.value.name,
          "user_id":response.id
        }
        let cname='New'
        this.pservice.addBoard(data_board,0).subscribe((response_b:any)=>{
          this.pservice.addColumns(response_b.id, cname).subscribe(
            (data: any) => {
              console.log(data)
             
            }
          )
        })
        
        let data_cr={
          "team_id":this.user.teamid,
          "team_member_id":response.id,
          "target":0,
          
        }
    
        this.loginservice.addTeamMemb(data_cr,0).subscribe((response_t:any)=>{
        })
        
         }); 
         

  }
  editcontact(cid:number){
    console.log(cid)
    let data_r={
      "username":this.registerForm.value.name,
      "email":this.registerForm.value.email,
      "password":this.registerForm.value.password,
      "profile": {
        "phone":this.registerForm.value.phone,
        "address":this.registerForm.value.address,
        "role":this.registerForm.value.role,
        "creator_id":this.user.data.id,
      }
      
     }
this.loginservice.editmember(data_r,cid).subscribe((reponse:any) => {
  //console.log(reponse);
      
});
}
    onReset() {
      this.submitted = false;
      this.registerForm.reset();
  }
   

  listRole(){
    this.loginservice.listRoleMembers(this.user.data.id).subscribe((response:any)=>{
      this.roles = response?.data?.roles;
  })
  }



}
