<div class="max-w w-full space-y-8  p-10 bg-white rounded-xl shadow-lg z-10 mb-15">
    <div class="flex flex-col sm:flex-row items-center mb-2">
        <h2 class="font-semibold  text-lg mr-auto">Admin - Team</h2>
        <div class="w-full sm:w-auto sm:ml-auto mt-2 sm:mt-0"></div>

        <!-- <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
            <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
                <a routerLink="/admin/add-team"  class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Create </a>
                <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-green-500">Export</button>
            </div>
        </div> -->
    </div>
    <mat-divider></mat-divider>
    
    <div class='overflow-x-auto overflow-y-auto  w-full'>
        <table class="min-w-max w-full table-auto">
            <thead>
                <tr class="bg-gray-200 text-gray-600 text-sm">
                    <th class="py-3 px-6 text-center">Team Name</th>
                    <th class="py-3 px-6 text-center">Target</th>
                    <th class="py-3 px-6 text-center">Progress</th>
                    <th class="py-3 px-6 text-center">Manage</th>
                </tr>
            </thead>
         
            <tbody class="text-gray-600 text-sm font-light">
                <tr  class="border-b border-gray-200 hover:bg-gray-100" *ngFor='let item of teamList  | paginate: { itemsPerPage: 10, currentPage: p }' >
                    <td class="py-3 px-6 text-center whitespace-nowrap">
                        {{item.teamname}}
                    </td>
                    <td class="py-3 px-6 text-center">
                        {{item.target}}
                    </td>
                    <td class="py-3 px-6 text-center">
                        {{item.progress}}%
                    </td>
                    <td class="py-3 px-6 text-center">
                        <div class="flex items-center ml-4" style="justify-content: center;">
                            <a routerLink="/superAdmin/team-member-report/{{item.creator_id}}/{{item.id}}" title="View" ><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                              </svg>
                            </a>
                            <!-- <a routerLink="/admin/add-team-member/{{item.user.id}}" title="Edit" ><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                              </svg>
                            </a> -->
                            <!-- <a  title="Delete" (click)="delete(item.id)" ><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                              </svg>
                            </a> -->
                            
                            <!-- <span class="text-base">{{item.configuser.first_name}}</span> -->
                        </div>
                    </td>
                </tr>
            
            </tbody>
        </table>
        <pagination-controls (pageChange)="p = $event" class="float-right mt-5"></pagination-controls>
    </div>        
    
</div>
<!-- *ngFor='let item of data  | paginate: { itemsPerPage: 10, currentPage: p }'  -->