<div class="max-w w-full space-y-8 h-screen mt-5  p-10 bg-white rounded-xl shadow-lg z-10 " style="height: 85vh !important; ">
    <div class="flex flex-col sm:flex-row items-center mb-4">
        <h2 class="font-semibold text-lg mr-auto">Accounts List</h2>
        

         <div class="block relative mr-4" *ngIf="view == 'list'">
                <input placeholder="Search"  id="name" [(ngModel)]="searchControl"  (input)="filter()"
                    class="appearance-none rounded-full  border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm
                     placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" autocomplete="off" />
        </div>

        <div class=" mb-6 mr-4 mt-3">
          <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
            </label>
        <select [(ngModel)]="view" (click) = "$event.stopPropagation()"
        class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
        <option value="">Choose an option</option>
        <!-- <option value=""  >View</option> -->
        <option  value="card">Card</option>
        <option value="list"  >List</option>
      </select>
      </div>
        
        <div *ngIf="view == 'list'"> <mat-icon [matMenuTriggerFor]="animals" class=" mr-4 icon cursor-pointer">filter_list</mat-icon> </div>
        <mat-menu class="custom-menu" #animals="matMenu">
        
      <div class="p-2">
        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
          Classification</label>
      <select [(ngModel)]="classificationFilter" (click) = "$event.stopPropagation()"
      class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
      <option value="">Select</option>
      <option value="Public">Public</option>
      <option value="Corporate">Corporate</option>
      <option value="Healthcare">Healthcare</option>

    </select>
    </div>
    <div class="p-2">
      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
        City</label> 
        <div class="flex justify-between ">
        <select [(ngModel)]="cityFilter" (click) = "$event.stopPropagation()"
      class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
      <option value="">Select</option>
      <option  *ngFor="let city of cityList" [value]="city.city">{{city.city}}</option>
    </select>
      </div>
  </div>
      <div class="p-2">
        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
          State</label>
          <div class="flex justify-between ">
          <select [(ngModel)]="stateFilter" (click) = "$event.stopPropagation()"
      class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
      <option value="">Select</option>
      <option  *ngFor="let state of stateList" [value]="state.state">{{state.state}}</option>
    </select>
        </div>
    </div>
    <div class="p-2">
      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
        Country</label>
        <div class="flex justify-between">
        <select [(ngModel)]="countryFilter" (click) = "$event.stopPropagation()"
      class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
      <option value="">Select</option>
      <option *ngFor="let country of countryList" [value]="country.country">{{country.country}}</option>
    </select>
      </div>
  </div>

    <div class="p-2 flex justify-between ">
      <button (click)="clearfilter()" class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">Clear</button>         
       <button (click)="filter()" class="mb-2 md:mb-0 bg-blue-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-blue-600">Filter</button>   
      </div>
        </mat-menu>

        <div class="text-right md:space-x-3 md:block flex flex-col-reverse">
          <a routerLink="/executive/customer-add/"  class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-white rounded-full hover:shadow-lg " style="background-color: #2a303c;"> Create </a>
        
        
      </div>
      </div>
    <mat-divider></mat-divider>
      <div *ngIf="view === 'list'" class="mt-4 w-full overflow-x-auto">
      <table  class="min-w-full table-auto border-collapse border border-gray-300 ">
          <thead class="bg-gray-200 text-gray-600 text-sm">
          <tr class="border-b border-gray-300 ">
            <th class="py-3 px-6 text-center border border-gray-300" >Registry id</th>
            <th class="py-3 px-6 text-center border border-gray-300" > Customer Name</th>
            <th class="py-3 px-6 text-center border border-gray-300" >Email</th>
            <th class="py-3 px-6 text-center border border-gray-300" >Phone Number</th>
            <th class="py-3 px-6 text-center border border-gray-300" >City</th>
            <th class="py-3 px-6 text-center border border-gray-300" >State</th>
            <th class="py-3 px-6 text-center border border-gray-300" >Country</th>
            <th class="py-3 px-6 text-center border border-gray-300" >classification</th>
            <th class="py-3 px-6 text-center border border-gray-300" >Action</th>
          </tr>
        </thead>
        <tbody class="text-gray-600 text-sm" *ngIf="organizationlist && organizationlist.length > 0; else noData">
          <tr *ngFor="let element of organizationlist | paginate: { itemsPerPage: 5, currentPage: p }" class="border-b border-gray-300 hover:bg-gray-100">
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.registry_id }}</td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.name }}</td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.email }}</td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.phoneNumber }}</td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.city }}</td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.state }}</td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.country }}</td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.classification }}</td>
            <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap"> <mat-icon class="cursor-pointer" [routerLink]="['/executive/customer-edit',element.id]">edit</mat-icon> 
              <mat-icon class="cursor-pointer" (click)="deleteCustomer(element)">delete</mat-icon></td>
          </tr>
          <!-- <tr *ngIf="organizationlist?.length === 0">
            <td colspan="9" class="text-center font-semibold text-lg py-4">No Data Found</td>
          </tr> -->
        </tbody>
      </table>
      <ng-template #noData><tr><td colspan="9" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>

</div>
<pagination-controls (pageChange)="p = $event" class="float-right mt-1 pagination" *ngIf="view === 'list'"></pagination-controls>

<div  *ngIf="view === 'card'"  class="mt-4  overflow-auto w-full" style="height:50vh !important ;"><app-customer-list></app-customer-list> </div>
</div>


