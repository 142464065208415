<ul>
	<li id="dashboard" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100  text-sm cursor-pointer " [class.active]="activeLink === 'dashboard'"
	(click)="setActiveLink('dashboard')" routerLink="/teamleader" >
	<svg xmlns="
	http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 icons"
	 viewBox="0 0 20 20"><path fill="currentColor" d="M3 6a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3zm3-2a2 2 0 0 0-2 2v1.058l5.5-.053V4zm4.5 0v8H16V6a2 2 0 0 0-2-2zm5.5 9h-5.5v3H14a2 2 0 0 0 2-2zm-6.5 3V8.005L4 8.058V14a2 2 0 0 0 2 2z"/></svg>

		<a  class="text-white">                
			 Kanban Board
		  </a>
	</li>
	<li id="members" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer " [class.active]="activeLink === 'members'" 
	(click)="setActiveLink('members')"  routerLink="/teamleader/members">
	<svg xmlns="
http://www.w3.org/2000/svg" class="h-5 w-5 icons flex-shrink-0 mr-1.5" viewBox="0 0 24 24"><path fill="currentColor" d="M5.5 7a3 3 0 1 1 6 0a3 3 0 0 1-6 0m3-5a5 5 0 1 0 0 10a5 5 0 0 0 0-10m7 0h-1v2h1a3 3 0 1 1 0 6h-1v2h1a5 5 0 0 0 0-10M0 19a5 5 0 0 1 5-5h7a5 5 0 0 1 5 5v2h-2v-2a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v2H0zm24 0a5 5 0 0 0-5-5h-1v2h1a3 3 0 0 1 3 3v2h2z"/></svg>
		<a class="text-white" >Members</a>
	</li>
	<li id="contacts" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer " [class.active]="activeLink === 'contacts'" 
	(click)="setActiveLink('contacts')"  routerLink="/teamleader/contact">
	<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icons flex-shrink-0 mr-1.5" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M18.8 19.8v-.485c0-.229-.235-.605-.44-.705l-5.66-2.76c-1.527-.745-1.904-2.546-.81-3.843l.36-.428c.552-.654 1.05-2.014 1.05-2.868V7c0-1.545-1.254-2.8-2.8-2.8A2.803 2.803 0 0 0 7.7 7v1.71c0 .856.496 2.21 1.05 2.866l.36.429c1.097 1.299.715 3.099-.81 3.843L2.64 18.61c-.203.099-.44.479-.44.705v.485zM1 20v-.685c0-.685.498-1.483 1.114-1.784l5.66-2.762c.821-.4 1.012-1.288.42-1.99l-.362-.429C7.096 11.478 6.5 9.85 6.5 8.71V7a4 4 0 0 1 8 0v1.71c0 1.14-.6 2.773-1.332 3.642l-.361.428c-.59.699-.406 1.588.419 1.99l5.66 2.762c.615.3 1.114 1.093 1.114 1.783V20a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1m19-5.5h3v1.2h-3zm-2-3h5v1.2h-5zm-2-3h7v1.2h-7z"/></svg>
		<a class="text-white" >Contacts</a>
	</li>
	<li id="logs" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer  " [class.active]="activeLink === 'logs'" (click)="setActiveLink('logs')" routerLink="/teamleader/contact-group">
		<svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 icons" viewBox="0 0 24 24"><path fill="currentColor" d="M18.75 4A3.25 3.25 0 0 1 22 7.25v9.505a3.25 3.25 0 0 1-3.25 3.25H5.25A3.25 3.25 0 0 1 2 16.755V7.25a3.25 3.25 0 0 1 3.066-3.245L5.25 4zm0 1.5H5.25l-.144.006A1.75 1.75 0 0 0 3.5 7.25v9.505c0 .966.784 1.75 1.75 1.75h13.5a1.75 1.75 0 0 0 1.75-1.75V7.25a1.75 1.75 0 0 0-1.75-1.75m-9.497 7a.75.75 0 0 1 .75.75v.582c0 1.272-.969 1.918-2.502 1.918S5 15.104 5 13.831v-.581a.75.75 0 0 1 .75-.75zm1.58-.001l1.417.001a.75.75 0 0 1 .75.75v.333c0 .963-.765 1.417-1.875 1.417q-.174 0-.337-.015a2.9 2.9 0 0 0 .206-.9l.009-.253v-.582c0-.269-.061-.524-.17-.751m4.417.001h3a.75.75 0 0 1 .102 1.493L18.25 14h-3a.75.75 0 0 1-.102-1.493zh3zm-7.75-4a1.5 1.5 0 1 1 0 3.001a1.5 1.5 0 0 1 0-3.001m3.87.502a1.248 1.248 0 1 1 0 2.496a1.248 1.248 0 0 1 0-2.496m3.88.498h3a.75.75 0 0 1 .102 1.493L18.25 11h-3a.75.75 0 0 1-.102-1.493zh3z"/></svg>

		<a  class="text-white" >            
			 Contact Groups
		  </a>
		
	</li>
	<li id="Products" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer " [class.active]="activeLink === 'Products'" 
	(click)="setActiveLink('Products')"  routerLink="/teamleader/product">
	<svg xmlns="http://www.w3.org/2000/svg"  class="h-5 w-5 icons flex-shrink-0 mr-1.5" viewBox="-3 -4 24 24"><path fill="currentColor" d="M7 16a2 2 0 1 1 0-4a2 2 0 0 1 0 4m7 0a2 2 0 1 1 0-4a2 2 0 0 1 0 4M.962 1.923A.962.962 0 0 1 .962 0h1.151c.902 0 1.682.626 1.878 1.506l1.253 5.642c.196.88.976 1.506 1.878 1.506h7.512l1.442-5.77H6.731a.962.962 0 0 1 0-1.922h9.345a1.923 1.923 0 0 1 1.866 2.39L16.5 9.12a1.92 1.92 0 0 1-1.866 1.457H7.122a3.846 3.846 0 0 1-3.755-3.012L2.113 1.923z"/></svg>

		<a class="text-white" >
			Products
		  </a>
	</li>
	<li id="Activities" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer " [class.active]="activeLink === 'Activities'" 
	(click)="setActiveLink('Activities')"  routerLink="/teamleader/activities">
	<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icons flex-shrink-0 mr-1.5"  viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12h4l3 8l4-16l3 8h4"/></svg>

		<a class="text-white" >Activity</a>
	</li>
	<li id="cronjobs" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm  cursor-pointer" [class.active]="activeLink === 'cronjobs'" (click)="setActiveLink('cronjobs')" routerLink="/teamleader/email">
	
		<svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 icons" viewBox="0 0 24 24"><path fill="currentColor" d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2zm-2 0l-8 5l-8-5zm0 12H4V8l8 5l8-5z"/></svg>
		<a class="text-white">
			Email
		  </a>
	</li>
	<li id="email" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer  " [class.active]="activeLink === 'email'" 
	(click)="setActiveLink('email')"  routerLink="/teamleader/email-template" >
	<svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 icons" viewBox="0 0 20 20"><path fill="currentColor" d="M2 4.5A2.5 2.5 0 0 1 4.5 2h9A2.5 2.5 0 0 1 16 4.5v3.535A3.5 3.5 0 0 0 15.5 8H15V4.5A1.5 1.5 0 0 0 13.5 3h-9A1.5 1.5 0 0 0 3 4.5v9A1.5 1.5 0 0 0 4.5 15H6v.5q0 .255.035.5H4.5A2.5 2.5 0 0 1 2 13.5zm11 3a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5m-8-2a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m2 6A2.5 2.5 0 0 1 9.5 9h6a2.5 2.5 0 0 1 2.5 2.5v4a2.5 2.5 0 0 1-2.5 2.5h-6A2.5 2.5 0 0 1 7 15.5zM9.5 10a1.5 1.5 0 0 0-1.476 1.231l4.476 2.686l4.476-2.686A1.5 1.5 0 0 0 15.5 10zM8 15.5A1.5 1.5 0 0 0 9.5 17h6a1.5 1.5 0 0 0 1.5-1.5v-3.117l-4.243 2.546a.5.5 0 0 1-.514 0L8 12.383z"/></svg>

		<a  class="text-white">
			Email Templates
		  </a>
	</li>
	<li id="database" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer " [class.active]="activeLink === 'database'" 
	(click)="setActiveLink('database')"  routerLink="/teamleader/email-signature">
	<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icons flex-shrink-0 mr-1.5"  viewBox="0 0 24 24"><path fill="currentColor" d="M9.75 20.85c1.78-.7 1.39-2.63.49-3.85c-.89-1.25-2.12-2.11-3.36-2.94A9.8 9.8 0 0 1 4.54 12c-.28-.33-.85-.94-.27-1.06c.59-.12 1.61.46 2.13.68c.91.38 1.81.82 2.65 1.34l1.01-1.7C8.5 10.23 6.5 9.32 4.64 9.05c-1.06-.16-2.18.06-2.54 1.21c-.32.99.19 1.99.77 2.77c1.37 1.83 3.5 2.71 5.09 4.29c.34.33.75.72.95 1.18c.21.44.16.47-.31.47c-1.24 0-2.79-.97-3.8-1.61l-1.01 1.7c1.53.94 4.09 2.41 5.96 1.79m11.09-15.6c.22-.22.22-.58 0-.79l-1.3-1.3a.56.56 0 0 0-.78 0l-1.02 1.02l2.08 2.08M11 10.92V13h2.08l6.15-6.15l-2.08-2.08z"/></svg>
 
		<a class="text-white" >
			Email Signatures
		  </a>
	</li>
	<li id="Quotation" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer " [class.active]="activeLink === 'Quotation'" 
	(click)="setActiveLink('Quotation')"  routerLink="/teamleader/quotation">
	<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icons flex-shrink-0 mr-1.5" viewBox="0 0 24 24"><path fill="currentColor" d="M18.5 20a.5.5 0 0 1-.5.5H6a.5.5 0 0 1-.5-.5v-3H4v3a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9.828a2 2 0 0 0-.586-1.414l-5.829-5.828l-.049-.04l-.036-.03a2 2 0 0 0-.219-.18a1 1 0 0 0-.08-.044l-.048-.024l-.05-.029c-.054-.031-.109-.063-.166-.087a2 2 0 0 0-.624-.138q-.03-.002-.059-.007L12.172 2H6a2 2 0 0 0-2 2v9h1.5V4a.5.5 0 0 1 .5-.5h6V8a2 2 0 0 0 2 2h4.5zm-5-15.379L17.378 8.5H14a.5.5 0 0 1-.5-.5zM8.75 11.5a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5zm-6 2.75a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5zm6 2.75a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5z"/></svg>
		<a class="text-white" >Quotations</a>
	</li>
	
	<li id="calendar" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer " [class.active]="activeLink === 'calendar'" 
	(click)="setActiveLink('calendar')"  routerLink="/teamleader/calendar">
	<svg xmlns="http://www.w3.org/2000/svg"class="h-5 w-5 icons flex-shrink-0 mr-1.5" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-2V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3m1 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7h16Zm0-9H4V7a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h2a1 1 0 0 1 1 1Z"/></svg>
		<a class="text-white" >Calender</a>
	</li>
	
	
	<li id="report" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm cursor-pointer " [class.active]="activeLink === 'report'" 
	(click)="setActiveLink('report')"  routerLink="/teamleader/report">
	<svg xmlns="
http://www.w3.org/2000/svg" class="h-5 w-5 icons flex-shrink-0 mr-1.5" viewBox="0 0 16 16"><path fill="none" stroke="currentColor" stroke-linejoin="round" d="M7.563 1.545H2.5v10.91h9V5.364M7.563 1.545L11.5 5.364M7.563 1.545v3.819H11.5m-7 9.136h9v-7M4 7.5h6M4 5h2m-2 5h6"/></svg>
		<a class="text-white" >Reports</a>
	</li>
	
	
</ul>
