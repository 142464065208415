import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from '../../services/login.service';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FormsModule } from '@angular/forms';
import { MainService } from '../../services/main-service';

@Component({
  selector: 'app-team-report',
  standalone: true,
  imports: [CommonModule, FormsModule,MatDividerModule,NgxPaginationModule,NgxSliderModule,RouterModule],
  templateUrl: './team-report.component.html',
  styleUrl: './team-report.component.scss'
})
export class TeamReportComponent implements OnInit {
  public user:any
  TeamsList:any[]=[];
  members:any[]=[];
  teamid:any
  teamname:any;
  isLoading: boolean = true; 
  noDataMessageShown: boolean = false; 
  showMembersTable: boolean = false;
  showleadstable:boolean=false;
  p: number = 1;
  secondpage :number = 1;
  monthArr : any =  ['Monthly','Yearly','Quaterly'];
  subTypeArr : any[] = [{id:1,month:'January'},{id:2,month:'February'},{id:3,month:'March'},{id:4,month:'April'},{id:5,month:'May'},{id:6,month:'June'},{id:7,month:'July'},{id:8,month:'August'},{id:9,month:'September'},{id:10,month:'October'},{id:11,month:'November'},{id:12,month:'December'}]
  typeModel : any = 'Monthly';
  subType : any = null;
  originalSubArr :any =[];
  leads:any[]=[];
 
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('secondTable') secondTable!: ElementRef;
  @ViewChild('thirdtable')thirdtable!:ElementRef;
console: any;
 
  constructor(private loginservice:LoginService, private mservice:MainService,private dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.listTeam()
    const currentMonthIndex = new Date().getMonth() + 1; 
    this.subType = currentMonthIndex;
    this.originalSubArr = this.subTypeArr;
    
    
  }

  listTeam(){
    this.loginservice.listteams(this.user.data.id).subscribe((response:any)=>{
   
      
     this.TeamsList=response  
    })

  }
  listSigTeam(id:number){
    this.showleadstable=false;
    this.showMembersTable = true;
    
     setTimeout(() => {
      const container = this.scrollContainer.nativeElement; 
      const target = this.secondTable.nativeElement; 

      
      const targetPosition = target.offsetTop - container.offsetTop;

      container.scrollTo({
        top: targetPosition, 
        behavior: 'smooth'
      });
    }, 100);
    this.loginservice.singleTeamMem(id).subscribe((response:any)=>{  
    this.members=response
    this.teamname=response[0]?.team?.teamname
  })

}
listLeads(teamid:number,mid:any){
  this.showleadstable = true;
  setTimeout(() => {
    const container = this.scrollContainer.nativeElement; 
    const target = this.thirdtable.nativeElement; 

    
    const targetPosition = target.offsetTop - container.offsetTop;

    container.scrollTo({
      top: targetPosition, 
      behavior: 'smooth'
    });
  }, 100);
 
this.mservice.listLeads(teamid,mid).subscribe(
  (response: any) => {
    this.leads=response;
    this.isLoading = false; 
    
  }
);

}


  
  onFilterChange(event:any){

    this.subTypeArr = [];
    this.subType = null;
    if(event?.target?.value == 'Yearly'){
      const currentYear = new Date().getFullYear();
      const lastTenYear: any = currentYear - 10
      for(let i = currentYear;i >= lastTenYear; i-- ){
        this.subTypeArr.push({id:i,month:i})
      }
    }else if(event?.target?.value == 'Quaterly'){
      this.subTypeArr = [{id:'1-3',month:'January - March'},{id:'4-6',month:'April - June'},{id:'7-9',month:'July - September'},{id:'10-12',month:'October - December'}]
    }else{
      this.subTypeArr = this.originalSubArr;
    }
  }

getdurartion(){
  this.showleadstable=false;
  this.showMembersTable=false;
  this.loginservice.listDuration(this.user?.data?.id,this.typeModel,this.subType).subscribe((result:any)=>{
    if(result?.data){
      this.TeamsList = result?.data;
    }
  })
}

onPageChange(event:any){
  console.log(event,'eventtttttuu')
}

}
