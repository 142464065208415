import { ChangeDetectionStrategy, Component, EventEmitter, input, Input, OnChanges, OnInit, Output, SimpleChanges,ChangeDetectorRef } from '@angular/core';
import { Task } from '../../admin/admin-global';
import { StarRatingColor, StarRatingComponent } from '../star-rating/star-rating.component';
import { MatDialog } from '@angular/material/dialog';
import { PipelineService } from '../drag-drops/pipeline.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { TaskNoteComponent } from '../task-note/task-note.component';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { LoginService } from '../../services/login.service';
import { MenutoggleService } from '../../services/menutoggle.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { imgurl } from '../../global';


@Component({
	selector: 'app-cust-cards',
	standalone: true,
  	imports: [CommonModule,RouterModule,MatDividerModule, MatMenuModule,MatButtonModule,MatFormFieldModule,MatDatepickerModule,MatTableModule,MatNativeDateModule,MatInputModule,MatIconModule,StarRatingComponent, MatTooltipModule],
	templateUrl: './cust-cards.component.html',
	styleUrls: ['./cust-cards.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustCardsComponent implements OnChanges {
	leads:any[] =[];
	@Input() color: any;
	cardcolorform:string[]= [];
	public id: any = 1;
	@Input()editStatus:boolean=true;
	@Input() task:any;
	@Input() colid:any;

	@Output() private ontaskcomplete = new EventEmitter();
	CardColor:string ="border-white";
	@Input() rating:any;
	@Input('starCount') public starCount: number = 4;
	starColor:StarRatingColor = StarRatingColor.accent;
	starColorP:StarRatingColor = StarRatingColor.primary;
	starColorW:StarRatingColor = StarRatingColor.warn;
	user: any;
	result: any;
	activityCount : any = 0;
	taskListArr : any [] = [];
	imgUrl = imgurl;


	onRatingChanged(event:any){
		this.pservice.changeRating(this.task.id,event).subscribe((reponse:any) => {
			this.ontaskcomplete.emit(reponse);
		});
	}

	colorchange(value:string){
		this.CardColor = value;
	}
	
	
	constructor(private dialog: MatDialog, private menuservice: MenutoggleService, private pservice:PipelineService,private loginservice:LoginService, private router:Router,private cdrf : ChangeDetectorRef
	) { }

	ngOnChanges(changes:SimpleChanges){
		if(changes['task']){
			this.user = this.loginservice.getUserdata()
		    this.getleads();
		}
	}

	ngOnInit(): void {
		this.getTaskList()
		
	}

	getTaskList(){
		this.pservice.getTaskList().subscribe((res:any)=>{
			if(res?.message){
				this.taskListArr = res?.message;
			}
		})
	}

	getActivityCount(){
		// this.pservice.getActivityCount(this.user?.data?.id,this.task?.id).subscribe((res:any)=>{
		// 	console.log(this.task,'taskkkk')
		// 	this.task.activityCount = res?.Activity_Count;
		// 	console.log(this.task,'nnnn')
		// })
	}
	EditCardColor(tid:number,colour:any)
	{
		this.pservice.changeColur(tid,colour).subscribe((reponse:any) => {
			this.ontaskcomplete.emit({tid:tid,colour:colour});
		});
		this.pservice.taskEditEmitter.emit({tid:tid})
	}
	getleads(){

		this.pservice.getTasks(this.colid).subscribe(
			(leads: any) => {
				this.leads = leads;
			}
		);
	}
	EditTask(task:any,tid:number,colid:number){
		let data = {
			"tid":tid,
			"colid":colid,
			"task" : task
		}
		this.pservice.taskEditEmitter.emit(data)
	}

	delTask(task:any){
		const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
			data: {
			title: 'Confirm Remove Task',
			message: 'Are you sure, you want to remove a task: ' + task.name
			}
		});
		confirmDialog.afterClosed().subscribe(result => {
			if (result === true) {
			this.pservice.delOnetask(task.id).subscribe((data)=>{
			this.ontaskcomplete.emit(data);
			this.menuservice.setprogress(true);
		})
	}
	});
	}

	changestatus(status:any,t_id:any ){
		this.taskListArr.forEach((item:any,i:any)=>{
			if(status == item?.name){
				var statusName : any = '';
				if(this.taskListArr[i+1]){
					statusName = this.taskListArr[i+1]?.name;
				}else{
					statusName = this.taskListArr[0]?.name;
				}
				console.log(statusName,'stsnnnmm')
				this.pservice.changestatus(t_id,this.user?.data?.id,statusName,'').subscribe((reponse)=>{
					if(reponse){
						this.pservice.ContactleadCountUpdateView(this.task.contact_id,t_id,statusName,this.user?.data?.id).subscribe((result:any)=>{
						});
						this.pservice.TasksToOppertunityView(t_id,this.user?.data?.id).subscribe((res:any)=>{
						});
						this.pservice.change_task_status(t_id,'',this.user?.data?.id).subscribe((response)=>{
						});
					}
			});
			this.task.status = statusName;
			this.task.task_stage= ''  ;
			}
		})
	}
	noteTask(task:any){
		const confirmDialog = this.dialog.open(TaskNoteComponent, {
			data: {
			title:'Add Extra Notes',
			id: task.id,
			colid:task.column_id,
			cid:task.contact_id
			}
		});
		confirmDialog.afterClosed().subscribe(result => {
			if (result === true) {
				}
		});
	}

}
