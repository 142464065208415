import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AddContactgroupService } from './add-contactgroups.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { SuccessComponent } from '../../success/success.component';
import { FailComponent } from '../../fail/fail.component';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-add-contactgroups',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule,RouterModule,MatDividerModule],
  templateUrl: './add-contactgroups.component.html',
  styleUrl: './add-contactgroups.component.scss'
})
export class AddContactgroupsComponent implements OnInit {

  //   registerForm!: FormGroup;
    public user: any;
    submitted = false;
    users:any=[];
    Acivity:any=[];
    cgroupForm!: FormGroup;
    isEdit: boolean=false;
    cid:any
    contacts:any=[]
    constructor(private dialog: MatDialog,private formBuilder: FormBuilder,
      private contactservice: AddContactgroupService,
      private router: Router,private loginservice: LoginService,private route: ActivatedRoute
      ) { }
  
    ngOnInit() {
        this.cgroupForm = this.formBuilder.group({
          name: ['', [Validators.required,Validators.pattern(/^[a-zA-Z\s]*$/)]],
    
         
         
        }, );
        this.user = this.loginservice.getUserdata()
        this.getuser()
        this.route.paramMap.subscribe(params => {
          this.cid = params.get('id')
         
          if(this.cid != null){
            
              this.isEdit = true;
          this.contactservice.getOneContactgroup(this.cid).subscribe((data:any)=>{
                      
            
              this.cgroupForm.setValue({
                  name:data.name,
                 
               
                  
              
              })
          })
        
      }
      else
      {
          this.isEdit = false;
      this.cgroupForm.reset();
      }
        });
      
    }
    editcontactgroup(cid:number){
      let data_val={
        "creator_id_id" :this.user.data.id,
        "name":this.cgroupForm.value.name
      }
      let uid=this.user.data.id
  this.contactservice.editContactgroup(data_val,cid).subscribe((reponse:any) => {
   this.router.navigate(['teamleader/contact-group/']);
  });
  }
    getuser(){
    
      this.contactservice.getContacts(this.user.data.id).subscribe(
        (result: any) => this.contacts = result
        )
      }
    // convenience getter for easy access to form fields
    get f() { return this.cgroupForm.controls; }
  
    onSubmit() {
     
      this.submitted = true;
      if (this.cgroupForm.invalid) {
          return;
      }
      let data_val={
        "creator_id_id" :this.user.data.id,
        "name":this.cgroupForm.value.name
      }
        this.contactservice.addContactgroup(data_val,0).subscribe((reponse:any)=>{
          let data_val1={
            "contact":[],
            "contact_group":reponse.id
        
          }
          
          this.submitted = true;
          if (this.cgroupForm.invalid) {
              return;
          }
            this.contactservice.addContactgroupmembers(data_val1,0).subscribe((reponse)=>{
        //  this.router.navigate(['teamleader/contact-group-member/',this.cgroupMForm.value.contact_group]);
           });  
          if(reponse)
          {
           const confirmDialog = this.dialog.open(SuccessComponent, {
      
             data: {
               title: 'Success',
               message: 'Added Succesfully!'
             }
           })
           confirmDialog.afterClosed().subscribe(result => {
       
            if (result == true) {
              this.router.navigate(['teamleader/contact-group/']);
           }
      
      
         });
        
     }
      else{
         const confirmDialog = this.dialog.open(FailComponent, {
      
             data: {
               title: 'Failed',
               message: 'Added Failed!'
             }
           })
           confirmDialog.afterClosed().subscribe(result => {
       
            if (result == true) {
                this.router.navigate(['teamleader/add-contactgroup'])
           }
      
      
         });
      }
        
       });  
    }
  
    onReset() {
        this.submitted = false;
        this.cgroupForm.reset();
    }
  }
  
