<div class="max-w w-full max-h space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 mt-5 mb-5">
    <div class="grid  gap-8 grid-cols-1 ">
        <form class="" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="flex flex-col ">
                <div class="flex flex-col sm:flex-row items-center mb-2">
                    <h2 class="font-semibold text-lg mr-auto">Sales Teams / New</h2>
                    <div class="w-full sm:w-auto sm:ml-auto sm:mt-0"></div>

                    <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
                      <a routerLink="/admin" class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                        <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-green-500">Save</button>
                    </div>
                </div>
                <mat-divider></mat-divider>
             
                <div class="mt-3 bg-white rounded border border-gray-100  px-20">
                    <div class="form mt-3"> 
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full px-3">
                              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                    Sales Team                              </label>
                               <input type="text" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm" placeholder="eg. Sales Team name"/>
                               <div *ngIf="submitted && f['name'].errors" class="invalid-feedback text-sm">
                                <div *ngIf="f['name'].errors['required']">Please fill out this field.</div>
                            </div>
                            </div>
                          </div>
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                              <label class="flex uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                
                              <input type="radio" formControlName="salestype" name="salestype" value="quotation" (click)="setContactType('Quotation')" checked  class="form-radio focus:outline-none focus:shadow-outline" />
                              <div class="select-none text-gray-700 px-2"> Quotation</div> </label>
                            </div>
                            <div class="w-full md:w-1/2 px-3">
                                <label class="flex uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                
                                    <input type="radio" formControlName="salestype" name="salestype" value="pilpeline" (click)="setContactType('Pipeline')" checked  class="form-radio focus:outline-none focus:shadow-outline" />
                                    <div class="select-none text-gray-700 px-2"> Pipeline</div> </label>
                            </div>
                          </div>
                     
                
                        <!-- <div *ngIf="isSelected('Quotation')"> -->
                     
                          <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                Team Leader
                              </label>
                            <select  formControlName="teamleader" [ngClass]="{ 'is-invalid': submitted && f['teamleader'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                              <option value="" disabled="true">Sales Person</option>
                              <option *ngFor="let list of users" value="{{list.id}}">{{list.username}}</option>
                           </select>
                           <div *ngIf="submitted && f['teamleader'].errors" class="invalid-feedback text-sm">
                            <div *ngIf="f['teamleader'].errors['required']">Please fill out this field.</div>
                        </div>
                            </div>
                            <div class="w-full md:w-1/2 px-3">
                              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                              Invoice Target
                              </label>
                              <select formControlName="invoicetarget" name="invoicetarget" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                                <option value=""></option>
                                <option value="1">Option 1</option>
                                <option value="2">Option 2</option>
                                <option value="3">Option 3</option>
                            </select>
                            </div>
                            
                          </div> 
                          <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3">
                              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                Email Alias(Accept emails from)
                              </label>
                              <select formControlName="email" name="email" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                                <option value=""></option>
                                <option value="test@gmail.com">Option 1</option>
                                <option value="test1@GMAIL.com">Option 2</option>
                                <option value="test3@gmail.com">Option 3</option>
                            </select>
                            </div>
                          </div>         
                     <!-- </div> -->
                    <!-- <div *ngIf="isSelected('Pipeline')">
                      <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                            Team Leader
                          </label>
                          <select  formControlName="teamleader" name="teamleader" [ngClass]="{ 'is-invalid': submitted && f.teamleader.errors }" class="relative outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline">
                            <option value=""></option>
                            <option value="1">Option 1</option>
                            <option value="2">Option 2</option>
                            <option value="3">Option 3</option>
                        </select>
                        <div *ngIf="submitted && f.teamleader.errors" class="invalid-feedback text-sm">
                          <div *ngIf="f.teamleader.errors.required">Please fill out this field.</div>
                      </div>
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                          Invoice Target
                          </label>
                          <select formControlName="invoicetarget" name="invoicetarget" class="relative h- outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline">
                            <option value=""></option>
                            <option value="1">Option 1</option>
                            <option value="2">Option 2</option>
                            <option value="3">Option 3</option>
                        </select>
                        </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Email Alias(Accept emails from)
                          </label>
                          <select formControlName="email" name="email" class="relative h- outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline">
                            <option value=""></option>
                            <option value="test@gmail.com">Option 1</option>
                            <option value="test1@GMAIL.com">Option 2</option>
                            <option value="test3@gmail.com">Option 3</option>
                        </select>
                        </div>
                      </div>    
                               
                    </div>       -->
                        <div class="rounded border w-full overflow-y-auto mx-auto">
                                    <div class="max-w w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 mb-5">
                                        <div class="grid  gap-8 grid-cols-1 ">
                                            <div class='overflow-x-auto  w-full'>
                                           
                                            <table class='mx-auto max-w-4xl w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 '>
                                                    <thead>
                                                        <tr class="text-left">
                                                            <th class="font-semibold text-sm  px-4 py-4"> Name </th>
                                                            <th class="font-semibold text-sm  px-2 py-4">  Login </th>
                                                            <th class="font-semibold text-sm  px-2 py-4 "> Language </th>
                                                            <th class="font-semibold text-sm  px-2 py-4">  Authentication </th>
                                                            <!-- <th class="font-semibold text-sm  px-2 py-4"> Two Factor Authentication </th> -->
                                                            <th class="font-semibold text-sm  px-2 py-4"> </th>
                                                           
                                                        </tr>
                                                    
                                                     
                                                    </thead>
                                                    <tbody  class="divide-y divide-gray-200">

                                                 
                                                    <tr >
                                                        <td class="px-2 py-4">
                                                           <p> <select #name name="name" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm"><option value="Administrator">Administrator</option><option value="Ramcy">Ramcy</option><option value="Demo">Demo</option></select> </p>
                                                        </td>
                                                        <td class="px-2 py-4">
                                                          <p><select #login name="login" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm"><option value="admin&#64;gmail.com">admin&#64;gmail.com</option><option value="ramcy&#64;gmail.com">ramcy&#64;gmail.com</option><option value="demo&#64;gmail.com">demo&#64;gmail.com</option></select></p>
                                                        </td>
                                                        <td class="px-2 py-4 ">
                                                          <p>  <select #language name="language" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm"  ><option value="English(US)">English(US)</option><option value="English(US)">English(US)</option></select> </p>
                                                           </td>
                                                        <td class="px-2 py-4"> <select #latestauthentication name="latestauthentication" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm" ><option value="07/05/2021">07/05/37</option></select>  </td>
                                                        <td class="px-2 py-4"><select #twofactorauthentication name="twofactorauthentication" class="relative w-100 bg-white text-sm text-gray-700 placeholder-gray-400  border-0 border-gray-400"  ><option value="07/05/2021 15:57:37">07/05/2021 15:57:37</option></select></td>
                                                        <td class="px-2 py-4">  <button  type="button" class="mb-2 ml-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-green-500" 
                                                          (click)="addMembers(name.value,login.value,language.value,latestauthentication.value,twofactorauthentication.value)">Add</button></td>
                                                       
                                                    </tr>
                                                    <tr *ngFor='let item of Members'>
                                                      <td class="px-2 py-4"> <input type="text" class="relative w-full bg-white text-sm text-gray-700 placeholder-gray-400  border-0 px-2  border-gray-400"  value="{{item.name}}"></td>
                                                      <td class="px-2 py-4"> <input type="text" class="relative  w-full bg-white text-sm text-gray-700 placeholder-gray-400  border-0 border-gray-400" value="{{item.login}}"></td>
                                                      <td class="px-2 py-4"> <input type="text"  class="relative w-full bg-white text-sm text-gray-700 placeholder-gray-400  border-0  border-gray-400"  value="{{item.language}}"></td>
                                                      <td class="px-2 py-4"> <input type="text"  class="relative w-full bg-white text-sm text-gray-700 placeholder-gray-400  border-0  border-gray-400" value="{{item.latestauthentication}}"></td>
                                                      <td class="text-sm  border-t border-grey-light whitespace-no-wrap">  <input type="text" class="relative w-full bg-white text-sm text-gray-700 placeholder-gray-400  border-0  border-gray-400"  value="{{item.twofactorauthentication}}"></td>
                                                      <td class="px-2 py-4"><button  type="button" class="mb-2 md:mb-0 bg-green-400 mt-3 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-white-500" (click)="removeMember()">Remove</button></td>
                                                    
                                                    </tr>
                                                   
                                                    </tbody>
                                                  </table>
                                                </div>
                                       
                                        
                                
                                  
                                </div>
                     
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </form>
    </div>
</div>
