import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, input, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Task } from '../../admin/admin-global';
import { StarRatingsComponent,StarRatingColor } from '../star-ratings/star-ratings.component';
import { MatDialog } from '@angular/material/dialog';
import { PipelineService } from '../../teamleader/drag-drops/pipeline.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { LoginService } from '../../services/login.service';
import { MenutoggleService } from '../../services/menutoggle.service';
import { TaskNoteComponent } from '../../teamleader/task-note/task-note.component';
import { LeadDialogComponent } from '../lead-dialog/lead-dialog.component';
import { EditLeadService } from '../../services/edit-lead.service';
import { ContactFormService } from '../../services/contact-form.service';
import { AddActivityModalComponent } from '../add-activity-modal/add-activity-modal.component';
import { ReportService } from '../../services/report.service';
import { imgurl } from '../../global';


@Component({
	selector: 'app-cust-card',
	standalone: true,
  	imports: [CommonModule,RouterModule,MatDividerModule, MatMenuModule,MatButtonModule,MatFormFieldModule,MatDatepickerModule,MatTableModule,MatNativeDateModule,MatInputModule,MatIconModule,StarRatingsComponent],
	templateUrl: './cust-card.component.html',
	styleUrls: ['./cust-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustCardComponent implements OnChanges {
	leads:any[] =[];
	newLeadList:any[]=[];
	@Input() color: any;
	cardcolorform:string[]= [];
	public id: any = 1;
	@Input()editStatus:boolean=true;
	@Input() task:any;
	@Input() colid:any;
	@Input() salestask:any;
	
	@Output() private ontaskcomplete = new EventEmitter();
	CardColor:string ="border-white";
	@Input() rating:any;
	@Input('starCount') public starCount: number = 4;
	starColor:StarRatingColor = StarRatingColor.accent;
	starColorP:StarRatingColor = StarRatingColor.primary;
	starColorW:StarRatingColor = StarRatingColor.warn;
	user: any;
	result: any;
	activityCount :any=0;
	taskListArr: any[]=[];
	imgurl=imgurl

	constructor(private dialog: MatDialog,   private rservice:ReportService, private contactservice: ContactFormService, private leadserv : EditLeadService, private menuservice: MenutoggleService, private pservice:PipelineService,private loginservice:LoginService, private router:Router
	) { }

	onRatingChanged(event:any){
		this.pservice.changeRating(this.salestask.id,event).subscribe((reponse:any) => {
			this.ontaskcomplete.emit(reponse);
		});
	}

	colorchange(value:string){
		this.CardColor = value;
		}
	
	
	
	ngOnChanges(changes:SimpleChanges){

		if(changes['task']){
			}
	}

	ngOnInit(): void {
		this.getTaskList();
		this.user = this.loginservice.getUserdata()
		console.log(this.salestask?.contact?.image, this.salestask,imgurl,'salestaskkkkkk imageeeee')
	}

	getTaskList(){
		this.pservice.getTaskList().subscribe((res:any)=>{
			if(res?.message){
				this.taskListArr = res.message;
			}
		})
	}
	
	EditCardColor(tid:number,colour:any)
	{
		this.pservice.changeColur(tid,colour).subscribe((response:any) => {
			this.ontaskcomplete.emit(response);
		});
		this.pservice.taskEditEmitter.emit({tid:tid})
	}
	
newLeadsList(){
		this.pservice.getNewLeadsList(this.user.data.id).subscribe((response:any)=>{
			this.newLeadList=response?.data?.leads
		  })
	}

	addlead(id:any){
	    const addLead = this.dialog.open(LeadDialogComponent, {
		  data: {
			id:id,
		  },
		});
		addLead.afterClosed().subscribe(result => {
			this.ontaskcomplete.emit('');
			this.rservice.setupdate(true)
		 
		});
	}
	
	deletelead(list:any){
		
		const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
			data: {
			  title: 'Confirm Remove Lead',
			  message: 'Are you sure, you want to remove a Lead: ' + list.name
			}
		  });
		  confirmDialog.afterClosed().subscribe(result => {
			if (result === true) {
			  this.contactservice.dellead(list.id).subscribe((data:any)=>{
				this.ontaskcomplete.emit('');
				this.rservice.setupdate(true);	  
			  })
		  }
	  });
	}
	
	addActivities(type:any,id:any){
	    const addActivity = this.dialog.open(AddActivityModalComponent, {
			data: {
				type:type,
				id: id
			  },
		});
		addActivity.afterClosed().subscribe(result => {
		this.ontaskcomplete.emit(result);
	
    });}


	noteTask(task:any){

		const confirmDialog = this.dialog.open(TaskNoteComponent, {
			data: {
			  title:'Add Extra Notes',
			  id: task.id,
			  colid:task.column_id,
			  cid:task.contact_id
			}
		  });
		  confirmDialog.afterClosed().subscribe(result => {
			if (result === true) {
				}
		});
	}

	changestatus(status:any,t_id:any ){
		console.log(this.taskListArr,'arrrr')
		this.taskListArr.forEach((item:any,i:any)=>{
			if(status == item?.name){
				
				var index : any = ''

				if(this.taskListArr[i+1]){
					index = this.taskListArr[i+1]?.name
				}else{
					index = this.taskListArr[0]?.name
				}
				
				this.pservice.changestatus(t_id,this.user?.data?.id,index,'').subscribe((reponse:any)=>{
				
					if(reponse){
						let current_status = reponse?.task_status;

						this.pservice.ContactleadCountUpdateView(this.salestask?.contact_id,t_id,index,this.user?.data?.id).subscribe((result:any)=>{
						});
						if(current_status=='Opportunity'){
							this.pservice.TasksToOppertunityView(t_id,this.user?.data?.id).subscribe((res:any)=>{
							});
						}
						else if(current_status=='Won'){
							this.pservice.OpportunityToQuotation(t_id,'').subscribe((result:any)=>{
								console.log(result,'Qquotationnnnnn')
							})
						}
						
						this.pservice.change_task_status(t_id,'',this.user?.data?.id).subscribe((response)=>{
						});
					}
					

			});
			this.salestask.status = index;
			this.salestask.task_stage= ''  ;
			}
		   
		})
		}


}
