import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { MainService } from '../../main-service';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-members-lead',
  standalone: true,
  imports: [NgxPaginationModule,RouterModule,MatDialogModule,MatDividerModule,CommonModule,
    MatIconModule],
  templateUrl: './members-lead.component.html',
  styleUrl: './members-lead.component.scss'
})
export class MembersLeadComponent implements OnInit {
   cid:any
  leads:any[]=[]
  mid: any;
  p:number=1;
creatid:any;
  ids: any;
  constructor(private route:ActivatedRoute,private loginservice:LoginService,private mservice:MainService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params =>{
      this.ids=params.get('ids')
      this.cid = params.get("id")
      this.mid=params.get("cid")
    })

    this.listLeads(this.cid,this.mid)
  }
  listLeads(cid:number,mid:any){
    this.mservice.listLeads(cid,mid).subscribe((response:any) => {
      this.leads=response
     this.creatid=response[0].team.creator_id
      
    })
    
  }

}
