import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from '../global';
@Injectable({
  providedIn: 'root'
})
export class QuotationListService {

  constructor(private http: HttpClient) { }
  
  getQuotation(userid:any,filter:any){
    const params = filter;
return this.http.get(apiurl + 'pipeline/quotation/list/'+userid+'/',{params});
               
  }

  deleteQuotation(id:any){
    return this.http.delete(apiurl + 'pipeline/quotation/delete/'+id+'/');

  }


  getOpportunity(id:any,filter:any){
    const params = filter;
    return this.http.get(apiurl + 'pipeline/opportunity-generator/list/'+id+'/',{params});
  }

  addOpportunity(data:any){
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json'); 
   return this.http.post(apiurl + 'pipeline/opportunity-generator/create/',data,{headers:headers});
  }

  getOppertunitydetails(id:any){
    return this.http.get(apiurl + 'pipeline/opportunity-generator/detail/'+id+"/");
  }

  editOpportunity(id:any,data:any){
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json'); 
   return this.http.put(apiurl + 'pipeline/opportunity-generator/update/'+id+"/",data,{headers:headers});
  }

  getLeads(uid:any,cu_id:any){
    return this.http.get(apiurl + 'pipeline/opportunity-generator/list-by-customer/'+uid+"/"+cu_id+"/");
  }

  getLeadsList(uid:any,cu_id:any){
    return this.http.get(apiurl + 'pipeline/leads/list-by-customer/'+uid+"/"+cu_id+"/");
  }

  getLeadsListInOpportunity(uid:any,cu_id:any){
    return this.http.get(`${apiurl}pipeline/leads/list-by-customer/${uid}/${cu_id}/?edit=1`);
  }

  getOpportunityIds(uid:any,cu_id:any){
    return this.http.get(apiurl + 'pipeline/quotation/list-by-customer/'+uid+"/"+cu_id+"/");
  }


  delopportunity(id:number){
    return this.http.delete(apiurl+"pipeline/opportunity-generator/delete/"+id+"/")
  }
  getSingleQuotation(id:number){
 
    return this.http.get(apiurl + 'pipeline/quotation-generator/detail/'+id+"/");
                 
    }
  addQuotation(data: any){
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json'); 
   return this.http.post(apiurl + 'pipeline/quotation/create/',data,{headers:headers});
    }

    
    Editquotation(data:any,cid:any)
    {
      const headers = new HttpHeaders();
      headers.append('Content-Type','application/json');
      return this.http.put(apiurl+"pipeline/quotation/update/"+cid+"/",data,{headers:headers})
    }
    delOneQuotation(cid:number){
      return this.http.delete(apiurl+"pipeline/rud_quotation/"+cid+"/")
     }



     getactivity(id:any,filter:any){
      const params = filter;
      return this.http.get(`${apiurl}activity-log/list/${id}`,{params});
      
     }


  addProduct(data: any,userid:number){
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json'); 
    return this.http.post(apiurl + 'productdetails/'+userid+"/",data,{headers:headers});
    }
    gettasklist(cid:number){
      return this.http.get(apiurl + 'pipeline/tasklistcustom/'+cid+"/");             
      }
      configurationlist(id:any)
      {
       return this.http.get(apiurl + 'pipeline/configurations/'+id+"/"+"member"+"/"); 
      }
      getquotesend(id:any,type:any)
      {
        return this.http.get(apiurl + 'pipeline/quotation_send_mail/'+id+"/"+type+"/"); 
      }
}
