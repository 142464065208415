<div class="max-w h-content w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 height">
    <div class="grid  gap-8 grid-cols-1 ">
        <form class="" [formGroup]="contactForm" (ngSubmit)="onSubmit(contactForm.value)">
        <div class="flex flex-col ">
                <div class="flex flex-col sm:flex-row items-center mb-6">
                    <h2 class="font-semibold text-lg mr-auto cursor-pointer"*ngIf="!isEdit">Contact / New  </h2>
                    <h2 class="font-semibold text-lg mr-auto cursor-pointer"*ngIf="isEdit">Contact / Edit  </h2> 
                    <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>

                   
                    <div class=" text-right md:space-x-3 md:block flex flex-col-reverse mb-2" *ngIf="!isEdit">
                        <a routerLink="/teamleader/contact" class="mb-2 md:mb-0 bg-white px-3 py-2 text-xs shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                          <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-xs shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg cursor-pointer color">Save</button>
                      </div>
                      <div class=" text-right md:space-x-3 md:block flex flex-col-reverse  mb-2" *ngIf="isEdit">
                        <a routerLink="/teamleader/contact" class="mb-2 md:mb-0 bg-white px-3 py-2 text-xs shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg  hover:bg-gray-100"> Cancel </a>
                          <a (click)="editcontact(singlecontact.id)" class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-xs shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg color cursor-pointer">Save</a>
                      </div>

                </div>
                <mat-divider></mat-divider>
                <div class="mt-3 bg-white rounded border border-gray-100  px-20" style="height: 64vh !important; overflow-y: auto !important;">
                    <div class="form mt-3" *ngIf="!isupload">
                        
                        <div class="flex flex-wrap -mx-3 mb-2">
                            
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                              <label class="flex  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                              <input type="radio" formControlName="contacttype" value="Individual"    class="form-radio focus:outline-none focus:shadow-outline" />
                              <div class="select-none text-gray-700 px-2"> Individual</div> </label>
                            </div>
                            <div class="w-full md:w-1/2 px-3">
                                <label class="flex  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">

                                    <input type="radio" formControlName="contacttype"  value="Company"    class="form-radio focus:outline-none focus:shadow-outline" />
                                    <div class="select-none text-gray-700 px-2"> Company</div> </label>
                            </div>
                           
                          </div>
                      
                            <div class="flex flex-wrap -mx-3 mb-2">
                            
                                   <div class="w-full md:w-1/2 px-3  mb-2">
                                  <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                   First Name</label>
                                    <input type="text" formControlName="first_name" [ngClass]="{ 'is-invalid': submitted && f['first_name'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-xs " placeholder="First Name"/>
                                    <div *ngIf="submitted && f['first_name'].errors" class="invalid-feedback text-xs">
                                     <div *ngIf="f['first_name'].errors['required']">Please fill out this field.</div>
                                 </div>
                                 
                                </div>
                                <div class="w-full md:w-1/2 px-3  mb-2">
                                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                     Last Name</label>
                                      <input type="text" formControlName="last_name" [ngClass]="{ 'is-invalid': submitted && f['last_name'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-xs" placeholder="Last Name"/>
                                      <div *ngIf="submitted && f['last_name'].errors" class="invalid-feedback text-xs">
                                       <div *ngIf="f['last_name'].errors['required']">Please fill out this field.</div>
                                   </div>
                                   
                                  </div>
                                  
                                  <div class="w-full md:w-1/2  px-3  mb-2" *ngIf="!isEdit">
                                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                     Organization</label>
                                      <select  formControlName="customer"  class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-xs" [ngClass]="{ 'is-invalid': submitted && f['customer'].errors }">
                                        <option [value]=null disabled selected>Select</option>
                                       
                                        <option *ngFor="let list of organizationlist" [value]="list?.id">{{list?.orgnisation_name}}({{list?.creater_name}})</option>
                                    </select>
                                    <div *ngIf="contactForm.get('customer')?.touched && contactForm.get('customer')?.hasError('required')" class="invalid-feedback text-xs">
                                        Organization is required
                                     </div>
                                     <div *ngIf="contactForm.get('customer')?.hasError('pattern')" class="error-msg">
                                        Invalid format
                                     </div>
                                   </div>
                                <!-- <div class="w-full md:w-1/2  px-3  mb-2" *ngIf="!isEdit">
                                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                      Assigned To</label>
                                      <input type="text" formControlName="salesperson_id" [ngClass]="{ 'is-invalid': submitted && f['salesperson_id'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-xs " placeholder="Assigned To"/>
                                    
                                      <div *ngIf="submitted && f['salesperson_id'].errors" class="invalid-feedback text-xs">
                                       <div *ngIf="f['salesperson_id'].errors['required']">Please fill out this field.</div>
                                   </div>
                                   
                                  </div> -->
                                  <div class="w-full md:w-1/2 px-3  mb-2">
                                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                    Address 1</label>
                                      <input type="text" formControlName="address_line_1" [ngClass]="{ 'is-invalid': submitted && f['address_line_1'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-xs " placeholder="Street1"/>
                                      <div *ngIf="submitted && f['address_line_1'].errors" class="invalid-feedback text-xs">
                                        <div *ngIf="f['address_line_1'].errors['required']">Please fill out this field.</div>
                                    </div>
                                   
                                  </div>
                                  
                              </div>
                              <div class="flex flex-wrap -mx-3 mb-2">

                               
                                  <div class="w-full md:w-1/2 px-3  mb-2">
                                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                    Address 2</label>
                                      <input type="text" formControlName="address_line_2" [ngClass]="{ 'is-invalid': submitted && f['address_line_2'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-xs " placeholder="Street2"/>
                                      <div *ngIf="submitted && f['address_line_2'].errors" class="invalid-feedback text-xs">
                                        <div *ngIf="f['address_line_2'].errors['required']">Please fill out this field.</div>
                                    </div>
                                   
                                  </div>
                                  <div class="w-full md:w-1/2 px-3  mb-2">
                                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                    City</label>
                                      <input type="text" formControlName="city" [ngClass]="{ 'is-invalid': submitted && f['city'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-xs " placeholder="City"/>
                                      <div *ngIf="submitted && f['city'].errors" class="invalid-feedback text-xs">
                                        <div *ngIf="f['city'].errors['required']">Please fill out this field.</div>
                                    </div>
                                   
                                  </div>


                                  <div class="w-full md:w-1/2  px-3  mb-2" *ngIf="!isEdit">
                                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">State</label>
                                      <select  formControlName="state"  class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-xs" [ngClass]="{ 'is-invalid': submitted && f['state'].errors }">
                                        <option [value]="null" selected disabled>Select</option>
                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                        <option value="Assam">Assam</option>
                                        <option value="Bihar">Bihar</option>
                                        <option value="Chandigarh">Chandigarh</option>
                                        <option value="Chhattisgarh">Chhattisgarh</option>
                                        <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                        <option value="Daman and Diu">Daman and Diu</option>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Lakshadweep">Lakshadweep</option>
                                        <option value="Puducherry">Puducherry</option>
                                        <option value="Goa">Goa</option>
                                        <option value="Gujarat">Gujarat</option>
                                        <option value="Haryana">Haryana</option>
                                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                        <option value="Jharkhand">Jharkhand</option>
                                        <option value="Karnataka">Karnataka</option>
                                        <option value="Kerala">Kerala</option>
                                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                                        <option value="Maharashtra">Maharashtra</option>
                                        <option value="Manipur">Manipur</option>
                                        <option value="Meghalaya">Meghalaya</option>
                                        <option value="Mizoram">Mizoram</option>
                                        <option value="Nagaland">Nagaland</option>
                                        <option value="Odisha">Odisha</option>
                                        <option value="Punjab">Punjab</option>
                                        <option value="Rajasthan">Rajasthan</option>
                                        <option value="Sikkim">Sikkim</option>
                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                        <option value="Telangana">Telangana</option>
                                        <option value="Tripura">Tripura</option>
                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                        <option value="Uttarakhand">Uttarakhand</option>
                                        <option value="West Bengal">West Bengal</option>
                                 </select>
                                      <div *ngIf="submitted && f['state'].errors" class="invalid-feedback text-xs">
                                       <div *ngIf="f['state'].errors['required']">Please fill out this field.</div>
                                   </div>
                                   
                                  </div>
                                  <div class="w-full md:w-1/2 px-3  mb-2">
                                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                    ZipCode</label>
                                      <input type="text" formControlName="zipcode" [ngClass]="{ 'is-invalid': submitted && f['city'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-xs " placeholder="ZipCode"/>
                                      <div *ngIf="submitted && f['zipcode'].errors" class="invalid-feedback text-xs">
                                        <div *ngIf="f['zipcode'].errors['required']">Please fill out this field.</div>
                                    </div>
                                   
                                  </div>

                                  <div class="w-full md:w-1/2  px-3  mb-2" *ngIf="!isEdit">
                                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                     Country</label>

                                  <select formControlName="country_code" [ngClass]="{ 'is-invalid': submitted && f['country_code'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-xs">
                                    <option [value]=null selected disabled>Select</option>
                                    <option value="India">India</option>
                                  
                                </select>
                                <div *ngIf="submitted && f['country_code'].errors" class="invalid-feedback text-xs">
                                    <div *ngIf="f['country_code'].errors['required']">Please fill out this field.</div>
                                </div></div>

                                <div class="w-full md:w-1/2 px-3  mb-2">
                                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                    Mobile Number </label>
                                      <input type="text" formControlName="mobile_phone" [ngClass]="{ 'is-invalid': submitted && f['mobile_phone'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-xs " placeholder="Mobile Number"/>
                                      <div *ngIf="submitted && f['mobile_phone'].errors" class="invalid-feedback text-xs">
                                        <div *ngIf="f['mobile_phone'].errors['required']">Please fill out this field.</div>
                                        <div *ngIf="f['mobile_phone'].errors['pattern']">Mobile must be a valid mobile address</div>
                                    </div>
                                   
                                  </div>

                                  <div class="w-full md:w-1/2 px-3  mb-2">
                                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                    Email</label>
                                      <input type="text" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-xs " placeholder="Email"/>
                                      <div *ngIf="submitted && f['email'].errors" class="invalid-feedback text-xs">
                                        <div *ngIf="f['email'].errors['required']">Please fill out this field.</div>
                                        <div *ngIf="f['email'].errors['email']">Email must be a valid email address</div>
                                    </div>
                                   
                                  </div>
                                  <div class="w-full md:w-1/2 px-3  mb-2">
                                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                    Website Link</label>
                                      <input type="text" formControlName="websitelink" [ngClass]="{ 'is-invalid': submitted && f['websitelink'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-xs " placeholder=" Website Link" name="websitelink"/>
                                      <div *ngIf="submitted && f['websitelink'].errors" class="invalid-feedback text-xs">
                                        <div *ngIf="f['websitelink'].errors['required']">Please fill out this field.</div>
                                    </div>
                                   
                                  </div>

                                  <div class="w-full md:w-1/2 px-3  mb-2" *ngIf="!isEdit">
                                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                   Photo</label> 
                                   <ngx-file-drag-drop
                                   formControlName="files"
                                 activeBorderColor="#3F51B5" class="focus:ring-white width dash-border file-uploader"
                                 [multiple]="false"
                                 (valueChanged)="onFileChanges($event)"></ngx-file-drag-drop>
                                   <div *ngIf="submitted && f['files'].errors" class="invalid-feedback text-xs">
                                       <div *ngIf="f['files'].errors['required']">Please fill out this field.</div>
                                      
                                   </div>
                                   
                                  </div><br>
                                  <div *ngIf="isEdit" >
                                    <div class="mb-2 px-3">
                                        <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">Change Photo</label>
                                    </div>
                                    <div class="px-3 row" style="display: flex;border: 2px dashed;border-radius:10px !important;margin-left: 2vh !important;width: 61vh !important;">
                                        <div class="col mt-3">
                                            <img src="{{ imagesview }}" alt="" style="width:12vh;height:11vh">
                                        </div>
                                        <div class="col mt-2" style="margin-left: 2vh !important;">
                                            <ngx-file-drag-drop
                                                formControlName="files"
                                                activeBorderColor="#3F51B5" class="focus:ring-white w-64 file-uploader"
                                                [multiple]="false"
                                                (valueChanged)="onFileChanges($event)" style="width: 45vh !important;">
                                            </ngx-file-drag-drop>
                                        </div>
                                    </div>
                                </div>
                    </div>
                </div>
                    <div class="form mt-3" *ngIf="isupload">
                    <div class="md:flex" >
                   <div class="md:w-1/3">
                            <label class="font-bold tracking-wide text-xs">Upload PDF</label>
                        </div>
                        <div class="md:flex-1 mb:mt-0">
                         
                            <div class="mb-2">
                            <input type="file" (change)="onFileChange1($event)" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-xs">
                            </div>
                        </div>
                      </div>
                    </div></div></div></form></div></div>
