<!-- <div class="w-80 notification-div overflow-y-auto">
    <p class="notification  ml-4 mt-3">Notifications</p>
    <div class="close cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"/></svg>    </div>
       
        <ul class="notifications-list" *ngFor="let item of notificationListArr ">
            <li class="notification-item">
                <div class="notification-details">
                    <span class="notification-title">{{item?.message}}</span>
                    <span class="notification-time">{{item?.created_at | date: 'yyyy-MMMM-dd HH:mm:ss a'}}</span>
                </div>
                
            </li></ul>
            <div *ngIf="notificationListArr?.length == 0" class="flex align-center justify-center mt-8">
                <img src="../assets/img/download.png"
                class="noImage">
               
            </div>
   
</div> -->



<div class="w-80 notification-div overflow-y-auto">
    <p class="notification  ml-4 mt-3">Notifications</p>
    
    <div class="close cursor-pointer" (click)="closeNotification()">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"/></svg></div>
        <div class="messages cursor-pointer "(click)="Clearnotification()">Mark All as Read</div>
        <div *ngIf="notificationListArr?.length && !loading" class="mt-5 ">
            <div *ngFor="let item of notificationListArr " class="p-2 messageDiv m-2">
                <div class="closeNotification cursor-pointer " (click)="closeSingleNotification(item?.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"/></svg>    </div>
                <p  class="message">{{ item?.message }}</p>
                
                    <p class="createdTime">{{ item?.created_at | date: 'MMM d, y, h:mm:ss a' }}</p>
              </div>
        </div>
       
        <div  *ngIf="notificationListArr?.length == 0 && !loading" class="flex align-center justify-center mt-8">
            <img src="../assets/img/download.png"
            class="noImage">
        </div>
       
   
</div>