import { Injectable } from '@angular/core';
//import { baseurl} from './../global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { apiurl } from '../../global'; 

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  
  constructor(
    private http:HttpClient,
  ) { }

  addProduct(data:any){
  
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
    console.log(data);
    return this.http.post(apiurl+'createproducts/'+0+"/",data,{headers:headers})
  }

  getUser(){
 
    return this.http.get(apiurl + 'user/alluser/');
                 
    }
  getProduct(userid:any){
 
        return this.http.get(apiurl + 'createproducts/'+userid+"/");
                     
    }
    ExportProductsDataView(userid:any){
      return this.http.get(apiurl +'user/ExportProductsDataView/'+userid+"/");
    }
    delOneProduct(cid:number){
      return this.http.delete(apiurl+"rud_products/"+cid+"/")
     }
  
     getOneProduct(cid:number){
      return this.http.get(apiurl+"rud_products/"+cid+"/")
}
    editProduct(data:any,cid:number){
    //console.log(data);
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
    return this.http.put(apiurl+"rud_products/"+cid+"/",data,{headers:headers})
    }
}
