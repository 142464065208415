<div class="px-8 py-6 " style="width:100% !important;height:85vh !important;overflow-y: auto !important; overflow-x: auto !important;">
    <div class="flex">
      <div class="w-1/5 relative">
        <select class="w-full flex-col  rounded-md text-white  pl-10 selectfilter" [(ngModel)]="typeModel" (change)="onFilterChange($event)">
          <!-- <option [value]="null" [disabled]="true" [selected]>Select an option</option> -->
          <option *ngFor="let item of typeArr" [value]="item">{{ item }}</option>
        </select>
        <!-- Icon placed absolutely inside the container -->
        <div class="absolute float-left top-1/2 ml-2 transform -translate-y-1/2 pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="dateIcon" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M12 14a1 1 0 1 0-1-1a1 1 0 0 0 1 1m5 0a1 1 0 1 0-1-1a1 1 0 0 0 1 1m-5 4a1 1 0 1 0-1-1a1 1 0 0 0 1 1m5 0a1 1 0 1 0-1-1a1 1 0 0 0 1 1M7 14a1 1 0 1 0-1-1a1 1 0 0 0 1 1M19 4h-1V3a1 1 0 0 0-2 0v1H8V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3m1 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9h16Zm0-11H4V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1ZM7 18a1 1 0 1 0-1-1a1 1 0 0 0 1 1"/></svg>
        </div>
        <div class="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>
      <div class="relative ml-3">
          <select class="border  rounded-md text-white  pl-10 selectfilter" [(ngModel)]="subType" (change)="onSubFilterChange($event)">
            <option [value]="null" [disabled]="true" [selected]>Select an option</option>
            <option *ngFor="let item of subTypeArr" [value]="item?.id">{{ item?.month }}</option>
          </select>
          <!-- Icon placed absolutely inside the container -->
        <div class="absolute float-left top-1/2 ml-2 transform -translate-y-1/2 pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="dateIcon" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M12 14a1 1 0 1 0-1-1a1 1 0 0 0 1 1m5 0a1 1 0 1 0-1-1a1 1 0 0 0 1 1m-5 4a1 1 0 1 0-1-1a1 1 0 0 0 1 1m5 0a1 1 0 1 0-1-1a1 1 0 0 0 1 1M7 14a1 1 0 1 0-1-1a1 1 0 0 0 1 1M19 4h-1V3a1 1 0 0 0-2 0v1H8V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3m1 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9h16Zm0-11H4V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1ZM7 18a1 1 0 1 0-1-1a1 1 0 0 0 1 1"/></svg>
        </div>
        <div class="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-3 gap-4 mt-6">
        <div class="p-3  rounded-md  targetsCard" >
            <div class="flex justify-between align-center mt-2">
               <p class="targetText ">Target</p>
               <img class="flex w-9 h-9  mr-3 rounded-md p-1" src="../assets/img/Increase.png" alt="default">

            </div>
            <div class="flex">
                <p class="targetTextCount ">₹{{dashboardData?.target ? dashboardData?.target : 0}}</p>
            </div>
        </div>
        <div class="p-3  rounded-md   targetsCard" >
            <div class="flex justify-between align-center mt-2">
               <p class="targetText ">Achievements</p>
               <img class="flex w-9 h-10  mr-3 rounded-md p-1" src="../assets/img/Fire.png" alt="default">

            </div>
            <div class="flex">
                <p class="targetTextCount ">₹{{dashboardData?.achived ? dashboardData?.achived : 0}}</p>
            </div>
        </div>
        <div class="p-3  rounded-md   targetsCard " >
            <div class="flex justify-between align-center mt-2">
               <p class="targetText ">Completion Percentage</p>
               <img class="flex w-9 h-9  mr-3 rounded-md p-1" src="../assets/img/Audit.png" alt="default">

            </div>
            <div class="flex justify-between align-center mt-2">
                <p class="targetTextCount ">{{dashboardData?.achived ? dashboardData?.achived : 0}}/{{dashboardData?.target ? dashboardData?.target : 0}}</p>
                <p class="">{{ dashboardData?.percentage ? (dashboardData?.percentage?.toFixed(1)) : 0}}%</p>
             </div>
        </div>
    </div>
    <div class="grid gap-4 grid-cols-3" >
        <div class="p-3 rounded-md adminCard mt-6" *ngFor="let item of dashboardData?.team_achivement">
          <p class="flex justify-center text-lg textSize mt-2">{{item?.teamname}}</p>
          <div class="p-3 mb-2 achivementsCard rounded-md mt-1">
           <div class="flex justify-between align-center mt-2">
            <p class="targetAdminText">Target</p>
            <p class="achievedAdminText">Achieved</p>
          </div>
          <div class="flex justify-between align-center mt-1 mr-3">
            <p class="textSize">₹{{item?.target ? item?.target : 0}}</p>
            <p class="textSize">₹{{item?.teamachieved ? item?.teamachieved : 0}}</p>
          </div>
          </div>
         </div>
    </div>
     <div class="flex mt-6">
     <p class=" analytics">Analytics</p>
     </div>
     <div class="flex mt-2">
     <div class="rounded-md adminCard  p-3 w-1/2">
        <p class="ml-2 overview ">Overview</p>
        <div id="pieChart" class="flex pieChart w-full"  #pieChart></div>
     </div>
     <div class="rounded-md adminCard ml-5 p-3 w-1/2">
      <div class="flex justify-between align-center">
        <p class="mt-1 ml-2 overview">Teams Overview In a Pie Chart</p>
      </div>
        <div id="pieChartAdmin" class="flex  pieChartAdmin w-full"  #pieChartAdmin></div>
        </div>
    </div>
    <div class="rounded-md adminCard mt-6 p-3 w-full">
      <div class="flex justify-between align-center">
        <p class="mt-2 ml-2 overview">Revenue Analytics</p>
        <div class="flex">
        <div class="relative" style="width: 13vh;">
          <select class="w-full flex-col  rounded-md text-white   pt-1 selectfilterAdmin h-8
          " [(ngModel)]="yearModel" (change)="onMonthlyChange()">
            <!-- <option [value]="null" [disabled]="true" [selected]>Select an option</option> -->
            <option *ngFor="let item of yearFilter" [value]="item?.id">{{ item?.year }}</option>
          </select>
          <div class="absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          </div>
        <div class="relative ml-2" style="width: 20.9vh;">
          <select class="w-full flex-col  rounded-md text-white   pt-1 selectfilterAdmin h-8
          " [(ngModel)]="teamModel" (change)="onMonthlyChange()">
            <!-- <option [value]="null" [disabled]="true" [selected]>Select an option</option> -->
            <option *ngFor="let item of teamsListArr" [value]="item?.id">{{ item?.teamname }}</option>
          </select>
          <div class="absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          </div>
          </div>
        </div>
      <div id="barChartTeam"  class="flex barChart w-full"  #barChartAdmin style="margin-top: 2vh !important;"></div>
      <!-- <div class="flex justify-center h-8 mt-5 mb-5" >The monthly data for this range not available</div> -->
   </div>
    </div>
  