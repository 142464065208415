import { CommonModule } from '@angular/common';
import { Component, OnInit,Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { Task } from '../../admin/admin-global';

@Component({
  selector: 'app-star-ratings',
  standalone: true,
  imports: [CommonModule,MatIconModule,RouterModule,MatFormFieldModule,MatButtonModule],
  templateUrl: './star-ratings.component.html',
  styleUrl: './star-ratings.component.scss',
  encapsulation: ViewEncapsulation.Emulated
})
export class StarRatingsComponent implements OnInit {
  @Input()rating : any;
  @Input('starCount') public starCount: number = 4;
  @Input('color') public color: string = 'accent';
  @Output() private ratingUpdated = new EventEmitter();

  public ratingArr: number[] =[]

  

  ngOnInit() {
    let index: number;
    for (index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }
  
  onClick(rating:number) {
  
    this.ratingUpdated.emit(rating);
  
  }
  showIcon(index:number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

}
export enum StarRatingColor {
  primary = "primary",
  accent = "accent",
  warn = "secondary"
}
