<div class="max-w w-full h-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 mt-5 mb-5">
    <div class="grid  gap-8 grid-cols-1 ">
      <form class="" [formGroup]="addteamForm" (ngSubmit)="onSubmit(addteamForm.value)">
        <div class="flex flex-col ">
                <div class="flex flex-col sm:flex-row items-center mb-2">
                    <h2 *ngIf="!isEdit" class="font-semibold text-lg mr-auto">Team / New</h2>
                    <h2 *ngIf="isEdit" class="font-semibold text-lg mr-auto">Team / Edit</h2>
                    <div class="w-full sm:w-auto sm:ml-auto sm:mt-0"></div>

                    <div class=" text-right md:space-x-3 md:block flex flex-col-reverse mb-2" *ngIf="!isEdit">
                      <a routerLink="/admin/team" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                        <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg cursor-pointer" style="background-color: #2a303c !important;" >Save</button>
                    </div>
                    <div class=" text-right md:space-x-3 md:block flex flex-col-reverse  mb-2" *ngIf="isEdit">
                      <a routerLink="/admin/team" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                        <a (click)="edit()" class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg cursor-pointer" style="background-color: #2a303c !important;">Save</a>
                    </div></div>
                <mat-divider></mat-divider>
             
                <div class="mt-3 bg-white rounded border border-gray-100  px-20">
                    <div class="form mt-3">
                      <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3">
                          <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                            Team Name</label>
                          <input type="text" formControlName="teamname" [ngClass]="{ 'is-invalid': submitted && f['teamname'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " placeholder="Team Name"/>
                          <div *ngIf="submitted && f['teamname'].errors" class="invalid-feedback text-sm">
                            <div *ngIf="f['teamname'].errors['required']">Please fill out this field.</div>
                          </div>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                            Target
                          </label>
                          <input type="text" formControlName="target" [ngClass]="{ 'is-invalid': submitted && f['target'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " placeholder="Enter Team Target"/>
                          <div *ngIf="submitted && f['target'].errors" class="invalid-feedback text-sm">
                            <div *ngIf="f['target'].errors['required']">Please fill out this field.</div>
                          </div>
                      </div></div>
                    </div>  </div>
        </div>
      </form>
    </div>
</div>

