<div class="max-w w-full space-y-8 mt-3  h-screen p-10 bg-white rounded-xl shadow-lg z-10 " style="height: 85vh !important;">
    <div class="flex flex-col">
    <div class="flex flex-col sm:flex-row items-center mb-4">
      <h2 class="font-semibold text-lg mr-auto">Contacts</h2>
      <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>

      <div class="my-2 flex sm:flex-row flex-col pr-2">

        <div class="block relative">
          <input placeholder="Search" id="name" [(ngModel)]="searchControl" (input)="searchCall()"
            class="appearance-none rounded-full  border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm
                         placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" autocomplete="off" />
        </div>
      </div>


      <mat-menu class="custom-menu" #animals="matMenu">
        <div class="p-2">
          <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
            Name</label>
          <input type="text" [(ngModel)]="nameFilter" (click)="$event.stopPropagation()"
            class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm" />
        </div>

        <div class="p-2">
          <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
            Email</label>
          <input type="text" [(ngModel)]="emailFilter" (click)="$event.stopPropagation()"
            class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm" />
        </div>
        <div class="p-2">
          <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
            Designation</label>
          <input type="text" [(ngModel)]="designationFilter" (click)="$event.stopPropagation()"
            class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm" />
        </div>
        <div class="p-2">
          <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
            Phone</label>
          <div class="flex justify-between ">
            <input type="text" [(ngModel)]="mobile_phoneFilter" (click)="$event.stopPropagation()"
              class="focus:ring-white w-52 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
          </div>
        </div>
        <div class="p-2 flex justify-between ">
          <button (click)="clearfilter()"
            class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">Clear</button>
          <button (click)="filter()"
            class="mb-2 md:mb-0 bg-blue-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-blue-600">Filter</button>
        </div>
      </mat-menu>
      <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
        <a [routerLink]="['/executive/customerlist']"
          class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">
          Cancel </a>
        <a [routerLink]="['/executive/add-contact','','']"
          class="mb-2 md:mb-0 px-5 bg-green-400 text-white py-2 text-sm shadow-sm font-medium tracking-wider border rounded-full hover:shadow-lg color">
          Create </a>

      </div>

    </div>
    <mat-divider></mat-divider>
    <div class=" mt-4 ">
      <table class="min-w-max w-full table-auto border-collapse border border-gray-300"style="height: 44vh !important;" >
          <thead class="bg-gray-200 text-gray-600 text-sm">
            <tr class="border-b border-gray-300 ">
              <th class="py-3 px-6 text-center border-r border-gray-300 sticky-header" >Name</th>
              <th class="py-3 px-6 text-center border-r border-gray-300 sticky-header" >Organization</th>
              <th class="py-3 px-6 text-center border-r border-gray-300 sticky-header" >Designation</th>
              <th class="py-3 px-6 text-center border-r border-gray-300 sticky-header" >Is Primary</th>
              <th class="py-3 px-6 text-center border-r border-gray-300 sticky-header" >Email</th>
              <th class="py-3 px-6 text-center border-r border-gray-300 sticky-header" >Phone Number</th>
              <th class="py-3 px-6 text-center border-r border-gray-300 sticky-header" >Action</th>

  
            </tr>
          </thead>
          <tbody class="text-gray-600 text-sm font-light" *ngIf="dataSource && dataSource.length > 0; else noData">
            <tr *ngFor="let element of dataSource | paginate: { itemsPerPage: 4, currentPage: p }" class="border-b border-gray-300 hover:bg-gray-100">
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{element?.first_name}} {{element.last_name}}</td>
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.customer_name }}</td>
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.designation }}</td>
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap"><mat-icon>{{element?.primary ? 'done' : 'close'  }}</mat-icon> </td>
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.email }}</td>
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.mobile_phone }}</td>
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                <mat-icon [routerLink]="['/executive/add-contact',cid,element.id]" class="icon-pointer">edit</mat-icon> 
                <mat-icon (click)="deleteContact(element)" class="icon-pointer">delete</mat-icon>
              </td>
            </tr>
           
          </tbody>
        </table>
        <ng-template #noData><tr><td colspan="7" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
        <pagination-controls (pageChange)="p = $event" class="float-right mt-1"></pagination-controls>
    </div>
      
   
  </div>
</div>




