import { Component, OnInit,Input,Output,EventEmitter, OnChanges, SimpleChanges,ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PipelineService } from '../drag-drops/pipeline.service';
import { ContactFormService } from '../../services/contact-form.service';
import { Board,Column,Task } from '../../admin/admin-global';
import { StarRatingColor } from '../star-rating/star-rating.component';
import { ProductService } from '../add-products/product.service';
import {  RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { DragDropsComponent } from '../drag-drops/drag-drops.component';
import { ToastrService } from 'ngx-toastr';
import { response } from 'express';
import { MenutoggleService } from '../../services/menutoggle.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
	selector: 'app-task',
	standalone: true,
  imports: [MatMenuModule,RouterModule,CommonModule,ReactiveFormsModule,FormsModule,DragDropsComponent,MatFormFieldModule,MatInputModule,MatSelectModule ],
	templateUrl: './task.component.html',
	styleUrl: './task.component.scss'
})
export class TaskComponent implements OnInit,OnChanges {
	public taskForm!: FormGroup;
	contact: any[] = [];
	submitted = false;
	@Input() colid: any;
	@Input() userid: any;
	@Input() tasks: any;

	@Input()
	column: any;
	@Input() subType:any = '';
	@Input()statustype : any ='';
	@Input()editTaskId : any = 0
	@Output() private ontaskcomplete = new EventEmitter();
	public isEdit:boolean = false;
	public isCatpro:boolean = false;
	public isCatser:boolean = false;
	public task!: Task;
	public board: any = Board;
	public user: any;
	productlist:any[]=[]
	customer: any;
	products_id: any[]=[];

	constructor(
		private formBuilder: FormBuilder,
		private pservice: PipelineService,
		private contactservice: ContactFormService,
		private proservice: ProductService,private loginservice: LoginService,
		private menuservice:MenutoggleService,
		private toaster:ToastrService,
		private cdrf : ChangeDetectorRef
		
	) { }

	ngOnInit(): void {
		
		this.user = this.loginservice.getUserdata()
		this.taskForm = this.formBuilder.group({
			contact_id: ['',Validators.required],
			column_id: [''],
			name: ['', Validators.required],
			budget: ['',Validators.required],
			phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
            email: ['', [Validators.required, Validators.email]],
			rating: [0],
			color: [''],
			category:['',Validators.required],
			product: [[null]],
			servicetitle: [''],
			servicedes:[''],
			creator_id:[''],
			customer_id : ['']
		});
		this.getcontacts(this.userid);
	}

	ngOnChanges(changes: SimpleChanges){
		if(changes['editTaskId']?.currentValue !=0){
			this.isEdit = true;
			this.pservice.getOneTask(this.editTaskId).subscribe((data:any)=>{
				this.task = data;
				this.taskForm.reset({
					contact_id:data.contact_id,
					column_id:data.column_id,
					name:data.name,
					budget:data.budget,
					email:data.email,
					phone:data.phone,
					rating:data.rating,
					color:data.color,
					category:data.category,
					product:data.products,
					servicetitle:data.service_title,
					servicedes:data.service_description,
					customer_id:data.customer_id,
					creator_id:this.user.data.id,
					
				})
			if(data.category=='product'){
				this.isCatpro=true
				this.isCatser=false;
				var productList : any = []
				this.proservice.getProduct(this.user.data.id).subscribe(
					(res: any) => {
						if(res){
							this.productlist = res
							data?.products.forEach((item:any)=>{
                                productList.push((item?.id)) 
							})
							this.taskForm.get('product')?.setValue(productList)
						}
					})
				}
		if(data.category=='service')
		{
			this.isCatser=true
			this.isCatpro=false
		}
			})
		}else{
			this.isEdit = false;
			this.taskForm?.reset('');
		}
	}
	getcontacts(userid: number) {

		this.contactservice.getContacts(userid).subscribe((contact:any)=>{
			this.contact=contact?.data;			
		})
	}
	get f() { return this.taskForm.controls; }

	onSubmit() {
		this.submitted = true;
		if (this.taskForm.invalid) {

            return;
        }
		
		this.taskForm?.value?.product?.forEach((valuess:any)=>{
			this.products_id.push(Number(valuess))
		})
		
		this.taskForm.value['status'] = this.statustype;
		this.taskForm.value['task_stage'] = this.subType;
		this.taskForm.value['column_id'] = this.colid;
		this.taskForm.value['customer_id']=this.customer;
		this.taskForm.value['color'] = "white";
		this.taskForm.value['products_id'] = this.products_id;
		this.taskForm.value['service_title'] = this.taskForm.value.servicetitle;
		this.taskForm.value['service_description'] = this.taskForm.value.servicedes;
		this.taskForm.value['team_id'] = this.user.teamid;
		this.taskForm.value['creator_id']=this.user.data.id;
		this.pservice.addTask(this.taskForm.value,this.colid).subscribe((reponse:any) => {
			if(reponse){
				this.pservice.ContactleadCountCreateView(reponse.contact_id,reponse.id).subscribe((result:any)=>{
			})
			}
			this.ontaskcomplete.emit(reponse);
			this.menuservice.setprogress(true)
		},
		(error:any)=>{
			if (error.message) {
           
				this.toaster.error(error.error.message , 'Error');
			  } 
		}
	);
	
	}
	edittask(taskid:number){
		if(this.taskForm.value.category=='product')
		{
			this.taskForm.value['products_id'] = this.taskForm.value.product
			this.taskForm.value['service_title'] = ''
			this.taskForm.value['service_description'] = ''
		}
		if(this.taskForm.value.category=='service')
		{
			this.taskForm.value['products_id'] = [];
			this.taskForm.value['service_title'] = this.taskForm.value.servicetitle
			this.taskForm.value['service_description'] = this.taskForm.value.servicedes
		}
		this.taskForm.value['column_id'] = this.colid;

		this.pservice.editTask(this.taskForm.value,taskid).subscribe((reponse:any) => {
			if(reponse){
			this.ontaskcomplete.emit(reponse);
			this.menuservice.setprogress(true)

		}
		},
		(error:any)=>{
			if (error.message) {
           
				this.toaster.error(error.error.message , 'Error');
			  } 
		}
	);
		
	}
    discard(column: Column) {
		column.mstatus = !column.mstatus;
		this.isCatpro=false
		this.isCatser=false
		this.submitted=false;
		
	}
	Onchangecat(res:any)
	{
		if(res=='product')
		{
        this.isCatpro=true
		this.isCatser=false
		this.proservice.getProduct(this.user.data.id).subscribe(
			(res: any) => this.productlist = res
	  
			)
		}
		if(res=='service')
		{
			this.isCatser=true
			this.isCatpro=false
		}
	}
	onContactChange(event:any){
		this.contact.forEach((item:any)=>{
			if(item.id==event.target.value){
				this.taskForm.get('email')?.setValue(item.email)
				this.taskForm.get('phone')?.setValue(item.mobile_phone)
				this.customer = item?.customer
			}
			
		})
	
	}

	
}
