<div class="max-w w-full h-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10">
  <div class="grid gap-8 grid-cols-1">
      <form [formGroup]="emailtemplateForm" (ngSubmit)="onSubmit()">
          <div class="flex flex-col">
              <!-- Header -->
              <div class="flex flex-wrap sm:flex-nowrap items-center justify-between mb-4">
                  <h2 class="font-semibold text-lg" *ngIf="!isEdit">Email Template / New</h2>
                  <h2 class="font-semibold text-lg" *ngIf="isEdit">Email Template / Edit</h2>
                  
                  <div class="flex items-center space-x-3">
                      <a 
                          routerLink="/teamleader/email-template" 
                          class="bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">
                          Cancel
                      </a>
                      <button *ngIf="!isEdit" 
                              type="submit" 
                              class="bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg" style="background-color: #2a303c !important;">
                          Save
                      </button>
                      <a *ngIf="isEdit" 
                         (click)="editemailtemplate(cid)" 
                         class="bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg  cursor-pointer" style="background-color: #2a303c !important;">
                          Save
                      </a>
                  </div>
              </div>

              <mat-divider></mat-divider>

              <!-- Form Body -->
              <div class="mt-6 bg-white rounded border border-gray-200 p-6">
                  <div class="form">
                      <!-- Name and Subject -->
                      <div class="flex flex-wrap -mx-3 mb-6">
                          <!-- Name Field -->
                          <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                              <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
                                  Name
                              </label>
                              <input 
                                  type="text" 
                                  formControlName="name" 
                                  [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" 
                                  class="w-full bg-white text-sm text-gray-700 placeholder-gray-400 border border-gray-400 rounded px-3 py-2" 
                                  placeholder="Template name"/>
                              <div *ngIf="submitted && f['name'].errors" class="text-red-500 text-xs mt-1">
                                  <div *ngIf="f['name'].errors['required']">Please fill out this field.</div>
                                  <div *ngIf="f['name'].errors['pattern']">Invalid Format.</div>
                              </div>
                          </div>

                          <!-- Subject Field -->
                          <div class="w-full md:w-1/2 px-3">
                              <label class="block text-gray-700 text-sm font-bold mb-2" for="subject">
                                  Subject
                              </label>
                              <input 
                                  type="text" 
                                  formControlName="subject" 
                                  [ngClass]="{ 'is-invalid': submitted && f['subject'].errors }" 
                                  class="w-full bg-white text-sm text-gray-700 placeholder-gray-400 border border-gray-400 rounded px-3 py-2" 
                                  placeholder="Subject"/>
                              <div *ngIf="submitted && f['subject'].errors" class="text-red-500 text-xs mt-1">
                                  <div *ngIf="f['subject'].errors['required']">Please fill out this field.</div>
                                  <div *ngIf="f['subject'].errors['pattern']">Invalid Format.</div>
                              </div>
                          </div>
                      </div>

                      <!-- Message Field -->
                      <div class="flex flex-wrap -mx-3 mb-6">
                          <div class="w-full px-3">
                              <label class="block text-gray-700 text-sm font-bold mb-2" for="message">
                                  Message
                              </label>
                              <textarea 
                                  formControlName="message" 
                                  [ngClass]="{ 'is-invalid': submitted && f['message'].errors }" 
                                  class="w-full bg-white text-sm text-gray-700 placeholder-gray-400 border border-gray-400 rounded px-3 py-2 h-28">
                              </textarea>
                              <div *ngIf="submitted && f['message'].errors" class="text-red-500 text-xs mt-1">
                                  <div *ngIf="f['message'].errors['required']">Please fill out this field.</div>
                                  <div *ngIf="f['message'].errors['pattern']">Invalid Format.</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </form>
  </div>
</div>
