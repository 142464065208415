<div class="max-w w-full space-y-8 h-screen p-10 bg-white rounded-xl shadow-lg z-10 mt-5 mb-5">
    <div class="flex flex-col sm:flex-row items-center mb-2">
        <h2 class="font-semibold uppercase text-lg mr-auto">All Contacts</h2>
        <div class="w-full sm:w-auto sm:ml-auto  sm:mt-0"></div>
       
    </div>
    <mat-divider></mat-divider>
    <div class='overflow-x-auto w-full'>
        <table class="min-w-max w-full table-auto">
            <thead>
                <tr class="bg-gray-200 text-gray-600  text-sm ">
                    <th class="py-3 px-6 text-left">Contact</th>
                    <th class="py-3 px-6 text-left">Assigned To</th>
                    <th class="py-3 px-6 text-left">Contact Type</th>
                    <th class="py-3 px-6 text-left">Email</th>
                    <th class="py-3 px-6 text-left">Phone</th> 
                 
                   
                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm font-light">
                <tr *ngFor='let item of contacts_list | paginate: { itemsPerPage: 10, currentPage: p }' class="border-b border-gray-200 hover:bg-gray-100">
                    <td class="py-3 px-6 text-left whitespace-nowrap">
                        <div class="flex items-center">
                      
                            <span class="text-base">{{item.name}}</span>
                        </div>
                    </td>
                    <td class="py-3 px-6 text-left">
                        <div class="flex items-center">
                           
                            <span class="text-base">{{item.salesperson.first_name}}</span>
                        </div>
                    </td>
                    <td class="py-3 px-6 text-left">
                        <div class="flex items-center">
                           
                            <span class="text-base">{{item.contacttype}}</span>
                        </div>
                    </td>
                    <td class="py-3 px-6 text-left">
                        <div class="flex items-center">
                           
                            <span class="text-base">{{item.email}}</span>
                        </div>
                    </td>
                    <td class="py-3 px-6 text-left">
                        <div class="flex items-center">
                           
                            <span class="text-base">{{item.phoneNumber}}</span>
                        </div>
                    </td>
                  
                 
                </tr>
             
                   
    
            </tbody>
        </table>
        
        <pagination-controls (pageChange)="p = $event" class="float-right mt-5 "></pagination-controls>

    </div>        
    
</div>



