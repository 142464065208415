import { Component, OnInit } from '@angular/core';
import { MainService } from '../../main-service';
import { LoginService } from '../../services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import {NgxPaginationModule} from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
declare var echarts: any;


@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule,NgxPaginationModule,FormsModule], 
  providers: [LoginService, MainService],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'] 
})
export class DashboardComponent implements OnInit {
  public user: any;
  TeamsList: any[] = [];
  admintarget: any;
  adminachievemnet: any;
  t_percentage: any;
  p: number = 1;
  userid: any;
  typeArr : any =  ['Monthly','Yearly','Quaterly'];
  subTypeArr : any[] = [{id:1,month:'January'},{id:2,month:'February'},{id:3,month:'March'},{id:4,month:'April'},{id:5,month:'May'},{id:6,month:'June'},{id:7,month:'July'},{id:8,month:'August'},{id:9,month:'September'},{id:10,month:'October'},{id:11,month:'November'},{id:12,month:'December'}]
  typeModel : any = 'Monthly';
  subType : any = null;
  originalSubArr :any = [];
  dashboardData : any;
  totalChartData : any [] = [];
  teamsChartData : any [] = [];
  teamModel : any = null;
  barChartData : any[] = [];
  xLabelArr : any[] = [];
  yLabelArr : any [] = [];
  achievementArr : any[] = [];
  targetArr : any[] = [];
  percentageArr : any[] = [];
  yearFilter : any[] = [];
  yearModel : any = '';
  teamsListArr : any [] = [];

  constructor(private mservice: MainService, private loginservice: LoginService, private dialog: MatDialog, private router: Router) {}

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata();
    const currentMonthIndex = new Date().getMonth() + 1; // 0-indexed
    this.subType = currentMonthIndex;
    this.originalSubArr = this.subTypeArr;
    this.yearModel = new Date().getFullYear();
    const lastTenYear: any = this.yearModel - 10
    for(let i = this.yearModel;i >= lastTenYear; i-- ){
      this.yearFilter.push({id:i,year:i})
    }
    this.loginservice.listTeam(this.user?.data?.id).subscribe((res:any)=>{
      if(res){
        this.teamsListArr = res;
        this.teamModel = this.teamsListArr[0]?.id;
        this.getMonthlyData();
      }
      
    })
    this.getDashboardData();
  }

  onFilterChange(event:any){
    this.subTypeArr = [];
    this.subType = null;
    if(event?.target?.value == 'Yearly'){
      const currentYear = new Date().getFullYear();
      const lastTenYear: any = currentYear - 10
      for(let i = currentYear;i >= lastTenYear; i-- ){
        this.subTypeArr.push({id:i,month:i})
      }
    }else if(event?.target?.value == 'Quaterly'){
      this.subTypeArr = [{id:'1-3',month:'January - March'},{id:'4-6',month:'April - June'},{id:'7-9',month:'July - September'},{id:'10-12',month:'October - December'}]
    }else{
      this.subTypeArr = this.originalSubArr;
    }
  }

  onSubFilterChange(event:any){
    this.getDashboardData();
  }

  getDashboardData(){
    this.dashboardData = [];
    this.totalChartData = [];
    this.teamsChartData = [];
    this.mservice.getAdminDashboard(this.typeModel,this.subType).subscribe((res:any)=>{
      if(res?.data){
        this.dashboardData = res?.data;
        if(this.dashboardData){
          this.totalChartData = [
            { "value": this.dashboardData?.target, "name": "Target" },
            { "value": this.dashboardData?.achived, "name": "Achieved" }
          ]
          this.getPieGraph();
          if(this.dashboardData?.team_achivement_percentage){
            this.dashboardData?.team_achivement_percentage.forEach((item:any)=>{
              this.teamsChartData.push({value: item?.achievement_percentage, name: item?.team_name})
            })
            this.getPieChartAdmin();
          }
        }
      }
    })
  }

  getMonthlyData(){
    this.xLabelArr = [];
    this.yLabelArr = [];
    this.achievementArr = [];
    this.targetArr = [];
    this.percentageArr = []
    this.mservice.getAdminMonthlyData(this.yearModel,this.teamModel).subscribe((res:any)=>{
      if(res?.data){
        if(res?.data?.team_monthly_achivement){
            this.xLabelArr = res?.data?.team_monthly_achivement?.xlabel;
            this.yLabelArr = res?.data?.team_monthly_achivement?.ylabel;
            res?.data?.team_monthly_achivement?.monthly_data.forEach((item:any)=>{
              this.targetArr.push(item?.target)
              this.achievementArr.push(item?.achievement);
              this.percentageArr.push(item?.percentage.toFixed(1))
            })
            this.getBarChart()
        }
      }
    })
  }

  getPieGraph() {
    const chartDom = document.getElementById('pieChart')!;
    if (chartDom) {
      const myChart = echarts.init(chartDom);

      const option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          left: 'center'
        },
        color: ['#7d92fe', '#a6baf6'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '75%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 10,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.totalChartData // Use updated data here
          }
        ]
      };
      myChart.setOption(option);
    }
  }

  getPieChartAdmin(){
    const chartDom = document.getElementById(`pieChartAdmin`)!;
    if (chartDom) {
      const myChart = echarts.init(chartDom);
      const option ={
       
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '75%'],
            center: ['50%', '60%'],
            data: this.teamsChartData,
            label: {
              show: true, // Ensure labels are visible
              color: '#000', // Set label text color
              fontSize: 10, // Set font size
              fontWeight: 'bold', // Make labels bold
              formatter: '{b}: {d}%', // Customize label format (name: value (percentage))
            },
            labelLine: {
              show: true, // Display lines connecting labels to the chart
              length: 10, // Length of the line
              length2: 10, // Length of the second segment
              smooth: true, // Smooth the label lines
            },
          }
        ]
      }
      myChart.setOption(option);
    }
  }

  getBarChart() {
    const chartDom = document.getElementById('barChartTeam')!;
    if (chartDom) {
      const myChart = echarts.init(chartDom);
      // Fix remaining data calculation
      const remainingData = this.targetArr.map((target, index) => 
        Math.max(0, target - this.achievementArr[index])
      );
      // Define the series for the bar chart
      const series = [
        {
          name: 'Achieved',
          type: 'bar',
          stack: 'total',
          barWidth: '53%',
          label: {
            show: true,
            position: 'inside', // Display percentage inside the bar
            fontSize: 9,
            color: '#ffffff',
            rotate: 90, // Rotate the label 
            formatter: (params: any) => {
              const percentage = this.percentageArr[params.dataIndex];
              return percentage > 0 ? `${percentage}%` : ''; // Only show if > 0
            },
          },
          itemStyle: {
            color: '#7d92fe', // Achieved bar color
          },
          data: this.achievementArr, // Achieved values
        },
        {
          name: 'Remaining',
          type: 'bar',
          stack: 'total',
          itemStyle: {
            color: '#a6baf6'
          },
          data: remainingData, // Remaining values
        },
      ];
  
      // Define chart options
      const option: any = {
        grid: {
          left: 50,
          right: 50,
          top: 50,
          bottom: 50,
        },
        xAxis: {
          type: 'category',
          data: this.xLabelArr, // Use X-axis data from API
        },
        yAxis: {
          type: 'value',
          max: Math.max(...this.yLabelArr), // Y-axis max from provided labels
          axisLabel: {
            formatter: (value: number) => `${value}`, // Display values with $ prefix
          },
        },
        series,
      };
      // Render the chart
      myChart.setOption(option);
    }
  }
  
  onMonthlyChange(){
    this.getMonthlyData()

  }

}
