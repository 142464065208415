<div class="max-w w-full h-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 height">
    <div class="grid  gap-8 grid-cols-1 ">
        <form class="" [formGroup]="productForm" (ngSubmit)="onSubmit()">
        <div class="flex flex-col ">
                <div class="flex flex-col sm:flex-row items-center mb-2">
                    <h2 class="font-semibold text-lg mr-auto" *ngIf="!isEdit">Product / New</h2>
                    <h2 class="font-semibold text-lg mr-auto" *ngIf="isEdit">Product / Edit</h2>
                    <div class="w-full sm:w-auto sm:ml-auto sm:mt-0"></div>
  
                    <div class=" text-right md:space-x-3 md:block flex flex-col-reverse mb-2" *ngIf="!isEdit">
                      <a routerLink="/teamleader/product" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                        <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg color cursor-pointer">Save</button>
                    </div>
                    <div class=" text-right md:space-x-3 md:block flex flex-col-reverse  mb-2" *ngIf="isEdit">
                      <a routerLink="/teamleader/product" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                        <a (click)="editproduct(cid)" class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg cursor-pointer color">Save</a>
                    </div>
                </div>
                <mat-divider></mat-divider>
             
                <div class="mt-3 bg-white rounded border border-gray-100  px-20">
                    <div class="form mt-3">
                     
             
                      <div class="flex flex-wrap -mx-3 mb-6">
                       
                        <div class="w-full md:w-1/2 px-3">
                          <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                            Name</label>
                        <input type="text" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" class="relative w-full bg-white text-sm text-gray-700 placeholder-gray-400 border h-5/6 border-gray-400" placeholder="Product Name"/>
                        <div *ngIf="submitted && f['name'].errors" class="invalid-feedback text-sm">
                         <div *ngIf="f['name'].errors['required']">Please fill out this field.</div>
                         <div *ngIf="f['name'].errors['pattern']">Invalid Format.</div>
                     </div>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                            Unit Price</label>
                        <input type="text" formControlName="price" [ngClass]="{ 'is-invalid': submitted && f['price'].errors }" class="relative w-full bg-white text-sm text-gray-700 placeholder-gray-400 border h-5/6 border-gray-400" placeholder="Price"/>
                        <div *ngIf="submitted && f['price'].errors" class="invalid-feedback text-sm">
                         <div *ngIf="f['price'].errors['required']">Please fill out this field.</div>
                         <div *ngIf="f['price'].errors['pattern']">Invalid Format.</div>

                     </div>
                          </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full py-3 px-3">
                          <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Description
                          </label>
                         <textarea   formControlName="description" [ngClass]="{ 'is-invalid': submitted && f['description'].errors }" class="relative w-full bg-white text-sm text-gray-700 placeholder-gray-400 border h-5/6 border-gray-400"></textarea>
                         <div *ngIf="submitted && f['description'].errors" class="invalid-feedback text-sm">
                          <div *ngIf="f['description'].errors['required']">Please fill out this field.</div>
                      </div>
                        </div>
                      </div>    
                               
                    </div>      
                    
                  
                </div>
        </div>
        </form>
    </div>
  </div>
  
  