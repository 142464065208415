<div class="scrolling">
<div class="flex flex-row bg-gray-600 text-gray-300 ">

    <div class="flex w-1/12 justify-center items-center">
        <svg xmlns="http://www.w3.org/2000/svg" (click)="prevMonth()" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
    </div>
    <div class="flex flex-col justify-center items-center w-10/12">
        <div class="text-3xl p-2">{{date | date:'MMMM'}}</div>
        <div class="text-base pb-4">{{ date| date:'fullDate'}}</div>
    </div>
    <div class="flex justify-center items-center w-1/12">
        <svg xmlns="http://www.w3.org/2000/svg" (click)="nextMonth()" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
    </div>
</div>
<div class="grid grid-cols-7 border-l-2 border-t-2 place-content-center bg-gray-200 border-gray-300">
    <div class="w-1/7 py-2 border-r-2 text-center border-gray-300">SUN</div>
    <div class="w-1/7 py-2 border-r-2 text-center border-gray-300">MON</div>
    <div class="w-1/7 py-2 border-r-2 text-center border-gray-300">TUE</div>
    <div class="w-1/7 py-2 border-r-2 text-center border-gray-300">WEN</div>
    <div class="w-1/7 py-2 border-r-2 text-center border-gray-300">THU</div>
    <div class="w-1/7 py-2 border-r-2 text-center border-gray-300">FRI</div>
    <div class="w-1/7 py-2 border-r-2 text-center border-gray-300">SAT</div>
</div>
<div class="grid grid-cols-7 border-l-2 border-t-2 relative border-gray-300 z-0 " >
    <div class="w-1/7 h-20 border-r-2 border-b-2 text-gray-400 p-2 border-gray-300 text-sm"
        *ngFor='let num of prevMonthDates() ;let i = index'>{{num}}</div>
    <div class="w-1/7 h-20 border-r-2 border-b-2  border-gray-300 p-1 " 
        *ngFor='let in of narray ;let i = index' (click)="addEvent(in)">
        <div class="rounded-full h-6 w-6 flex items-center justify-center text-sm"
            [ngClass]="{'bg-purple-900 text-gray-100':i+1 == currentDay && date.getMonth() == currentMonth && date.getFullYear() == currentYear }">
            {{ in.pos}}</div>
           <div class="inline-flex mx-1" *ngFor='let num of eventslist;'>
            <div *ngIf="in.pos === num.day">
        <div class=" border rounded-full border-blue-200 bg-gray-200 px-1  text-xs cursor-pointer"  (click) ="mouseEnter(num.edate,num.activity_type) "> {{num.activity_type}}<span class="rounded-full  px-1 items-center text-gray-100 ml-2 text-xs bg-purple-900 " *ngIf="num.activity_type === 'Meetings'">{{num.meeting_count}}</span><span class="rounded-full  px-1 items-center text-gray-100 ml-2 text-xs bg-purple-900" *ngIf="num.activity_type === 'Events'">{{num.events_count}}</span><span class="rounded-full  px-1 items-center text-gray-100 ml-2 text-xs bg-purple-900" *ngIf="num.activity_type === 'Emails'">{{num.emails_count}}</span></div>  </div>
    </div>

        <app-add-event class="flex flex-wrap justify-self-center z-50 "  *ngIf="in.mstat" [date]="date"
           (addEventStatus)="menuclose($event)" (addEventData)="eventData($event)"> </app-add-event>

    </div>





</div></div>
