
export class Column {
    constructor(
        public id: number,
        public name: string,
        public tasks: Task[],
        public mstatus: boolean
    ) { }
}
export class Board {
    constructor(
        public id: number,
        public name: string,
        public columns: Column[]) { }
}
export class Task {
    constructor(
        public id: number,
        public customer:number,
        public creator_id:number,
        public contact_id: number,
        public column_id: number,
        public name: string,
        public budget: number,
        public appName: string,
        public srating: number,
        public color: string,
        public ciImage: string,
        public status: string,
        public email:string,
        public task_stage:string,
        public activityCount:string,
    ) { }
}
export class Contact {
    constructor(
        public id: number,
        public contacttype: string,
        public first_name: string,
        public address1: string,
        public address2: string,
        public gstvat:number,
        public probability:number,
        public rating:number,
        public expected_revenue: string,
        public email: string,
        public phoneNumber:number,
        public website_link: string,
        public country: string,
        public state: string,
        public zipcode:number,
        public city: string,
        public tag: string,
        public extranote: string,
        public deliverymethod: string,
        public paymentmethod: string,
        public paymentterms: string,
        public bankname: string,
        public accountnumber: string,
        public accountreceivable: string,
        public accountpayable: string,
        public salesteam: string,
        public salesperson: string,
    ) { }
}
export class Quotes {
    constructor(
        public quote: Quote[]


    ) { }
}
export class Quote {

    constructor(

        public product: string,
        public description: string,
        public quantity: number,
        public unitprice: number,
        public taxes: number,
        public subtotal: number
    ) { }
}
export class Members {
    constructor(
        public member: Member[]
        // public bstatus:boolean

    ) { }
}
export class Member {

    constructor(

        public name: string,
        public login: string,
        public language: string,
        public latestauthentication: string,
        public twofactorauthentication: string,

    ) { }
}
export class AllEventTasks {
    constructor(
        public date: Date,
        public eventTask: EventTask[]) { }
}
export class EventTask {

    constructor(
        public name: string,
        public hour: number,
        public minutes: number


    ) { }
}

export class Activities {
    constructor(
        public activity: Activity[]
        // public bstatus:boolean

    ) { }
}
export class Activity {
    constructor(

        public activity_name: string,
        public default_user: string,
        public summary: string,
        public description: string,

    ) { }
}
