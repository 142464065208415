import { Component, OnInit, Inject,Output,EventEmitter, NgModule} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PipelineService } from '../drag-drops/pipeline.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { LoginService } from '../../services/login.service';
@Component({
  selector: 'app-task-note',
  standalone: true,
  imports: [CommonModule,RouterModule,MatDialogModule,ReactiveFormsModule,FormsModule,MatIconModule],
  templateUrl: './task-note.component.html',
  styleUrl: './task-note.component.scss'
})
export class TaskNoteComponent implements OnInit {
  public registerForm!: FormGroup;
  @Output() private ontaskcomplete = new EventEmitter();
  submitted = false;
  user: any;

  constructor(
    public dialogRef: MatDialogRef<TaskNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pservice: PipelineService,
    private formBuilder: FormBuilder,
    private loginservice:LoginService
  ) {}

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata();
    this.registerForm = this.formBuilder.group({
      extranote: ['', Validators.required], // Set validation rule
    });
  }

  // Getter for form controls for use in the template
  get f() {
    return this.registerForm.controls;
  }

  editTask(tid: number, colid: any, cid: any): void {
    this.submitted = true; // Set submitted to true to trigger error display
    console.log(this.registerForm.controls,'controlsss')
    if (this.registerForm.invalid) {
      // Stop further processing if form is invalid
      return;
    }

    this.pservice
      .changenote(tid, this.registerForm.value.extranote,this.user?.data?.id)
      .subscribe((response: any) => {
        console.log(response);
        this.ontaskcomplete.emit(response);
        this.dialogRef.close(); // Close the dialog on successful submission
      });
  }

  onClose(): void {
    this.dialogRef.close(); // Close the dialog manually
  }
}
