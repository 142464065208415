import { Component, OnInit } from '@angular/core';
import { ContactFormService } from '../../services/contact-form.service';
import { LoginService } from '../../services/login.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-assigned-contacts',
  standalone: true,
  imports: [CommonModule,RouterModule,MatDividerModule,NgxPaginationModule],
  templateUrl: './assigned-contacts.component.html',
  styleUrl: './assigned-contacts.component.scss'
})
export class AssignedContactsComponent implements OnInit {
  contacts_list:any[]=[]
  public user:any;
  p:number=1;
  constructor(private cservice:ContactFormService,private loginservice:LoginService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.getcontactslist(this.user.data.id)
  }
  getcontactslist(id:any)
  {
    this.cservice.getContactsAssigned(id).subscribe((res:any)=>{
      this.contacts_list=res
    });
  }
}
