import { Component,OnInit } from '@angular/core';
import { ActivitiesService } from '../../services/activities.service';
import { MatDividerModule } from '@angular/material/divider';
import { FormControl, FormGroup, FormsModule,FormBuilder,ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { CommonModule,DatePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { LoginService } from '../../services/login.service';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-last-updated-report',
  standalone: true,
  imports: [MatDividerModule,FormsModule,MatTableModule,CommonModule,ReactiveFormsModule,MatFormFieldModule,MatDatepickerModule,MatNativeDateModule,MatInputModule,MatIconModule,NgxPaginationModule],
  providers : [ActivitiesService,DatePipe,LoginService],
  templateUrl: './last-updated-report.component.html',
  styleUrl: './last-updated-report.component.scss'
})
export class LastUpdatedReportComponent  implements OnInit{
  lastupdatedReport:any;
  filter:any=""
  displayedColumns: string[] = ['lead', 'opp', 'cloud-sales-rep','lastupdated',];
  filterForm! : FormGroup
  user : any;
  p:number=1;

  constructor(private activityserve: ActivitiesService, private formBuilder : FormBuilder,private datepipe : DatePipe, private loginservice : LoginService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()?.data?.id
    this.lastUpdatedReport('');
    this.filterForm = new FormGroup({
      fromDate: new FormControl(), // Define your form control here
      toDate : new FormControl()
    });
  }

   
  applyFilter(){
    const filter = {
      date_from : this.datepipe.transform(this.filterForm?.value?.fromDate, 'yyyy-MM-dd')  ,
      date_to : this.datepipe.transform(this.filterForm ?.value?.toDate,'yyyy-MM-dd')
    }
    this.lastUpdatedReport(filter)
  }

  lastUpdatedReport(data:any){ 
    this.activityserve.lastupdatedReport(this.user,data).subscribe(
      (result: any) =>{ 
        this.lastupdatedReport = result.data
      }
      );    
  }


}
