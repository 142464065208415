<div class="p-10 bg-white rounded-xl shadow-lg">
    <div class="grid  gap-8 grid-cols-1 ">
        <div class="flex flex-col ">
            <div class="flex flex-col sm:flex-row items-center mb-2">
                <h2 class="font-semibold text-lg mr-auto"><a routerLink="/teamleader/contact" class="hoverlink">Contacts</a> / Contact View</h2>
                <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0">
                </div>
                <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
                    <button routerLink="/teamleader/contact" class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100" >Cancel</button>
                    <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg color "(click)="exportcontact()">Export</button>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="mt-3 bg-white rounded px-20">
                <div class="form mt-3">
                    <div class="flex bg-gray-200"  style="justify-content: center;">
                        <div class="w-30 h-12 p-2 border-solid border-1 border-light-gray-200">
                            <p><b>Opportunities : {{lists.opportunity_count}}</b></p>
                        </div>&nbsp;
                        <div class="w-30  h-12 p-2">
                            <p><b>Deals : {{lists.deal_count}}</b></p>
                        </div>&nbsp;
                        <div class="w-30  h-12 p-2">
                            <p><b>Total Leads : {{lists.total_leads_count}}</b></p>
                        </div>
                    </div>
                    <div class="w-full">
                        <div class="flex flex-row bg-white shadow-xl rounded-lg py-3">
                            <div class="flex w-60 p-2" style="margin-left: 10vh !important;margin-top: 10vh !important;">
                                <img class="w-32 h-32 " src="{{singlecontact.image}}" alt="User">
                            </div>
                            <div class="flex">
                                <table class="text-xs my-3" style="font-size: medium !important;">
                                    <tbody>
                                        <tr>
                                            <td class="px-2 py-2 text-gray-500 font-semibold">Name</td>
                                            <td class="px-2 py-2">&nbsp;: &nbsp;{{singlecontact.first_name}} {{singlecontact.last_name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="px-2 py-2 text-gray-500 font-semibold">Phone</td>
                                            <td class="px-2 py-2">&nbsp;: &nbsp;{{singlecontact.mobile_phone}}</td>
                                        </tr>
                                        <tr>
                                            <td class="px-2 py-2 text-gray-500 font-semibold">Email</td>
                                            <td class="px-2 py-2">&nbsp;: &nbsp;{{singlecontact.email}}</td>
                                        </tr>
                                        <tr>
                                            <td class="px-2 py-2 text-gray-500 font-semibold">Address</td>
                                            <td class="px-2 py-2">&nbsp;: &nbsp;{{singlecontact.address_line_1}}<br>&nbsp;&nbsp; &nbsp;{{singlecontact.address_line_2}}</td>
                                        </tr>
                                        <tr *ngIf="singlecontact.city!=''">
                                            <td class="px-2 py-2 text-gray-500 font-semibold">City</td>
                                            <td class="px-2 py-2">&nbsp;: &nbsp;{{singlecontact.city}}</td>
                                        </tr>
                                        <tr *ngIf="singlecontact.state!=''">
                                            <td class="px-2 py-2 text-gray-500 font-semibold">State</td>
                                            <td class="px-2 py-2">&nbsp;: &nbsp;{{singlecontact.state}}</td>
                                        </tr>
                                        <tr *ngIf="singlecontact.zipcode!=''">
                                            <td class="px-2 py-2 text-gray-500 font-semibold">Zipcode</td>
                                            <td class="px-2 py-2">&nbsp;: &nbsp;{{singlecontact.zipcode}}</td>
                                        </tr>
                                        <tr *ngIf="singlecontact.country!=''">
                                            <td class="px-2 py-2 text-gray-500 font-semibold">Country</td>
                                            <td class="px-2 py-2">&nbsp;: &nbsp;{{singlecontact.country_code}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
