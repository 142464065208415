import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenutoggleService } from '../../services/menutoggle.service';
import { LoginService } from '../../services/login.service';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReportService } from '../../services/report.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SuccessComponent } from '../../success/success.component';
import { MatCardModule } from '@angular/material/card';
import { EditLeadService } from '../../services/edit-lead.service';
import { ExecutiveComponent } from '../executive.component';
import { ToastrService } from 'ngx-toastr';
import { NotificationComponent } from "../../teamleader/notification/notification.component";
import { CustCardComponent } from '../cust-card/cust-card.component';
import { NotificationSidebarComponent } from '../notification-sidebar/notification-sidebar.component';


@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule,MatCardModule,ExecutiveComponent,RouterModule,NotificationSidebarComponent],
//   providers : [EditLeadService,ReportService,LoginService],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit {
	public menuclass:string = "hidden";
	public user:any
	public totaltarget:any = 0
	progress:any = 0;
	teamname:any;
	notifyFlag = false;
	notification=0;
	uname:any
	 uid:any
	public isNotif:boolean = false;
	disabledP = false;
	isDropdownVisible: boolean = false
	degraded : any;
	barProgress = '';
	performanceDetails : any = [];
	menuFlag = false;
	role: any;
	
    constructor(
	  private breakpointObserver: BreakpointObserver,
	  private menuservice:MenutoggleService,
	  private loginservice:LoginService,
	  private rservice:ReportService,
	
	  private router:Router,
	  private dialog: MatDialog,
	  private lsService : EditLeadService,
	  private toaster:ToastrService,
	  
  ) {}

    ngOnInit(): void { 
	
		this.user = this.loginservice.getUserdata()
		
      this.rservice.notificationUpdate$.subscribe((data:any) => {
        if(data){
          this.notification = data?.notification_count
        }else{
          this.notification = 0;
        }
        
      });
		this.getNotificationList();
	    this.rservice.getupdate().subscribe((res:any)=>{
		this.getPerformanceData();
	
		})	
		
	  
		this.uid=this.user.data.id
		this.menuservice.onGetnav.subscribe((data:any)=>{
		   
		    if(data==true){
				
			  this.menuclass = 'hidden';
			
		    } else{
				
			  this.menuclass = 'block';
		    }
		});
		
		this.user = this.loginservice.getUserdata()
	  
		this.uid=this.user.data.id
		this.uname=this.user.data.first_name;
		console.log(this.uname,'unameiiiii')
		this.role= this.user.data.groups[0].name;
		this.getPerformanceData();
	}
	
	getPerformanceData(){
		this.rservice.getprogressreport(this.user.data.id).subscribe((res:any)=>{
			this.performanceDetails = res;
			this.progress = this.performanceDetails[0]?.member_progress ? this.performanceDetails[0]?.member_progress : 0 ;
			
		})
	}

    getteamname(id:any)
	{
		this.rservice.getprog(id).subscribe((response:any)=>{
			this.teamname =response.teamname
				
		})
	}
	getnotifi(id:any)
	{
		this.rservice.getnotification(id).subscribe((response:any)=>{
			this.notification=response
				
		})
	}
	eventupdate(res:any)
	{
		this.rservice.updatenotification(this.user.data.id,res).subscribe((response:any)=>{
			this.getnotifi(this.user.data.id)
					
		})
	}
	
	openmenu(){
		this.menuservice.onSetnav(true);
	}
	logout(){
		localStorage.removeItem("usertoken")
		localStorage.removeItem("userdata")
		this.toaster.success('Logout Sucessfully','success')
		this.router.navigate(['/'])

  }
  getNotification(){
	this.notifyFlag = !this.notifyFlag
  }

   /**
   * Returns a color for a certain percent
   * @param percent The current progress
   */
   whichColor(percent: string){
    // Get all entries index as an array
    let k: Array<any> = Object.keys(this.degraded);
    // Convert string to number
    k.forEach((e, i) => k[i] = +e);
    // Sort them by value
    k = k.sort((a, b) => a - b);
    // Percent as number
    let p = +percent
    // Set last by default as the first occurrence
    let last = k[0];
    // Foreach keys 
    for(let val of k){
      // if current val is < than percent
      if(val < p){
        last = val;
      }
      // if val >= percent then the val that we could show has been reached
      else if(val >= p -1){
        return this.degraded[last];
      }
    }
    // if its the last one return the last
    return this.degraded[last];
  }

  whichProgress(progress: string){
    try{
      return Math.round(+this.progress * 100) / 100;
    }
    catch{
      return progress;
    }
  }
  onChangePassword(){
	this.isDropdownVisible = false;
  }

  onProfileClick(){
	this.isDropdownVisible = !this.isDropdownVisible
  }
  getNotificationList(){
    this.rservice.connect(this.user?.data?.id)
  
    }
  

  notifyCloseEvent()
  {
    this.notifyFlag = false;
  }
 
  notifySingleCloseEvent()
  {
	
    this.getNotificationList();
}
}
