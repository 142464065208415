import { AfterViewInit, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, NgZone, NO_ERRORS_SCHEMA, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EmailService } from './email.service';
import { EmailtemplateService } from '../add-email-templates/email-template.service';
import { AddContactgroupService } from '../add-contactgroups/add-contactgroups.service';
import { Router, RouterModule } from '@angular/router';
import { ContactFormService } from '../../services/contact-form.service';
import { LoginService } from '../../services/login.service';
// import { AngularEditorConfig, AngularEditorModule } from '@kolkov/angular-editor';
import { SuccessComponent } from '../../success/success.component';
import { FailComponent } from '../../fail/fail.component';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
// import { angularEditorConfig } from '@kolkov/angular-editor/lib/config';
declare var ClassicEditor: any;


 
@Component({
  selector: 'app-emails',
  standalone: true,
  imports: [CommonModule,MatDividerModule,ReactiveFormsModule,RouterModule],
  templateUrl: './emails.component.html',
  styleUrl: './emails.component.scss',
 
 
})
export class EmailsComponent implements OnInit,AfterViewInit,OnDestroy {
  public user: any;
  contact:any[] =[];
  template:any[]=[]
  esignature:any[]=[]
  dmessage:any
  recepientlist:any
  group_list:any[]=[]
  contact_list:any[]=[]
  dsignature:any
  registerForm!: FormGroup;
  submitted = false;
  isGroup:boolean=false;
  htmlContent = '';
  editorInstance  : any 
 
  constructor(private dialog: MatDialog,private formBuilder: FormBuilder,private etempservice:EmailtemplateService,private cgroupservice:AddContactgroupService,
    private emailservice: EmailService,private contactservice: ContactFormService,private loginservice: LoginService,
    private router: Router,private zone: NgZone,private cdRef: ChangeDetectorRef) { }
 
  ngOnInit() {
    this.user = this.loginservice.getUserdata()
      this.registerForm = this.formBuilder.group({
          contacts: [null, Validators.required],
          mailtype:['',Validators.required],
          subject:['',Validators.required],
          cc:['',Validators.email],
          bcc:['',Validators.email],
          message:['', Validators.required],
          signature:[null,Validators.required],
          template:[null,Validators.required],
          user:this.user.data.id,
          groupid:[null,]
         
      }, );
     
      this.getcontacts(this.user.data.id)
      this.getEmailtemplate(this.user.data.id)
     
      this.getEmailsignature(this.user.data.id)
     
    }
 
    ngAfterViewInit() {
      setTimeout(() => {
         // Initialize CKEditor after the view is loaded
      const editorContainer = document.querySelector('#editor'); // Target div
      if (editorContainer) {
        ClassicEditor.create(editorContainer)
          .then((editor: any) => {
            this.editorInstance = editor;
            editor.setData(''); // Set initial content
            editor.model.document.on('change:data', () => {
              this.htmlContent = editor.getData(); // Update content on changes
            });
          })
          .catch((error: any) => {
            console.error('There was a problem initializing CKEditor:', error);
          });
      }
        
        }, 0);
    }
   
   
     
    ngOnDestroy() {
     
    }
 
    getEmailsignature(userid :number){
 
    this.etempservice.getEmailsignature(userid).subscribe(
      (sdata: any) =>{
        this.esignature = sdata
      }
 
      );
  }
    getEmailtemplate(userid :number){
 
    this.etempservice.getEmailtemplate(userid).subscribe(
      (tdata: any) => this.template = tdata
 
      );
  }
    getcontacts(userid: number){
   
      this.contactservice.contacts(userid).subscribe((contact: any)=>{
        this.contact=contact;
        contact.forEach((item: any) => {
        });
      })
       
      }
    groups(type:any)
{
  this.isGroup = type == 'group' ? true : false;
  this.registerForm.get('subject')?.reset('');
  this.registerForm.get('contacts')?.reset(null);
  this.registerForm.get('cc')?.reset('');
  this.registerForm.get('bcc')?.reset('');
  this.registerForm.get('message')?.reset('');
  this.registerForm.get('signature')?.reset(null);
  this.registerForm.get('template')?.reset(null);
  this.registerForm.get('groupid')?.reset(null);
  if(type=='group'){
    this.registerForm.get('groupid')?.setValidators([Validators.required])
    this.registerForm.get('groupid')?.updateValueAndValidity();
  }
  else{
    this.registerForm.get('groupid')?.clearValidators();
  }
 
  // this.isGroup=true
  this.cgroupservice.getContactgroup(this.user.data.id).subscribe(
    (data: any) => this.group_list = data
 
    );
    this.submitted=false;
}
groupmembers(val:any)
{
 let group_members_list:any
  let  contact_email_list:  any = [];
  let email_arr:any[]=[]
  this.cgroupservice.getContactgroupMembers(val).subscribe(
    (data: any) =>{
   
      this.contact_list = data[0].contact
      for(let res of this.contact_list)
      {
      //  let data_r={
      //    "email":res.contact.email
      //  }
        contact_email_list.push(res.email)
        const email_li = contact_email_list
       group_members_list = email_li.toString();
       this.registerForm.controls['contacts'].setValue(group_members_list)
     
      }
     
});
}
emailtemplate(val:any){
  this.etempservice.getOneEmailtemplate(val).subscribe((data:any)=>{
    if(data){
      this.dmessage = data.message;
      this.cdRef.detectChanges(); // Manually trigger change detection
      this.registerForm.controls['message'].setValue(this.dmessage)
      this.registerForm.controls['subject'].setValue(data.subject)
      this.editorInstance.setData(data?.message)
    }
  })
}
emailsignature(val:any)
{
  this.etempservice.getOneEmailsignature(val).subscribe((data:any)=>{
    this.dsignature=data.signature                
    // this.registerForm.controls['signature'].setValue(this.dsignature)
   
  })
}
get f() { return this.registerForm.controls; }
 
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
        return;
    }
    if (this.editorInstance) {
      const val = this.editorInstance.getData()
      let cleanedContent = val.replace(/<p>/g, '');   // Remove opening <p> tags
      cleanedContent = cleanedContent.replace(/<\/p>/g, '');
      this.registerForm.controls['message'].setValue(cleanedContent)
    }
    let data_ema={
    "contact_group_id":this.registerForm.value.groupid,
    "user_id":this.user.data.id,
    "to":this.registerForm.value.contacts,
    "mailtype":this.registerForm.value.mailtype,
    "subject":this.registerForm.value.subject,
    "message":this.registerForm.value.message,
    "signatures":this.registerForm.value.signature,
    "cc":this.registerForm.value.cc,
    "bcc":this.registerForm.value.bcc,
    "send_now":true
  }
    this.emailservice.addEmail(data_ema).subscribe((reponse)=>{
      if(reponse)
      {
      const confirmDialog = this.dialog.open(SuccessComponent, {
 
        data: {
          title: 'Success',
          message: 'Email Send Succesfully!'
        }
      })
      confirmDialog.afterClosed().subscribe(result => {
            if (result == true) {
              this.submitted = false;
              this.registerForm.reset();
             this.editorInstance.setData('');
              this.router.navigate(['teamleader/email/']);
       
      }
    });
  }
else{
   const confirmDialog = this.dialog.open(FailComponent, {
 
       data: {
         title: 'Failed',
         message: 'Email Send Failed!'
       }
     })
     confirmDialog.afterClosed().subscribe(result => {
 
      if (result == true) {
          this.router.navigate(['teamleader/email'])
     }
 
 
   });
}
   });
 
}
onReset() {
  this.submitted = false;
  this.registerForm.reset();
}
cancel(){
  this.submitted = false;
  this.registerForm.reset();
}
}