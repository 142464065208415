<div class="max-w w-full h-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 height">
    <div class="grid  gap-8 grid-cols-1 ">
      <form class="" [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm.value)">
        <div class="flex flex-col">
                <div class="flex flex-col sm:flex-row items-center mb-2">
                    <h2 class="font-semibold text-lg mr-auto"*ngIf="!isEdit">Members / New</h2>
                    <h2 class="font-semibold text-lg mr-auto" *ngIf="isEdit">Members / Edit</h2>
                    <div class="w-full sm:w-auto sm:ml-auto sm:mt-0"></div>
                    <div class=" text-right md:space-x-3 md:block flex flex-col-reverse mb-2" *ngIf="!isEdit">
                      <a routerLink="/teamleader/members" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 cursor-pointer"> Cancel </a>
                        <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg color cursor-pointer">Save</button>
                    </div>
                    <div class=" text-right md:space-x-3 md:block flex flex-col-reverse  mb-2" *ngIf="isEdit">
                      <a routerLink="/teamleader/members" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 cursor-pointer"> Cancel </a>
                        <a (click)="editcontact(cid)" class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg color cursor-pointer">Save</a>
                    </div>


                </div>
                <mat-divider></mat-divider>
             
                <div class="mt-3 bg-white rounded border border-gray-100  px-20"  style="height: 65vh !important; overflow-y: auto;">
                    <div class="form mt-3">
                     
                      <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3">
                          <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                            Name</label>
                        <input type="text" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " placeholder="Name"/>
                        <div *ngIf="submitted && f['name'].errors" class="invalid-feedback text-sm">
                         <div *ngIf="f['name'].errors['required']">Please fill out this field.</div>
                     </div>
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                            Email</label>
                        <input #em type="email" formControlName="email" (click)="userexist(em.value)"[ngClass]="{ 'is-invalid': submitted && f['email'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " placeholder=" Email"/>
                        <div  class="invalid-feedback text-sm" *ngIf="isUserExist">User Exist!</div>
                        <div *ngIf="submitted && f['email'].errors" class="invalid-feedback text-sm">
                         <div *ngIf="f['email'].errors['required']">Please fill out this field.</div>
                         <div *ngIf="f['email'].errors['email']">Invalid Format.</div>
                     </div>
                        </div>
                      </div>
                     
                      <div class="flex flex-wrap -mx-3 mb-6">
                       
                        <div class="w-full md:w-1/2 px-3">
                          <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                            Phone</label>
                        <input type="number" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f['phone'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " placeholder="phone number"/>
                        <div *ngIf="submitted && f['phone'].errors" class="invalid-feedback text-sm">
                         <div *ngIf="f['phone'].errors['required']">Please fill out this field.</div>
                         <div *ngIf="f['phone'].errors['pattern']">invalid Format.</div>
                     </div>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                           Role
                            </label>
                            <select   formControlName="role" [ngClass]="{ 'is-invalid': submitted && f['role'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                              <option value=null disabled selected>Select</option>
                              <option *ngFor="let rl of roles" [value]="rl" >{{rl}}</option>
                              
                              
                            </select>
                          <div *ngIf="submitted && f['role'].errors" class="invalid-feedback text-sm">
                            <div *ngIf="f['role'].errors['required']">Please fill out this field.</div>
                        </div>
                          </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-6" *ngIf="!isEdit">
                       
                          <div class="w-full md:w-1/2 px-3">
                            <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                              Password</label>
                              <input class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm "
                              formControlName="password"
                              type="password" 
                               placeholder="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                               <div *ngIf="submitted && f['password'].errors" class="invalid-feedback text-sm" >
                                  <div *ngIf="f['password'].errors['required']">Please fill out this field.</div>
                          </div>
                          </div>
                          <div class="w-full md:w-1/2 px-3">
                            <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                              Confirm Password</label>
                              <input class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm "   
                              formControlName="confirm_password"
                              type="password" 
                              placeholder="confirm password" [ngClass]="{ 'is-invalid': submitted && f['confirm_password'].errors }">
                              <div *ngIf="submitted && f['confirm_password'].errors" class="invalid-feedback text-sm" >
                                  <div *ngIf="f['confirm_password'].errors['required']">Please fill out this field.</div>
                              <div *ngIf="f['confirm_password'].errors ? f['confirm_password'].errors['confirmedValidator'] : false">Password and Confirm Password must be match.</div>
                          </div>
                          </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full  px-3">
                          <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Address
                          </label>
                         <textarea   formControlName="address" class="relative w-full bg-white text-sm text-gray-700 placeholder-gray-400 border h-5/6 border-gray-400"></textarea>
                        </div>
                      </div>    
                               
                    </div>      
                    
                  
                </div>
        </div>
      </form>
    </div>
  </div>
  
  