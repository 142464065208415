<div class="max-w w-full space-y-8  p-10 bg-white rounded-xl shadow-lg z-10 ">
    <div class="flex flex-col sm:flex-row items-center mb-2">
        <h2 class="font-semibold  text-lg mr-auto">Team Members Report - {{members[0]?.team?.teamname}}</h2>
        <a class="justify-start hover-text-blue link-hover" routerLink="/superAdmin/admin-report/{{cid}}">Report</a>/Team Members</div>
    <mat-divider></mat-divider>
    
    <div class='overflow-x-auto overflow-y-auto  w-full'>
        <table class="min-w-max w-full table-auto">
            <thead>
                <tr class="bg-gray-200 text-gray-600 text-sm">
                    <th class="py-3 px-6 text-center">Members</th>
                    <th class="py-3 px-6 text-center">Role</th>
                    <th class="py-3 px-6 text-center">Target</th>
                    <th class="py-3 px-6 text-center">Progress</th>
                    <th class="py-3 px-6 text-center">Manage</th>
                </tr>
            </thead>
         
                <tbody class="text-gray-600 text-sm font-light">
                <tr  class="border-b border-gray-200 hover:bg-gray-100" *ngFor='let item of members  | paginate: { itemsPerPage: 10, currentPage: p }' >
                    <td class="py-3 px-6 text-center whitespace-nowrap">
                        {{item.team_member.username}}
                    </td>
                    <td class="py-3 px-6 text-center">
                        {{item.team_member.groups[0].name}}
                    </td>
                    <td class="py-3 px-6 text-center">
                        {{item.target}}
                    </td>
                    
                    <td class="py-3 px-6 text-center">
                        {{item.member_progress}} %
                    </td>
                    <td class="">
                        <div class="iconAlign">
                            <span routerLink="/superAdmin/member-leads/{{cid}}/{{item.team_id}}/{{item.team_member_id}}/"  class="cursor-pointer bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs">View Leads</span>
                        </div>
                    </td>
                </tr>
            
            </tbody>
        </table>
        <pagination-controls (pageChange)="p = $event" class="float-right"></pagination-controls>
    </div>        
    
</div>
