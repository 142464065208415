import { Component, OnInit, Input, Output,EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CdkDrag,
	CdkDragDrop,
	CdkDropList,
	CdkDropListGroup,
	moveItemInArray,
	transferArrayItem, } from '@angular/cdk/drag-drop';

import { LoginService } from '../../services/login.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { LeadDialogComponent } from '../lead-dialog/lead-dialog.component';
import { CompanyservService } from '../../services/companyserv.service';
import { ContactFormService } from '../../services/contact-form.service';
import { QuotationListService } from '../../services/quotation-list.service';
import { ActivityaddComponent } from '../activityadd/activityadd.component';
import { AddActivityModalComponent } from '../add-activity-modal/add-activity-modal.component';
import { OpportunityDialogComponent } from '../opportunity-dialog/opportunity-dialog.component';
import { QuotationDialogComponent } from '../quotation-dialog/quotation-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { CustCardComponent } from '../cust-card/cust-card.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import { DragDropModule} from '@angular/cdk/drag-drop';
import {MatBadgeModule} from '@angular/material/badge';
import {MatDialogModule} from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditLeadService } from '../../services/edit-lead.service';
import { MenutoggleService } from '../../services/menutoggle.service';
import { Board, Column, Task } from '../../admin/admin-global';
import { Subject } from 'rxjs';
import { PipelineService } from '../../teamleader/drag-drops/pipeline.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Console } from 'console';
import { response } from 'express';

@Component({
  selector: 'app-drag-drop',
  standalone: true,
  imports: [MatIconModule, MatTooltipModule, CustCardComponent,MatMenuModule,CommonModule,FormsModule,LayoutModule,ScrollingModule,CdkStepperModule,DragDropModule,MatBadgeModule,MatDialogModule,NgSelectModule,ReactiveFormsModule],
  providers : [QuotationListService,CompanyservService,ContactFormService,PipelineService,LoginService,EditLeadService],
  templateUrl: './drag-drop.component.html',
  styleUrl: './drag-drop.component.scss'
})
export class DragDropComponent implements OnInit {

	dialogRef!: MatDialogRef<any>;
private rating: number = 1;
	private color: string = 'bg-blue-600';
	public board: any = Board;
	public boardid: any;
	public user: any;
	item:any;
	@Input() userid: any; // 
	public currentEditedTask:number = 0;
	public tasklis:any=[];
	@Input() column:any
	@Input() task:any;
	isBoxVisible: boolean = true;
	 subStatusAddId : any = null;
	@Output() private ontaskcomplete = new EventEmitter();
	opportunity: string = 'opportunity'; // Add this line
	private childEvent = new Subject<any>;
	isLeadFlag = false;
	isOpportunityFlag = false;
	isQuotationFlag = false;
	showInputFlag = false;
	columnText : any = '';
	opportunityFlag = false
	wonFlag = false;
	dropContainerFlag = false;
	statusArr : any[] = [];
	taskActiveId : any = null;
	salestask: any []=[];
	substatusArr : any[] = []
	statusList: any;


	constructor(
		private pservice: PipelineService,
		private loginservice: LoginService,
		private dialog: MatDialog,
		private cdrf : ChangeDetectorRef,
		private leadserv : EditLeadService,
		// public dialogs: MatDialogRef<DragDropComponent>
	) { }

	subStatus = new FormControl('', [Validators.required]);
 

	ngOnInit() {
		this.user = this.loginservice.getUserdata()
		this.getTaskList();
		this.getsalestasks();
		this.getSubTaskList()
		this.getBoards(this.user.data.id);
		
		
	}

	getTaskList(){
		this.pservice.getTaskList().subscribe((res:any)=>{
			
			if(res?.message){
				this.statusArr = res.message;
			}
		})
	}
	close(boxContainer: HTMLElement) {
		boxContainer.style.display = 'none'; 
	}
	getBoards(userid: number) {
	
		this.pservice.getBoard(userid).subscribe(
			(data: any) => {
				
				this.board = new Board(data[0]['id'], data[0]['name'], [])
				this.boardid = data[0]['id'];
				this.getColumns(this.boardid);
			}
		)
	}
	addlead(id:any,status:any,substatus:any){
	    const addLead = this.dialog.open(LeadDialogComponent, {
		  data: {
			id:id,
			status:status,
			substatus:substatus
		  },
		});
		addLead.afterClosed().subscribe(result => {
			if(result == 'from_create'){
				this.getsalestasks();
			}
		});
	}
	// cancelSubStatus(){

	// }
	getColumns(boardid: number) {
		
		this.pservice.getColumns(boardid).subscribe(
			(cdata: any) => {
				
				this.board.columns = [];
				for (let cdat of cdata) {
					let col = new Column(cdat['id'], cdat['name'], [], false)
					this.board.columns.push(col)
					this.getTasks(col)

				}
			}
		)

	}
	getTasks(column: Column) {
		// this.pservice.getTask(column.id).subscribe(
		// 	(data: any) => {
		// 		for (let task of data) {
		// 			this.pservice.getActivityCount(this.user?.data?.id,task?.id).subscribe((res:any)=>{
		// 				let status=task.status 
		// 				let image=task.contact.image
		// 				let activityCount = res?.Activity_Count
		// 				let ntask = new Task(
		// 					task['id'],
		// 					task['customer'],
		// 					task['creator_id'],
		// 					task['contact_id'],
		// 					task['column_id'],
		// 					task['name'],
		// 					task['budget'],
		// 					task['appname'],
		// 					task['rating'],
		// 					task['color'],
		// 					task['ciImage'] = image,
		// 					task['status'],
		// 					task['email'],
		// 					task['task_stage'],
		// 					task['activityCount'] = activityCount
		// 				)
		// 				column.tasks.push(ntask)
		// 				console.log(column?.tasks)
		// 			})
		// 		}
			
		// 	}
		// )
	}

	
	showcolstatus(column: Column) {
		
		if (column.mstatus) {
			
			return "block"
		} else {
			
			return "hidden"
		}
	}

	removeColumn() {
		if (this.board.columns.length) {
			this.board.columns.pop();
		}
	}
	delColumn(column:any){
		const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
			data: {
			  title: 'Confirm Remove Column',
			  message: 'Are you sure, you want to remove a column: ' + column.name
			}
		  });
		  confirmDialog.afterClosed().subscribe(result => {
			
			if (result === true) {
				
		this.pservice.delOneColumn(column.id).subscribe((data:any)=>{
			
		  this.getBoards(this.user.data.id);
		})
	}
   });
	}

	boards: any = { columns: [] }; 
 
   
	getConnectedLists(status: any): string[] {
		const connectedLists:any = [];
		this.boards.columns.forEach((column:any) => {
		  connectedLists.push(column.id); 
		});
		return connectedLists;
	  }
   
	 onDrop (event: CdkDragDrop<any[]>, targetStatus:any): void {
	
        this.statusList = [];
        const taskDetails = event.previousContainer.data[event.previousIndex] as any;
        if(this.statusArr?.length){
           var i :any = 0;
            this.statusArr.forEach((item:any)=>{
                i = i + 1;
                this.statusList.push({order:i,name:item?.name})
                if(this.substatusArr?.length){
                    this.substatusArr.forEach((subItem:any)=>{
                        if(subItem?.task_status == item?.name){
                            i = i + 1;
                        this.statusList.push({order:i,name:subItem?.sub_status})
                        }
                    })
                }
            })
        }
            var currentOrder : any = '';
            var targetOrder : any = '';
            if(targetStatus?.name){
                currentOrder = '';
                this.statusList.forEach((item:any)=>{
                    if(item.name == targetStatus?.name){
                        currentOrder = item?.order;
                    }
                })
            }else if(targetStatus?.sub_status){
                currentOrder = '';
                this.statusList.forEach((item:any)=>{
                    if(item.name == targetStatus?.sub_status){
                        currentOrder = item?.order;
                    }
                })
            }
            if(taskDetails?.status && taskDetails?.task_stage == ""){
                targetOrder = '';
                this.statusList.forEach((item:any)=>{
                    if(item.name == taskDetails?.status){
                        targetOrder = item?.order;
                    }
                })
            }else if(taskDetails?.status && taskDetails?.task_stage){
                targetOrder = '';
                this.statusList.forEach((item:any)=>{
                    if(item.name == taskDetails?.task_stage){
                        targetOrder = item?.order;
                    }
                })
            }
            if(currentOrder < targetOrder ){
                return;
            }
            if (event.previousContainer === event.container) {
               // Reordering within the same list
                const previousIndex = event.previousIndex;
                const currentIndex = event.currentIndex;
            } else {
				
                if(targetStatus?.name){
				
                taskDetails.status = targetStatus?.name;
                taskDetails.task_stage = '';
				
                this.pservice.changestatus(taskDetails?.id,this.user?.data?.id,targetStatus?.name,targetStatus?.name).subscribe((reponse:any)=>
					{
                    if(reponse){
					
                        this.pservice.ContactleadCountUpdateView(taskDetails?.contact_id,taskDetails?.id,targetStatus?.name,this.user?.data?.id).subscribe((result:any)=>{
                        })
                        this.pservice.change_task_status(taskDetails?.id,'',this.user?.data?.id).subscribe((response)=>{
							console.log(response,'task_stageeeee')

                        });
						let current_status=reponse?.task_status
                        if(current_status=='Opportunity'){
							this.pservice.TasksToOppertunityView(taskDetails?.id,this.user?.data?.id).subscribe((res:any)=>{
							});
						}
						else if(current_status=='Won'){
							this.pservice.OpportunityToQuotation(taskDetails?.id).subscribe((result:any)=>{
								
							})
						}
                    }
                })
            }
			else if(targetStatus?.sub_status){
                taskDetails.status = targetStatus?.task_status;
                taskDetails.task_stage = targetStatus?.sub_status;
                this.pservice.changestatus(taskDetails?.id,this.user?.data?.id,targetStatus?.task_status,targetStatus?.sub_status).subscribe((reponse:any)=>{
                    if(reponse){
                        this.pservice.ContactleadCountUpdateView(taskDetails.contact_id,taskDetails?.id,targetStatus?.task_status,this.user?.data?.id).subscribe((result:any)=>{
                        })
                        this.pservice.change_task_status(taskDetails?.id,targetStatus?.sub_status,this.user?.data?.id).subscribe((response)=>{
                        });
						let current_status=reponse?.task_status
                       
						if(current_status=='Opportunity'){
							this.pservice.TasksToOppertunityView(taskDetails?.id,this.user?.data?.id,targetStatus?.sub_status).subscribe((res:any)=>{
							});
						}
						else if(current_status=='Won'){
							this.pservice.OpportunityToQuotation(taskDetails?.id,targetStatus?.sub_status).subscribe((result:any)=>{
								console.log(result,'Qquotationnnnnn')
							})
						}
                    }
                })
            }
    }
    }
	
     
	addColumnToBoard(name: string) {
		this.pservice.addColumns(this.boardid, name).subscribe(
			(data: any) => {
				this.getBoards(this.user.data.id);
			}
		)
	}
	boardchange(name:any,id:any,bid:any)
	{
		
		let data_c={
			
			"name":name,
			"board_id":bid
		}
		this.pservice.editColumn(data_c,id).subscribe((reponse)=>{
		
		   }); 
	}
	reloadboard(event:any){
		this.cdrf.detectChanges()
		this.getsalestasks();
		this.ontaskcomplete.emit(event);
		
	}

	onColumnAdd(){

	}

	getsalestasks(){
		this.salestask = []
		this.pservice.getsalesTask(this.user.data.id).subscribe((result:any)=>{
			if(result){
			    result.forEach((item:any)=>{
					this.pservice.activityCount(this.user.data.id, item?.id).subscribe((res: any) => {
						if(res) {
							item.activityCount = res?.Activity_Count;
						    console.log("Activity Count:",res, this.salestask);
			                this.salestask.push(item);

						} else {
							console.error("Failed to fetch activity count.");
						}
					});

			})

		}
		})
	
	}
	


	addSubStatus(id:any){
		
		this.columnText = null;
		if (this.subStatusAddId === id) {
			
			this.subStatusAddId = null;
			
		  } else {
			this.subStatusAddId = id;
		  }
	}
	createSubStatus(id:any){
        if(this.subStatus.valid){
            const status:any = this.subStatus?.value;
            const formData: FormData = new FormData();
            formData.append('task_status_id', id);
			formData.append('user_id',this.user.data.id);
            formData.append('sub_status', status);
   
            this.pservice.createSubStatus(formData).subscribe((res:any)=>{
                if(res?.message){
                    this.subStatusAddId = null;
                    this.getSubTaskList();
					this.subStatus.reset();
                }
            })
        }else{
            return
        }
       
    }

	getSubTaskList(){
        this.substatusArr = [];
        this.pservice.getSubStatusList(this.user?.data?.id).subscribe((res:any)=>{
            if(res?.message){
                this.substatusArr = res?.message;
				
            }
        })
    }

	RemoveSubStatus(subItemId: any){
		this.pservice.DeleteSubTask(subItemId).subscribe((result:any)=>{
			this.getSubTaskList();
		})

	  }

}
