import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from '../../global';

@Injectable({
  providedIn: 'root'
})
export class GetEventsService {

  constructor(
    private http:HttpClient,
  ) { }
  getEvents(){
    return this.http.get(apiurl + 'events');             
    }
    getActivities(userid:number,cdate:any){
      return this.http.get(apiurl + 'pipeline/eventscustom/'+userid+"/"+cdate+"/");             
      }
      geteventdetails(uid:any,edate:any,cat:any)
      {
        return this.http.get(apiurl + 'pipeline/eventsbycat/'+uid+"/"+edate+"/"+cat+"/");   
      } 
  }
