import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from '../../global';
@Injectable({
  providedIn: 'root'
})
export class EditLeadService {

  constructor(private http: HttpClient) { }
  
  editContacts(id:number){
      console.log(id);
    return this.http.get(apiurl + 'pipeline/edittask/'+id);             
    }
}
