import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddContactgroupService } from '../add-contactgroups/add-contactgroups.service';
import { LoginService } from '../../services/login.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrService } from 'ngx-toastr';
declare var XLSX: any;

@Component({
  selector: 'app-contact-group-member',
  standalone: true,
  imports: [CommonModule,RouterModule,ReactiveFormsModule,MatDividerModule,NgxPaginationModule],
  templateUrl: './contact-group-member.component.html',
  styleUrl: './contact-group-member.component.scss'
})
export class ContactGroupMemberComponent implements OnInit {
  data: any[] = [];
  p:number=1;
  public user: any;
  gid:any
  error:any;
  cid:any
  gmid:any
  public isEdit:boolean = false;
  public Activity:any=[];
  contact_group: any;
  constructor( private dialog: MatDialog,private contactservice: AddContactgroupService,private loginservice: LoginService,
    private router: Router,private route: ActivatedRoute, private toaster:ToastrService) { }
    ngOnInit() {
      this.user = this.loginservice.getUserdata()
    
      this.route.paramMap.subscribe(params => {
        this.cid = params.get('id')
        
        if(this.cid != null){
          this.getcontactgroup(this.cid)
      
    }
    
      });

     
    }
  
  getcontactgroup(id :number){

    this.contactservice.getContactgroupMembers(id).subscribe(
      (res: any) => {
        console.log(res,'resultis')
   this.gid=res[0].id
   this.data = res[0].contact
   this.contact_group=res[0].contact_group.id;
  });
  }


  EditContactgroup(cid:number){
		console.log(cid)
    this.router.navigate(['teamleader/update-contactgroup-member/',cid]);

	}
  delContact(list:any){
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Remove Contact',
        message: 'Are you sure, you want to remove a contact: ' + list.first_name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
		this.contactservice.removecontactlist(list.id,this.gid).subscribe((data)=>{
      console.log(data,'dataissss')
      this.getcontactgroup(this.cid)
   
     this.router.navigate(['teamleader/contact-group-member/',this.cid]);
		})
	}
});
}
contactgroupExport() {
  this.contactservice.ExportContactGroupMembersDataView(this.contact_group).subscribe((result: any) => {
    console.log(this.contact_group, "Exporting contact group...");
    if (result?.message) {
      const flattenedData = result.message.map((group: any) => {
        return group.Contacts.map((contact: string) => ({
          "Contact Group": group["Contact Group"],
          "Contact": contact
        }));
      }).flat();
      const worksheet = XLSX.utils.json_to_sheet(flattenedData);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, 'contact-group-data.xlsx');
    }

    if (result?.status === false) {
      this.toaster.error(result?.message, 'Error');
    }
  });
}
// contactgroupExport(){
//   this.contactservice.ExportContactGroupMembersDataView(this.contact_group).subscribe((result:any)=>{
//     console.log(this.contact_group,"111111111111111111111111111111111111111111111111111111111111111111")


//     if(result?.message){
//       const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.message);
//       const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
//       XLSX.writeFile(workbook, 'table-data.xlsx');
//     }
//   if(result?.status==false){
//     this.toaster.error(result?.message,'error')
//    }

//   }) 
// }
}

