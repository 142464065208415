import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { MainService } from '../../services/main-service';
import {NgxPaginationModule} from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-member-leads',
  standalone: true,
  imports: [NgxPaginationModule,RouterModule,CommonModule,MatDialogModule,MatDividerModule,CommonModule,
     MatIconModule],
  templateUrl: './member-leads.component.html',
  styleUrl: './member-leads.component.scss'
})
export class MemberLeadsComponent implements OnInit {
   cid:any
  leads:any[]=[]
  mid:any
  p: number = 1;
  creatid:any;
  constructor(private route:ActivatedRoute,private loginservice:LoginService,private mservice:MainService, private toaster:ToastrService) { }

  ngOnInit(): void {
    
    this.route.paramMap.subscribe(params =>{
      this.cid = params.get("id")
      this.mid=params.get("cid")
    })

    this.listLeads(this.cid,this.mid)
  }

  listLeads(cid:number,mid:any){
    this.mservice.listLeads(cid,mid).subscribe((response:any) => {
      this.leads=response;
      if(response?.status==false){
        this.toaster.error(response.message,'error')
      }
      
    })
    
  }

}
