import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyservService } from '../../services/companyserv.service';
import { LoginService } from '../../services/login.service';
import { CommonServiceService } from '../../services/common-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe,CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@Component({
  selector: 'app-company',
  standalone: true,
  imports: [ReactiveFormsModule,MatDividerModule,CommonModule,MatIconModule,MatFormFieldModule,MatDatepickerModule,MatNativeDateModule,RouterModule,MatInputModule],
  providers : [DatePipe],
  templateUrl: './company.component.html',
  styleUrl: './company.component.scss'
})
export class CompanyComponent {
  constructor( private formBuilder:FormBuilder,private datePipe: DatePipe,private router: Router,private route: ActivatedRoute,private servdata:CommonServiceService,private loginservice :LoginService,private companyserv:CompanyservService,private toaster:ToastrService ) {}

  addressGroup!: FormGroup;
  user:any;
  ishide:boolean=true;
  countryList:any;
  stateList:any;
  cityList:any;
  cstDetails:any;
  cid:any = 0;
  addressDetails:any = [];
  userdata:any = JSON.parse(localStorage.getItem('userdata')!);
  id=this.userdata?.data?.id;
  
  ngOnInit(): void { 
    this.route.params.subscribe((params:any)=>{
      if (params['id']!=undefined){
        this.cid = params['id'];
        this.getOrg()
        this.getlistAddress()
      }
      if(this.cid == 0){
        // this.addMoreFormControl()
      }else{
        this.ishide=false;
  
      }
    })
   
    this.user = this.loginservice.getUserdata()
    this.getCountry()
    this.getState()
    this.getCity()
    this.getRegisteryId()
  }

  getRegisteryId(){
    this.companyserv.getRandomRegisteryId().subscribe((res :any)=>{
      if(res?.data){
        this.customerForm.get('registry_id')?.setValue(res?.data?.registry_id)
      }else{
        this.customerForm.get('registry_id')?.setValue('')
      }
    })
  }

  getCountry(){
    this.servdata.getCountry(this.id).subscribe((response:any)=>{
      this.countryList=response.data
    })
  }

  getState(){
    this.servdata.getState(this.id).subscribe((response:any)=>{
      this.stateList=response.data
    })
  }

  getCity(){
    this.servdata.getCity(this.id).subscribe((response:any)=>{
      this.cityList=response.data
    })
  }

  customerForm : FormGroup = this.formBuilder.group({
    contacttype: ['company'],
    name: [ '', [Validators.required,Validators.pattern(/^[a-zA-Z ]+$/)]],
    registry_id: ['', [Validators.pattern('[0-9]{5}')]],
    tax_identification_number: ['', [Validators.pattern(/^\d{3} \d{3} \d{6}$/)]],
    classification: [ ''],
    email:[ '', [Validators.email]],
    address1: [ '', Validators.required],
    address2: [''],
    city:[ '',Validators.required],
    state: ['',Validators.required],
    country: ['',Validators.required],
    zipcode: [ '',[Validators.required,Validators.pattern('[0-9]{6}')]],
    websitelink:[ '',Validators.required],
    phoneNumber: [ '', [Validators.required,Validators.pattern(/^\+(?:[0-9] ?){6,14}[0-9]$/)]],
    fax: ['', [ Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)]],
    comments : [''],
    partner : [false]
    // address_data:this.formBuilder.array([])
}, );


// get address_data():FormArray{
//   return this.customerForm.get('address_data') as FormArray;
// }

// addMoreFormControl(){
//   this.addressGroup =this.formBuilder.group({
//     primary:[false,[Validators.required]],
//     sites:['',[Validators.required]],
//     todate:['',[Validators.required]],
//     fromdate:['',[Validators.required]],
//     purpose:['',[Validators.required]],
//     biil_to_site:['',[Validators.required]],
//     id : ['']
//   })
  
//   this.address_data.push(this.addressGroup);
//   // this.ishide=true;
// }

onSubmit() {
  this.customerForm.markAllAsTouched();
  const postdata = {
    teamid: this.user.teamid,
    contacttype: this.customerForm.value.contacttype,
    name: this.customerForm.value.name,
    registry_id: this.customerForm.value.registry_id,
    tax_identification_number: this.customerForm.value.tax_identification_number,
    classification: this.customerForm.value.classification,
    email: this.customerForm.value.email,
    address1: this.customerForm.value.address1,
    address2: this.customerForm.value.address2,
    city: this.customerForm.value.city,
    state: this.customerForm.value.state,
    country: this.customerForm.value.country,
    zipcode: this.customerForm.value.zipcode,
    websitelink: this.customerForm.value.websitelink,
    phoneNumber: this.customerForm.value.phoneNumber,
    fax: this.customerForm.value.fax,
    // address_data: this.customerForm.value.address_data,
    salesperson: this.user.data.id,
    creator: this.user.data.id,
    comments: this.customerForm.value.comments,
    partner : this.customerForm.value.partner
  };

  if (this.customerForm.valid) {
    if (this.cid === 0) {
      this.companyserv.companyAdd(postdata).subscribe((response: any) => {
        if (response.status_code === 201) {
          this.toaster.success('Successfully Saved', 'Success');
          this.router.navigate(['/executive/customerlist']);
        } else if (response.status_code === 409) {
          this.toaster.error('Record already exists', 'Error'); // Handle existing record
        } else {
          this.toaster.error('Error saving record', 'Error');
        }
      });
    } else {
      this.companyserv.companyEdit(postdata, this.cid).subscribe((response: any) => {
        if (response.status_code === 200) {
          this.toaster.success('Successfully Updated', 'Success');
          this.router.navigate(['/executive/customerlist']);
        } else {
          this.toaster.error('Error updating record', 'Error');
        }
      });
    }
  } else {
     return;
  }
}

  removeForm(index:number,id:any){
  //  this.address_data.removeAt(index);
    if(this.cid != 0){
      if( id != ''){
      this.companyserv.delAddress(id).subscribe((res:any)=>{
        if(res){
        this.toaster.success('Removed','success' )
        }
    })}
    else{
  // this.toaster.error('deleted','error')
 
    }

   
    }
}

getOrg(){
  this.companyserv.getorg(this.cid).subscribe((response:any)=>{
    this.cstDetails=response.company_data
    this.addressReset()

  })
}

getlistAddress(){
  this.companyserv.getaddress(this.cid).subscribe((response:any)=>{
   if(response){
    this.addressDetails=response.address_list
    this.addressReset()
    }
  })
}

addressReset(){
  // var addArr :any = []
//   this.addressDetails.forEach((item:any)=>{
//   // this.addMoreFormControl()
//   addArr.push({
//     primary : item?.primary,
//     fromdate : item?.fromdate,
//     todate : item?.todate,
//     biil_to_site : item?.biil_to_site,
//     purpose : item?.purpose,
//     sites : item?.sites,
//     id : item?.id
//   })
//  })

  this.customerForm.reset({
    contacttype : this.cstDetails?.contacttype,
    name : this.cstDetails?.name,
    registry_id : this.cstDetails?.registry_id,
    tax_identification_number : this.cstDetails?.tax_identification_number ,
    classification : this.cstDetails?.classification,
    email : this.cstDetails?.email ,
    address1 : this.cstDetails?.address1,
    address2 : this.cstDetails?.address2,
    city : this.cstDetails?.city,
    state : this.cstDetails?.state,
    country : this.cstDetails?.country,
    zipcode : this.cstDetails?.zipcode,
    websitelink : this.cstDetails?.websitelink,
    phoneNumber : this.cstDetails?.phoneNumber,
    fax : this.cstDetails?.fax,
    // address_data : addArr
    comments : this.cstDetails?.comments,
    partner : this.cstDetails?.partner,

  })
  }
  
//   updateAddress(index: any, id: any) {
//     // const addressFormGroup: any = this.address_data.at(index);
   

//     // Check if the address is already updated
//     if (addressFormGroup?.pristine) {
//         this.toaster.info('No changes made to update', 'info');
//         return;
//     }

//     this.companyserv.addressEdit(addressFormGroup?.value, id).subscribe((res: any) => {
//         if (res?.status_code === 200) {
//             this.toaster.success(res.message, 'success');
//         } else {
//             this.toaster.error('Not Updated', 'error');
//         }
//     });
// }


}
