<div class="max-w w-full space-y-8 mt-2 p-10 bg-white rounded-xl shadow-lg z-10"
  style="height: 85vh; overflow: hidden;">
  <div class="flex flex-col sm:flex-row items-center mb-4">
    <h2 class="font-semibold text-lg mr-auto">Last Updated Report</h2>
  </div>
  <hr />

  <form class="form-control" [formGroup]="filterForm">
    <div class="flex flex-wrap space-x-4">
      <div class="p-2">
        <label class="block tracking-wide text-gray-400 text-sm mb-2" for="from-date">From Date:</label>
        <input class="border rounded-md w-full p-2" formControlName="fromDate" type="date" placeholder="MM/DD/YYYY" />
      </div>
      <div class="p-2">
        <label class="block tracking-wide text-gray-400 text-sm mb-2" for="to-date">To Date:</label>
        <input class="border rounded-md w-full p-2" formControlName="toDate" type="date" placeholder="MM/DD/YYYY" />
      </div>
      <div class="p-2 flex items-center">
        <button class=" text-white font-bold py-2 px-4 rounded cursor-pointer top" style="background-color: #2a303c;"
          (click)="applyFilter()">
          Submit
        </button>
      </div>
    </div>
  </form>

  <div class="mt-4 w-full overflow-x-auto">
    <table class="min-w-max w-full table-auto border-collapse border border-gray-300">
      <thead class="bg-gray-200 text-gray-600 text-sm">
        <tr class="border-b border-gray-300 ">
          <th class="py-3 px-6 text-center border border-gray-300" >Lead</th>
          <th class="py-3 px-6 text-center border border-gray-300" >Opportunity</th>
          <th class="py-3 px-6 text-center border border-gray-300" >Cloudvice Sales Rep</th>
          <th class="py-3 px-6 text-center border border-gray-300" >Last Updated</th>
        </tr>
      </thead>
      <tbody class="text-gray-600 text-sm " *ngIf="lastupdatedReport && lastupdatedReport.length > 0; else noData">
        <tr *ngFor="let element of lastupdatedReport | paginate: { itemsPerPage: 5, currentPage: p }" class="border-b border-gray-300 hover:bg-gray-100">
          <td class="py-2 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.lead_name }}</td>
          <td class="py-2 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.oppertunity_name }}</td>
          <td class="py-2 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.assign }}</td>
          <td class="py-2 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element.created_date }}</td>
        </tr>
        <!-- <tr *ngIf="lastupdatedReport?.length === 0">
          <td colspan="4" class="text-center font-semibold text-lg py-4">No Data Found</td>
        </tr> -->
      </tbody>
    </table>
    <ng-template #noData><tr><td colspan="4" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
  </div>
  <pagination-controls (pageChange)="p = $event" class="float-right pagination mt-1"></pagination-controls>

</div>