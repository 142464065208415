<div class="max-w w-full space-y-8 h-full p-10 bg-white rounded-xl shadow-lg z-10 mb-5">
    <div class="flex flex-col sm:flex-row items-center mb-2">
        <h2 class="font-semibold uppercase text-lg mr-auto">Meeting</h2>
        <div class="w-full sm:w-auto sm:ml-auto mt-2 sm:mt-0"></div>

    </div>
    <mat-divider></mat-divider>
    
    <div class='overflow-x-auto overflow-y-auto  w-full'>
        <table class="min-w-max w-full table-auto">
            <thead>
                <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th class="py-3 px-6 text-left">Subject</th>
                    <th class="py-3 px-6 text-left">Date</th>
                    <th class="py-3 px-6 text-left">Duration</th>
                    <!-- <th class="py-3 px-6 text-left">Attendee</th> -->
                   
                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm font-light">
                <tr *ngFor='let item of data' class="border-b border-gray-200 hover:bg-gray-100">
                    <td class="py-3 px-6 text-left whitespace-nowrap">
                        <div class="flex items-center">
                      
                            <span class="text-base">{{item.eventname}}</span>
                        </div>
                    </td>
                    <td class="py-3 px-6 text-left">
                        <div class="flex items-center">
                           
                            <span class="text-base">{{item.edate}}</span>
                        </div>
                    </td>
                    <td class="py-3 px-6 text-left">
                        <div class="flex items-center">
                           
                            <span class="text-base">{{item.hour}} {{item.minute}}</span>
                        </div>
                    </td>
                    <!-- <td class="py-3 px-6 text-left">
                        <div class="flex items-center">
                           
                            <span class="text-base">{{item.contacts}}</span>
                        </div>
                    </td> -->
                </tr>
   
            </tbody>
        </table>
    </div>        
    
</div>
