<div class=" w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10" style="min-height: 84vh !important; max-height: 600px; overflow-y: auto;">
     <div class="flex flex-col sm:flex-row items-center mb-2">
        <h2 class="font-semibold  text-lg mr-auto">Contact group Members</h2>
        <div class="w-full sm:w-auto sm:ml-auto mt-2 sm:mt-0"></div>

        <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
            <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
                <a routerLink="/teamleader/add-contactgroup-member/{{gid}}/{{cid}}"  class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Add </a>
                <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg color" (click)="contactgroupExport()">Export</button>
            </div>
        </div>
    
    </div>
    <mat-divider></mat-divider>

    <div class="mt-6">
        <table class="min-w-full table-auto border-collapse border border-gray-200">
            <thead>
                <tr class="bg-gray-200 text-gray-600 text-sm">
                    <th class="py-3 px-6 border border-gray-300 text-center">Name</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Manage</th>
                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm">
                <tr *ngFor="let item of data | paginate: { itemsPerPage: 5, currentPage: p }" class="border-b border-gray-200 hover:bg-gray-100">
                    <td class="py-3 px-6 border border-gray-300 text-center">{{item.first_name}}</td>
                    <td class="py-3 px-6 border border-gray-300 text-center">
                        <div class="flex justify-center space-x-2">
                        <span class="bg-red-200 mr-2 text-purple-600 py-1 px-3 rounded-full text-xs"><a (click)="delContact(item)"><i class="fa fa-trash"></i></a></span>
                        
                        </div>
                    </td>
                </tr>
                <tr *ngIf="data?.length === 0">
                    <td colspan="7" class="text-center font-semibold text-lg py-4">No Data Found</td>
                  </tr>
            </tbody>
        </table>

        <pagination-controls 
            class="mt-4 float-right"
            (pageChange)="p = $event">
        </pagination-controls>
    </div> 
</div>
