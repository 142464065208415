<ul>
	<li id="dashboard" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100  text-sm  cursor-pointer " 
	[class.active]="activeLink === 'dashboard'"(click)="setActiveLink('dashboard')" routerLink="/superAdmin">
		<svg class="flex-shrink-0 mr-1.5 h-5 w-5 icons" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
		</svg>

		<a  class="text-white">                
			 Dashboard
		  </a>
	</li>
	<li id="logs" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm  cursor-pointer"
	[class.active]="activeLink ==='logs'"
	(click)="setActiveLink('logs')" routerLink="/superAdmin/admins">
		<svg class="flex-shrink-0 mr-1.5 h-5 w-5 icons" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
		</svg>

		<a  class="text-white">            
			 New Admin
		  </a></li>
		

	<!-- <li id="logs" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm ">
		<svg class="flex-shrink-0 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
		</svg>

		<a routerLink="/admin/team-report">            
			 Report
		  </a>
		
	</li> -->

	<!-- <li id="database" class="my-1 py-2 pl-6 flex items-center hover:bg-gray-100 text-sm ">
		<svg class="flex-shrink-0 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
		</svg>

		<a (click)="logout()" >
			Logout
		  </a>
	</li> -->


</ul>
