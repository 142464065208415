import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ReportService } from '../../teamleader/reports/report.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-notification-sidebar',
  standalone: true,
  imports: [MatIconModule,CommonModule],
  providers: [DatePipe],
  templateUrl: './notification-sidebar.component.html',
  styleUrl: './notification-sidebar.component.scss'
})
export class NotificationSidebarComponent implements OnInit {

  notificationListArr : any[] = [];
  user : any = '';
  loading:boolean=false;
  @Output()private notifyCloseEvent = new EventEmitter();
  @Output()private notifySingleCloseEvent = new EventEmitter();
  constructor(private reportservice:ReportService, private loginservice:LoginService,private datePipe: DatePipe){

  }

ngOnInit(): void {
  this.user = this.loginservice.getUserdata()
 this.getNotification();
  
}

closeNotification(){
  this.notifyCloseEvent.emit()
}

closeSingleNotification(notify_id:any){
  this.reportservice.clearSingleNotification(this.user?.data?.id,notify_id).subscribe((res:any)=>{
    if(res?.message == "Notification readed successfully"){
      this.notifySingleCloseEvent.emit();
      this.getNotification();
    }
  })
}
getNotification(){
  this.notificationListArr = [];
  this.reportservice.connect(this.user?.data?.id)
  this.reportservice.notificationUpdate$.subscribe((data:any) => {
    if(data?.message?.length){
        this.loading = false;
        this.notificationListArr = data?.message
    }else{
      this.loading = true;
    }
  });
//   .
}
Clearnotification(){
  this.reportservice.markAllNotification(this.user?.data?.id).subscribe((res:any)=>{
    if(res){
     this.notifySingleCloseEvent.emit();
      this.getNotification();
    }
  })
}
}
