import { Injectable } from '@angular/core';
//import { baseurl} from './../global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { apiurl } from '../../global';
@Injectable({
  providedIn: 'root'
})
export class AddContactgroupService {

  
  constructor(
    private http:HttpClient,
  ) { }

  addContactgroup(data:any,userid:any){
  
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
    console.log(data);
    return this.http.post(apiurl+'contactsgroup/'+userid+"/",data,{headers:headers})
  }
  addContactgroupmembers(data:any,userid:any){
  
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
    return this.http.post(apiurl+'contactsgroupmembers/'+userid+"/",data,{headers:headers})
  }
  getUser(){
 
    return this.http.get(apiurl + 'user/alluser/');
                 
    }
getContactgroup(userid:any){
 
        return this.http.get(apiurl + 'contactsgroup/'+userid+"/")
                     
  }
  exportContactGroupDataView(userid:any){
return this.http.get(apiurl+'user/ExportContactGroupDataView/'+userid+"/")
  }
  getContactmember(userid:any){
 
    return this.http.get(apiurl + 'getcontactmemberscustom/'+userid+"/")
                 
}
  getContacts(userid:any){
 
    return this.http.get(apiurl + 'retivcontactlist/'+userid+"/")
                 
}
/* group members*/
getContactgroupMembers(userid:any){
 
  return this.http.get(apiurl + 'ret_contactgroupmembers/'+userid+"/")
               
}
ExportContactGroupMembersDataView(gid:any){

  return this.http.get(apiurl+'/user/ExportContactGroupMembersDataView/'+gid)
}

getOneContactgroupMembers(cid:number){
  return this.http.get(apiurl+"rud_contactgroupmembers/"+cid+"/")
}
editContactgroupMembers(data:any,cid:number){
//console.log(data);
const headers = new HttpHeaders();
headers.append('Content-Type','application/json');
return this.http.put(apiurl+"rud_contactgroupmembers/"+cid+"/",data,{headers:headers})
}
delOneContactgroupMembers(cid:number){
return this.http.delete(apiurl+"rud_contactgroupmembers/"+cid+"/")
}

 getOneContactgroup(cid:number){
          return this.http.get(apiurl+"rud_contactgroup/"+cid+"/")
  }
  editContactgroup(data:any,cid:number){
    //console.log(data);
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
    return this.http.put(apiurl+"rud_contactgroup/"+cid+"/",data,{headers:headers})
  }
  delOneContactgroup(cid:number){
    return this.http.delete(apiurl+"rud_contactgroup/"+cid+"/")
   }
   removecontactlist(cid:any,gid:any)
   {
    return this.http.get(apiurl+"remove_contactmemberfromgroup/"+gid+"/"+cid+"/")
   }
   addgroupmember(res:any)
   {
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
    return this.http.post(apiurl+'add_membertogroup/',res,{headers:headers})
   }
}
