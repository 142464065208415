import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from '../../global';
@Injectable({
  providedIn: 'root'
})
export class TargetService {


  
  constructor(private http: HttpClient) { }
  
  getTarget(userid:number){
 
  return this.http.get(apiurl + 'configuration/'+userid+"/");
               
  }
  getRole(userid:number){
 
    return this.http.get(apiurl + 'configuration/'+userid+"/");
                 
    }
  addTargets(result: any,id:any){
   
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');   
		return this.http.post(apiurl + 'createteammembers/',result,{headers:headers});
      
    }
  
    listrRole(id:any){
      return this.http.get(apiurl + 'user/getrole/'+id);
      
    }
    getteamembers(id:any)
    {
      return this.http.get(apiurl + 'createteammembers/'+id+"/");
    }
    ExportTeamMembersDataView(teamid:any){
      return this.http.get(apiurl + 'user/ExportTeamMembersDataView/'+teamid+"/");
    }
    getoneteamembers(id:any)
    {
      return this.http.get(apiurl + 'rud_teammembers/'+id+"/");
    }
    edittarget(data:any,cid:number){
      //console.log(data);
      const headers = new HttpHeaders();
      headers.append('Content-Type','application/json');
      return this.http.put(apiurl+"rud_teammembers/"+cid+"/",data,{headers:headers})
    }
}
