import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from './global';
import { json } from 'stream/consumers';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  tokenGet :any = localStorage.getItem('usertoken')
  userToken :any = JSON.parse(this.tokenGet);
  httpOption  = {
    headers : new HttpHeaders({
      'Content-Type' : 'application/json',
      'Authorization': `Bearer ${this.userToken}`
      
    })
  }
  
  constructor(private http: HttpClient) { }
  
  
  getTarget(userid:number){
 
  return this.http.get(apiurl + 'configuration/'+userid+"/");
               
  }
  getRole(userid:number){
 
    return this.http.get(apiurl + 'configuration/'+userid+"/");
                 
    }
  addTargets(data:any,userid:number){
   
      const headers = new HttpHeaders();
      headers.append('Content-Type','application/json');
  
      return this.http.post(apiurl+"events/"+userid+"/",data,{headers:headers})
      
    }
    userexist(res:any){
 
      return this.http.get(apiurl + 'user/userexists/'+res+"/");
                   
      }
    getperformance(id:any)
    {
      return this.http.get(apiurl + 'overallteamperformance/'+id+"/"); 
    }
    adminperformance()
    {
      return this.http.get(apiurl + 'adminperformance/'); 
    }
    listLeads(id:any,mid:any){
      return this.http.get(apiurl + 'pipeline/tasklistby_tmid/'+id+"/"+mid+"/");
    }

    getSuperAdminDashboard(duration:any,durationType:any){
      return this.http.get(`${apiurl}user/SuperAdminDashBoard/?duration=${duration}&durationType=${durationType}`,this.httpOption);
    }

    getSuperAdminBarGraph(year:any,adminId:any,){
      return this.http.get(`${apiurl}user/AdminsMonthlyAchivement/?year=${year}&admin=${adminId}`);
    }

    getAdminDashboard(duration:any,durationType:any){
      return this.http.get(`${apiurl}user/AdminDashBoard/?duration=${duration}&durationType=${durationType}`,this.httpOption);
    }
    getdurationlists(team_id:any){
      return this.http.get(`${apiurl}team-member-report/?team_id=${team_id}`,this.httpOption);
    }
    getdurationlist(duration:any,durationType:any,teamid:any){
      return this.http.get(`${apiurl}team-member-report/?duration=${duration}&durationType=${durationType}&team_id=${teamid}`,this.httpOption);
    }
    getAdminMonthlyData(year:any,teamId:any){
      return this.http.get(`${apiurl}user/TeamMonthlyAchivement/?team=${teamId}&year=${year}`,this.httpOption);
    }

   
}
