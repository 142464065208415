<nav class="hidden md:flex justify-between items-center bg-white p-4 shadow-md h-16">
	<div class="navbar ">
		<div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
			
			<div class="flex-1 px-2" style="cursor: pointer !important;">
				<svg xmlns="http://www.w3.org/2000/svg" (click)="openmenu()" [ngClass]="menuclass" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
				</svg>
			</div>
			
		</div>
		<div class="flex-none gap-2 relative">
			<!-- <button class="btn btn-ghost btn-circle " > -->
				<div class="indicator cursor-pointer" (click)="getNotification()">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 " fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" /></svg>
				<span class="badge badge-xs badge-primary indicator-item">{{notificationCount}}</span>
				</div>
	
			<!-- </button> -->
			<app-notification-admin *ngIf="notifyFlag" class="absolute notify-div" (notifyCloseEvent)="notifyCloseEvent()" (notifySingleCloseEvent)="notifySingleCloseEvent()"></app-notification-admin>
			
		<h1 class="font-semibold userlogin ml-5">{{uname|titlecase}}<br>
			<span class="colourchange">{{role|titlecase}}</span>
		</h1>
		
		<div class="dropdown dropdown-end">

			<label tabindex="0" (click) ="onProfileClick()" class="btn btn-ghost btn-circle avatar">
			<div class="w-10 rounded-full">
				<img src="../../../assets/img/user.png" />
			</div>
			</label>
			
			<ul tabindex="0"  class="mt-3 p-2 shadow menu menu-compact dropdown-content bg-white rounded-box w-52">
			<li><a routerLink="/admin/change-password/{{uid}}" (click)="onChangePassword()">Change Password</a></li> 
			<li><a (click)="logout()">Logout</a></li>
			</ul>
		</div>
	
		</div>
	</div>
	
</nav>