import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, interval, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { apiurl } from '../../global';
import { LoginService } from '../../services/login.service';
declare var WebSocket: any;

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  user = this.loginservice.getUserdata();
  private socket: WebSocket | any;
  public notificationUpdate$ = new BehaviorSubject<any>('');
  constructor(private http: HttpClient, private loginservice  : LoginService) { }
  
  getreport(userid:number){
    return this.http.get(apiurl + 'createteammembers/'+userid+"/");             
    }
    ExportReportDataView(teamid:number){
      return this.http.get(apiurl + 'user/ExportReportDataView/'+teamid+"/")
    }
    getprog(userid:number){
      return this.http.get(apiurl + 'rud_team/'+userid+"/");             
      }
      getprogressreport(id:number){
        return this.http.get(apiurl + 'retriveteammembers/'+id+"/");             
        }
  gettarget(userid:number){
      return this.http.get(apiurl + 'target/'+userid+"/");             
      }
      getalleport(teamid:number,userid:any){
        return this.http.get(apiurl + 'pipeline/tasklistby_tmid/'+teamid+"/"+userid+"/");             
        }
        getnotification(id:any) 
        {
          return this.http.get(apiurl + 'pipeline/notificationcount/'+id+"/");             
        }
        updatenotification(id:any,res:any) 
        {
          return this.http.get(apiurl + 'pipeline/update_notificationcount/'+id+"/"+res+"/");             
        }

        getnotificationList(user_id:any) 
        {
          return this.http.get(`${apiurl}pipeline/UnreadNotificationList/?user_id=${user_id}`);             
        }

      clearSingleNotification(user_id:any,notify_id:any){
        return this.http.put(`${apiurl}pipeline/ReadNotification/?user_id=${user_id}&notification_id=${notify_id}`,''); 
      }

      markAllNotification(user_id:any){
        return this.http.put(`${apiurl}pipeline/ClearAllUnreadNotification/?user_id=${user_id}`,''); 
      }

      connect(userId: string): void {
        const socketUrl = `ws://${apiurl}/ws/notifications/${userId}/`;
        this.socket = new WebSocket(socketUrl);
    
        // Handle incoming WebSocket messages
        this.socket.onmessage = (event: any) => {
          const data = JSON.parse(event.data);
          if (data?.notification_count !== undefined) {
            this.notificationUpdate$.next(data); // Emit the updated count
          }
        };
    
        this.socket.onerror = (error: any) => {
          console.error('WebSocket error:', error);
        };
    
        this.socket.onclose = () => {
          setTimeout(() => this.connect(this.user?.data?.id), 5000); // Retry after 5 seconds
        };
      }

     
}
