import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { GetEventsService } from '../full-calenders/get-events.service';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
@Component({
  selector: 'app-event-views',
  standalone: true,
  imports: [CommonModule,RouterModule,MatDividerModule],
  templateUrl: './event-views.component.html',
  styleUrl: './event-views.component.scss',
 
})
export class EventViewsComponent implements OnInit {
  data: any[] = [];
  constructor(private geteventservice: GetEventsService,
    private router: Router,) { }

  ngOnInit(): void {
    this.getEvents()
      
  }
  getEvents(){

    this.geteventservice.getEvents().subscribe(
      (data: any) => this.data = data
      );
  }
}
