import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from '../../global';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }
  addEmail(result: any){
    console.log(result);
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');   
    return this.http.post(apiurl + 'createemails/'+0+"/",result,{headers:headers});

    }
    editEmail(data:any,cid:number){
      //console.log(data);
      const headers = new HttpHeaders();
      headers.append('Content-Type','application/json');
      return this.http.put(apiurl+"rud_emails/"+cid+"/",data,{headers:headers})
    }
}
