import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EmailtemplateService } from '../add-email-templates/email-template.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { SuccessComponent } from '../../success/success.component';
import { FailComponent } from '../../fail/fail.component';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-add-email-signatures',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule,RouterModule,MatDividerModule],
  templateUrl: './add-email-signatures.component.html',
  styleUrl: './add-email-signatures.component.scss'
})
export class AddEmailSignaturesComponent implements OnInit {

  //   registerForm!: FormGroup;
    public user: any;
    submitted = false;
    users:any=[];
    Acivity:any=[];
    isEdit: boolean=false;
    cid:any
    emailsignatureForm!: FormGroup;
    constructor(private dialog: MatDialog,private formBuilder: FormBuilder,
      private eservice: EmailtemplateService,
      private router: Router,private loginservice: LoginService,private route: ActivatedRoute
      ) { }
  
    ngOnInit() {
        this.emailsignatureForm = this.formBuilder.group({
          name: ['', [Validators.required,Validators.pattern(/^[a-zA-Z\s]*$/)]],                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
          signature:['', [Validators.required,Validators.pattern(/^[a-zA-Z\s]*$/)]],
       
        }, );
        this.user = this.loginservice.getUserdata()
        this.getuser()
        this.route.paramMap.subscribe(params => {
          this.cid = params.get('id')
         
          if(this.cid != null){
            
              this.isEdit = true;
          this.eservice.getOneEmailsignature(this.cid).subscribe((data:any)=>{
                      
            
              this.emailsignatureForm.setValue({
                  name:data.name,
                  signature:data.signature,
               
                  
              
              })
          })
        
      }
      else
      {
          this.isEdit = false;
      this.emailsignatureForm.reset();
      }
        });
    }
    editemailsignature(cid:number){
      this.emailsignatureForm.value.creator_id=this.user.data.id
      this.eservice.editEmailsignature(this.emailsignatureForm.value,cid).subscribe((reponse:any) => {
    //console.log(reponse);
    this.router.navigate(['teamleader/email-signature/']);
  });
  }
    getuser(){
    
      this.eservice.getUser().subscribe(
        (users: any) => this.users = users
        )
      }
    // convenience getter for easy access to form fields
    get f() { return this.emailsignatureForm.controls; }
  
    onSubmit() {
      this.submitted = true;
      if (this.emailsignatureForm.invalid) {
        console.log('gggggg')
          return;
      }
      this.emailsignatureForm.value.creator_id=this.user.data.id
        this.eservice.addEmailsignature(this.emailsignatureForm.value,0).subscribe((reponse)=>{
          if(reponse)
          {
           const confirmDialog = this.dialog.open(SuccessComponent, {
      
             data: {
               title: 'Success',
               message: 'Added Succesfully!'
             }
           })
           confirmDialog.afterClosed().subscribe(result => {
       
            if (result == true) {
              this.router.navigate(['teamleader/email-signature/']);
           }
      
      
         });
      }
      else{
         const confirmDialog = this.dialog.open(FailComponent, {
      
             data: {
               title: 'Failed',
               message: 'Added Failed!'
             }
           })
           confirmDialog.afterClosed().subscribe(result => {
       
            if (result == true) {
                this.router.navigate(['teamleader/add-email-signature'])
           }
      
      
         });
      }
       });  
    }
  
    onReset() {
        this.submitted = false;
        this.emailsignatureForm.reset();
    }
  }
  