import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivitiesService } from '../../services/activities.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CompanyservService } from '../../services/companyserv.service';
import { QuotationListService } from '../../services/quotation-list.service';
import { ContactFormService } from '../../services/contact-form.service';
import {RouterModule} from '@angular/router';
import {MatTableModule} from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-activities',
  standalone: true,
  imports: [RouterModule,MatTableModule,MatIconModule,CommonModule,DatePipe,MatDividerModule,MatDialogModule,NgxPaginationModule],
  providers : [LoginService,CompanyservService,QuotationListService,ContactFormService],
  templateUrl: './activities.component.html',
  styleUrl: './activities.component.scss'
})
export class ActivitiesComponent {
  constructor(private loginservice: LoginService,private activityserve:ActivitiesService,private contactservice: ContactFormService,private companyserv:CompanyservService,private quotationserv:QuotationListService,private dialog: MatDialog) { }
  user:any;
  isLoading: boolean = true; // Flag to indicate loading state
  noDataMessageShown: boolean = false;
  userid:any;
  activitylist:any[]=[]
  organizationlist:any;
  contactlist:any;
  leadList:any;
  opportunitylist:any;
  quotationlist:any;
  leadFilter="";
  opportunityFilter="";
  quotationFilter="";
  dateFilter=""
  statusFilter=""
  searchControl=""
  advfilter={
    lead_name:this.leadFilter,
    opportunity_name:this.opportunityFilter,
    quotation_name:this.quotationFilter,
    due_date:this.dateFilter,
    status:this.statusFilter,
  }
  displayedColumns: string[] = ['activityRef', 'customer', 'dueDate','status','description','action' ];
  p:number=1;
  

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.userid=this.user.data.id 
    this.getActivity("") 
  }

  getActivity(filter:any){
    this.quotationserv.getactivity(this.userid,filter).subscribe(
      (activitylist: any) =>{ 
        this.activitylist = activitylist.data;
        this.isLoading=false;

        if(this.activitylist.length===0){
          this.noDataMessageShown=true;

        }
        else{
          this.noDataMessageShown=false;
        }
        
       }
      );    
  }

  filter(){
    this.advfilter={
      lead_name:this.leadFilter,
      opportunity_name:this.opportunityFilter,
      quotation_name:this.quotationFilter,
      due_date:this.dateFilter,
      status:this.statusFilter,
   }
   this.getActivity(this.advfilter)
 }

 clearfilter(){
  this.leadFilter=""
  this.opportunityFilter=""
  this.quotationFilter=""
  this.dateFilter=""
  this.statusFilter=""
  this.advfilter={
    lead_name:"",
    opportunity_name:"",
    quotation_name:"",
    due_date:"",
    status:"",
  }
  this.getActivity(this.advfilter)
}
  deleteActivity(list:any){
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Remove Activity',
        message: 'Are you sure, you want to remove a Activity: ' + list.id
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.activityserve.delOneactivity(list.id).subscribe((data:any)=>{
        this.getActivity('')
		})
	}
});

}


}
