import { Component, OnInit , ViewEncapsulation} from '@angular/core';
import { LoginService } from '../../services/login.service';
import { TargetService } from '../targetss/target.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddMeberssComponent } from '../add-meberss/add-meberss.component';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../reports/report.service';

declare var XLSX: any;


@Component({
  selector: 'app-memberses',
  templateUrl: './memberses.component.html',
  styleUrls: ['./memberses.component.scss'] ,
  standalone: true,
  imports: [CommonModule,RouterModule,MatDividerModule,NgxPaginationModule,AddMeberssComponent],
 
})
export class MembersesComponent implements OnInit {
  public user:any
  membrList:any[]=[];
  p:number=1;
  exportsteams: any;
  teamprogress:any = 0
  teamtarget:any = 0
  constructor(private loginservice:LoginService,private tservice:TargetService, private toaster:ToastrService, private reportService: ReportService,) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.listMember();
    this.getteamprog(this.user.teamid)
  }

  listMember(){
    this.reportService.getreport(this.user.teamid).subscribe((data: any) => {
        this.membrList = data
        // console.log(this.membrList,'memberrrrr')
    })
  }
  
  ExportTeamMembersDataView(){
    this.tservice.ExportTeamMembersDataView(this.user.teamid).subscribe((response:any)=>{
      const worksheet = XLSX.utils.json_to_sheet(response.message);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, 'table-data.xlsx');
      if(response?.status==false){
        this.toaster.error(response.message,'error')
      }
    })
  }
  changeRole(id:any){
    let data={
      "creator_id":this.user.data.id,
      "role":id
    }
  }

  getteamprog(userid: number){
    this.reportService.getprog(userid).subscribe(
      (data: any) =>{ 
      this.teamprogress = data.progress,
      this.teamtarget= data.target ? data.target : 0
    });
  }
}
