<div class="max-w w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 mt-5 mb-5">
    <div class="grid  gap-8 grid-cols-1 ">
        <form class="" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="flex flex-col ">
                <div class="flex flex-col sm:flex-row items-center mb-2">
                    <h2 class="font-semibold text-lg mr-auto">Lead / Edit</h2>
                    <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>

                    <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
                        <button class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </button>
                        <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-green-500">Edit</button>
                    </div>
                </div>
                
                <mat-divider></mat-divider>
                <div *ngFor='let item of list'>
                <div class="mt-5 px-20">
                    <div class="form">
                        <!-- <div class="flex">
                            <div class="w-1/6 bg-gray-100 h-12 p-2 border-solid border-1 border-light-gray-200">
                                <p>0 Opportunities</p>
                            </div>
                            <div class="w-1/6 bg-gray-100 h-12 p-2">
                                <p>0 Meetings</p>
                            </div>
                            <div class="w-1/6 bg-gray-100 h-12 p-2">
                                <p>0 Sales</p>
                            </div>
                            <div class="w-1/6 bg-gray-100 h-12 p-2">
                                <p>0 Subscriptions</p>
                            </div>
                            <div class="w-1/6 bg-gray-100 h-12 p-2">
                                <p>0 Tasks</p>
                            </div>
                            <div class="w-1/6 bg-gray-100 h-12 p-2">
                                <p>0 Tickets</p>
                            </div>
                        </div> -->
                        <div>
                            <div class="grid cols-span-1 gap-5">
                                <div class="flex p-4">
                                    <div class="md:w-1/6">
                                        <label class="font-bold tracking-wide text-sm">Opportunity</label>
                                    </div>
                                    <div class="md:flex-1 mb:mt-0">
                                        <div class="mb-4">
                                            <input type="text" class="relative w-full bg-white text-sm text-gray-700 placeholder-gray-800  border-0 border-b-2 border-gray-400" value="{{item.name}}"/>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            <div class="grid grid-cols-2 gap-5 p-4">
                                <div class="md:flex">
                                    <div class="md:w-1/3">
                                        <label class="font-bold tracking-wide text-sm">Expected Revenue</label>
                                    </div>
                                    <div class="md:flex-1 mb:mt-0">
                                        <div class="mb-4">
                                            <input type="text" value="{{item.budget}}" class="elative w-full bg-white text-sm text-gray-700 placeholder-gray-800  border-0 border-b-2 border-gray-400">
                                        <span class="text-gray-100">at</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="md:flex">
                                    <div class="md:w-1/3">
                                        <label class="font-bold tracking-wide text-sm">Probability</label>
                                    </div>
                                    <div class="md:flex-1 mb:mt-0">
                                        <div class="mb-4">
                                            <input type="text" value="10.0" class="elative w-full bg-white text-sm text-gray-700 placeholder-gray-800  border-0 border-b-2 border-gray-400">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="grid grid-cols-2">
                                <div>
                                    <main class="flex-1 md:p-0  flex flex-col">
                                        <section class="p-4">
                                            <div class="md:flex">
                                                <div class="md:w-1/3">
                                                    <label class="font-bold tracking-wide text-sm">Customer</label>
                                                </div>
                                                <div class="md:flex-1 mb:mt-0">
                                                    <div class="mb-4">
                                                        <input type="text" value="{{item.contact.name}}" class="relative outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="md:flex">
                                                <div class="md:w-1/3">
                                                    <label class="font-bold tracking-wide text-sm">Email</label>
                                                </div>
                                                <div class="md:flex-1 mb:mt-0">
                                                    <div class="mb-4">
                                                        <input type="text" value="{{item.email}}" class="relative outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="md:flex">
                                                <div class="md:w-1/3">
                                                    <label class="font-bold tracking-wide text-sm">Phone</label>
                                                </div>
                                                <div class="md:flex-1 mb:mt-0">
                                                    <div class="mb-4">
                                                        <input type="text" value="{{item.phone}}" class="relative outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="md:flex">
                                                <div class="md:w-1/3">
                                                    <label class="font-bold tracking-wide text-sm">Salesperson</label>
                                                </div>
                                                <div class="md:flex-1 mb:mt-0">
                                                    <div class="mb-4">
                                                        <select class="relative outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline">
                                                            <option value="">Demo person</option>
                                                            <option value="">Option 1</option>
                                                            <option value="">Option 2</option>
                                                            <option value="">Option 3</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="md:flex">
                                                <div class="md:w-1/3">
                                                    <label class="font-bold tracking-wide text-sm">Sales Team</label>
                                                </div>
                                                <div class="md:flex-1 mb:mt-0">
                                                    <div class="mb-4">
                                                        <select class="relative outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline">
                                                            <option value="">Demo Team</option>
                                                            <option value="">Option 1</option>
                                                            <option value="">Option 2</option>
                                                            <option value="">Option 3</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </main>
                                </div>
                            
                                <div class="px-5">
                                    <main class="flex-1 md:p-0  flex flex-col">
                                        <section class="p-4">
                                            <div class="md:flex">
                                                <div class="md:w-1/3">
                                                    <label class="font-bold tracking-wide text-sm">Expected Closing	</label>
                                                </div>
                                                <div class="md:flex-1 mb:mt-0">
                                                    <div class="mb-4">
                                                        <input type="date" value="07/14/2021" class="relative outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="md:flex">
                                                <div class="md:w-1/3">
                                                    <label class="font-bold tracking-wide text-sm">Priority</label>
                                                </div>
                                                <div class="md:flex-1 mb:mt-0">
                                                    <div class="mb-4">
                                                        <input type="text" value="{{item.contact.rating}}" class="relative outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="md:flex">
                                                <div class="md:w-1/3">
                                                    <label class="font-bold tracking-wide text-sm">Tags</label>
                                                </div>
                                                <div class="md:flex-1 mb:mt-0">
                                                    <div class="mb-4">
                                                        <select class="relative outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline">
                                                            <option value="">Tags..</option>
                                                            <option value="">Option 1</option>
                                                            <option value="">Option 2</option>
                                                            <option value="">Option 3</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="md:flex">
                                                <div class="md:w-1/3">
                                                    <label class="font-bold tracking-wide text-sm">Company</label>
                                                </div>
                                                <div class="md:flex-1 mb:mt-0">
                                                    <div class="mb-4">
                                                        <input type="text" value="Demo Company" class="relative outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline">
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </main>
                                </div>
                            </div>
                        </div>

                        <div class="rounded border w-full mx-auto mt-4">
                            <ul id="tabs" class="inline-flex pt-2 px-1 w-full border-b">
                                <li  [class.active]="viewMode == 'tab1'" rel="tab1" (click)="viewMode ='tab1'" class="cursor-pointer px-4 text-gray-800 font-semibold py-2 rounded-t">Internal Notes</li>
                                <li  [class.active]="viewMode == 'tab2'" rel="tab2" (click)="viewMode ='tab2'" class="cursor-pointer px-4 text-gray-800 font-semibold py-2 rounded-t">Extra Information</li>
                            </ul>

                            <div id="tab-contents" [ngSwitch]="viewMode">
                                <div id="tab1" class=" p-4" *ngSwitchCase="'tab1'">
                                    <p class="text-sm">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Blanditiis inventore voluptates quos, </p>
                                </div>
                                <div id="tab2" class="p-4" *ngSwitchCase="'tab2'">
                                    <div class="w-full text-sm">
                                        <p class="font-bold">Contact Information</p>
                                        <div class="grid grid-cols-2 gap-5 p-4">
                                            <div class="md:flex">
                                                <div class="md:w-1/3">
                                                    <label class="font-bold text-gray-600 tracking-wide text-sm">Company Name	</label>
                                                </div>
                                                <div class="md:flex-1 mb:mt-0">
                                                    <p>Demo Name </p>
                                                </div>
                                            </div>
                                            <div class="md:flex">
                                                <div class="md:w-1/3">
                                                    <label class="font-bold text-gray-600 tracking-wide text-sm">Address</label>
                                                </div>
                                                <div class="md:flex-1 mb:mt-0">
                                                    <p>
                                                        1920 Del Dew Drive
                                                        Chevy Chase
                                                        Australia
                                                    </p>
                                                </div>
                                            </div> 
                                            <div class="md:flex">
                                                <div class="md:w-1/3">
                                                    <label class="font-bold text-gray-600 tracking-wide text-sm">Website Link</label>
                                                </div>
                                                <div class="md:flex-1 mb:mt-0">
                                                    <p>Demo Name </p>
                                                </div>
                                            </div> 
                                            <div class="md:flex">
                                                <div class="md:w-1/3">
                                                    <label class="font-bold text-gray-600 tracking-wide text-sm">Contact Name</label>
                                                </div>
                                                <div class="md:flex-1 mb:mt-0">
                                                    <p>Demo Name </p>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </div>
</div>