<div #scrollContainer class="overflow-y-auto"  style="height:85vh !important;">
<div class="max-w w-full space-y-8  p-10 bg-white rounded-xl shadow-lg z-10" style="height:86vh !important;">
  <div class="flex">
      <div class="w-1/5 relative">
        <select class="w-full flex-col  rounded-md text-white  pl-10 selectfilter" [(ngModel)]="typeModel" (change)="onFilterChange($event)">
         
          <option *ngFor="let item of monthArr" [value]="item">{{ item }}</option>
        </select>
    
        <div class="absolute float-left top-1/2 ml-2 transform -translate-y-1/2 pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="dateIcon" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M12 14a1 1 0 1 0-1-1a1 1 0 0 0 1 1m5 0a1 1 0 1 0-1-1a1 1 0 0 0 1 1m-5 4a1 1 0 1 0-1-1a1 1 0 0 0 1 1m5 0a1 1 0 1 0-1-1a1 1 0 0 0 1 1M7 14a1 1 0 1 0-1-1a1 1 0 0 0 1 1M19 4h-1V3a1 1 0 0 0-2 0v1H8V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3m1 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9h16Zm0-11H4V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1ZM7 18a1 1 0 1 0-1-1a1 1 0 0 0 1 1"/></svg>
        </div>
        <div class="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>
      <div class="relative ml-3">
          <select class="border  rounded-md text-white  pl-10 selectfilter" [(ngModel)]="subType" (change)="getdurationlists()">
            <option [value]="null" [disabled]="true" [selected]>Select an option</option>
            <option *ngFor="let item of subTypeArr" [value]="item?.id">{{ item?.month }}</option>
          </select>
         
        <div class="absolute float-left top-1/2 ml-2 transform -translate-y-1/2 pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="dateIcon" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M12 14a1 1 0 1 0-1-1a1 1 0 0 0 1 1m5 0a1 1 0 1 0-1-1a1 1 0 0 0 1 1m-5 4a1 1 0 1 0-1-1a1 1 0 0 0 1 1m5 0a1 1 0 1 0-1-1a1 1 0 0 0 1 1M7 14a1 1 0 1 0-1-1a1 1 0 0 0 1 1M19 4h-1V3a1 1 0 0 0-2 0v1H8V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3m1 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9h16Zm0-11H4V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1ZM7 18a1 1 0 1 0-1-1a1 1 0 0 0 1 1"/></svg>
        </div>
        <div class="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>
    </div>
    <p>Team-Target: ₹{{teams?.team_target ? teams?.team_target : 0 }} <span style="float:right">Team-Progress: {{ teams?.team_progress ? (teams?.team_progress | number: '1.0-0'): 0 }}%</span></p>
    <mat-divider></mat-divider>
    
    <div class="w-full overflow-x-auto">
        <table class="min-w-max w-full table-auto border-collapse border border-gray-300">
            <thead class="bg-gray-200 text-gray-600 text-sm">
                <tr class="border-b border-gray-300">
                    <th class="py-3 px-6 text-center border-r border-gray-300">Name</th>
                    <th class="py-3 px-6 text-center border-r border-gray-300">Assigned to</th>
                    <th class="py-3 px-6 text-center border-r border-gray-300">Completion Percentage</th>
                    <th class="py-3 px-6 text-center border-r border-gray-300">Target</th> 
                    
                </tr>
            </thead>
         
            <tbody class="text-gray-600 text-sm " *ngIf="data && data.length > 0; else noData">
                <tr *ngFor='let item of data | paginate: { itemsPerPage: 5, currentPage: p }' class="border-b border-gray-200 hover:bg-gray-100 ">
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap cursor-pointer" (click)="getallreport(item?.team,item?.team_member_id)">
                        {{item.team_member.first_name}}
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        {{item.team_member.groups[0].name}}
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                      {{item.member_progress}}%
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        ₹{{item.target}}
                    </td>
                
                   
                </tr>
            
            </tbody>
        </table>
        <ng-template #noData><tr><td colspan="4" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
    </div>        
    <pagination-controls (pageChange)="p = $event" class="float-right pagination mt-1"></pagination-controls>
    
</div>
<div  #secondTable  *ngIf="showMembersTable"  style="height: 71vh !important;" class="mt-4 max-w w-full space-y-8  p-10 bg-white rounded-xl shadow-lg z-10  ">
    <div class="flex flex-col sm:flex-row items-center mb-2">
        <h2 class="font-semibold  text-lg mr-auto">Leads</h2>
        
    </div>
    
    
    <div class='overflow-x-auto overflow-y-auto  w-full' style="overflow-y: auto; height: 50vh !important;">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-0 gap-4">
       
        <div 
          *ngFor="let item of allreportlist | paginate: { itemsPerPage: 10, currentPage: p }" 
          class="bg-white border border-gray-200 rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow duration-200"
        >
          <h3 class="text-lg font-semibold text-gray-700 mb-2 position">{{ item.name }}</h3>
          
          <p class="text-gray-500">
            <span class="font-medium fontsize" >Email : </span> <span class="fontsize">{{ item.email }}</span>
          </p>
          <p class="text-gray-500">
            <span class="font-medium fontsize" >Budget : </span> <span class="fontsize">₹{{ item.budget }}</span>
          </p>
          <p class="text-gray-500">
            <span class="font-medium fontsize" >Type : </span> <span class="fontsize">{{ item.type }}</span>
          </p>
          <p class="text-gray-500">
            <span class="font-medium fontsize">Status &nbsp;&nbsp;:</span>
            <span class="fontsize" [ngClass]="{
              'text-blue-500': item.status === 'Opportunity',
              'text-yellow-500': item.status === 'Lead',
              'text-green-500': item.status === 'Won'
            }"> {{ item.status }} </span>
          </p>
        </div>
       
      </div>
      
      
       
    </div>        
    <div class="flex justify-center top ">
      <h1 *ngIf="!isLoading && noDataMessageShown" class="font-semibold text-lg">No Data Found</h1>
    </div>
  </div>


</div>