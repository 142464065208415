<div
  class="max-w-2xl w-full h-auto space-y-6 p-6 bg-white rounded-xl shadow-lg z-10 mt-5 mb-5 mx-auto border border-gray-200">
  <div class="grid gap-8 grid-cols-1">
    <form [formGroup]="registerForm" (ngSubmit)="editcontact()">
      <div class="flex flex-col items-center">
        <h2 class="font-semibold text-lg mb-4">Change Password</h2>
        <mat-divider></mat-divider>

        <div class="mt-3 bg-white rounded border border-gray-200 px-8 py-4 w-full">
          <div class="form mt-3">
            <div class="flex flex-wrap justify-center mb-4">
              <div class="w-full md:w-3/4 px-3">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                  Current Password
                </label>
                <input class="focus:ring-white w-full focus:border-gray-300 border-gray-400 sm:text-sm rounded-md p-2"
                  formControlName="password" type="password" placeholder="Password"
                  [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                <div *ngIf="submitted && f['password'].errors" class="invalid-feedback text-sm">
                  <div *ngIf="f['password'].errors['required']">Please fill out this field.</div>
                  <div *ngIf="f['password'].errors['password']">Password must contain at least 8 characters, including
                    uppercase, lowercase, number, and special character.</div>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap justify-center mb-4">
              <div class="w-full md:w-3/4 px-3">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                  New Password
                </label>
                <input class="focus:ring-white w-full focus:border-gray-300 border-gray-400 sm:text-sm rounded-md p-2"
                  formControlName="confirm_password" type="password" placeholder="Confirm Password"
                  [ngClass]="{ 'is-invalid': submitted && f['confirm_password'].errors }">
                <div *ngIf="submitted && f['confirm_password'].errors" class="invalid-feedback text-sm">
                  <div *ngIf="f['confirm_password'].errors['required']">Please fill out this field.</div>
                  <div
                    *ngIf="f['confirm_password'].errors ? f['confirm_password'].errors['confirmedValidator'] : false">
                    Password and Confirm Password must match.</div>
                </div>
              </div>
            </div>


            <div class="flex flex-col sm:flex-row items-center mb-2 space-y-2 sm:space-y-0 sm:space-x-3 justify-center">
              <a (click)="  onReset()"
                class="bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 cursor-pointer">
                Cancel
              </a>
              <button
                class="bg-green-400 px-4 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg border border-green-400 color cursor-pointer"
                type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>