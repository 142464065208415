import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog,MatDialogModule } from '@angular/material/dialog';
import { PipelineService } from '../drag-drops/pipeline.service';
import { ContactFormService } from '../../services/contact-form.service';
import { Router, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { LeadDialogComponent } from '../lead-dialog/lead-dialog.component';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { SearchPipe } from '../../search.pipe';
import { ToastrService } from 'ngx-toastr';

declare var XLSX: any;


@Component({
  selector: 'app-contact-views',
  standalone: true,
  imports: [ReactiveFormsModule,FormsModule,MatDividerModule,MatTableModule,RouterModule,MatIconModule,CommonModule, SearchPipe ],

  templateUrl: './contact-views.component.html',
  styleUrls: ['./contact-views.component.scss']
})
export class ContactViewsComponent implements OnInit {
  public user: any;
  public val: any
  users:any=[];
  contactlist:any[]=[];
  public isFilter:boolean = false;

  contact:any[] =[];
  first_name = new FormControl('');
  category = new FormControl(''); 
  email = new FormControl(''); 
  type = new FormControl(''); 
  @Output() private ontaskcomplete = new EventEmitter();
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  constructor( private dialog: MatDialog,private pservice: PipelineService,private contactservice: ContactFormService,private router: Router,private loginservice: LoginService, private toastr : ToastrService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    
    this.getcontacts(this.user.data.id)
    this.getcontactlist(this.user.data.id)
  }
  getcontacts(userid: number){
    this.contactservice.contacts(userid).subscribe(
      (contact: any) => {
        this.contact = contact

  });
    }
  getcontactlist(userid: number){
  
    this.contactservice.getContactsAssigned(userid).subscribe(
      (contactlist: any) =>{
        if(contactlist?.length){
          this.contactlist = contactlist

        }else{
          this.contactlist = []
        }
        console.log(this.contactlist,'list contactt')
      }
      );
      
  }
  
  EditContact(cid:number){
		let data = {
			"cid":cid,
    }
    console.log(data)
    this.router.navigate(['teamleader/add-contact/',cid]);
		this.contactservice.contactEditEmitter.emit(data)
	}

  delContact(list:any){
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Remove Contact',
        message: 'Are you sure, you want to remove a contact: ' + list.first_name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
		this.contactservice.delOneContact(list.id).subscribe((data)=>{
			console.log("*************$$$$$$$$$$$$")
			console.log(data)
      this.getcontactlist(this.user.data.id)
		})
	}
});
}
addtask(list:any)
{
 console.log(list,'listt')
  const leadDialog = this.dialog.open(LeadDialogComponent, {
    data: {
		  title:'Convert to Lead',
		  id: list?.id,
		  phone:list?.mobile_phone,
		  email:list?.email,
      customer:list?.customer.id

		}
  })
  }

  downloadSampleFile(){
    const headers = [{"First Name":"", "Last Name":"","State":"","ZipCode" :"","Mobile Number":"","Email":"","Address Line1" : "","Address Line" : ""}];
    const worksheet = XLSX.utils.json_to_sheet(headers);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, 'sample.xlsx');
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click(); // Triggers file selection
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:',file, file.name);
      // Handle the file upload logic here
      const formData = new FormData();
      formData.append('file',file, file.name);
      this.contactservice.onBulkUpload(formData).subscribe((res:any)=>{
        if(res?.message){
           this.toastr.success(res.message,'success')
        }else{
          
        }
      })
    }
  }

 
}
