import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MenutoggleService } from '../../services/menutoggle.service'; 
import { LoginService } from '../../services/login.service'; 
import { ReportService } from '../reports/report.service';
import { ActivatedRoute,Router, RouterModule,NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from '../../success/success.component';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { ToastrService } from 'ngx-toastr';
import { NotificationComponent } from "../notification/notification.component";


@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, RouterModule, MatCardModule, NotificationComponent],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit {
	 menuclass:string = "hidden";
    public user:any;
    public totaltarget:any = 0;
	progress:any = 0;
	teamname:any;
	isDropdownVisible: boolean = false;
	notification:any;
	disabledP = false;
	uname:any
	public uid:any
	public isNotif:boolean = false;
	role: any;
	achived: any = 0;
	notifyFlag = false;
	notificationCount = 0
	
  constructor(
	  private breakpointObserver: BreakpointObserver,
	  private menuservice:MenutoggleService,
	  private loginservice:LoginService,
	  private rservice:ReportService,
	  private router:Router,
	  private dialog: MatDialog,
	  private toaster:ToastrService
  ) {}


  ngOnInit(): void {
	this.user = this.loginservice.getUserdata()
	this.router.events.subscribe((event) => {
		if (event instanceof NavigationEnd) {
		  this.notifyFlag = false;
		}
	});
	this.getNotificationList()
	this.rservice.notificationUpdate$.subscribe((data:any) => {
		if(data){
			this.notificationCount = data?.notification_count;
			// const audio = new Audio('assets/img/mixkit-bell-notification-933.wav');
            // audio.play();
		}else{
			this.notificationCount = 0;
		}
		 // Update the UI automatically
	});
	this.uid=this.user.data.id
	this.uname=this.user.data.first_name;
	this.role= this.user.data.groups[0].name;
	this.menuservice.getProgress().subscribe((res:any)=>{
		this.target(this.user.data.id);
	})	
		
	this.menuservice.onGetnav.subscribe((data:any)=>{
		if(data==true){
		  this.menuclass = 'hidden';
		} else{
			this.menuclass = 'block';
		}
	});
		this.uid=this.user.data.id
		this.uname=this.user.data.first_name
		this.target(this.user.data.id)
		this.getteamname(this.user.teamid)
		this.getnotifi(this.user.data.id)
	}
	target(id:any)
	{
    this.rservice.getprogressreport(id).subscribe((response:any)=>{
		this.totaltarget = response[0].target ? response[0].target : 0
		this.progress= response[0].member_progress ? response[0].member_progress : 0
		this.achived=response[0].member_acheived ? response[0].member_acheived : 0 ;
	})
	}
	getteamname(id:any)
	{
		this.rservice.getprog(id).subscribe((response:any)=>{
			this.teamname =response.teamname
			//console.log(this.teamname,'fff')
			
		})
	}
	getnotifi(id:any)
	{
		this.rservice.getnotification(id).subscribe((response:any)=>{
			this.notification=response
		})
	}
	eventupdate(res:any)
	{
		this.rservice.updatenotification(this.user.data.id,res).subscribe((response:any)=>{
			this.getnotifi(this.user.data.id)
			this.notification=response;
		})
	}
	openmenu(){
		this.menuservice.onSetnav(true);
	  }
	logout(){
		localStorage.removeItem("usertoken")
		localStorage.removeItem("userdata")
		this.toaster.success('Logout Sucessfully','success')
		this.router.navigate(['/'])
	}

    whichProgress(progress: string){
    try{
      return Math.round(+this.progress * 100) / 100;
    }
    catch{
      return progress;
    }
  }

  getNotification(){
	this.notifyFlag = !this.notifyFlag
  }
  
  onChangePassword(){
	this.isDropdownVisible = false;
  }

  onProfileClick(){
	this.isDropdownVisible = !this.isDropdownVisible
  }

  notifyCloseEvent(){
	this.notifyFlag = false;
  }

  notifySingleCloseEvent(){
	this.getNotificationList()
  }

	getNotificationList(){
		this.rservice.connect(this.user?.data?.id)
		// 	.subscribe({
		// 	  next: (data: any) => {
		// 		console.log(data,'dataaaaaaaa')
		// 		if(data?.message?.length){
		// 		   this.notificationCount = data?.notification_count;
		// 		}else{
		// 			this.notificationCount = 0;
		// 		}
		// 	},
		// });
	}
}
