import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit ,ViewEncapsulation } from '@angular/core';
import {FormGroup,FormControl,Validators,FormArray, FormBuilder, ReactiveFormsModule} from '@angular/forms';
// import { AngularEditorConfig } from '@kolkov/angular-editor';
import {ScheduleService } from './schedule.service';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { ContactFormService } from '../../services/contact-form.service';
import { LoginService } from '../../services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from '../../success/success.component'; 
import { FailComponent } from '../../fail/fail.component';
import {CommonModule, formatDate} from '@angular/common';
import { MatDivider, MatDividerModule } from '@angular/material/divider';
// import { AngularEditorModule } from '@kolkov/angular-editor';
declare var ClassicEditor: any;



@Component({
  selector: 'app-schedules',
  standalone: true,
  imports: [CommonModule,RouterModule,MatDividerModule,ReactiveFormsModule],
  templateUrl: './schedules.component.html',
  styleUrl: './schedules.component.scss',
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SchedulesComponent implements OnInit,AfterViewInit {
  public user: any;
  activityForm!: FormGroup;
  submitted = false;
  contactlist:any[] =[];
  activity:any[]=[];
  sid:any;
  public isEdit: boolean=false;
  public isEmail: boolean=false;
  cid:any
  str: any;
  currentDate:any;
  editorInstance  : any ;

  constructor(private dialog: MatDialog,private formBuilder: FormBuilder,
    private scheduleservice: ScheduleService,private contactservice: ContactFormService,private loginservice: LoginService,
    private router: Router,private route: ActivatedRoute) { }

  ngOnInit() {
    this.user = this.loginservice.getUserdata()
    this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en')
   // console.log(this.user.data.id)
      this.activityForm = this.formBuilder.group({
       
          eventname: ['', Validators.required],
          edate:['', Validators.required],
          contact_id:[Validators, Validators.required],
          description:['', Validators.required],
          hour:['', Validators.required],
          minute:['', Validators.required],
          activitytype:[null,Validators.required],
          user_id:[this.user.data.id]

          
      }, );
   
      this.getcontacts(this.user.data.id)
      this.route.paramMap.subscribe(params => {
        this.cid = params.get('id')
        this.str = params.get('str')
       if(this.str)
       {
        this.activityForm.controls['contact_id'].setValue(this.cid)
       }
      //  console.log(this.str,'strrrr')
        if(this.cid && this.str== null){
          
            this.isEdit = true;
        this.scheduleservice.getOneActivity(this.cid).subscribe((data:any)=>{
            console.log(data,'edit')
            this.activityForm.setValue({
              eventname:data.eventname,
              edate:data.date_and_time,
              contact_id:data.task_id,
              description:data.description,
              activitytype:data.activity_type,
              hour:data.hour,
              minute:data.minute,
              "user_id":this.user.data.id
            })
        })
      
    }
    else
    {
        this.isEdit = false;
    this.activityForm.reset();
    }
      });


  }

  ngAfterViewInit() {
     setTimeout(() => {
          // Initialize CKEditor after the view is loaded
       const editorContainer = document.querySelector('#editor'); // Target div
       if (editorContainer) {
         ClassicEditor.create(editorContainer)
           .then((editor: any) => {
             this.editorInstance = editor;
             editor.setData(''); // Set initial content
             editor.model.document.on('change:data', () => {
               this.htmlContent = editor.getData(); // Update content on changes
             });
           })
           .catch((error: any) => {
             console.error('There was a problem initializing CKEditor:', error);
           });
       }
         
     
     
    }, 0);
  }
  
  getcontacts(userid: number){
    console.log('contacytsss',userid)
    this.scheduleservice.gettasklist(userid).subscribe(
      (contactlist: any) => {
        this.contactlist = contactlist
        console.log(this.contactlist,'contactssssss')
      }
      
      );
     
  }
    
  htmlContent = '';

  config: any = {
  editable: true,
  spellcheck: true,
  height: '35rem',
  minHeight: '15rem',
  placeholder: 'Enter text here...',
  translate: 'no',
  defaultParagraphSeparator: 'p',
  defaultFontName: 'Arial',
  toolbarHiddenButtons: [
    ['bold']
    ],
  customClasses: [
    {
      name: "quote",
      class: "quote",
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: "titleText",
      class: "titleText",
      tag: "h1",
    },
  ]
};
editactivity(cid:number){
  
  let data_val = {
    "eventname":this.activityForm.value.eventname,
    "edate":this.activityForm.value.edate,
    "task_id":this.activityForm.value.contact_id,
    "emails_id":null,
    "description":this.activityForm.value.description,
    "activity_type":this.activityForm.value.activitytype,
    "hour":this.activityForm.value.hour,
    "minute":this.activityForm.value.minute,
    "user_id":this.user.data.id,
    "scheduled_status":false
  }

this.scheduleservice.editschedule(data_val,cid).subscribe((reponse:any) => {
this.router.navigate(['teamleader/activities/']);
});
}
get f() { return this.activityForm.controls; }

onSubmit() {
  this.submitted = true;
  if (this.editorInstance) {
    const val = this.editorInstance.getData()
    let cleanedContent = val.replace(/<p>/g, '');   // Remove opening <p> tags
    cleanedContent = cleanedContent.replace(/<\/p>/g, '');
    this.activityForm.controls['description'].setValue(cleanedContent)
  }
  let data = {
    "eventname":this.activityForm.value.eventname,
    "edate":this.activityForm.value.edate,
    "task_id":this.activityForm.value.contact_id,
    "emails_id":null,
    "description":this.activityForm.value.description,
    "activity_type":this.activityForm.value.activitytype,
    "hour":this.activityForm.value.hour,
    "minute":this.activityForm.value.minute,
    "user_id":this.user.data.id,
    "customer_id":this.cid
  }
  this.scheduleservice.addSchedule(data,this.user.data.id).subscribe((reponse)=>{
    if(reponse)
    {
     const confirmDialog = this.dialog.open(SuccessComponent, {

       data: {
         title: 'Success',
         message: 'Added Succesfully!'
       }
     })
     confirmDialog.afterClosed().subscribe(result => {
 
      if (result == true) {
          this.router.navigate(['teamleader/activities'])
     }


   });
}
else{
   const confirmDialog = this.dialog.open(FailComponent, {

       data: {
         title: 'Failed',
         message: 'Added Failed!'
       }
     })
     confirmDialog.afterClosed().subscribe(result => {
 
      if (result == true) {
          this.router.navigate(['teamleader/schedule'])
     }


   });
}
   }); }

   onReset() {
    this.submitted = false;
    this.activityForm.reset();
}
changeEmail(val:any)
{
  if(val=='Email')
  {
this.isEmail=true
  }
}
}
