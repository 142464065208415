import { Component, OnInit } from '@angular/core';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { LoginService } from '../../services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { CompanyservService } from '../../services/companyserv.service';
import { CommonServiceService } from '../../services/common-service.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { CustomerListComponent } from '../customer-list/customer-list.component';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-customerlist',
  standalone: true,
  imports: [CommonModule,FormsModule,MatIconModule,MatMenuModule,MatDividerModule,MatTableModule,RouterModule,CustomerListComponent,NgxPaginationModule],
  providers : [LoginService,CompanyservService,CommonServiceService],
  templateUrl: './customerlist.component.html',
  styleUrl: './customerlist.component.scss'
})
export class CustomerlistComponent {
  constructor(private loginservice: LoginService,private appserv :CommonServiceService,private dialog: MatDialog,private companyserv:CompanyservService) { }
  user:any;
  userid:any;
  organizationlist:any[]=[]
  cityList:any;
  stateList:any;
  countryList:any;
  view="card"
  displayedColumns: any[] = ['registryid','name','email','number','city','state','country' ,'classification','action'];
  nameFilter="";
  // emailFilter="";
  classificationFilter="";
  cityFilter="";
  stateFilter=""
  countryFilter=""
  searchControl=""
  advfilter={
    name:this.nameFilter,
    // email:this.emailFilter,
    classification:this.classificationFilter,
    city:this.cityFilter,
    state:this.stateFilter,
    country:this.countryFilter,
    search_name:this.searchControl
  }
  p:number=1;



  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.userid=this.user.data.id
    this.getOrganisation('')
    this.getCity()
    this.getState()
    this.getCountry()
  }

  getCity(){
    this.appserv.getCity(this.userid).subscribe(
      (cityList: any) =>{ 
        this.cityList = cityList.data
       }
      );    
  }
  getState(){
    this.appserv.getState(this.userid).subscribe(
      (stateList: any) =>{ 
        this.stateList = stateList.data
       }
      );    
    }

  getCountry(){
    this.appserv.getCountry(this.userid).subscribe(
      (countryList: any) =>{ 
        this.countryList = countryList.data
       }
      );    
  }

  getOrganisation(filter:any){
    this.companyserv.getOrganization(this.userid,filter).subscribe(
      (organizationlist: any) =>{ 
        this.organizationlist = organizationlist.company_list
       }
      );    
 }


  deleteOrganization(list:any){
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Remove Customer',
        message: 'Are you sure, you want to remove a customer: ' + list.name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
		this.companyserv.delCustomer(list.id).subscribe((data:any)=>{
      this.getOrganisation('')
		})
	}
});
}

filter(){
  this.advfilter={
   name:this.nameFilter,
    // email:this.emailFilter,
    classification:this.classificationFilter,
    city:this.cityFilter,
    state:this.stateFilter,
    country:this.countryFilter,
    search_name:this.searchControl

 }
 this.getOrganisation(this.advfilter)
}

clearfilter(){
this.nameFilter=""
// this.emailFilter=""
this.classificationFilter=""
this.cityFilter=""
this.stateFilter=""
this.countryFilter=""
this.advfilter={
  name:"",
  // email:"",
  classification:"",
  city:"",
  state:"",
  country:"",
  search_name:""
}
this.getOrganisation(this.advfilter)
}

deleteCustomer(data:any){
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: 'Confirm Remove Customer',
      message: 'Are you sure, you want to remove a customer: ' + data.name
    }
  });
  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
  this.companyserv.delCustomer(data.id).subscribe((data:any)=>{
    this.getOrganisation('')
  
  })
}
});
}

}
