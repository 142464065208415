import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MainService } from '../../services/main-service';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-add-target',
  standalone: true,
  imports: [ReactiveFormsModule,CommonModule,FormsModule,MatDividerModule,RouterModule,MatFormFieldModule,MatDatepickerModule,MatNativeDateModule,MatInputModule,MatIconModule],
  templateUrl: './add-target.component.html',
  styleUrl: './add-target.component.scss'
})
export class AddTargetComponent implements OnInit {
  targetForm!: FormGroup;
  submitted = false;
  public user: any;
  roles:any[]=[]
  constructor(private formBuilder: FormBuilder,private loginservice: LoginService, private router: Router,private route: ActivatedRoute,private mservice:MainService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.targetForm = this.formBuilder.group({
      role: ['', Validators.required],
      duration:['', Validators.required],
      members:['', Validators.required],
      amount:['', Validators.required]
    }, );
  }
  get f() { return this.targetForm.controls; }
  onSubmit() {
    this.submitted = true;
      //console.log(this.registerForm.value);
      let data_tr={
        "creator_id":this.user.data.id,
        "duration":this.targetForm.value.duration,
        "assigned_to":this.targetForm.value.members,
        "target_budget":this.targetForm.value.amount,

      }
      this.mservice.addTargets(data_tr).subscribe((reponse)=>{
     });  
  }

  listRole(){
  //   this.loginservice.listrRole(this.user.data.id).subscribe((response:any)=>{
  //     this.roles=response 
  // })
  }

}
