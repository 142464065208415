<div class=" w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10" style="min-height: 85vh !important; max-height: 600px; overflow-y: auto;">
    <div class="flex flex-col sm:flex-row items-center mb-4">
        <h2 class="font-semibold text-lg mr-auto">Leads</h2>
        <h4 class="float-right memberClass"><span class="cursor-pointer " routerLink="/teamleader/members">Members</span> / <span>Leads</span></h4>

       
    </div>
    <div class="mt-6 w-full overflow-x-auto">
        <table class="min-w-full table-auto border-collapse border border-gray-200">
            <thead>
                <tr class="bg-gray-200 text-gray-600 text-sm">
                     <th class="py-3 px-6 border border-gray-300 text-center">Lead</th>
                    <th class="py-3 px-6border border-gray-300 text-center">Email</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Budget</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Type</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Status</th> 
                    
                
                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm">
                <tr *ngFor="let item of allreportlist | paginate: { itemsPerPage: 5, currentPage: p }" class="border-b border-gray-200 hover:bg-gray-100 whitespace-nowrap">
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">{{item.name}}</td>
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">{{item.email}}</td>
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">₹{{item.budget}}</td>
                   <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap"> {{item.type}}</td>
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">{{item.status}}</td>
                   </tr>
            </tbody>
        </table>

       
    </div>
    <pagination-controls 
    class="mt-4 pagination float-right"
    (pageChange)="p = $event">
</pagination-controls>
</div>


