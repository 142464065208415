<div class="max-w  w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 " style="height: 85vh !important;">
    <div class="grid  gap-8 grid-cols-1 "> 
        <form class="" [formGroup]="registerForm" >
        <div class="flex flex-col ">
                <div class="flex flex-col sm:flex-row items-center mb-6">
                    <h2 class="font-semibold text-lg mr-auto"  *ngIf="!isEdit" >Quotation / New</h2>
                    <h2 class="font-semibold text-lg mr-auto"  *ngIf="isEdit" >Quotation / Edit</h2>
                    <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>
                        <div class=" text-right md:space-x-3 md:block flex flex-col-reverse mb-2" *ngIf="!isEdit">
                        <a routerLink="/teamleader/quotation" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                          <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg cursor-pointer"  style="background-color: #2a303c;" (click)="onSubmit()">Save</button>
                      </div>
                      <div class=" text-right md:space-x-3 md:block flex flex-col-reverse  mb-2" *ngIf="isEdit">
                        <a routerLink="/teamleader/quotation" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                          <a (click)="editquotation()" class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg cursor-pointer "  style="background-color: #2a303c;">Save</a>
                      </div>

                </div>
                <mat-divider></mat-divider>
                <div class="mt-3 bg-white rounded border border-gray-100  px-20" style="height: 60vh !important;overflow-y: auto;">
                    <div class="form mt-3">
                        <div class="flex flex-wrap -mx-3 ">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0"  *ngIf="!isEdit">
                              <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                               Customer
                              </label>
                            <select  #cid formControlName="contact"  [ngClass]="{ 'is-invalid': submitted && f['contact'].errors }" (change)="onContactChange($event)" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                                <option value=null disabled selected>Select</option>
                                <option *ngFor="let list of contactleadlist " value="{{list.contact_id}}">{{list?.contact_name}}</option>
                         </select>
                         <div *ngIf="submitted && f['contact'].errors" class="invalid-feedback text-sm">
                            <div *ngIf="f['contact'].errors['required']">Please fill out this field.</div>
                        </div> 
                    </div>
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0"  *ngIf="isEdit">
                        <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                         Customer
                        </label>
                      <select  #cid [disabled]="isDisabled" formControlName="contact"  [ngClass]="{ 'is-invalid': submitted && f['contact'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm"  (change)="onContactChange($event)" >
                          <option value=null disabled selected>Select</option>
                          <option *ngFor="let list of contactleadlist " value="{{list?.contact_id}}">{{list?.contact_name}}</option>
                   </select>
                   <div *ngIf="submitted && f['contact'].errors" class="invalid-feedback text-sm">
                      <div *ngIf="f['contact'].errors['required']">Please fill out this field.</div>
                  </div> 
              </div>
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0" *ngIf="!isEdit">
                        <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                         Lead
                        </label>
                      <select  formControlName="task"  [ngClass]="{ 'is-invalid': submitted && f['task'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm" (change)="leadChange($event)">
                          <option value=null disabled selected>Select</option>
                          <option *ngFor="let list of taskArr" value="{{list?.task_id}}">{{list?.task_name}}</option>
                   </select>
                   <div *ngIf="submitted && f['task'].errors" class="invalid-feedback text-sm">
                      <div *ngIf="f['task'].errors['required']">Please fill out this field.</div>
                  </div> 
              </div>
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0" *ngIf="isEdit">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                 Lead
                </label>
              <select  formControlName="task"  [ngClass]="{ 'is-invalid': submitted && f['task'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                  <option value=null disabled selected>Select</option>
                  <option *ngFor="let list of quatationlistss" value="{{list?.id}}">{{list?.name}}</option>
           </select>
           <div *ngIf="submitted && f['task'].errors" class="invalid-feedback text-sm">
              <div *ngIf="f['task'].errors['required']">Please fill out this field.</div>
          </div> 
      </div>
                    <div class="w-full md:w-1/2 px-3 py-3">
                        <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                       Date
                        </label>
               
                      <input type="date"  formControlName="date" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm" [ngClass]="{ 'is-invalid': submitted && f['date'].errors }">
                      <div *ngIf="submitted && f['date'].errors" class="invalid-feedback text-sm">
                        <div *ngIf="f['date'].errors['required']">Please fill out this field.</div>
                    </div> 
                    </div>
                            <div class="w-full md:w-1/2 px-3 py-3">
                              <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                             Expiration
                              </label>
                     
                            <input type="date"  formControlName="expiration" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm" [ngClass]="{ 'is-invalid': submitted && f['expiration'].errors }">
                            <div *ngIf="submitted && f['expiration'].errors" class="invalid-feedback text-sm">
                                <div *ngIf="f['expiration'].errors['required']">Please fill out this field.</div>
                            </div> 
                        </div>
                            <div class="w-full md:w-1/2 px-3 py-3 md:mb-0">
                                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                GST No
                                </label>
                                <input type="text"  formControlName="gst_no" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm" [ngClass]="{ 'is-invalid': submitted && f['gst_no'].errors }" placeholder=" eg:-07ABCDE1234F1Z9">
                                <div *ngIf="submitted && f['gst_no'].errors" class="invalid-feedback text-sm">
                                    <div *ngIf="f['gst_no'].errors['required']">Please fill out this field.</div>
                                    <div *ngIf="f['gst_no'].errors['pattern']">Invalid GST Format.</div>
                                </div>
                            </div>
                              <div class="w-full md:w-1/2 px-3 py-3 md:mb-0">
                                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                GST Taxes(Taxes applicable in amount)
                                </label>
                                <input type="text"  formControlName="gst_amount" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm" [ngClass]="{ 'is-invalid': submitted && f['gst_amount'].errors }">
                                <div *ngIf="submitted && f['gst_amount'].errors" class="invalid-feedback text-sm">
                                    <div *ngIf="f['gst_amount'].errors['required']">Please fill out this field.</div>
                                    <div *ngIf="f['gst_amount'].errors['pattern']">Invalid Format</div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap -mx-3">
                           
                            <div class="w-full md:w-1/2 px-3 py-3">
                              <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                             Payment Terms
                              </label>
                              <input type="text"  formControlName="paymentterms" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm" [ngClass]="{ 'is-invalid': submitted && f['paymentterms'].errors }">
                              <div *ngIf="submitted && f['paymentterms'].errors" class="invalid-feedback text-sm">
                                <div *ngIf="f['paymentterms'].errors['required']">Please fill out this field.</div>
                            </div>
                            </div>
                            <div class="w-full md:w-1/2 px-3 py-3">
                                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                               Amount
                                </label>
                       
                              <input type="text"  formControlName="amount" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm" [ngClass]="{ 'is-invalid': submitted && f['amount'].errors }">
                              <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback text-sm">
                                <div *ngIf="f['amount'].errors['required']">Please fill out this field.</div>
                                <div *ngIf="f['amount'].errors['pattern']">Invalid  Format.</div>
                            </div>
                            </div>
                           
                            <div class="w-full  px-3 py-3">
                                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                              Product Details
                                </label>
                                <textarea formControlName="product_details" name="product_details" id="" class="w-full min-h-[100px] max-h-[300px] h-28  outline-none border border-gray-400 rounded py-1 px-2 w-full bg-white text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline mb-4"  spellcheck="false" placeholder="Product Details" [ngClass]="{ 'is-invalid': submitted && f['product_details'].errors }" ></textarea>
                                <div *ngIf="submitted && f['product_details'].errors" class="invalid-feedback text-sm" >
                                    <div *ngIf="f['product_details'].errors['required']" style="margin-top: -3vh;
    margin-top: -3vh;">Please fill out this field.</div>
                                    <div *ngIf="f['product_details'].errors['maxlength']" style="margin-top: -3vh;
    margin-top: -3vh;">Max length of characters should be 25 characters</div>
                                </div>
                            </div>
                                
                        </div>
                      
                                             
                   
                    </div>
                </div>
        </div>
        </form>
      
    </div>
</div>