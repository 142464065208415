
<div class="max-w w-full h-content space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 height " >
  <div class="grid  gap-8 grid-cols-1 ">
      <form class="" [formGroup]="activityForm">
      <div class="flex flex-col ">
              <div class="flex flex-col sm:flex-row items-center mb-6">
                  <h2 class="font-semibold text-lg mr-auto">Schedule Activity</h2>
                  <div class="w-full sm:w-auto sm:ml-auto sm:mt-0"></div>

                  <div class=" text-right md:space-x-3 md:block flex flex-col-reverse mb-2" *ngIf="!isEdit">
                    <a routerLink="/teamleader/activities" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                      <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg cursor-pointer "  style="background-color: #2a303c;" (click)="onSubmit()">Save</button>
                  </div>
                  <div class=" text-right md:space-x-3 md:block flex flex-col-reverse  mb-2" *ngIf="isEdit">
                    <a routerLink="/teamleader/activities" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                      <a (click)="editactivity(cid)" class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg cursor-pointer  " style="background-color: #2a303c;">Save</a>
                  </div>

                  
              </div>
              <mat-divider></mat-divider>
                <div class="mt-3 bg-white rounded border border-gray-100  px-20">
                  <div class="form mt-3">
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-full p-3 mb-6 md:mb-0">
                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                     Name
                    </label>
                    <input type="text" formControlName="eventname"  [ngClass]="{ 'is-invalid': submitted && f['eventname'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm "/>
                    
                  <div *ngIf="submitted && f['eventname'].errors" class="invalid-feedback text-sm">
                    <div *ngIf="f['eventname'].errors['required']">Please fill out this field.</div>
                </div>
              </div>
                  <div class="w-full md:w-1/2 p-3 mb-6 md:mb-0">
                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                      Activity Type
                    </label>
                  
                    <select  #emval formControlName="activitytype" (change)="changeEmail(emval.value)" [ngClass]="{ 'is-invalid': submitted && f['activitytype'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                     
                      <option [value]=null disabled selected >Select</option>
                      <option  value="Events">Events</option>
                          <option  value="Meetings">Meetings</option>
                    </select>
                 
             <div *ngIf="submitted && f['activitytype'].errors" class="invalid-feedback text-sm">
              <div *ngIf="f['activitytype'].errors['required']">Please fill out this field.</div>
          </div>
                  </div>
          
                  <div class="w-full md:w-1/2 p-3">
                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                   Assigned to
                    </label>
                  
        
                  <select   formControlName="contact_id"  [ngClass]="{ 'is-invalid': submitted && f['contact_id'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                    <option [value]=null selected disabled>Select</option>
                    <option *ngFor="let element of contactlist" value="{{element.id}}">{{element.name}}</option>    
                 </select>
                 <div *ngIf="submitted && f['contact_id'].errors" class="invalid-feedback text-sm">
                  <div *ngIf="f['contact_id'].errors['required']">Please fill out this field.</div>
              </div>
                  </div>
                  <div class="w-full md:w-1/2 p-3 mb-6 md:mb-0">
                    <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                     Due Date
                    </label>
                    <input type="datetime-local" formControlName="edate"  [ngClass]="{ 'is-invalid': submitted && f['edate'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm "/>
                    
                  <div *ngIf="submitted && f['edate'].errors" class="invalid-feedback text-sm">
                    <div *ngIf="f['edate'].errors['required']">Please fill out this field.</div>
                </div>
              </div>
              <div class="w-full md:w-1/4 p-3">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                Hour
                </label>
                <input type="text" #hour formControlName="hour" [ngClass]="{ 'is-invalid': submitted && f['hour'].errors }" name="hour" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " placeholder="Hr"> 
                <div *ngIf="submitted && f['hour'].errors" class="invalid-feedback text-sm">
                    <div *ngIf="f['hour'].errors['required']">Please fill out this field.</div>
                </div>
              
              </div>
              <div class="w-full md:w-1/4 p-3">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                Minute
                </label>
                <input type="text" #minute formControlName="minute"  [ngClass]="{ 'is-invalid': submitted && f['minute'].errors }" name="minute" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " placeholder="MM"> 
                <div *ngIf="submitted && f['minute'].errors" class="invalid-feedback text-sm">
                    <div *ngIf="f['minute'].errors['required']">Please fill out this field.</div>
                </div>
              
              </div>

                </div> 
                
       
                <div class="flex flex-wrap -mx-3 mt-3 mb-4">
                  <div class="w-full mx-3">
                    <div class=" justify-center">
                      <!-- <angular-editor formControlName="description" [ngClass]="{ 'is-invalid': submitted && f['description'].errors }" class="mt-3 h-10 justify-center" [(ngModel)]="htmlContent" [config]="config"></angular-editor> -->
                      <textarea id="editor"  formControlName="description"  
                    [ngClass]="{ 'is-invalid': submitted && f['description'].errors }" ></textarea>
                  </div>
                  <div *ngIf="submitted && f['description'].errors" class="invalid-feedback text-sm">
                    <div *ngIf="f['description'].errors['required']">Please fill out this field.</div>
                </div>
                  </div>
                 </div>
                </div>    
              </div>
           </div>
        </form>
    </div>
</div>