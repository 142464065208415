import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { QuotationListService } from '../../services/quotation-list.service';
import { LoginService } from '../../services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { apiurl } from '../../global';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactFormService } from '../../services/contact-form.service';
import { EditLeadService } from '../../services/edit-lead.service';
import { CompanyservService } from '../../services/companyserv.service';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxFileDragDropModule } from "ngx-file-drag-drop";
import { ToastrService } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { PipelineService } from '../../teamleader/drag-drops/pipeline.service';


@Component({
  selector: 'app-quotation-generation',
  standalone: true,
  imports: [ReactiveFormsModule,FormsModule,MatDividerModule,CommonModule,MatIconModule,RouterModule,MatMenuModule,NgSelectModule,NgxFileDragDropModule,MatSelectModule],
  providers : [QuotationListService,LoginService,ContactFormService,EditLeadService,CompanyservService],
  templateUrl: './quotation-generation.component.html',
  styleUrl: './quotation-generation.component.scss'
})
export class QuotationGenerationComponent {
  // quotationForm!: FormGroup;
  user:any;
  leadlist:any;
  opplist:any;
  leadDetails:any;
  salesReplist:any;
  partnerSalesReplist:any;
  contactlist:any;
  organizationlist:any;
  Qid = 0;
  quotationDetails:any;
  isChecked:boolean=false;
	selectedImages:any;
  fileName : any;
  quatationstatus: any=[];
  typeArr = ['Software Sales','Software Development','Implementation Services','Managed Services','Assessment Services','Others'];
	productArr : any = [];
  leadid: any;


  constructor( private formBuilder: FormBuilder, private toaster:ToastrService,  private quotationserv :QuotationListService,private companyserv:CompanyservService,private qutationserv:QuotationListService,private leadserv: EditLeadService,private contactservice: ContactFormService,private dialog: MatDialog,private router: Router,private route: ActivatedRoute,private qservice:QuotationListService,private loginservice:LoginService, private preservice:PipelineService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata();
		this.quotationForm.get('cloudvice_sales_rep')?.setValue(this.user?.data?.first_name)
		this.getProductList()
    
    this.route.params.subscribe((params:any)=>{
      if (params['id']!=undefined){
        this.Qid=params['id']
        
      }
      if(this.Qid !=0){
        this.getQuotationDetails()
      }
    })
   
  this.getLeadlist()
  this. getOpportunity('')
  this.salesrepList()
  this.getpartnerSalesrepList()
  // this.getcontactwithoutCustomer()
  this.getOrganisationList()
 this.getquatationstatus('won')
  }

  getProductList(){
    this.leadserv.getProductList().subscribe((res :any)=>{
    if(res?.data){
      this.productArr = res?.data;
    }
      })
}
  checkbox(){
    this.isChecked = !this.isChecked
  }

  getLeadlist(){   
    this.contactservice.getLead(this.user.data.id,'').subscribe(
      (leadlist: any) => {this.leadlist = leadlist?.data?.tasks
        
      });   
  }

  getquatationstatus(status:any){
    this.contactservice.getQuatationstatus(status,this.user.data.id).subscribe((result:any)=>{
      this.quatationstatus=result?.data;
      // console.log('result quatation is',this.quatationstatus)
    })
  }
  // getoppList(){
  //   this.contactservice.getLead(this.user.data.id,'').subscribe(
  //     (opplist: any) => {this.leadlist = opplist?.data?.tasks
  //       
  //     });  
  // }

  getOpportunity(filter:any){
    
    this.quotationserv.getOpportunity(this.user.data.id,filter).subscribe(
      (opportunitylist: any) =>{ 
        this.opplist = opportunitylist.data
        // console.log( this.opplist ,'opportunityyyyy', this.quotationDetails?.oppertunity_unique_id)
        this.quotationForm.patchValue({
          oppertunity_unique_id :this.quotationDetails?.oppertunity_unique_id ? this.quotationDetails?.oppertunity_unique_id : ''
        })
       }
      );    
  }

  getLeadIdwithOpportunityUniqueId(oppertunity_unique_id:any){

    const matchingOpportunity = this.opplist.find(
      (item: any) => item.opportunity_unique_id == oppertunity_unique_id
    );
    // console.log("matched", matchingOpportunity);
    
    if (matchingOpportunity) {
      return matchingOpportunity.lead;
    }
    else{
      return null
    }
    
  }

  getLeadData(oid:any){
    // console.log(this.opplist,oid?.target?.value,'oppliooo')
    this.opplist.forEach((item:any)=>{
      if(item.oppertunity_unique_id == oid?.target?.value){
        // console.log(item,'iyuuii777')
        const currency = item?.currency.toLowerCase()
        this.quotationForm.patchValue({
          type:item?.type,
          partner_sales_rep : item?.partner_sales_rep,
          products_id : item?.products,
          contact : item?.contact,
          currency : currency
          

        })
        // console.log(this.quotationForm?.controls,'cntrls')
        // this.quotationForm.patchValue({contact:item?.contact})
        this.leadid=item.lead;
      }
    })
    this.qutationserv.getOppertunitydetails(oid?.target?.value).subscribe((response:any)=>{
      this.leadDetails=response?.data?.Opportunity
      this.quotationForm.patchValue({oppertunity_unique_id:this.leadDetails.id})
      
    })
  }

  salesrepList(){
    this.leadserv.salesRepList().subscribe(
      (salesReplist: any) =>{ 
        this.salesReplist = salesReplist.data
        
       });  
  }
  
  getpartnerSalesrepList(){
    this.leadserv.partnersalesRepList().subscribe(
      (partnerSalesReplist: any) =>{ 
        this.partnerSalesReplist = partnerSalesReplist.data
        
       });  
  }

  // getcontactwithoutCustomer(){
  //   console.log('hiiicontactsssss')
  //   this.contactservice.getContactwithoutCus(this.user.data.id,'').subscribe(
  //     (contactlist: any) => {
  //       console.log(contactlist,'contactsssssss')
  //       this.contactlist = contactlist.data
        
  //     });   
  // }
  getOrganisationList(){
    
    this.companyserv.getCompany(this.user.data.id,"").subscribe(
      (organizationlist: any) =>{ 
        // console.log(organizationlist,'organizationnnnn')
        this.organizationlist = organizationlist.company_list
        
       });    
  }   

  
    quotationForm : FormGroup = this.formBuilder.group({
      quote_name: ['', [Validators.required]],
      oppertunity_unique_id: ['', [Validators.required]],
      customer: ['', [Validators.required]],
      contact: ['', [Validators.required]],
      description: [''],
      quotation_revenue: ['',[Validators.required]],
      currency: ['usd', [Validators.required]],
      type: ['', [Validators.required]], 
      quotation_stage: ['',],
      forecast_period: ['', ],
      forecast_probablity: ['',],
      cloudvice_sales_rep: [''],
      partner_sales_rep: [''],
      comments: [''],
      is_approved: [false ],    
      approved_amount: [''],  
	    file: [null],
      products_id: [[], [Validators.required]],

      

  },);
  


  onSubmit(){
    this.quotationForm.markAllAsTouched();
    // console.log(this.quotationForm.controls,'cntrlss')
    let quotationstage = this.quotationForm.value.quotation_stage || 'Won';
    const postData={
      quote_name: this.quotationForm.value.quote_name,
      oppertunity_unique_id: this.quotationForm.value.oppertunity_unique_id,
      customer: this.quotationForm.value.customer,
      contact: this.quotationForm.value.contact,
      description: this.quotationForm.value.description,
      quotation_revenue: this.quotationForm.value.quotation_revenue,
      currency: this.quotationForm.value.currency,
      type: this.quotationForm.value.type,
      quotation_stage: quotationstage,
      forecast_period: this.quotationForm.value.forecast_period,
      forecast_probablity: this.quotationForm.value.forecast_probablity,
      cloudvice_sales_rep: this.user?.data?.id,
      partner_sales_rep: this.quotationForm.value.partner_sales_rep,
      comments: this.quotationForm.value.comments,
      is_approved: this.quotationForm.value.is_approved,
      approved_amount: this.quotationForm.value.approved_amount,
      creator_id:this.user.data.id,
      file:this.selectedImages,
      products_id: this.quotationForm.value.products_id,

    }

    if (this.quotationForm.valid){
      if(this.Qid == 0){
      this.qutationserv.addQuotation(postData).subscribe((response:any)=>{
        if(response.status == "success"){
          
           
            this.preservice.changestatus(this.leadid,this.user.data.id,quotationstage,'').subscribe((result:any)=>{
              // console.log(result,'stageeee')
            })
          
         
          this.toaster.success(response.message,'success')
          this.router.navigate(['/executive/quotation'])
        }
      
      })
    }else{
      this.quotationserv.Editquotation(postData,this.Qid).subscribe((res:any)=>{
        if(res.status == "success"){
          this.toaster.success(res.message,'success')
      this.router.navigate(['/executive/quotation'])
        }
      })
    }
      }
  }

  getQuotationDetails(){
    this.qutationserv.getSingleQuotation(this.Qid).subscribe((response:any)=>{
      
      this.quotationDetails = response?.data?.quotation
      // console.log(this.quotationDetails,'detailesssssss',response.data,'responsedataaa')
      if(this.quotationDetails?.file != null){
          const parts = this.quotationDetails?.file?.split('/');
          // Get the last part which is the file name
          this.fileName = parts[parts?.length - 1]
          }
        this.editForm(this.quotationDetails)
      })
  }

  editForm(data: any){
  // console.log(data,'uniqueiddddddddd')
    this.quotationForm.reset({
      quote_name: data?.quote_name,
      oppertunity_unique_id :data?.oppertunity_unique_id,
      customer : data?.customer ,
      contact : data?.contact,
      description : data?.service_description,
      quotation_revenue : data?.quotation_revenue,
      currency : data?.currency ,
      type : data?.type,
      quotation_stage : data?.quotation_stage,
      cloudvice_sales_rep : this.user?.dta?.first_name,
      partner_sales_rep : data?.partner_sales_rep,
      // comments : data?.comments,
      is_approved : data?.is_approved,
      approved_amount : data?.approved_amount,
      file:this.selectedImages,
      products_id: data?.products,
    })
  }


 
  onFileChange(event: any) {
    // console.log(event,this.quotationDetails?.file,'hhiik')
    if(event != null && event.length > 0){
    const files: FileList = event;
    const file: File = files[0]; // Access the file object directly
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.selectedImages = e.target.result;
    };
    reader.readAsDataURL(file);

    if(this.quotationDetails?.file != undefined && this.quotationDetails?.file !== null){
      // Log the file name
      // console.log('hai wwww')
      this.fileName = '';
      this.quotationDetails.file = null;
    }
    }
  }
    customerChange(event:any){
      this.opplist = []
      const cu_id = event?.target?.value
      this.qutationserv.getLeads(this.user.data.id,cu_id).subscribe((res:any)=>{
        if(res?.data){
          this.opplist = res?.data;
          // console.log(this.opplist,'opportunittttt')
      }
    })
   
    
    this.contactservice.getContactwithoutCus(this.user.data.id,'',cu_id).subscribe((result:any)=>{
      this.contactlist = result?.data
      // console.log(this.contactlist,'lidstssss')
    })
  
    }

    downloadFile(){
      if(this.quotationDetails?.file != null){
      const fileUrl = this.quotationDetails?.file // Change this to your file URL
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = fileUrl; // Change the file name if needed
      document.body.appendChild(
        a);
      a.click();
      document.body.removeChild(a);
      }
    
    }

}
