import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { LoginService } from '../../services/login.service';
import { ActivitiesService } from '../../services/activities.service';
import { Router, RouterModule } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDivider, MatDividerModule } from '@angular/material/divider';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { CompanyservService } from '../../services/companyserv.service';
import { QuotationListService } from '../quotation-lists/quotation-list.service';
import { ContactFormService } from '../../services/contact-form.service';

@Component({
  selector: 'app-activity',
  standalone: true,
  imports: [RouterModule,MatTableModule,MatIconModule,CommonModule,DatePipe,MatDividerModule,MatDialogModule,NgxPaginationModule],
  providers:[LoginService,CompanyservService,QuotationListService,ContactFormService],
  templateUrl: './activity.component.html',
  styleUrl: './activity.component.scss'
})
export class ActivityComponent implements OnInit {
  data: any[] = [];
  p:number=1;
  public user: any;
  SelectedIDs:any[]=[];
  constructor( private dialog: MatDialog,private activitiesservice: ActivitiesService,
    private router: Router,private loginservice: LoginService) { }

  ngOnInit() {
   
    this.user = this.loginservice.getUserdata()
    this.getactivities(this.user.data.id)  }

getactivities(userid: number){

  this.activitiesservice.getActivities(userid).subscribe(
    (data: any) =>{
      this.data = data
      
    } 

    );
}
Editactivity(id:any)
{
  this.router.navigate(['teamleader/update-activities/',id]);
}
EditEmailactivity(id:any)
{
  this.router.navigate(['teamleader/update-scheduleemail/',id]);
}
deleteactivity(item:any)
{
  
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Remove Activity',
        message: 'Are you sure, you want to remove a activity: ' + item.eventname
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
		this.activitiesservice.delOneactivity(item.id).subscribe((data)=>{
      this.getactivities(this.user.data.id)
    
		})
	}
});

}
selectID(id:any, event:any){
  this.SelectedIDs.push(id);
}
}
