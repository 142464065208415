import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-targets',
  standalone: true,
  imports: [CommonModule,NgxPaginationModule,MatDivider],
  templateUrl: './targets.component.html',
  styleUrl: './targets.component.scss'
})
export class TargetsComponent {
p:number=1;
data:any=[];
}
