import { Component, OnInit,ViewEncapsulation,Output,EventEmitter } from '@angular/core';
import { ContactFormService } from '../../services/contact-form.service';
import { LoginService } from '../../services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PipelineService } from '../../services/pipeline.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { LeadDialogComponent } from '../lead-dialog/lead-dialog.component';
import { FormControl } from '@angular/forms';
import { CommonServiceService } from '../../services/common-service.service';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-contact-view',
  standalone: true,
  imports: [ReactiveFormsModule,FormsModule,MatDividerModule,MatTableModule,RouterModule,MatIconModule,CommonModule,NgxPaginationModule],
  providers : [ContactFormService,LoginService,PipelineService,CommonServiceService],
  templateUrl: './contact-view.component.html',
  styleUrl: './contact-view.component.scss'
})
export class ContactViewComponent {
  p:number=1;
  dataSource:any[] = [];
  public user: any;
  contactlist:any[]=[];
  contact:any[] =[];
  id:any;
  cid:any;
  searchControl:any

  constructor( private dialog: MatDialog, pservice: PipelineService,private route:ActivatedRoute,private contactservice: ContactFormService,private router: Router,private loginservice: LoginService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params:any)=>{
      if (params['id']!=undefined){
        this.cid=params['id']
        console.log(this.cid,'ciddd')
      }
    })
    this.user = this.loginservice.getUserdata()
    if(this.cid){
      this.getcontactlist(this.user.data.id,this.cid)

    }
    else{
      this.getcontactwithoutCustomer(this.user.data.id,'')
    }
   
  }

  searchCall(){
    // console.log(this.searchControl,'searchdata')
    this.getcontactwithoutCustomer(this.user.data.id,this.searchControl)
  }


  getcontacts(userid: number){
    this.contactservice.getContacts(userid).subscribe(
      (contact: any) => this.contact = contact
      );   
  }


  getcontactlist(userid: number,cid:number){
  if(this.cid!==undefined){
    this.contactservice.getcontactlists(userid,cid).subscribe((result:any)=>{
      this.dataSource =result?.data;
      console.log(this.dataSource,'datasrccc')
  })
  }
  else{
    this.contactservice.getContact(userid,cid).subscribe(
      (contactlist: any) => {
        this.dataSource = contactlist.data
        console.log(this.dataSource,"this.dataSource")
      });
    
  }
   
}


  getcontactwithoutCustomer(userid: number,search:any){
    this.contactservice.getContactwithoutCus(userid,search).subscribe(
      (contactlist: any) => {
        this.dataSource = contactlist.data
        // console.log(this.dataSource,"this.dataSource")
      });   
  }
 

deleteContact(element:any){
  // console.log(element,'element')
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: 'Confirm Remove Contact',
      message: 'Are you sure, you want to remove a contact: ' + element.first_name
    }
  });
  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
  this.contactservice.delOneContact(element.id).subscribe((data:any)=>{
    // console.log(data,'deletedata')
    if(this.cid){
      this.getcontactlist(this.user.data.id,this.cid)
    }
    else{
      this.getcontactwithoutCustomer(this.user.data.id,'')
    }

  })
}
});

}

}
