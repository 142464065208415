import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MainService } from '../../main-service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-add-targets',
  standalone: true,
  imports:[ReactiveFormsModule,CommonModule,FormsModule,MatDividerModule,RouterModule,MatFormFieldModule,MatDatepickerModule,MatNativeDateModule,MatInputModule,MatIconModule],
  templateUrl: './add-targets.component.html',
  styleUrl: './add-targets.component.scss'
})
export class AddTargetsComponent  implements OnInit {
  targetForm!: FormGroup;
  submitted = false;
  public user: any;
  constructor(private formBuilder: FormBuilder,private loginservice: LoginService, private router: Router,private route: ActivatedRoute,private mservice:MainService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.targetForm = this.formBuilder.group({
      role: ['', Validators.required],
      duration:['', Validators.required],
      amount:['', Validators.required]
    }, );
  }
  get f() { return this.targetForm.controls; }
  onSubmit() {
    this.submitted = true;
      //console.log(this.registerForm.value);
      this.mservice.addTargets(this.targetForm.value,this.user.data.id).subscribe((reponse)=>{
     });  
  }

}
