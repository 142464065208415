import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { AddContactgroupService } from '../add-contactgroups/add-contactgroups.service';
import { LoginService } from '../../services/login.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrService } from 'ngx-toastr';
declare var XLSX: any;

@Component({
  selector: 'app-contactgroups',
  standalone: true,
  imports: [CommonModule,RouterModule,MatDivider,NgxPaginationModule],
  templateUrl: './contactgroups.component.html',
  styleUrl: './contactgroups.component.scss'
})
export class ContactgroupsComponent implements OnInit {
  data: any[] = [];
  public user: any;
  error:any;
  p:number=1;
  public Activity:any=[];
  res: any;
  constructor( private dialog: MatDialog,private contactservice: AddContactgroupService,private loginservice: LoginService,private toaster:ToastrService,
    private router: Router,) { }
    ngOnInit() {
      this.user = this.loginservice.getUserdata()
      this.getcontactgroup(this.user.data.id)
      
    }
  
  getcontactgroup(userid :number){

    this.contactservice.getContactgroup(userid).subscribe(
      (data: any) => this.data = data

      );
  }
  exportContactGroupDataView(){
    this.contactservice.exportContactGroupDataView(this.user.data.id).subscribe((result:any)=>{
     
      if(result?.message){
        const worksheet = XLSX.utils.json_to_sheet(result.message);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        XLSX.writeFile(workbook, 'table-data.xlsx');
      }
    if(result?.status==false){
      this.toaster.error(result?.message,'error')
     }
  
    })
  }
  EditContactgroup(cid:number){
		
    this.router.navigate(['teamleader/update-contactgroup/',cid]);

	}
  delContact(list:any){
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Remove Contact Group',
        message: 'Are you sure, you want to remove a contact Group: ' + list.name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
		this.contactservice.delOneContactgroup(list.id).subscribe((data)=>{
      this.getcontactgroup(this.user.data.id)
    
		})
	}
});
}
}
