import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PipelineService } from '../../services/pipeline.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactFormService } from '../../services/contact-form.service';
import { LoginService } from '../../services/login.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';


@Component({
  selector: 'app-contact-customer',
  standalone: true,
  imports: [FormsModule,ReactiveFormsModule,MatMenuModule,RouterModule,MatDividerModule,MatTableModule,MatIconModule,CommonModule,NgxPaginationModule],
  providers : [PipelineService,ContactFormService,LoginService],
  templateUrl: './contact-customer.component.html',
  styleUrl: './contact-customer.component.scss'
})
export class ContactCustomerComponent {
  displayedColumns: string[] = ['name','customer', 'designation', 'email','primary', 'phoneNo','action'];
  dataSource:any;
  public user: any;
  p:number=1;
  contactlist:any[]=[];
  contact:any[] =[];
  id:any;
  cid:any;
  searchControl:any;
  nameFilter="";
  emailFilter="";
  designationFilter="";
  mobile_phoneFilter=""
  // countryFilter=""
  advfilter={
    first_name:this.nameFilter,
    email:this.emailFilter,
    designation:this.designationFilter,
    mobile_phone:this.mobile_phoneFilter,
    // country:this.countryFilter,
    // search:this.searchControl
  }

  constructor( private dialog: MatDialog, pservice: PipelineService,private route:ActivatedRoute,private contactservice: ContactFormService,private router: Router,private loginservice: LoginService) { }

  ngOnInit(): void {

    this.route.params.subscribe((params:any)=>{
      if (params['id']!=undefined){
        this.cid=params['id'];
      }
    })
    this.user = this.loginservice.getUserdata()
    if(this.cid){
      this.getcontactlist(this.user.data.id,this.cid,'')

    }
    
   
  }

  searchCall(){
    this.getcontactlist(this.user.data.id,this.cid,this.searchControl)
  }


  getcontactlist(userid: number,cid:number,filter:any){
    this.contactservice.getcustomerContacts(userid,cid,filter).subscribe(
      (contactlist: any) => {
        this.dataSource = contactlist.data;
      });
}



deleteContact(element:any){
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: 'Confirm Remove Contact',
      message: 'Are you sure, you want to remove a contact: ' + element.first_name
    }
  });
  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
  this.contactservice.delOneContact(element.id).subscribe((data:any)=>{
    if(this.cid){
      this.getcontactlist(this.user.data.id,this.cid,'')
    }
    // else{
    //   this.getcontactwithoutCustomer(this.user.data.id,'')
    // }

  })
}
});

}

filter(){
  this.advfilter={
    first_name:this.nameFilter,
    email:this.emailFilter,
    designation:this.designationFilter,
    mobile_phone:this.mobile_phoneFilter,
 }
 this.getcontactlist(this.user.data.id,this.cid,this.advfilter)
}

clearfilter(){
  this.advfilter={
    first_name:"",
    email:"",
    designation:"",
    mobile_phone:"",
}
this.getcontactlist(this.user.data.id,this.cid,this.advfilter)
}

}
