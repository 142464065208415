import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MainService } from '../../services/main-service';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-add-configuration',
  standalone: true,
  imports:[ReactiveFormsModule,CommonModule,MatDividerModule,RouterModule,MatFormFieldModule,MatDatepickerModule,MatNativeDateModule,MatInputModule,MatIconModule],
  templateUrl: './add-configuration.component.html',
  styleUrl: './add-configuration.component.scss'
})
export class AddConfigurationComponent implements OnInit {
  configForm!: FormGroup;
  submitted = false;
  public user: any;
  public images: any[]=[];
  private cid:any
  public isEdit:boolean = false;
  imagesview: any;
  imgname: any;
  constructor(private formBuilder: FormBuilder,private loginservice: LoginService, private router: Router,private route: ActivatedRoute,private mservice:MainService) { }
  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.configForm = this.formBuilder.group({
      cname: ['', Validators.required],
      email:['', [Validators.required,Validators.email]],
      phone:['', [Validators.required,Validators.pattern(/^[7-9]\d{9}$/)]],
      address:['', Validators.required],
      files:[''],
      gstno: ['', Validators.required],
      accountnumber:['', Validators.required],
      accountname:['', [Validators.required,Validators.pattern(/^[A-Za-z\s]+$/)]],
      bankname:['', [Validators.required,Validators.pattern(/^[A-Za-z\s]+$/)]],
      ifsc:['',[Validators.required, Validators.pattern(/^[A-Z]{4}0[A-Z0-9]{6}$/)]],
    }, );
    this.route.paramMap.subscribe(params => {
      this.cid = params.get('id')
      if(this.cid != null){
        this.isEdit = true;
        this.mservice.getOneconfig(this.cid).subscribe((data:any)=>{
            this.imgname=data.logo.replace('http://192.168.1.20:8000/images/','');
            this.configForm.setValue({
            cname: data.name,
            email:data.email,
            phone:data.phone,
            address:data.address,
            files:'',
            gstno: data.gst_no,
            accountnumber:data.account_number,
            accountname:data.account_name,
            bankname:data.bank_name,
            ifsc:data.ifsc_code,

          })
          this.imagesview=data.logo
      })
    
  }
  else
  {
      this.isEdit = false;
  this.configForm.reset();
  }
    });
  }
  onImageSelect(event:any){
    const reader = new FileReader();
    

    reader.onload = (event: ProgressEvent) => {
      let img = (<FileReader>(
        event.target
      )).result;
      let data ={
        "image":img
      }
      this.images.push(img);
     // this.imageedit.push(data)
    };
    reader.readAsDataURL(event.target.files[0]);
   // this.imageselect = null;
  }

  get f() { return this.configForm.controls; }
  onSubmit() {
    this.submitted=true;
    if(this.configForm.invalid){
      return;
      
    }
    let data_val=
    {
     
      "logo": this.images[0],
      "address":this.configForm.value.address,
      "gst_no": this.configForm.value.gstno,
      "account_name": this.configForm.value.accountname,
      "account_number": this.configForm.value.accountnumber,
      "ifsc_code": this.configForm.value.ifsc,
      "bank_name": this.configForm.value.bankname,
      "name": this.configForm.value.cname,
      "email": this.configForm.value.email,
      "phone": this.configForm.value.phone,
      "creator_id": this.user.data.id,
    
    }
      if(this.cid != null){
        this.mservice.editConfig(data_val,this.cid).subscribe((reponse)=>{
          this.router.navigate(['admin/configuration'])
       }); 
     
  }else{
      this.mservice.addConfig(data_val).subscribe((reponse)=>{
      this.router.navigate(['admin/configuration'])
     }); 
  }
}

  editconfig() {
    if(this.configForm.value['logo']!='')
    {
      this.configForm.value['logo'] = this.images[0]
    }
    let data_val1=
    {
     
      "logo": this.images[0],
      "address":this.configForm.value.address,
      "gst_no": this.configForm.value.gstno,
      "account_name": this.configForm.value.accountname,
      "account_number": this.configForm.value.accountnumber,
      "ifsc_code": this.configForm.value.ifsc,
      "bank_name": this.configForm.value.bankname,
      "name": this.configForm.value.cname,
      "email": this.configForm.value.email,
      "phone": this.configForm.value.phone,
      "creator_id": this.user.data.id,
    } 
  }
}
