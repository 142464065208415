<div class=" w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10"
style="height: 85vh; overflow: hidden;">
    <div class="flex flex-col sm:flex-row items-center mb-4">
        <h2 class="font-semibold text-lg mr-auto">Members</h2>
        <div class="w-full sm:w-auto sm:ml-auto mt-2 sm:mt-0">
            <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
                <a routerLink="/teamleader/add-member"
                    class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">
                    Create </a>
                <button
                    class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg "
                    style="background-color: #2a303c;" s (click)="ExportTeamMembersDataView()">Export</button>
            </div>
        </div>
    </div>
    <p>Team-Target: ₹{{teamtarget ? teamtarget : 0}} <span style="float:right">Team-Progress: {{teamprogress ? teamprogress : 0}}%</span></p>
    <mat-divider></mat-divider>
    <div class="mt-4 w-full overflow-x-auto">
        <table class="min-w-max w-full table-auto border-collapse border border-gray-300">
            <thead>
                <tr class="bg-gray-200 text-gray-600 text-sm">
                    <th class="py-3 px-6 border border-gray-300 text-center">Name</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Assigned to</th>
                    <th class="py-3 px-6  border border-gray-300 text-center">Progress</th>
                    <th class="py-3 px-6 border border-gray-300  text-center">Target</th>
                    <th class="py-3 px-6  border border-gray-300 text-center">Leads</th>
                    <th class="py-3 px-6  border border-gray-300 text-center">Manage</th>
                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm " *ngIf="membrList && membrList.length > 0; else noData">
                <tr *ngFor="let item of membrList | paginate: { itemsPerPage: 5, currentPage: p }"
                    class="border-b border-gray-200 hover:bg-gray-100 whitespace-nowrap">
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap"> {{item.team_member.first_name}}</td> 
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">{{item.team_member.groups[0].name}}</td>
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap"> {{item.member_progress}} %</td>
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">₹{{item.target ? item.target : 0}}</td>
                    <td class="py-3 px-6  border border-gray-300 text-center whitespace-nowrap">
                        <div class="" style="justify-content: center;">
                            <span class="bg-red-200 mr-2 text-purple-600 py-1 px-3 rounded-full text-xs"><a
                                    routerLink="/teamleader/allreport/{{item.team_id}}/{{item.team_member_id}}"
                                    tittle="view ">Leads</a></span>
                        </div>
                    </td>
                    <td class="py-3 px-6 text-center whitespace-nowrap">
                        <div class="" style="justify-content: center;" *ngIf="user?.data?.id == item?.created_by_id;else noData">
                            <span class="bg-red-200 mr-2 text-purple-600 py-1 px-3 rounded-full text-xs"><a
                                    routerLink="/teamleader/add-target/{{item.id}}/{{item.team_member_id}}"
                                    title="Add Target">Target</a></span>
                        </div>
                        <ng-template #noData>
                           <span>---</span>
                        </ng-template>
                    </td>
                </tr>
                <!-- <tr *ngIf="membrList?.length === 0">
                    <td colspan="6" class="text-center  font-semibold text-lg pt-9">No Data Found</td>
                  </tr> -->
            </tbody>
        </table>
        <ng-template #noData><tr><td colspan="6" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
    </div>
    <pagination-controls (pageChange)="p = $event" class="float-right pagination mt-1"></pagination-controls>

</div>