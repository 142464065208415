import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ScheduleService } from '../schedules/schedule.service';
import { AddEventService } from './add-event.service';
import { CommonModule, formatDate } from '@angular/common';
import { ContactFormService } from '../../services/contact-form.service';
import { Router, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-add-event',
  standalone: true,
  imports: [CommonModule,RouterModule,ReactiveFormsModule,FormsModule],
  templateUrl: './add-event.component.html',
  styleUrl: './add-event.component.scss'
})
export class AddEventComponent implements OnInit {
  @Input() date:any;
  @Input() selectedDay:any;
  @Output() addEventStatus= new EventEmitter();
  @Output() addEventData = new EventEmitter();
  public user: any;
  public events:any[] = [];
  registerForm!: FormGroup;
  submitted = false;
  contact:any[] =[];
  currentDate:any
  edate_com:any
  constructor(private scservice:ScheduleService,private addeventservice:AddEventService,private formBuilder: FormBuilder,private contactservice: ContactFormService, private router: Router,private loginservice: LoginService) { }

  ngOnInit(): void {
    this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en')
    this.user = this.loginservice.getUserdata()
    this.registerForm = this.formBuilder.group({ 
      eventname: ['', Validators.required],
      ctime:['', Validators.required],
      contacts:['', Validators.required],
      user:this.user.data.id,
      hour:['', Validators.required],
      minute:['', Validators.required],
      activitytype:['', Validators.required],
      edate:['']
  }, );

  //console.log(this.date,'hfghgf')
  //this.registerForm.controls['contacts'].setValue(group_members_list)
    this.getevent()
    this.getcontacts(this.user.data.id)
  }
  
  menuClose(stat:Event){

    this.addEventStatus.emit(this.selectedDay);

  }
  getcontacts(userid: number){
  
    this.scservice.gettasklist(userid).subscribe(
      (contact: any) => this.contact = contact
      );
  }
   
  // AddEvent(name:string,hour:number,minute:number){
  
  //   // this.events = new EventTask(name,hour,minute);
  //   this.addEventStatus.emit(this.selectedDay);

  //   // this.addEventData.emit(this.events);
  //  // this.addeventservice.addEvents(name,this.date).subscribe(edata => {
  //     //console.log(edata);
  //     // if (edata.data.status == true)
  //     // {this.getevent}
  //   })

   
  // }
  get f() { return this.registerForm.controls; }
  onSubmit() {
  this.edate_com=this.registerForm.value.edate+this.registerForm.value.ctime
  //console.log(this.edate_com)
    //console.log(this.registerForm.value.edate)
    let data = {
      "eventname":this.registerForm.value.eventname,
      "edate":this.registerForm.value.edate,
      "task_id":this.registerForm.value.contacts,
      "emails_id":null,
      "description":this.registerForm.value.description,
      "activity_type":this.registerForm.value.activitytype,
      "hour":this.registerForm.value.hour,
      "minute":this.registerForm.value.minute,
      "user_id":this.user.data.id
    }
    this.submitted = true;
    this.scservice.addSchedule(data,0).subscribe((reponse)=>{
    //console.log(reponse);
    this.addEventStatus.emit(this.selectedDay);
    window.location.href="/teamleader/calendar"
   });  
}
onReset() {
  this.submitted = false;
  this.registerForm.reset();
}
  getevent(){

    this.addeventservice.getEvents(this.user.data.id).subscribe(
      edata => {
        this.events.push(edata)
        //console.log(this.events);
      }
      )
  }

}
