<div class=" w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10" style="min-height: 86vh !important; max-height: 500px;">
    <div class="flex flex-col sm:flex-row items-center mb-4">
        <h2 class="font-semibold text-lg mr-auto">Members</h2>
        <div class="w-full sm:w-auto sm:ml-auto mt-2 sm:mt-0">
            <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
                <a routerLink="/admin/add-team-member"  class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Create </a>
                <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg " style="background-color: #2a303c;" (click)="exportmembers()" >Export</button>
            </div>
        </div>
    </div>
    
    <mat-divider></mat-divider>

    <div class="mt-6 w-full overflow-x-auto">
        <table class="min-w-full table-auto border-collapse border border-gray-300">
            <thead>
                <tr class="bg-gray-200 text-gray-600 text-sm">
                    <th class="py-3 px-6 border border-gray-300 text-center">Name</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Email</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Phone</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Manage</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Status</th>
                   </tr>
            </thead>
            <tbody class="text-gray-600 text-sm" *ngIf="membrList && membrList.length > 0; else noData">
                <tr *ngFor="let item of membrList | paginate: { itemsPerPage: 5, currentPage: p }" class="border-b border-gray-200 hover:bg-gray-100  ">
                     <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap"> {{item.user.first_name}}</td>
                     <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap"> {{item.user.email}}</td>
                     <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">{{item.phone}}</td>
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">
                        <div class="flex justify-center gap-2">
                            <span class="bg-red-200 mr-2 text-purple-600 py-1 px-3 rounded-full text-xs"><a routerLink="/admin/add-team-member/{{item.user.id}}"><i class="fa fa-pencil"></i></a></span>
                          
                        </div></td>
                            
                                <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">
                                    <div class="flex justify-center gap-2">
                                        <span class="bg-red-200 mr-2 text-purple-600 py-1 px-3 rounded-full text-xs "><span *ngIf="item.user.is_active==true">Active</span><span *ngIf="item.user.is_active==false"> Not Active</span>
                                            </span> 
                                        </div></td>
                            </tr>
            </tbody>
            </table>
            <ng-template #noData><tr><td colspan="5" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
</div>
<pagination-controls (pageChange)="p = $event" class="float-right pagination mt-1"></pagination-controls>

</div>

