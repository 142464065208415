import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { CompanyservService } from '../../services/companyserv.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-customer-list',
  standalone: true,
  imports: [RouterModule,MatBadgeModule,CommonModule],
  providers : [LoginService,CompanyservService],
  templateUrl: './customer-list.component.html',
  styleUrl: './customer-list.component.scss'
})
export class CustomerListComponent {
  constructor(private loginservice: LoginService,private dialog: MatDialog,private companyserv:CompanyservService) { }
  user:any;
  userid:any;
  organizationlist:any[]=[]
  p:number=1;

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.userid=this.user.data.id
    this.getOrganisation()
  }

  getOrganisation(){
    this.companyserv.getOrganization(this.userid,'').subscribe(
      (organizationlist: any) =>{ 
        this.organizationlist = organizationlist.company_list
      }
      );    
 }


  deleteOrganization(list:any){
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Remove Customer',
        message: 'Are you sure, you want to remove a customer: ' + list.name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
		this.companyserv.delCustomer(list.id).subscribe((data:any)=>{
      this.getOrganisation()
    
		})
	}
});

}
}
