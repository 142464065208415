<div class=" w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10" style="min-height: 84vh !important; max-height: 500px;">
    <div class="flex flex-col sm:flex-row items-center mb-4">
        <h2 class="font-semibold text-lg mr-auto">Teams</h2>
        <div class="w-full sm:w-auto sm:ml-auto mt-2 sm:mt-0">
             <div class="text-right md:space-x-3 md:block flex flex-col-reverse">
                <a routerLink="/admin/add-team" class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Create </a>
                <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg" style="background-color: #2a303c;" (click)="exportTeams()">Export</button>
            </div>
        </div>
    </div>
    
    <mat-divider></mat-divider>

    <div class="mt-6 w-full overflow-x-auto">
        <table class="min-w-full table-auto border-collapse border border-gray-300">
            <thead>
                <tr class="bg-gray-200 text-gray-600 text-sm">
                   <th class="py-3 px-6 border border-gray-300 text-center">Team Name</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Target</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Manage</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Status</th>
                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm" *ngIf="TeamsList && TeamsList.length > 0; else noData">
                <tr *ngFor="let item of TeamsList | paginate: { itemsPerPage: 5, currentPage: p }" class="border-b border-gray-200 hover:bg-gray-100 ">
                     <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">{{item.teamname ? item.teamname : ''}}</td>
                     <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">₹{{item.target ? item.target : 0}}</td>
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">
                        <div class="flex justify-center gap-2">
                            <span class="bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs">
                                <a routerLink="/admin/team-members-details/{{item.id}}" *ngIf="item.status==true" title="Add Team Member">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M12.692 11.335q.552-.589.776-1.364t.224-1.587t-.224-1.586t-.776-1.364q1.135.104 1.875.95q.74.847.74 2t-.74 2t-1.875.95m5 7.281v-1.692q0-.735-.265-1.4t-.838-1.096q1.025.315 1.872.903q.847.587.847 1.593v1.693zm1.616-6.115v-2h-2v-1h2v-2h1v2h2v1h-2v2zM8.692 11.385q-1.237 0-2.119-.882t-.88-2.119t.88-2.118t2.12-.882t2.118.882t.881 2.118t-.881 2.12t-2.119.88m-7 7.232v-1.647q0-.619.36-1.158q.361-.54.971-.838q1.415-.679 2.833-1.018q1.417-.34 2.836-.34t2.837.34t2.832 1.018q.61.298.97.838q.361.539.361 1.158v1.647z"/>
                                    </svg>
                                </a>
                            </span>  
                            <span class="bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs">
                                <a routerLink="/admin/add-team/{{item.id}}" title="Edit">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24">
                                        <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                            <path d="M7 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-1"/>
                                            <path d="M20.385 6.585a2.1 2.1 0 0 0-2.97-2.97L9 12v3h3zM16 5l3 3"/>
                                        </g>
                                    </svg>
                                </a>
                            </span>
                         </div>
                    </td>
                    <td class="py-3 px-6 border border-gray-300 text-center whitespace-nowrap">
                        <div class="flex justify-center gap-2">
                            <span class="bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs">
                                <button type="button" *ngIf="item.status==true" (click)="removeteam(item.id,'False')">
                                    <span>Active</span>
                                </button>
                                <button type="button" *ngIf="item.status==false" (click)="addteam(item.id,'True')">
                                    <span> Not Active </span>
                                </button>
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <ng-template #noData><tr><td colspan="4" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
    </div>
    <pagination-controls (pageChange)="p = $event" class="float-right pagination mt-1"></pagination-controls>

</div>
