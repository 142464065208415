<div class="max-w h-content w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 mt-3"
  style="height: 84vh !important;overflow-y: auto;">
  <div class="grid ">
    <form class="form-control" [formGroup]="customerForm">
      <div class="flex flex-col ">
        <div class="flex flex-col sm:flex-row items-center mb-6">
          <h2 class="font-semibold text-lg mr-auto">Account / {{cid ? 'Edit' : 'New'}}</h2>
          <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>

          <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
            <a routerLink="/executive/customerlist"
              class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 cursor-pointer">
              Cancel </a>
            <button
              class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg"
              style="background-color: #2a303c;" type="submit" (click)="onSubmit()">Save</button>
          </div>

        </div>
        <mat-divider></mat-divider>
        <div class="form mt-3">
          <div>
            <h3 class="text-black p-2 text-lg mr-auto">Organization Information</h3>

            <div *ngIf="customerForm.get('contacttype')?.value === 'company'" class="mt-4">
              <div>

                <div class="flex justify-between">
                  <div class="p-2 form-control ">
                    <label class="block   tracking-wide text-gray-400 text-sm mb-2" for="grid-password"
                      style="display: flex;justify-content: start;">
                      Customer Type :</label>
                    <select formControlName="contacttype"
                      class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
                      <option value="" disabled="true">Select</option>
                      <option value="company">Company</option>
                    </select>
                  </div>

                  <div class="p-2">
                    <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                      <span class="text-red-600">*</span> Name :</label>
                    <input type="text" formControlName="name"
                      class="focus:ring-white w-64 h-9 rounded-md border-gray-200  border-gray-300  sm:text-sm " />
                    <div *ngIf="customerForm.get('name')?.touched && customerForm.get('name')?.hasError('required')"
                      class="error-msg">
                      Name is Required
                    </div>
                    <div *ngIf="customerForm.get('name')?.hasError('pattern')" class="error-msg">
                      Invalid Format
                    </div>
                  </div>
                  <div class="p-2">
                    <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                      Registary ID :</label>
                    <input type="text" formControlName="registry_id"
                      class="focus:ring-white w-64 h-9 rounded-md border-gray-200  border-gray-300  sm:text-sm"
                      placeholder="12345" />
                    <!-- <div
                      *ngIf="customerForm.get('registry_id')?.touched && customerForm.get('registry_id')?.hasError('required')"
                      class="error-msg">
                      registryId is required
                    </div> -->
                    <div *ngIf="customerForm.get('registry_id')?.hasError('pattern')" class="error-msg">
                      Invalid Format
                    </div>
                  </div>



                </div>
                <div class="flex  justify-between">
                  <div class="p-2">
                    <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                      Classification :</label>
                    <select formControlName="classification"
                      class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                      <option value="" disabled="true">Select classification</option>
                      <option value="Public">Public</option>
                      <option value="Corporate">Corporate</option>
                      <option value="Healthcare">Healthcare</option>
                    </select>
                    <!-- <div
                      *ngIf="customerForm.get('classification')?.touched && customerForm.get('classification')?.hasError('required')"
                      class="error-msg">
                      classification is required
                    </div> -->
                    <div *ngIf="customerForm.get('classification')?.hasError('pattern')" class="error-msg">
                      Invalid Format
                    </div>
                  </div>

                  <div class="p-2">
                    <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                      Email :</label>
                    <input type="email" formControlName="email"
                      class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                    <!-- <div *ngIf="customerForm.get('email')?.touched && customerForm.get('email')?.hasError('required')"
                      class="error-msg">
                      Email is required
                    </div> -->
                    <div *ngIf="customerForm.get('email')?.hasError('pattern')" class="error-msg">
                      Invalid Format
                    </div>
                  </div>
                  <div class="p-2">
                    <label class="block  tracking-wide text-gray-400 text-sm  mb-2" for="grid-password">
                      Tax Identification Number :</label>
                    <input type="text" formControlName="tax_identification_number" placeholder="XXX XXX XXXXXX"
                      class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                    <!-- <div
                      *ngIf="customerForm.get('tax_identification_number')?.touched && customerForm.get('tax_identification_number')?.hasError('required')"
                      class="error-msg">
                      Tax identification Number is required
                    </div> -->
                    <div *ngIf="customerForm.get('tax_identification_number')?.hasError('pattern')" class="error-msg">
                      Invalid Format
                    </div>
                  </div>

                </div>
                <div class="p-2 flex justify-start">
                  <input type="checkbox" formControlName="partner"
                    class="focus:ring-white w-6 h-6 rounded-md border-gray-200  border-gray-300  sm:text-sm" />
                  <label class="block pl-2 tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                    Is Partner?</label>
              </div>
              </div>

              <div><br>
                <h3 class=" p-2 text-lg text-black mr-auto">Address :</h3>
                <div class="flex justify-between">
                  <div class="p-2">
                    <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                      <span class="text-red-600">*</span> Address Line 1 :</label>
                    <input type="text" formControlName="address1"
                      class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                    <div
                      *ngIf="customerForm.get('address1')?.touched && customerForm.get('address1')?.hasError('required')"
                      class="error-msg">
                      Address Line 1 is Required
                    </div>
                    <div *ngIf="customerForm.get('address1')?.hasError('pattern')" class="error-msg">
                      Invalid Format
                    </div>
                  </div>

                  <div class="p-2">
                    <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                      Address Line 2 :</label>
                    <input type="text" formControlName="address2"
                      class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                    <!-- <div
                      *ngIf="customerForm.get('address2')?.touched && customerForm.get('address2')?.hasError('required')"
                      class="error-msg">
                      Address Line 2 is Required
                    </div> -->
                    <div *ngIf="customerForm.get('address2')?.hasError('pattern')" class="error-msg">
                      Invalid Format
                    </div>
                  </div>

                  <div class="p-2">
                    <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                      <span class="text-red-600">*</span>   City :</label>
                    <input type="text" formControlName="city"
                      class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                    <div *ngIf="customerForm.get('city')?.touched && customerForm.get('city')?.hasError('required')"
                      class="error-msg">
                      City is Required
                    </div>
                    <div *ngIf="customerForm.get('city')?.hasError('pattern')" class="error-msg">
                      Invalid Format
                    </div>
                  </div>
                </div>

                <div class="flex justify-between">


                  <div class="p-2">
                    <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                      <span class="text-red-600">*</span>  State :</label>
                    <input type="text" formControlName="state"
                      class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />

                    <div *ngIf="customerForm.get('state')?.touched && customerForm.get('state')?.hasError('required')"
                      class="error-msg">
                      State is Required
                    </div>
                    <div *ngIf="customerForm.get('state')?.hasError('pattern')" class="error-msg">
                      Invalid Format
                    </div>
                  </div>

                  <div class="p-2">
                    <label class="block  tracking-wide text-gray-400 text-sm  mb-2" for="grid-password">
                      <span class="text-red-600">*</span> Country :</label>
                    <input type="text" formControlName="country"
                      class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />

                    <div
                      *ngIf="customerForm.get('country')?.touched && customerForm.get('country')?.hasError('required')"
                      class="error-msg">
                      Country is Required
                    </div>
                    <div *ngIf="customerForm.get('country')?.hasError('pattern')" class="error-msg">
                      Invalid Format
                    </div>
                  </div>

                  <div class="p-2">
                    <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                      <span class="text-red-600">*</span> Zip Code :</label>
                    <input type="text" formControlName="zipcode"
                      class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                    <div
                      *ngIf="customerForm.get('zipcode')?.touched && customerForm.get('zipcode')?.hasError('required')"
                      class="error-msg">
                      Zip Code is Required
                    </div>
                    <div *ngIf="customerForm.get('zipcode')?.hasError('pattern')" class="error-msg">
                      Invalid Format
                    </div>
                  </div>

                </div>

                <div class="flex justify-between">
                  <div class="p-2">
                    <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                      <span class="text-red-600">*</span> Website :</label>
                    <input type="text" formControlName="websitelink"
                      class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                    <div
                      *ngIf="customerForm.get('websitelink')?.touched && customerForm.get('websitelink')?.hasError('required')"
                      class="error-msg">
                      Website is Required
                    </div>
                    <div *ngIf="customerForm.get('websitelink')?.hasError('pattern')" class="error-msg">
                      Invalid Format
                    </div>
                  </div>

                  <div class="p-2">
                    <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                      <span class="text-red-600">*</span> Phone :</label>
                    <input type="text" formControlName="phoneNumber"
                      class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm"
                      placeholder="with country code" />
                    <div
                      *ngIf="customerForm.get('phoneNumber')?.touched && customerForm.get('phoneNumber')?.hasError('required')"
                      class="error-msg">
                      Phone Number is Required
                    </div>
                    <div *ngIf="customerForm.get('phoneNumber')?.hasError('pattern')" class="error-msg">
                      Invalid Format
                    </div>
                  </div>

                  <div class="p-2">
                    <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                      Fax :</label>
                    <input type="text" formControlName="fax"
                      class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm"
                      placeholder="xxx-xxx-xxxx" />
                    <!-- <div *ngIf="customerForm.get('fax')?.touched && customerForm.get('fax')?.hasError('required')"
                      class="error-msg">
                      Fax is required
                    </div> -->
                    <div *ngIf="customerForm.get('fax')?.hasError('pattern')" class="error-msg">
                      Invalid format
                    </div>
                  </div>
                  
                </div>
                <div class="flex w-full">
                  <div class="p-2 w-full">
                    <label class="block tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                      Comments :
                    </label>
                    <textarea formControlName="comments" rows="5" 
                      class="focus:ring-white w-full rounded-md focus:border-gray-200 border-gray-300 sm:text-sm"></textarea>
                  </div>
                </div>
                
              </div><br><br>
              <!-- <div class="row" style="display: flex;" *ngIf="ishide">
                <h3 class="text-lg text-black mr-auto">Address Type :</h3>
                <div class="row" style="display: flex;float: right; margin-right: 3vh !important;">
                  <div (click)="addMoreFormControl()"><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px"
                      class="cursor-pointer" viewBox="0 0 48 48">
                      <g fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="4">
                        <path d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z" />
                        <path stroke-linecap="round" d="M24 16v16m-8-8h16" />
                      </g>
                    </svg></div>
                  &nbsp;<span>Add</span>
                </div>
              </div><br> -->
              <!-- <div formArrayName="address_data">
                <div class="address-sec mt-3" *ngFor="let address of address_data.controls; let i = index"
                  [formGroupName]="i">

                  <mat-icon class="cursor-pointer mt-2"
                    (click)="updateAddress(i,address.get('id')?.value)" title="edit">edit</mat-icon>
                  <mat-icon class="cursor-pointer" (click)="removeForm(i,address.get('id')?.value)"
                    *ngIf='i!==0'>close</mat-icon>
                  <div class="flex justify-between">

                    <div class="p-2 flex justify-start">
                      <input type="checkbox" formControlName="primary"
                        class="focus:ring-white w-6 h-6 rounded-md border-gray-200  border-gray-300  sm:text-sm" />
                      <label class="block pl-4 tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        Primary</label>
                      <div *ngIf="address.get('primary')?.touched && address.get('primary')?.hasError('required')"
                        class="error-msg">
                        primary is required
                      </div>
                    </div>

                    <div class="p-2">
                      <mat-form-field class="example-full-width">
                        <mat-label>Starting date</mat-label>
                        <input formControlName="fromdate" matInput [matDatepicker]="picker">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker">
                          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="address.get('fromdate')?.touched && address.get('fromdate')?.hasError('required')"
                        class="error-msg">
                        Starting date is required
                      </div>

                    </div>

                    <div class="p-2">
                      <mat-form-field class="example-full-width">
                        <mat-label>Ending date</mat-label>
                        <input formControlName="todate" matInput [matDatepicker]="picker1">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker1">
                          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="address.get('todate')?.touched && address.get('todate')?.hasError('required')"
                        class="error-msg">
                        Ending date is required
                      </div>

                    </div>

                    <div class="flex justify-start">
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Purpose</label>
                        <select formControlName="purpose"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
                          <option value="shipto">Ship-to</option>
                          <option value="billto">Bill-to</option>
                        </select>
                        <div *ngIf="address.get('purpose')?.touched && address.get('purpose')?.hasError('required')"
                          class="error-msg">
                          Purpose is required
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="flex justify-between">
                    <div class="p-2">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        Site</label>
                      <input type="text" formControlName="sites"
                        class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                      <div *ngIf="address.get('sites')?.touched && address.get('sites')?.hasError('required')"
                        class="error-msg">
                        site is required
                      </div>

                    </div>

                    <div class="p-2">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        bill to Site</label>
                      <input type="text" formControlName="biil_to_site"
                        class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                      <div
                        *ngIf="address.get('biil_to_site')?.touched && address.get('biil_to_site')?.hasError('required')"
                        class="error-msg">
                        bill to Site is required
                      </div>

                    </div>


                  </div>

                </div>
              </div> -->
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
</div>