import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, input, Input, NgModule, OnInit, Output,ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Board, Column, Task } from '../../admin/admin-global';
import { PipelineService } from './pipeline.service';
import { LoginService } from '../../services/login.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CdkDrag, CdkDragDrop, CdkDropList, CdkDropListGroup, DragDropModule, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatBadgeModule } from '@angular/material/badge';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, Validators,FormControl,ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { TaskComponent } from '../task/task.component';
import { CustCardsComponent } from '../cust-cards/cust-cards.component';
import { Subject } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
	selector: 'app-drag-drops',
	standalone: true,
	imports: [MatIconModule,MatMenuModule,CommonModule,FormsModule,LayoutModule,ScrollingModule,
		CdkDropListGroup, CdkDropList, CdkDrag,	CdkStepperModule,DragDropModule,MatBadgeModule,MatDialogModule,NgSelectModule,TaskComponent,CustCardsComponent,MatTooltipModule,ReactiveFormsModule],
	providers:[PipelineService,LoginService],
	templateUrl: './drag-drops.component.html',
	styleUrl: './drag-drops.component.scss',
	
})
export class DragDropsComponent implements OnInit {
	private rating: number = 1;
	private color: string = 'bg-blue-600';
	public board: any = Board;
	public boardid: any;
	public user: any;
	item:any;
	@Input() userid: any; // 
	public currentEditedTask:number = 0;
	public tasklis:any=[];
	@Input() column:any
	@Input() task:any;
	 colid: any;
	@Output() private ontaskcomplete = new EventEmitter();
	opportunity: string = 'opportunity'; // Add this line
	isLeadFlag = false;
	isOpportunityFlag = false;
	isQuotationFlag = false;
	showInputFlag = false;
	columnText : any = '';
	opportunityFlag = false
	wonFlag = false;
	statusArr : any[] = [];
	taskActiveId : any = null;
	subStatusAddId : any = null;
	substatusArr : any[] = [];
	boards: any = { columns: [] }; // Your board structure containing columns and tasks
	statusList : any[] = [];
	editTaskId = 0;

    constructor(
		private pservice: PipelineService,
		private loginservice: LoginService,
		private dialog: MatDialog,
		private cdrf : ChangeDetectorRef
	) { }


	subStatus = new FormControl('', [Validators.required]);

	ngOnInit() {
		this.user = this.loginservice.getUserdata()
		this.getTaskList();
		this.getSubTaskList();
		this.getBoards(this.user.data.id);
		this.pservice.taskEditEmitter.subscribe((data:any)=>{
			this.editTaskId = 0;
			setTimeout(() => {
				this.editTaskId = data?.tid;
			})
			if(data){
				this.statusArr.forEach((item:any)=>{
					if(item?.name == data?.task?.status && data?.task?.task_stage == ''){
						this.taskActiveId = item?.id
					}
				})
				this.substatusArr.forEach((item:any)=>{
					if(item?.sub_status == data?.task?.task_stage){
						this.taskActiveId = item?.id
					}
				})
				for( let column of this.board.columns ){
					if (column.id == data.colid && data.tid != 0){
						column.mstatus = true;
					}
				}
			}
		})

			
	}

	getTaskList(){
		this.pservice.getTaskList().subscribe((res:any)=>{
			if(res?.message){
				this.statusArr = res.message;
			}
		})
	}
	getBoards(userid: number) {
		this.pservice.getBoard(userid).subscribe(
			(data: any) => {
				this.board = new Board(data[0]['id'], data[0]['name'], [])
				this.boardid = data[0]['id'];
				this.getColumns(this.boardid);
			}
		)
	}

	getColumns(boardid: number) {
		this.pservice.getColumns(boardid).subscribe(
			(cdata: any) => {
				this.board.columns = [];
				for (let cdat of cdata) {
					let col = new Column(cdat['id'], cdat['name'], [], false)
					this.board.columns.push(col)
					this.getTasks(col)
				}
			}
		)
	}

	getTasks(column: Column) {
		this.pservice.getTask(column.id,this.user?.teamid).subscribe(
			(data: any) => {
				for (let task of data) {
					this.pservice.getActivityCount(this.user?.data?.id,task?.id).subscribe((res:any)=>{
						let status = task.status 
						let image=task.contact.image
						let activityCount = res?.Activity_Count
						let ntask = new Task(
							task['id'],
							task['customer'],
							task['creator_id'],
							task['contact_id'],
							task['column_id'],
							task['name'],
							task['budget'],
							task['appname'],
							task['rating'],
							task['color'],
							task['ciImage'] = image,
							task['status'],
							task['email'],
							task['task_stage'],
							task['activityCount'] = activityCount
						)
						column.tasks.push(ntask)
						// console.log(column.tasks,'ressss')
					})
				}
			}
		)
	}

	togglecolmenu(column: Column,id:any) {
		if(this.taskActiveId == id){
			this.taskActiveId = null;
		}
        this.taskActiveId = id;
		this.editTaskId = 0;
		column.mstatus = !column.mstatus;
		let data = {"tid":0,"colid":column.id}
		// this.pservice.taskEditEmitter.emit(data)
	}

	showcolstatus(column: Column) {
		if (column.mstatus) {
			return "block"
		} else {
			return "hidden"
		}
	}

	removeColumn() {
		if (this.board.columns.length) {
			this.board.columns.pop();
		}
	}

	delColumn(column:any){
		const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
			data: {
			  title: 'Confirm Remove Column',
			  message: 'Are you sure, you want to remove a column: ' + column.name
			}
		  });
		  confirmDialog.afterClosed().subscribe(result => {
			if (result === true) {
			this.pservice.delOneColumn(column.id).subscribe((data:any)=>{
				// console.log('deletedd',data)
			this.getBoards(this.user.data.id);
			})
	}
   });
	}

	addColumnToBoard(name: string) {
		this.pservice.addColumns(this.boardid, name).subscribe(
			(data: any) => {
				this.getBoards(this.user.data.id);
			}
		)
	}
	boardchange(name:any,id:any,bid:any)
	{
		let data_c={
			
			"name":name,
			"board_id":bid
		}
		this.pservice.editColumn(data_c,id).subscribe((reponse)=>{
		
		   }); 
	}
	reloadboard(event:any){
		this.cdrf.detectChanges()
		this.getBoards(this.user.data.id);
		this.ontaskcomplete.emit(event);
	}

	addSubStatus(id:any,name:any){
		if (this.subStatusAddId === id) {
			this.subStatusAddId = null;
		} else {
			this.subStatusAddId = id;
		}
	}

	createSubStatus(id:any){
		if(this.subStatus.valid){
			const status:any = this.subStatus?.value;
			const formData: FormData = new FormData();
			formData.append('task_status_id', id);
			formData.append('sub_status', status);
			formData.append('user_id', this.user?.data?.id);
            this.pservice.createSubStatus(formData).subscribe((res:any)=>{
				if(res?.message){
					this.subStatusAddId = null;
					this.subStatus.reset('')
					this.getSubTaskList()
				}
			})
		}else{
			return
		}
	}

	getSubTaskList(){
		this.substatusArr = [];
		this.pservice.getSubStatusList(this.user?.data?.id).subscribe((res:any)=>{
			if(res?.message){
				this.substatusArr = res?.message;
			}
		})
	}

	
	onDrop(event: CdkDragDrop<any[]>, targetStatus: any, targetColumn: any): void {
		this.statusList = [];
		const taskDetails = event.previousContainer.data[event.previousIndex] as any;
		if(this.statusArr?.length){
		   var i :any = 0;
		    this.statusArr.forEach((item:any)=>{
				i = i + 1;
				this.statusList.push({order:i,name:item?.name})
				if(this.substatusArr?.length){
					this.substatusArr.forEach((subItem:any)=>{
						if(subItem?.task_status == item?.name){
							i = i + 1;
						this.statusList.push({order:i,name:subItem?.sub_status})
						}
					})
				}
			})
		}
			var currentOrder : any = '';
			var targetOrder : any = '';
			if(targetStatus?.name){
				currentOrder = '';
				this.statusList.forEach((item:any)=>{
					if(item.name == targetStatus?.name){
						currentOrder = item?.order;
					}
				})
			}else if(targetStatus?.sub_status){
				currentOrder = '';
				this.statusList.forEach((item:any)=>{
					if(item.name == targetStatus?.sub_status){
						currentOrder = item?.order;
					}
				})
			}
			if(taskDetails?.status && taskDetails?.task_stage == ""){
				targetOrder = '';
				this.statusList.forEach((item:any)=>{
					if(item.name == taskDetails?.status){
						targetOrder = item?.order;
					}
				})
			}else if(taskDetails?.status && taskDetails?.task_stage){
				targetOrder = '';
				this.statusList.forEach((item:any)=>{
					if(item.name == taskDetails?.task_stage){
						targetOrder = item?.order;
					}
				})
			}
			if(currentOrder < targetOrder ){
				return;
			}
			if (event.previousContainer === event.container) {
			  
				const previousIndex = event.previousIndex;
				const currentIndex = event.currentIndex;
			} else {
				if(targetStatus?.name){
			    taskDetails.status = targetStatus?.name;
				taskDetails.task_stage = '';
				this.pservice.changestatus(taskDetails?.id,this.user?.data?.id,targetStatus?.name,targetStatus?.name).subscribe((response)=>{
					if(response){
						this.pservice.ContactleadCountUpdateView(taskDetails.contact_id,taskDetails?.id,targetStatus?.name,this.user?.data?.id).subscribe((result:any)=>{
						})
						this.pservice.change_task_status(taskDetails?.id,'',this.user?.data?.id).subscribe((response)=>{
						});
						this.pservice.TasksToOppertunityView(taskDetails?.id,this.user?.data?.id).subscribe((res:any)=>{
						})
					}
				})
			}else if(targetStatus?.sub_status){
				taskDetails.status = targetStatus?.task_status;
				taskDetails.task_stage = targetStatus?.sub_status;
				this.pservice.changestatus(taskDetails?.id,this.user?.data?.id,targetStatus?.task_status,targetStatus?.sub_status).subscribe((reponse)=>{
					if(reponse){
						this.pservice.ContactleadCountUpdateView(taskDetails.contact_id,taskDetails?.id,targetStatus?.task_status,this.user?.data?.id).subscribe((result:any)=>{
						})
						this.pservice.change_task_status(taskDetails?.id,targetStatus?.sub_status,this.user?.data?.id).subscribe((response)=>{
						});
						this.pservice.TasksToOppertunityView(taskDetails?.id,this.user?.data?.id).subscribe((res:any)=>{
						})
					}
				})
			}
    }
	}
	RemoveSubStatus(subitem:any){
		this.pservice.DeleteSubTask(subitem).subscribe((result:any)=>{
			this.getSubTaskList();
		})
	}

	

}
