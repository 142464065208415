import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { QuotationListService } from '../../services/quotation-list.service';
import { ContactFormService } from '../../services/contact-form.service';
import { Router } from '@angular/router';
import { EditLeadService } from '../../services/edit-lead.service';
import { CompanyservService } from '../../services/companyserv.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { ToastrService } from 'ngx-toastr';
import e from 'express';
import { MatSelectModule } from '@angular/material/select';


import { PipelineService } from '../../teamleader/drag-drops/pipeline.service';

@Component({
  selector: 'app-opportunity-add-edit',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, MatDividerModule, CommonModule, MatIconModule, RouterModule, NgxFileDragDropModule,MatSelectModule],
  providers: [LoginService, QuotationListService, ContactFormService, EditLeadService, CompanyservService],
  templateUrl: './opportunity-add-edit.component.html',
  styleUrl: './opportunity-add-edit.component.scss'
})
export class OpportunityAddEditComponent {
  public user: any;
  // opportunityForm!: FormGroup;
  lid: any;
  contactdetails: any;
  opportunityDetails: any;
  leadlist: any = [];
  leadDetails: any;
  salesReplist: any;
  partnerSalesReplist: any;
  organizationlist: any;
  contactlist: any;
  oid: any;
  showOption: any;
  selectedImages: any;
  fileName: any;
  selectedUniqueId : any
  oppertunitystatus: any=[];
  submitted = false;
  typeArr = ['Software Sales','Software Development','Implementation Services','Managed Services','Assessment Services','Others'];
	productArr : any = [];

  constructor(private formBuilder: FormBuilder,private cdr: ChangeDetectorRef, private companyserv: CompanyservService, private toaster:ToastrService, private leadserv: EditLeadService, private router: Router, private contactservice: ContactFormService, private qutationserv: QuotationListService, private route: ActivatedRoute, private loginservice: LoginService,private pservice:PipelineService) { }



  ngOnInit() {
    this.user = this.loginservice.getUserdata();
		this.opportunityForm.get('cloudvice_sales_rep')?.setValue(this.user?.data?.first_name)
		this.getProductList()

    this.getOrganisationList();
    this.getLeadlist();
    this.editOpportunity();
    // this.editForm();
    this.salesrepList();
    this.getpartnerSalesrepList();
    this.getcontactwithoutCustomer();
    this.getoppertunityStatus('opportunity');
    this.route.params.subscribe((params: any) => {
      if (params['id'] != undefined) {
        this.lid = params['id']
      }
    })

    this.route.params.subscribe((params: any) => {
      if (params['id1'] != undefined) {
        this.oid = params['id1']
      }
    })
    if(this.lid != ''){
      this.getLeadData(this.lid, '');
    }
    this.user = this.loginservice.getUserdata()
    if (this.oid !== '') {
      this.getOppDetails();

    }
    
  }
  opportunityForm: FormGroup = this.formBuilder.group({
    opportunity_name: ['', [Validators.required]],
    lead_unique_id: ['', [Validators.required]],
    customer: ['', [Validators.required]],
    contact: ['', [Validators.required]],
    description: ['',],
    oppertunity_revenue: ['',[Validators.required]],
    currency: ['USD', [Validators.required]],
    type: ['', [Validators.required]],
    products_id: [[], [Validators.required]],
    oppertunity_stage: [''],
    forecast_probablity: ['',[Validators.required]],
    forecast_period: ['',[Validators.required]],
    cloudvice_sales_rep: [''],
    partner_sales_rep: [''],
    comments: ['',],
    referal: ['',],
    file: [null],

  },);

  getLeadlist() {
    this.contactservice.getLead(this.user.data.id, '').subscribe(
      (leadlist: any) => {
        this.leadlist = leadlist?.data?.tasks;
  }
  );
  }

  getProductList(){
    this.leadserv.getProductList().subscribe((res :any)=>{
 if(res?.data){
   this.productArr = res?.data;
 }
  })
}


  getLeadData(lid: any, type: any): void {
    if (type == 'from_page') {
      const selectedOption = lid.target as HTMLSelectElement;
      this.selectedUniqueId = selectedOption.selectedOptions[0].getAttribute('data-unique-id');
      lid = lid.target?.value;
    }
  
   
    this.contactservice.getleaddetails(lid).subscribe(
      (response: any) => {
        
  
        if (response?.data?.lead) {
          this.leadDetails = response.data.lead;
            this.opportunityForm.patchValue({customer: this.leadDetails?.customer,
            contact: this.leadDetails?.contact,
            lead_unique_id: this.leadDetails?.id,
            opportunity_name: this.leadDetails?.id,
            // forecast_period: this.leadDetails?.forecast_period,
            partner_sales_rep: this.leadDetails?.partner_sales_rep,
            // cloudvice_sales_rep: this.leadDetails?.cloudvice_sales_rep,
            lead_description: this.leadDetails?.lead_description,
            type: this.leadDetails?.type,
            products_id: this.leadDetails?.products,
          });
            // this.opportunityForm.get('lead_description')?.setValue('klllllllllllll')
          
          this.cdr.detectChanges();
        } else {
        }
      },
      (error: any) => {
      }
    );
  }

  getoppertunityStatus(status:any){
    this.contactservice.getoppertunitystatus(status,this.user.data.id).subscribe((result:any)=>{
      this.oppertunitystatus=result?.data;
    })
  }
  salesrepList() {
    this.leadserv.salesRepList().subscribe(
      (salesReplist: any) => {
        this.salesReplist = salesReplist.data
       });
  }

  getpartnerSalesrepList() {
    this.leadserv.partnersalesRepList().subscribe(
      (partnerSalesReplist: any) => {
        this.partnerSalesReplist = partnerSalesReplist.data
      });
  }

  getOrganisationList() {
    this.companyserv.getCompany(this.user.data.id, "").subscribe(
      (organizationlist: any) => {
        this.organizationlist = organizationlist.company_list
      //  this.editForm();
        
      });
  }

  getcontactwithoutCustomer() {
    this.contactservice.getContactwithoutCus(this.user.data.id, '').subscribe(
      (contactlist: any) => {
        this.contactlist = contactlist.data
      });
  }

  onSubmit() {
    this.opportunityForm.markAllAsTouched();
    this.submitted = true;
    console.log(this.opportunityForm,'opp formmm')
    let opportunityStage = this.opportunityForm.value.oppertunity_stage || 'Opportunity';
    const postData = {
      oppertunity_name: this.opportunityForm.value.opportunity_name,
      lead_unique_id: this.selectedUniqueId,
      lead : this.opportunityForm.value.lead_unique_id,
      customer: this.opportunityForm.value.customer,
      contact: this.opportunityForm.value.contact,
      oppertunity_description: this.opportunityForm.value.description,
      oppertunity_revenue: this.opportunityForm.value.oppertunity_revenue,
      currency: this.opportunityForm.value.currency,
      type: this.opportunityForm.value.type,
      products_id: this.opportunityForm.value.products_id,
      oppertunity_stage: opportunityStage,
      forecast_period: this.opportunityForm.value.forecast_period,
      forecast_probablity: this.opportunityForm.value.forecast_probablity,
      cloudvice_sales_rep: this.user?.data?.id,
      partner_sales_rep: this.opportunityForm.value.partner_sales_rep,
      comments: this.opportunityForm.value.comments,
      referal: this.opportunityForm.value.referal,
      creator_id: this.user.data.id,
      file: this.selectedImages,

    }
    if (this.opportunityForm.valid) {
      this.qutationserv.addOpportunity(postData).subscribe((response: any) => {
        if (response.status_code === 201) {
          this.pservice.changestatus(this.lid,this.user.data.id,opportunityStage,'').subscribe((result:any)=>{
           
          })
          this.toaster.success(response.message,'success');
          this.router.navigate(['/executive/opportunity_list'])
        }
      })
    }else{
      return;
    }
  }

  getOppDetails() {
    this.qutationserv.getOppertunitydetails(this.oid).subscribe((response: any) => {
      this.opportunityDetails = response?.data?.Opportunity
      console.log(this.opportunityDetails,'opp detailsss')
      if (this.opportunityDetails?.file != null) {
        const parts = this.opportunityDetails?.file?.split('/');
        // Get the last part which is the file name
        this.fileName = parts[parts?.length - 1]
      }
      this.customerChange(this.opportunityDetails?.customer,'from_ts');
      this.opportunityForm.get('opportunity_name')?.disable()
      this.opportunityForm.get('contact')?.disable()
      // this.getLeadData(this.opportunityDetails?.oppertunity_name, '')
      this.editForm();
      this.changePeriod()

    })
  }

  editForm() {
    this.opportunityForm.reset({
      opportunity_name: this.opportunityDetails?.oppertunity_name ,
      lead_unique_id: this.opportunityDetails?.lead,
      // lead : this.opportunityForm.value.lead_unique_id,
      customer: this.opportunityDetails?.customer,
      contact: this.opportunityDetails?.contact,
      description: this.opportunityDetails?.oppertunity_description,
      oppertunity_revenue: this.opportunityDetails?.oppertunity_revenue,
      currency: this.opportunityDetails?.currency,
      type: this.opportunityDetails?.type,
      products_id: this.opportunityDetails?.products,
      oppertunity_stage: this.opportunityDetails?.oppertunity_stage,
      forecast_probablity: this.opportunityDetails?.forecast_probablity,
      forecast_period: this.opportunityDetails?.forecast_period,
      cloudvice_sales_rep: this.user?.data?.first_name,
      partner_sales_rep: this.opportunityDetails?.partner_sales_rep,
      comments: this.opportunityDetails?.comments,
      referal: this.opportunityDetails?.referal
    })
  }

  monthArr: any = []
  changePeriod() {
    if (this.opportunityForm?.value?.forecast_probablity == '40' || this.opportunityForm?.value?.forecast_probablity == '50' || this.opportunityForm?.value?.forecast_probablity == '60' || this.opportunityForm?.value?.forecast_probablity == '70') {
      this.monthArr = [1, 2, 3, 4, 5]
    } else {
      this.opportunityForm.get('forecast_period')?.setValue('')
      this.monthArr = [1, 2]
    }
  }

  editOpportunity() {
    this.opportunityForm.markAllAsTouched();
    const postData = {
      oppertunity_name: this.opportunityForm.value.opportunity_name,
      lead_unique_id: this.selectedUniqueId,
      lead : this.opportunityForm?.value?.lead_unique_id,
      customer: this.opportunityForm.value.customer,
      contact: this.opportunityForm.value.contact,
      oppertunity_description: this.opportunityForm.value.description,
      oppertunity_revenue: this.opportunityForm.value.oppertunity_revenue,
      currency: this.opportunityForm.value.currency,
      type: this.opportunityForm.value.type,
      products_id: this.opportunityForm.value.products_id,
      oppertunity_stage: this.opportunityForm.value.oppertunity_stage,
      forecast_period: this.opportunityForm.value.forecast_period,
      forecast_probablity: this.opportunityForm.value.forecast_probablity,
      cloudvice_sales_rep: this.user?.data?.id,
      partner_sales_rep: this.opportunityForm.value.partner_sales_rep,
      comments: this.opportunityForm.value.comments,
      referal: this.opportunityForm.value.referal,
      creator_id: this.user.data.id,
      file: this.selectedImages,
    }
    if (this.opportunityForm.valid) {
      this.qutationserv.editOpportunity(this.oid, postData).subscribe((response: any) => {
        if (response.status_code === 200) {
          this.toaster.success(response.message,'success');
          this.router.navigate(['/executive/opportunity_list']);
        }
      })
    }
  }

  onFileChange(event: any) {
    if(event != null && event.length > 0){
    const files: FileList = event;
    const file: File = files[0]; // Access the file object directly
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.selectedImages = e.target.result;
    };
    reader.readAsDataURL(file);

    if(this.opportunityDetails?.file != undefined && this.opportunityDetails?.file !== null){
      this.fileName = '';
      this.opportunityDetails.file = null;
    }
    }
  }

  customerChange(event: any,type:any) {
    // console.log('change',this.leadlist)
    this.leadlist = [];
    var leadid = event?.target?.value;
    if(type == 'from_ts'){
      leadid = event
    }
    this.qutationserv.getLeadsListInOpportunity(this.user.data.id, leadid).subscribe((res: any) => {
      if (res?.data) {
        this.leadlist = res?.data;
      }

    })

  }

  downloadFile() {
    if (this.opportunityDetails?.file != null) {
      const fileUrl = this.opportunityDetails?.file // Change this to your file URL
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = fileUrl; // Change the file name if needed
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

  }

}
