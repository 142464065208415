import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QuotationListService } from './quotation-list.service';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrService } from 'ngx-toastr';

declare var XLSX: any;

@Component({
  selector: 'app-quotation-lists',
  standalone: true,
  imports: [CommonModule,RouterModule,MatDividerModule,NgxPaginationModule,DatePipe],
  templateUrl: './quotation-lists.component.html',
  styleUrl: './quotation-lists.component.scss',
  // encapsulation: ViewEncapsulation.None
})
export class QuotationListsComponent implements OnInit {
  data: any=[]
  p:number=1;
  expiration:any;
  public user: any;
  exportsdetailes: any;
  constructor(private dialog: MatDialog,private quotationlistservice: QuotationListService,private loginservice: LoginService,
    private router: Router,private toaster:ToastrService,) { }
    ngOnInit() {
      this.user = this.loginservice.getUserdata()
      this.getquotation();

      
    }
  
  getquotation(){
    this.quotationlistservice.getQuotation(this.user.data.id).subscribe(
      
      (data: any) =>{this.data = data
        // console.log(this.data,'dataatatatat')
      
      });
  }
  statusupdate(id:any,res:any)
  {
    if(res=='Send')
    {
    let data_val={
      "status":'Accept'
    }
    this.quotationlistservice.Editquotation(data_val,id).subscribe((reponse:any) => {
    
     });
    }
    if(res=='Accept')
    {
    let data_val={
      "status":'Reject'
    }
    this.quotationlistservice.Editquotation(data_val,id).subscribe((reponse:any) => {
     
     this.getquotation();
     });
    }
  }

  Editquotation(id:any)
  {
    this.router.navigate(['teamleader/update-quotation/',id]);
  }
  deletequotation(list:any){
     const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Remove Quotation',
        message: 'Are you sure, you want to remove a quotation: ' + list.contact_name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
		this.quotationlistservice.delOneQuotation(list.id).subscribe((data)=>{
      this.getquotation()
    
		})
	}
});

}
  generate(id:any){
    this.router.navigate(['teamleader/quotation-generate/',id]);
  }

  generateinvoice(id:any)
  {
    this.router.navigate(['teamleader/invoice-generate/',id]);
  }

  ExportQuotationDataView(){
    this.quotationlistservice.getexport(this.user.data.id).subscribe((result:any)=>{
      
      this.exportsdetailes=result;
      const worksheet = XLSX.utils.json_to_sheet(result.message);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, 'table-data.xlsx');
    
    if(result?.status==false){
      this.toaster.error(result.message,'error')
    }

    })
  }
}
