<button class="staricon"
  mat-icon-button  
  *ngFor="let ratingId of ratingArr; index as i" 
  [id]="'star_'+i" 
  (click)="onClick(i+1)" 
  style="height: 3vh !important; width: 0vh !important; margin-left: 0vh !important;">
  <mat-icon [ngClass]="{'filled': i < starCount, 'unfilled': i >= starCount}">
    {{ showIcon(i) }}
  </mat-icon>
</button>

<mat-error *ngIf="starCount == null || starCount == 0">
  Star count is <strong>required</strong> and cannot be zero
</mat-error>
