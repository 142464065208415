<h1 mat-dialog-title style="display: flex;justify-content: center; margin-top: 3vh !important;">{{data.title}}</h1>
<div mat-dialog-content>
  {{data.message}}
</div>
<div style=" display: flex; margin:20px; justify-content: center;">
  <input style=""class="focus:outline-none cursor-pointer text-white bg-red-700 hover:bg-red-800  font-medium rounded-lg text-sm px-4 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 cursor-pointer" type="button" value="Confirm" 
  [mat-dialog-close]="true">
  <input type="button" value="Cancel" class="text-gray-900 bg-white border  cursor-pointer border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white 
  dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" 
  [mat-dialog-close]="false">
</div>
