import { Component, NgModule, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule, RouterOutlet } from '@angular/router';
import { MainService } from '../../services/main-service';
import { LoginService } from '../../services/login.service';
import { ConfirmedValidator } from '../../confirmed.validator';
import { SuccessComponent } from '../../success/success.component';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-member',
  standalone: true,
  imports: [ReactiveFormsModule,CommonModule,MatDividerModule,RouterModule,MatFormFieldModule,MatDatepickerModule,MatNativeDateModule,MatInputModule,MatIconModule],
  templateUrl: './add-member.component.html',
  styleUrl: './add-member.component.scss'
})
export class AddMemberComponent implements OnInit {
  registerForm!: FormGroup;
  submitted = false;
  public user:any
  private cid:any
  public isEdit:boolean = false;
  mainservice: any;
  roles:any[]=[]
  constructor(private formBuilder: FormBuilder,private dialog: MatDialog, private router: Router,private route: ActivatedRoute,private mservice:MainService, private loginservice:LoginService,private toaster:ToastrService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.form()
    this.route.paramMap.subscribe(params => {
      this.cid = params.get('id')
     
      if(this.cid != null){
        
          this.isEdit = true;
      this.loginservice.getOneMember(this.cid).subscribe((data:any)=>{        
          this.registerForm.setValue({
            name:data[0].user.first_name,
            address:data[0].address,
            role:data[0].role,
            phone:data[0].phone,
            email:data[0].user.email,
            password:data[0].user.password,
            confirm_password:data[0].user.password

          })
      })
    
  }
  else
  {
      this.isEdit = false;
  this.registerForm.reset();
  }
    });

  this.listRole()
  }
  form() {
	  this.registerForm = this.formBuilder.group({
		  name: ['', Validators.required],
		  email: ['', [Validators.required, Validators.email]],
      phone:['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
		  role:['',Validators.required],
      address:[''],
		  password: ['', [Validators.required]],
		  confirm_password: ['', [Validators.required]]
	  
  }, { 
	validator: ConfirmedValidator('password', 'confirm_password')
  }
	   );
	  
  }
  get f() { return this.registerForm.controls; }

  onSubmit(colid:any) {
    this.submitted = true;
      if (this.registerForm.invalid) {
          return;
          
      } 
      
      // const formdata = new FormData();
      
     // this.registerForm.value['image'] = apiurl+this.img.name
      // stop here if form is invalid
      // 
     ;

     let data_r={
      "username":this.registerForm.value.email,
      "first_name":this.registerForm.value.name,
      "email":this.registerForm.value.email,
      "password":this.registerForm.value.password,
      "profile": {
        "phone":this.registerForm.value.phone,
        "address":this.registerForm.value.address,
        "role":this.registerForm.value.role,
        "creator_id":this.user.data.id,
      }
      
     }
     this.loginservice.addProfile(data_r).subscribe((response:any)=>{

     
        const confirmDialog = this.dialog.open(SuccessComponent, {
          data: {
				  title: 'Success',
				  message: 'Added Succesfully!'
				}
			  })
			  confirmDialog.afterClosed().subscribe(result => {
          if (result == true) {
            this.router.navigate(['superAdmin/admins'])
          }


		    });
      },(error:any)=>{
        this.toaster.error(error.error.email[0])
         }
        ); 

  }
//   editcontact(cid:number){
//     this.registerForm.value.salesperson_id=this.user.data.id
// this.mservice.editConfig(this.registerForm.value,cid).subscribe((reponse:any) => {
//   //console.log(reponse);
      
// });
// }
    onReset() {
      this.submitted = false;
      this.registerForm.reset();
  }
   

  listRole(){
    this.loginservice.listRoleMembers(this.user.data.id).subscribe((response:any)=>{
      this.roles = response ?.data?.roles;
  })
  }
  
  edit(){
    this.submitted = true;
      if (this.registerForm.invalid) {
          return;
          
      } 
      
    let data_r={
      "username":this.registerForm.value.email,
      "first_name":this.registerForm.value.name,
      "email":this.registerForm.value.email,
      "password":this.registerForm.value.password,
      "profile": {
        "phone":this.registerForm.value.phone,
        "address":this.registerForm.value.address,
        "role":this.registerForm.value.role,
        "creator_id":this.user.data.id,
      }
      
     }
     this.loginservice.editmember(data_r,this.cid).subscribe((reponse:any) => {
      const confirmDialog = this.dialog.open(SuccessComponent, {

				data: {
				  title: 'Success',
				  message: 'Edited Succesfully!'
				}
			  })
			  confirmDialog.afterClosed().subscribe(result => {
          if (result == true) {
            this.router.navigate(['superAdmin/admins'])
          }


		    });
          
    });

  }


}
