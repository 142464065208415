import { Component, OnInit } from '@angular/core';
import { EditLeadService } from './edit-lead.service';
import { ActivatedRoute, Params, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { MatIconModule } from '@angular/material/icon';
@Component({
  selector: 'app-edit-leads',
  standalone: true,
	imports: [ReactiveFormsModule,MatDividerModule,MatFormFieldModule,MatDatepickerModule,MatNativeDateModule,RouterModule,MatInputModule,MatSelectModule,CommonModule,MatIconModule,NgxFileDragDropModule],
  templateUrl: './edit-leads.component.html',
  styleUrl: './edit-leads.component.scss'
})
export class EditLeadsComponent implements OnInit {
  registerForm!: FormGroup;
  list:any=[];
 // public id: any;
  salesteam: any[] = [];
  users:any=[];
  public user: any;
  private routeSub: any;
  constructor(private editleadservice: EditLeadService,private route: ActivatedRoute,private loginservice: LoginService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.routeSub = this.route.params.subscribe((params: Params): void => {
      const id = params['id'];
      this.getcontacts(id)
  });
 
  this.getuser()
}
getuser(){

this.loginservice.getUser().subscribe(
  (users: any) => this.users = users
  )
}

  getcontacts(id: number){
  
    this.editleadservice.editContacts(id).subscribe(
      (list: any) => this.list = list
      );
  }
  private selectedLink: string="Individual";        
  
    setContactType(e: string): void   
    {  
        this.selectedLink = e;     
    }  
    
    isSelected(name: string): boolean   
    {  
        if (!this.selectedLink) { // if no radio button is selected, always return false so every nothing is shown  
            return false;  
        }  
            return (this.selectedLink === name); // if current radio button is selected, return true, else return false  
    }  

    name = '!!!';
    viewMode = 'tab1';


    
    onSubmit(){

    }

}
