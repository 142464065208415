import { Component, OnInit } from '@angular/core';
import { QuotationListService } from '../../services/quotation-list.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CompanyservService } from '../../services/companyserv.service';
import { ContactFormService } from '../../services/contact-form.service';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTableModule } from '@angular/material/table';
import { NgxPaginationModule } from 'ngx-pagination';


@Component({
  selector: 'app-quotation-list',
  standalone: true,
  imports: [ReactiveFormsModule,FormsModule,MatDividerModule,CommonModule,MatIconModule,RouterModule,MatMenuModule,NgSelectModule,MatTableModule,NgxPaginationModule],
  providers : [QuotationListService,LoginService,CompanyservService,ContactFormService],
  templateUrl: './quotation-list.component.html',
  styleUrl: './quotation-list.component.scss'
})
export class QuotationListComponent {
  constructor(private loginservice: LoginService,private contactservice: ContactFormService,private companyserv:CompanyservService,private quotationserv:QuotationListService,private dialog: MatDialog) { }
  user:any;
  userid:any;
  p:number=1;
  isLoading: boolean = true; // Flag to indicate loading state
  noDataMessageShown: boolean = false; // Flag for showing no data message
  quotationlist:any[]=[]
  organizationlist:any;
  contactlist:any;
  customerFilter= null;;
  contactFilter= null;
  typeArr  = [{id: 'HCM', name : 'HCM'},{id: 'ERP', name : 'ERP'},{id: 'OCI', name : 'HCM'}]
  
  // stageFilter="";
  typeFilter= null
  rangeFilter2=""
  searchControl=""
  advfilter :any={
    customer:this.customerFilter,
    contact:this.contactFilter,
    // oppertunity_stage:this.stageFilter,
    type:this.typeFilter,
    // oppertunity_revenue_lte:this.rangeFilter2,
    search:this.searchControl
  }
  displayedColumns: string[] = ['quote_unique_id','quotationName','customer','QuoteRevenue','approved_amount','type','contact','quote_stage','salesRep','partnerSalesRep','action' ];


  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.userid=this.user.data.id
    this.getQuotation('')
    this.getOrganisationList()
    this.getcontactwithoutCustomer(this.userid,'')
  }

  getQuotation(filter:any){
    // this.quotationserv.getQuotation( this.userid,filter).subscribe(
    //   (quotationlist: any) =>{ 
    //     this.quotationlist = quotationlist.data
    //     this.isLoading=false;
    //    }
    //   );
    
    
    this.quotationserv.getQuotation(this.userid,filter).subscribe(
      (quotationlist: any) => {
        this.quotationlist=quotationlist.data;
        console.log(this.quotationlist,'listssssquotattt')
        this.isLoading = false; 
        
        if (this.quotationlist.length === 0) {
          this.noDataMessageShown = true;
        } else {
          this.noDataMessageShown = false; 
        }
      },
      (error) => {
        console.error('Error fetching activity reports', error);
        this.isLoading = false; // Set loading to false even on error
        this.noDataMessageShown = true; // Optionally show no data message on error
      }
    );
  }
  

  getOrganisationList(){
    this.companyserv.getCompany(this.user.data.id,'').subscribe(
      (organizationlist: any) =>{ 
        this.organizationlist = organizationlist.company_list
    });    
  }

  getcontactwithoutCustomer(userid: number,search:any){
    this.contactlist = []
    this.contactservice.getContactwithoutCus(userid,search).subscribe(
      (contactlist: any) => {
        contactlist.data.forEach((item:any)=>{
          item.name = item?.first_name + ' ' + item?.last_name
        })
        this.contactlist = contactlist.data
        
      });   
  }

  filter(){
    this.advfilter={
     customer:this.customerFilter,
     contact:this.contactFilter,
    //  oppertunity_stage:this.stageFilter,
     type:this.typeFilter,
    //  oppertunity_revenue_lte:this.rangeFilter2,
     search:this.searchControl

   }
  
   this.getQuotation(this.advfilter)
 }

 clearfilter(){
  this.customerFilter= null;
  this.contactFilter= null;
  // this.stageFilter=""
  this.typeFilter= null;
  // this.rangeFilter2=""
  this.advfilter={
    customer:"",
    contact:"",
    // oppertunity_stage:"",
    type:"",
    // oppertunity_revenue_lte:"",
    search:this.searchControl

  }
  
  this.getQuotation(this.advfilter)
}


  deleteQuotation(list:any){

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Remove Quotation',
        message: 'Are you sure, you want to remove a Quotation: ' + list.quote_name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
		this.quotationserv.deleteQuotation(list.id).subscribe((data:any)=>{
      this.getQuotation('')
    
		})
	}
});

}

customSearchFn(term: string, item:any) {
  item.name = item.name.replace(',','');
  term = term.toLocaleLowerCase();
  return item.name.toLocaleLowerCase().indexOf(term) > -1;
}

onCustomerFilterChange(value: any) {
 this.customerFilter = value // Output the selected value to the console;
 this.getcontactlist(this.user?.data?.id,value)

}

getcontactlist(uid : any,cuid :any){
  this.contactlist = []
  this.contactservice.getContact(uid,cuid).subscribe(
    (contactdetails: any) => {
      contactdetails.data.forEach((item:any)=>{
        item.name = item?.first_name + ' ' + item?.last_name
      })
      this.contactlist = contactdetails.data
    
    }
    );
  }

getStatusClass(status: string): string[] {
  switch (status) {
    case 'new':
      return ['bg-blue-100', 'text-blue-800'];
    case 'open':
      return ['bg-green-100', 'text-green-800'];
    case 'closed':
      return ['bg-red-100', 'text-red-800'];
    default:
      return ['bg-gray-200', 'text-gray-800']; // or any default classes
  }
}

}
