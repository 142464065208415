<div class="max-w w-full space-y-8  p-10 " style="height:86vh !important; overflow-y: auto !important;">
    <div class="flex flex-col sm:flex-row items-center mb-6">
      <h2 class="font-semibold text-lg mr-auto">Invoice</h2>
      <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>
    <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
        <button (click)="captureScreen()" class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
          <span>Download</span>
          <svg class="fill-current ml-2 -mr- w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
        </button>
        <button type="button" (click)="sendnow(list.id)" class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
          <span>Send</span>
          <svg aria-hidden="true" class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
         </button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="mx-20 my-20 p-16 bg-white-100 border border-gray-200" style="max-width: 1000px;" id="contentToConvert" >
        <div class="flex items-center justify-between mb-8  px-3">
          <div>
            <span class="text-2xl">Invoice </span>Ref No: REFIC{{list.id}}<br />
            <span>Date</span>:  {{date}}<br />
            <span>Expiry Date</span>: {{expiration}}<br />
          </div>
          <div class="text-right">
            <img src="{{configlist.logo}}" class="size" />
          </div>
        </div>
        <div class="flex justify-between mb-8 px-3">
          <div>
            {{configlist.name}}<br>
            {{configlist.address}}<br>
            {{configlist.email}}<br />
            {{configlist.phone}}<br>
            GST No. {{configlist.gst_no}}
          </div>
          <div class="text-right">
            {{list.contact.name}}<br />
            {{list.contact.address1}}<br />
           {{list.contact.city}}{{list.contact.state}}{{list.contact.country}}<br />
            {{list.contact.email}}<br />
            {{list.contact.phoneNumber}}<br>
           GST No. {{list.gst_no}}
          </div>
        </div>
        <div class="border border-t-2 border-gray-200 mb-8 px-3"></div>
        <h1 class="text-2xl text-left px-3 font-medium"> {{list.task.name}}</h1>
        <div class="mb-8 px-3">
          <p> {{list.product_details}}</p>
          <div class="flex justify-between mb-4  py-2" *ngIf="list.task.category=='product'">
            <div>Product Details:</div>
            <div class="text-left font-medium">{{list.task.products.product_name}}<br><span class="text-left text-sm font-light">{{list.task.products.product_description}} </span></div>
          </div>
          <div class="flex justify-between mb-4 py-2" *ngIf="list.task.category=='service'">
            <div>Service Details:</div>
            <div class="text-left font-medium">{{list.task.service_title}}<br><span class="text-left text-sm font-light">{{list.task.service_description}}</span> </div>
          </div>
        </div>
        <div class="flex justify-between mb-4  px-2 py-2">
          <div>Payment Terms:</div>
          <div class="text-left font-medium">{{list.paymentterms}} </div>
        </div>
                <div class="flex justify-between mb-4 bg-gray-200 px-3 py-2">
          <div>Subtotal (in ₹)</div>
          <div class="text-right font-medium"> ₹ {{list?.amount}} </div>
        </div>
                <div class="flex justify-between mb-4 bg-gray-200 px-3 py-2">
          <div>GST</div>
          <div class="text-right font-medium"> ₹ {{list?.gst_amount}} </div>
        </div>
        <div class="flex justify-between items-center mb-2 px-3">
          <div class="text-2xl leading-none"><span class="">Total</span>:</div>
          <div class="text-2xl text-right font-medium">₹ {{grandtotal}}<br>
            <span class="text-sm text-right font-medium">{{grandtotal_word}}</span></div>
        </div>
        <div class="mb-8 px-3 text-center">
          <span>To be paid before</span> {{expiration}} on <b class="underline font-bold">REFQC{{list?.id}}</b> specifying the invoice 
        </div>
        <div class="mb-8 text-4xl text-center px-3">
          <span>Thank you!</span>
        </div>
        <div class="text-center text-sm px-3">
          hello&#64;yourdomain.com  www.yourdomain.com
        </div>
      </div>
    </div>