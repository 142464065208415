import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from '../../global';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class AddSalesService {
  
  
  constructor(private http: HttpClient) { }
  addSalesteam(result: any,userid:number){
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');   
    return this.http.post(apiurl + 'salesteam/'+ userid +"/",result,{headers:headers});

    }

}
