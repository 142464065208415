import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AddSalesService } from './add-sales.service';
import { Router, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { Member } from '../../admin/admin-global';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-add-sales',
  standalone: true,
  imports: [CommonModule,RouterModule,MatDividerModule,ReactiveFormsModule],
  templateUrl: './add-sales.component.html',
  styleUrl: './add-sales.component.scss'
})
export class AddSalesComponent implements OnInit {

  users:any=[];
  registerForm!: FormGroup;
  submitted = false;
  Members:any=[];
  public user: any;
  constructor(private formBuilder: FormBuilder,
    private addsalesservice: AddSalesService,
    private router: Router,private loginservice: LoginService) { }

  ngOnInit() {
      this.registerForm = this.formBuilder.group({
        
          name: ['', Validators.required],
          salestype:[''],
          teamleader:['', Validators.required],
          invoicetarget:[''],
          email:[''],

          
      }, );
      this.user = this.loginservice.getUserdata()
      this.getuser()
  }
  getuser(){
  
    this.loginservice.getUser().subscribe(
      (users: any) => this.users = users
      )
    }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
      this.submitted = true;

    
      this.addsalesservice.addSalesteam(this.registerForm.value,this.user.data.id).subscribe((reponse)=>{
        console.log(reponse);
       });
  }

  onReset() {
      this.submitted = false;
      this.registerForm.reset();
  }

  private selectedLink: string="Quotation";        

  setContactType(e: string): void   
  {  
      this.selectedLink = e;     
  }  
  
  isSelected(name: string): boolean   
  {  
      if (!this.selectedLink) {   
          return false;  
      }  
          return (this.selectedLink === name);  
  }  

  name = '!!!';
    viewMode = 'tab1';
   
    addMembers(name:string,login:string,language:string,latestauthentication:string,twofactorauthentication:string){
        
        let m = new Member (
          name,
          login,
          language,
          latestauthentication,
          twofactorauthentication
          
        )
                this.Members.push(m);
                console.log(this.Members);
              
        }
         removeMember() {
            if (this.Members.length) {
               this.Members.pop();
            }
          }
         
	}  
  // openDialog() {
  //   const dialogRef = this.dialog.open(DialogueTeamMemberComponent,{
  //   disableClose: false
    
  // });
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }
 

// @Component({
//     selector: '../dialogue-team-member/app-dialogue-team-member',
//     templateUrl: '../dialogue-team-member/dialogue-team-member.component.html',
    
//   })
//   export class DialogueTeamMemberComponent {
      
//   }