import { Component, OnInit,Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-fail',
  standalone: true,
  imports: [MatDialogModule],
  templateUrl: './fail.component.html',
  styleUrl: './fail.component.scss'
})
export class FailComponent {

  title: string | undefined;
  message: any 
  constructor(public dialogRef: MatDialogRef<FailComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
