import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from '../../global';


@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  
  constructor(private http: HttpClient) { }
  addSchedule(result: any,id:number){
    console.log(result);
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');   
    return this.http.post(apiurl+'pipeline/events/'+id+"/",result,{headers:headers})

    }
    gettasklist(userid:number){
      console.log(userid,'userrrrr')
      return this.http.get(apiurl + 'pipeline/tasklist/'+userid+"/"+"role"+"/");             
      }
      editactivity(data:any,cid:number){
        //console.log(data);
        const headers = new HttpHeaders();
        headers.append('Content-Type','application/json');
        return this.http.put(apiurl+"rud_contact/"+cid+"/",data,{headers:headers})
      }
      getOneActivity(activity_id:number){
        return this.http.get(apiurl+"pipeline/rudevents/"+activity_id+"/")
      }
      editschedule(data:any,cid:number){
        //console.log(data);
        const headers = new HttpHeaders();
        headers.append('Content-Type','application/json');
        return this.http.put(apiurl+"pipeline/rudevents/"+cid+"/",data,{headers:headers})
      }
}
