<div class="p-2 bg-white  rounded-md  border border-t-4" [ngClass]="salestask.color">
	<div class="text-xs">
		<div class="flex items-center" style="margin-top: -2vh !important;margin-bottom: -1vh !important;">
			<h3 class="flex-grow  ml-2">
				<p>{{ salestask?.name }}</p>

			</h3>
			<div>
				<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu" class="flex flex-col text-xs">
					<button *ngIf="editStatus" (click)="addlead(salestask.id)" class="flex p-2 items-center space-x-1">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
							stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
								d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
						</svg>
						<span>Edit</span>
					</button>

					<button *ngIf="editStatus" (click)="deletelead(salestask)" class="flex p-2 items-center space-x-1">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
							stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
								d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
						</svg>
						<span>Delete</span>
					</button>


					<div class="grid grid-cols-3 p-2 gap-2">
						<div class="h-5 w-6 bg-red-400 border border-red-800"
							(click)="EditCardColor(salestask?.id,'border-red-400')"></div>
						<div class="h-5 w-6 bg-yellow-300 border border-yellow-800"
							(click)="EditCardColor(salestask?.id,'border-yellow-400')"></div>
						<div class="h-5 w-6 bg-green-300 border border-green-800"
							(click)="EditCardColor(salestask?.id,'border-green-400')"></div>
						<div class="h-5 w-6 bg-blue-300 border border-blue-800"
							(click)="EditCardColor(salestask?.id,'border-blue-400')"></div>
						<div class="h-5 w-6 bg-indigo-300 border border-indigo-800"
							(click)="EditCardColor(salestask?.id,'border-indigo-400')"></div>
						<div class="h-5 w-6 bg-purple-300 border border-purple-800"
							(click)="EditCardColor(salestask?.id,'border-purple-400')"></div>
						<div class="h-5 w-6 bg-pink-300 border border-pink-800"
							(click)="EditCardColor(salestask?.id,'border-pink-400')"></div>
						<div class="h-5 w-6 bg-gray-300 border border-gray-800"
							(click)="EditCardColor(salestask?.id,'border-gray-400')"></div>
						<div class="h-5 w-6 bg-white border border-gray-200"
							(click)="EditCardColor(salestask?.id,'border-gray-200')"></div>
					</div>
				</mat-menu>

			</div>
		</div>
		<div class="flex ml-2">
			<button type="button" class="bg-blue-200  text-xs text-black" style="border-radius: 6px !important;
    padding: 1vh;" (click)="changestatus(salestask.status,salestask.id)" title="change status"> {{
				salestask?.status}}</button>


			<p
				style="margin-left: 1vh !important;border-radius: 6px !important;padding: 1vh;background-color: #e5e7eb;">
				₹{{salestask?.budget}}</p>
			<p
				style="margin-left: 1vh !important;border-radius: 6px !important;padding: 1vh;background-color: #e0ec9b;">
				{{salestask?.type}}</p>

		</div>
		<div class="mt-4 flex ml-2">
			<button class="flex justify-center; mt-1 relative" (click)="addActivities('salestask?.name',salestask?.id)">
				<P class="text-black absolute activityCount text-blue-500">{{salestask?.activityCount}}</P>

				<svg style="color:rgb(190, 188, 188)" xmlns="http://www.w3.org/2000/svg"
					class="stroke-current   float-left mr-2 " width="35" height="27" viewBox="0 0 24 24">
					<g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
						stroke-width="2">
						<path
							d="M9 20H6a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4h11a4 4 0 0 1 4 4v3M8 2v2m7-2v2M2 8h19m-2.5 7.643l-1.5 1.5" />
						<circle cx="17" cy="17" r="5" />
					</g>
				</svg>
			</button>
			<button
				style="margin-left : 1vh !important;border-radius: 6px !important;background-color: #e5e7eb;padding:0.5vh;padding-right : 1vh"
				(click)="noteTask(salestask)" class="flex justify-center">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
					<path fill="currentColor" d="M11.5 12.5H6v-1h5.5V6h1v5.5H18v1h-5.5V18h-1z" />
				</svg>
				<span class="mt-1">Add Note</span>
			</button>
		</div>
		<div class="flex items-end py-1">
			<div class="flex-grow">
				<app-star-ratings [rating]="salestask?.rating" [starCount]="starCount" [color]="starColorW"
					(ratingUpdated)="onRatingChanged($event)">
					
				</app-star-ratings>
				<span>		
				</span>
			</div>
			<img class="profile-box" [src]="salestask?.contact?.image ? imgurl + salestask?.contact?.image : 'assets/img/image.png'" title="{{salestask.email}}"
				[routerLink]="['/executive/contact/',salestask?.contact_id]">
			<!--  -->
		</div>

	</div>