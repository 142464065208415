import { CommonModule } from '@angular/common';
import { Component, OnInit , ViewEncapsulation} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-targetss',
  standalone: true,
  imports: [CommonModule,RouterModule,NgxPaginationModule,MatDividerModule],
 templateUrl: './targetss.component.html',
  styleUrl: './targetss.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class TargetssComponent implements OnInit {
p:number=1;
data:any=[];
  constructor() { }

  ngOnInit(): void {
  }

}
