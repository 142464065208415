<div>
  <h2 class="font-semibold text-lg mt-5 ml-5">Add Activity</h2>
  <form class="form-control" [formGroup]="addactivityForm">
    <div class="flex flex-col">
      <mat-divider></mat-divider>
      <div class="mt-3 bg-white overflow-y-auto h-auto rounded border border-gray-100 mx-5">
        <div class="form mt-3">
          <div class="flex  gap-2">

            <div class="p-2 w-64 sm:w-1/2">
              <label class="block text-gray-400 text-sm mb-2" for="eventname">Name:</label>
              <input type="text" formControlName="eventname" autocomplete="off"
                class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200 border-gray-300 sm:text-sm" />
              <div *ngIf="addactivityForm.get('eventname')?.touched && addactivityForm.get('eventname')?.hasError('required')"
                class="error-msg text-red-500 text-sm">
                Activity Name is required
              </div>
            </div>

            <!-- Activity Type Select -->
            <div class="p-2 w-64 sm:w-1/2">
              <label class="block text-gray-400 text-sm mb-2" for="activity_type">Activity Type:</label>
              <select formControlName="activity_type"
                class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200 border-gray-300 sm:text-sm">
                <option value="events">Events</option>
                <option value="meetings">Meetings</option>
              </select>
              <div *ngIf="addactivityForm.get('activity_type')?.touched && addactivityForm.get('activity_type')?.hasError('required')"
                class="error-msg text-red-500 text-sm">
                Activity Type is required
              </div>
            </div></div>
            <div class="flex  gap-4">
            
            <div class="p-2 w-64 sm:w-1/2">
              <label class="block text-gray-400 text-sm mb-2" for="hour">Hour:</label>
              <input type="number" formControlName="hour"
                class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200 border-gray-300 sm:text-sm" />
              <div *ngIf="addactivityForm.get('hour')?.touched && addactivityForm.get('hour')?.hasError('required')"
                class="error-msg text-red-500 text-sm">
                Hour is required
              </div>
            </div>

           
            <div class="p-2 w-64 sm:w-1/2">
              <label class="block text-gray-400 text-sm mb-2" for="minute">Minute:</label>
              <input type="number" formControlName="minute"
                class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200 border-gray-300 sm:text-sm" />
              <div *ngIf="addactivityForm.get('minute')?.touched && addactivityForm.get('minute')?.hasError('required')"
                class="error-msg text-red-500 text-sm">
                Minute is required
              </div>
            </div></div>
            <div class="flex gap-4">
              <div class="p-2 w-64">
                <label class="block text-gray-400 text-sm mb-2" for="description">Description:</label>
                <textarea formControlName="description" class="focus:ring-white w-full h-16 rounded-md focus:border-gray-200 border-gray-300 sm:text-sm"></textarea>
                <div *ngIf="addactivityForm.get('description')?.touched && addactivityForm.get('description')?.hasError('required')"
                  class="error-msg text-red-500 text-sm">
                  Description is required
                </div>
              </div>
            <div class="p-2 w-64 sm:w-1/2">
              <label class="block text-gray-400 text-sm mb-2" for="edate">Due Date:</label>
              <mat-form-field class="w-full datePicker">
                <input formControlName="edate" matInput [matDatepicker]="picker1" placeholder="MM/DD/YYYY">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker1">
                  <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
              <div *ngIf="addactivityForm.get('edate')?.touched && addactivityForm.get('edate')?.hasError('required')"
                class="error-msg text-red-500 text-sm">
                Due Date is required
              </div>
            </div>
            

          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="flex flex-col sm:flex-row items-center mr-5 mt-4">
      <div class="w-full sm:w-auto sm:ml-auto">
        <a (click)="close()" class="mb-2 cursor-pointer bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">
          Cancel
        </a>
      </div>
      <div class="w-full sm:w-auto sm:ml-3 mt-2 sm:mt-0 text-right">
        <button class="bg-green-400 px-5 py-2 text-sm color shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg"
          (click)="onSubmit()" type="submit">
          Save
        </button>
      </div>
    </div>
  </form>
</div>
