import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from '../../global';
@Injectable({
  providedIn: 'root'
})
export class QuotationListService {

  constructor(private http: HttpClient) { }
  
  getQuotation(userid:number){
 
  return this.http.get(apiurl + 'pipeline/quotation/'+userid+"/");
               
  }
getexport(userid:number){
  return this.http.get(apiurl + 'user/ExportQuotationDataView/'+userid+"/"); 
}

  getSingleQuotation(id:number){
 
    return this.http.get(apiurl + 'pipeline/rud_quotation/'+id+"/");
                 
    }
  addInvoice(data: any,userid:number){
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json'); 
   return this.http.post(apiurl + 'pipeline/quotation/'+userid+"/",data,{headers:headers});
    
    }
    addQuotation(data: any){
      const headers = new HttpHeaders();
      headers.append('Content-Type','application/json'); 
     return this.http.post(apiurl + 'pipeline/CreateQuotationAPIView/',data,{headers:headers});
      
      }
    Editquotation(data:any,cid:any)
    {
      const headers = new HttpHeaders();
      headers.append('Content-Type','application/json');
      return this.http.put(apiurl+"pipeline/rud_quotation/"+cid+"/",data,{headers:headers})
    }
    delOneQuotation(cid:number){
      return this.http.delete(apiurl+"pipeline/rud_quotation/"+cid+"/")
     }
  addProduct(data: any,userid:number){
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json'); 
    return this.http.post(apiurl + 'productdetails/'+userid+"/",data,{headers:headers});
    }
    gettasklist(cid:number){
      return this.http.get(apiurl + 'pipeline/tasklistcustom/'+cid+"/");             
      }
      configurationlist(id:any)
      {
        console.log(id,'gdfg')
        return this.http.get(apiurl + 'pipeline/configurations/'+id+"/"+"member"+"/"); 
      }
      getquotesend(id:any,type:any,data:any)
      {
        return this.http.post(apiurl + 'pipeline/quotation_send_mail/'+id+"/"+type+"/",data); 
      }
     
}
