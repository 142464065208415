<div class=" w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10" style="min-height: 84vh !important; max-height: 500px;">
    <div class="flex flex-col sm:flex-row items-center mb-4">
        <h2 class="font-semibold text-lg mr-auto">Team - Members</h2>
        <div class="w-full sm:w-auto sm:ml-auto mt-2 sm:mt-0">
            <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
                <a routerLink="/admin/add-team-members/{{cid}}"  class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">Add Team Members </a>
                <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg " style="background-color: #2a303c;" (click)="exportTeammember()">Export</button>
            </div>
        </div>
    </div>
    
    <mat-divider></mat-divider>

    <div class="mt-6">
        <table class="min-w-full table-auto border-collapse border border-gray-300">
            <thead>
                <tr class="bg-gray-200 text-gray-600 text-sm">
                    <th class="py-3 px-6  border border-gray-300 text-center">Members</th>
                    <th class="py-3 px-6  border border-gray-300 text-center">Role</th>
                    <th class="py-3 px-6  border border-gray-300 text-center">Target</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Manage</th>
                    
                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm" *ngIf="members && members.length > 0; else noData">
                <tr *ngFor="let item of members | paginate: { itemsPerPage: 5, currentPage: p }" class="border-b border-gray-200 hover:bg-gray-100">
                     <td class="py-3 px-6 border border-gray-300 text-center">{{item.team_member.first_name}}</td>
                     <td class="py-3 px-6 border border-gray-300 text-center">{{item.team_member.groups[0].name}}</td>
                     <td class="py-3 px-6 border border-gray-300 text-center">₹ {{item.target}}</td>
                    <td class="py-3 px-6 border border-gray-300 text-center">
                        <div class="flex justify-center gap-2">
                            <span class="bg-red-200 text-purple-600 py-1 px-3  rounded-full text-xs"><svg xmlns="
                                http://www.w3.org/2000/svg" routerLink="/admin/add-team-members/{{cid}}/{{item.id}}/{{item.team_member.id}}"class="cursor-pointer"
                                width="20px" height="20px" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M7 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-1"/><path d="M20.385 6.585a2.1 2.1 0 0 0-2.97-2.97L9 12v3h3zM16 5l3 3"/></g></svg>
                            </span>
                                <span class="bg-red-200 text-purple-600 py-1 px-3 ml-2 rounded-full text-xs">
                                <svg xmlns="
                                http://www.w3.org/2000/svg" (click)="deltemember(item.id,item.team_member_id,item.team_member.first_name)" class="cursor-pointer"
                                width="20px" height="20px" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z"/></svg></span>
                            
                         </div>
                    </td>
                    
                </tr>
            </tbody>
        </table>
        <ng-template #noData><tr><td colspan="4" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
        <pagination-controls 
            class="mt-1 float-right"
            (pageChange)="p = $event">
        </pagination-controls>
    </div>
</div>

