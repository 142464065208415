import { Component, OnInit, Inject,Output,EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GetEventsService } from '../full-calenders/get-events.service';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { LoginService } from '../../services/login.service';
import { PipelineService } from '../drag-drops/pipeline.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-event-dialog',
  standalone: true,
  imports: [CommonModule,RouterModule,MatDialogModule],
  templateUrl: './event-dialog.component.html',
  styleUrl: './event-dialog.component.scss'
})
export class EventDialogComponent implements OnInit {
  public registerForm!: FormGroup;
  title: any;
  
  message: any;
  public user:any
  e_details:any[]=[]
  @Output() private ontaskcomplete = new EventEmitter();
  constructor(private gservice:GetEventsService,private loginservice:LoginService,public dialogRef: MatDialogRef<EventDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,private pservice:PipelineService,private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.user=this.loginservice.getUserdata()
   //this.geteventdetails()
 
  }
  geteventdetails(edate:any,cat:any)
  {
    this.gservice.geteventdetails(this.user.data.id,edate,cat).subscribe((response:any)=>{
      this.e_details=response
    })
  }
}

