import { Component, OnInit } from '@angular/core';
import { ActivitiesService } from '../../services/activities.service';
import { LoginService } from '../../services/login.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-hot-lead-report',
  standalone: true,
  imports: [MatDividerModule,MatTableModule,CommonModule,NgxPaginationModule],
  providers : [ActivitiesService,LoginService],
  templateUrl: './hot-lead-report.component.html',
  styleUrl: './hot-lead-report.component.scss'
})
export class HotLeadReportComponent implements OnInit {
  constructor(private activityserve:ActivitiesService,private loginservice: LoginService) { }

  p:number=1;
  hotLeadReportlist:any[] = [];
  user:any;
  userid:any;
  displayedColumns: string[] = ['leads', 'salesRep', 'customer','contact','comment'];
  isLoading:boolean=true;
  noDataMessageShown:boolean=false;

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.userid=this.user.data.id 
    this.getHotledReport()
  }

  getHotledReport(){ 
    this.activityserve.hotleadReport(this.userid).subscribe(
      (hotLeadReportlist: any) =>{ 
        this.hotLeadReportlist = hotLeadReportlist.data.tasks
        this.isLoading=false;
         if(this.hotLeadReportlist.length===0){
          this.noDataMessageShown=true;
         }
         else{
          this.noDataMessageShown=false;
         }
        // console.log(this.hotLeadReportlist,'hotLeadReportlist....')
       }
      );    
  }

}
