<div class="max-w h-content w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 mt-3 "
  style="height: 83vh !important;">
  <div class="grid ">
    <form class="form-control" [formGroup]="contactForm">
      <div class="flex flex-col ">
        <div class="flex flex-col sm:flex-row items-center mb-6">
          <h2 class="font-semibold text-lg mr-auto">Contact / {{contactId?'Edit':'New'}}</h2>
          <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>

          <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
            <a (click)="cancel()"
              class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 icon-pointer">
              Cancel </a>
            <button
              class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg color"
              (click)="contactId? editContact() :onSubmit()" type="submit">Save</button>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="mt-3 bg-white overflow-y-auto h-screen rounded border border-gray-100"
          style="overflow-y: auto; height: 60vh !important;">
          <div class="form mt-3">
            <div>
              <div class="mt-4">
                <div>

                  <div class="p-4 flex justify-start">
                    <input type="checkbox" formControlName="primary"
                      class="focus:ring-white w-6 h-6 rounded-md border-gray-200  border-gray-300  sm:text-sm" />
                    <label class="block pl-4 tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                      Primary</label>
                  </div>

                  <div class="flex justify-between">
                    <div class="p-2">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        <span class="text-red-600">*</span> Organization :</label>
                      <select formControlName="customer_name" #contactaddress 
                        class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                        <option value="" disabled="true">Organization</option>
                        <option *ngFor="let list of organizationlist" [value]="list?.id">{{list?.name}}</option>
                      </select>
                      <div
                        *ngIf="contactForm.get('customer_name')?.touched && contactForm.get('customer_name')?.hasError('required')"
                        class="error-msg">
                        Organization is Required
                      </div>
                      <div *ngIf="contactForm.get('customer_name')?.hasError('pattern')" class="error-msg">
                        Invalid format
                      </div>
                    </div>

                    <div class="p-2">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        <span class="text-red-600">*</span> First Name :</label>
                      <input type="text" formControlName="first_name"
                        class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                      <div
                        *ngIf="contactForm.get('first_name')?.touched && contactForm.get('first_name')?.hasError('required')"
                        class="error-msg">
                        First Name is Required
                      </div>
                      <div *ngIf="contactForm.get('first_name')?.hasError('pattern')" class="error-msg">
                        Invalid format
                      </div>
                    </div>

                    <div class="p-2">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        <span class="text-red-600">*</span> Last Name :</label>
                      <input type="text" formControlName="last_name"
                        class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                      <div
                        *ngIf="contactForm.get('last_name')?.touched && contactForm.get('last_name')?.hasError('required')"
                        class="error-msg">
                        Last Name is Required
                      </div>
                      <div *ngIf="contactForm.get('last_name')?.hasError('pattern')" class="error-msg">
                        Invalid format
                      </div>
                    </div>

                  </div>

                  <div class="flex justify-between">

                    <div class="p-2">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        Designation :</label>
                      <input type="text" formControlName="designation"
                        class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                      <!-- <div
                        *ngIf="contactForm.get('designation')?.touched && contactForm.get('designation')?.hasError('required')"
                        class="error-msg">
                        Designation is Required
                      </div>
                      <div *ngIf="contactForm.get('designation')?.hasError('pattern')" class="error-msg">
                        Invalid format
                      </div> -->
                    </div>

                    <div class="p-2">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        Email :</label>
                      <input type="text" formControlName="email"
                        class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                      <!-- <div *ngIf="contactForm.get('email')?.touched && contactForm.get('email')?.hasError('required')"
                        class="error-msg">
                        Email is Required
                      </div> -->
                      <div *ngIf="contactForm.get('email')?.hasError('pattern')" class="error-msg">
                        Invalid format
                      </div>
                    </div>

                    <div class="p-2 flex justify-between " style="margin-left: 0vh !important;">
                      <div class="pr-4">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          <span class="text-red-600">*</span>  Code :</label>
                        <select formControlName="country_code" [(ngModel)]="selectedOption1"
                          class="focus:ring-white w-20 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
                          <option [value]="item" *ngFor="let item of phoneCodes">{{item}}</option>
                         
                        </select>
                      </div>

                      <div>
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Office Phone :</label>
                        <input type="text" formControlName="office_phone"
                          class="focus:ring-white w-40 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                        <div
                          *ngIf="contactForm.get('office_phone')?.touched && contactForm.get('office_phone')?.hasError('required')"
                          class="error-msg">
                          Office Phone is Required
                        </div>
                        <div *ngIf="contactForm.get('office_phone')?.hasError('pattern')" class="error-msg">
                          Invalid format
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="flex justify-between">

                    <div class="p-2">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        Mobile Phone :</label>
                      <input type="text" formControlName="mobile_phone"
                        class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                      <!-- <div
                        *ngIf="contactForm.get('designation')?.touched && contactForm.get('designation')?.hasError('required')"
                        class="error-msg">
                        Designation is Required
                      </div>
                      <div *ngIf="contactForm.get('designation')?.hasError('pattern')" class="error-msg">
                        Invalid format
                      </div> -->
                    </div>

                    <div class="p-2 " *ngIf="!isEdit">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        Photo :</label>
                     <ngx-file-drag-drop formControlName="image" activeBorderColor="#3F51B5"
                       class="focus:ring-white width dash-border  w-64 file-uploader rounded" [multiple]="false"
                       (valueChanged)="onFileChanges($event)"></ngx-file-drag-drop>
                      <!-- <div *ngIf="contactForm.get('email')?.touched && contactForm.get('email')?.hasError('required')"
                        class="error-msg">
                        Email is Required
                      </div> -->
                      
                    </div>
                    <div *ngIf="isEdit">
                      <div class="mb-2 p-2">
                        
                          <label class="block  tracking-wide text-gray-400 text-sm " for="grid-password" style="margin-left: 13vh !important;">
                            Change Photo :</label>
                      </div>
                      <div class="px-3 row"
                        style="display: flex;border: 2px dashed;border-radius:10px !important;margin-left: 13vh !important;width: 52vh !important;">
                        <div class="col mt-3">
                          <img src="{{ contactdetails?.image }}" *ngIf="contactdetails?.image"alt="" style="width:12vh;height:11vh">
                          <h4 *ngIf="!contactdetails?.image">No Image Added</h4>
                        </div>
                        <div class="col mt-2" style="margin-left: 2vh !important;">
                          <ngx-file-drag-drop  activeBorderColor="#3F51B5"
                        class="focus:ring-white width  file-uploader" [multiple]="false"
                        (valueChanged)="onFileChanges($event)"></ngx-file-drag-drop>
                      
                        </div>
                      </div>
                    </div>

                    <div class="p-2">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        Comments :</label>
                      <textarea type="text" rows="3" formControlName="comments"
                        class="focus:ring-white w-64  rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" ></textarea>
                    
                    </div>

                  </div>
                 
<!--                   
                      <div class=" p-2 w-64 h-9 sizes  mb-2" *ngIf="!isEdit">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                           Photo :</label>
                        <ngx-file-drag-drop formControlName="image" activeBorderColor="#3F51B5"
                          class="focus:ring-white width dash-border file-uploader rounded" [multiple]="false"
                          (valueChanged)="onFileChanges($event)"></ngx-file-drag-drop> -->
                        <!-- <div *ngIf="contactForm.get('image')?.touched && contactForm.get('image')?.hasError('required')"
                          class="error-msg">
                          Files is Required
                        </div> -->
    
                      <!-- </div> -->
                      <!-- <div *ngIf="isEdit">
                        <div class="mb-2 p-2">
                          
                            <label class="block  tracking-wide text-gray-400 text-sm " for="grid-password" style="margin-left: 13vh !important;">
                              Change Photo :</label>
                        </div>
                        <div class="px-3 row"
                          style="display: flex;border: 2px dashed;border-radius:10px !important;margin-left: 13vh !important;width: 52vh !important;">
                          <div class="col mt-3">
                            <img src="{{ contactdetails?.image }}" alt="" style="width:12vh;height:11vh">
                          </div>
                          <div class="col mt-2" style="margin-left: 2vh !important;">
                            <ngx-file-drag-drop  activeBorderColor="#3F51B5"
                          class="focus:ring-white width  file-uploader" [multiple]="false"
                          (valueChanged)="onFileChanges($event)"></ngx-file-drag-drop>
                        
                          </div>
                        </div>
                      </div> -->

                
                 
                  <div class="p-4 flex justify-start" style="margin-top: 4vh !important; margin-left:-1vh !important">
                    <input type="checkbox" (change)="organizations($event)" 
                      class="focus:ring-white w-6 h-6 rounded-md border-gray-200  border-gray-300  sm:text-sm" />
                    <label class="block pl-4 tracking-wide text-gray-400 text-sm mb-2 font-semibold size " for="grid-password">
                   Address (Same as  Accounts)</label>
                  </div>
                  <!-- <h2 class="font-semibold text-lg p-3 mr-auto">Address</h2> -->

                  <div class="flex justify-between">
                    <div class="p-2">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        <span class="text-red-600">*</span>  Address Line 1 :</label>
                      <input type="text" formControlName="address_line_1"
                        class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                      <div
                        *ngIf="contactForm.get('address_line_1')?.touched && contactForm.get('address_line_1')?.hasError('required')"
                        class="error-msg">
                        Address Line 1 is Required
                      </div>
                      <div *ngIf="contactForm.get('address_line_1')?.hasError('pattern')" class="error-msg">
                        Invalid format
                      </div>
                    </div>

                    <div class="p-2">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        Address Line 2 :</label>
                      <input type="text" formControlName="address_line_2"
                        class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                      <!-- <div
                        *ngIf="contactForm.get('address_line_2')?.touched && contactForm.get('address_line_2')?.hasError('required')"
                        class="error-msg">
                        Address Line 2 is Required
                      </div> -->
                      <div *ngIf="contactForm.get('address_line_2')?.hasError('pattern')" class="error-msg">
                        Invalid format
                      </div>
                    </div>

                    <div class="p-2">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        <span class="text-red-600">*</span> City :</label>
                      <input type="text" formControlName="city"
                        class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                      <div *ngIf="contactForm.get('city')?.touched && contactForm.get('city')?.hasError('required')"
                        class="error-msg">
                        City is Required
                      </div>
                      <div *ngIf="contactForm.get('city')?.hasError('pattern')" class="error-msg">
                        Invalid format
                      </div>
                    </div>
                  </div>


                  <div class="flex justify-between">


                    <div class="p-2">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        <span class="text-red-600">*</span> State :</label>
                      <input type="text" formControlName="state"
                        class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                      <div *ngIf="contactForm.get('state')?.touched && contactForm.get('state')?.hasError('required')"
                        class="error-msg">
                        State is Required
                      </div>
                      <div *ngIf="contactForm.get('state')?.hasError('pattern')" class="error-msg">
                        Invalid format
                      </div>
                    </div>



                    <div class="p-2">
                      <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                        <span class="text-red-600">*</span> Zip Code :</label>
                      <input type="text" formControlName="zipcode"
                        class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                      <div
                        *ngIf="contactForm.get('zipcode')?.touched && contactForm.get('zipcode')?.hasError('required')"
                        class="error-msg">
                        Zip Code is Required
                      </div>
                      <div *ngIf="contactForm.get('zipcode')?.hasError('pattern')" class="error-msg">
                        Invalid format
                      </div>
                    </div>

                    
                    

                    <div class=" flex justify-between">
                      <!-- <div class="pr-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Code :</label>
                        <select formControlName="country_code" [(ngModel)]="selectedOption1"
                          class="focus:ring-white w-20 h-9 rounded-md focus:border-gray-200  border-gray-400  sm:text-sm">
                          <option value="+91">+91</option>
                          <option value="+1">+1</option>
                        </select>
                      </div> -->
                      

                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Fax :</label>
                        <input type="text" formControlName="fax"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                        <!-- <div
                          *ngIf="contactForm.get('address_line_2')?.touched && contactForm.get('address_line_2')?.hasError('required')"
                          class="error-msg">
                          Address Line 2 is Required
                        </div> -->
                        <!-- <div *ngIf="contactForm.get('fax')?.hasError('pattern')" class="error-msg">
                          Invalid format
                        </div> -->
                      </div>
  


                    </div></div>

                  <div class="flex">
               
                    <!-- <div class=" w-100  mb-2" *ngIf="!isEdit">
                      <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                        Photo</label>
                      <ngx-file-drag-drop formControlName="image" activeBorderColor="#3F51B5"
                        class="focus:ring-white width dash-border file-uploader" [multiple]="false"
                        (valueChanged)="onFileChanges($event)"></ngx-file-drag-drop> -->
                      <!-- <div *ngIf="contactForm.get('image')?.touched && contactForm.get('image')?.hasError('required')"
                        class="error-msg">
                        Files is Required
                      </div> -->
<!-- 
                    </div> -->
                   
                 
                   
                    
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </form>
  </div>
</div>