import { Component, OnInit , ViewEncapsulation} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailtemplateService } from '../add-email-templates/email-template.service';
import { LoginService } from '../../services/login.service';
import { Router, RouterModule } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrService } from 'ngx-toastr';
declare var XLSX: any;

@Component({
  selector: 'app-email-signatures',
  standalone: true,
  imports: [CommonModule,MatDividerModule,RouterModule,NgxPaginationModule],
  templateUrl: './email-signatures.component.html',
  styleUrl: './email-signatures.component.scss',
 
})
export class EmailSignaturesComponent implements OnInit {
  data: any[] = [];
  public user: any;
  error:any;
  p:number=1;
  public Activity:any=[];
  constructor(private dialog: MatDialog,private emailtservice: EmailtemplateService,private loginservice: LoginService, private toaster:ToastrService,
    private router: Router,) { }
    ngOnInit() {
      this.user = this.loginservice.getUserdata()
      this.getEmailsignature(this.user.data.id)
      
    }
  
    getEmailsignature(userid :number){

    this.emailtservice.getEmailsignature(userid).subscribe(
      (data: any) => this.data = data

      );
  }
  Editemailsig(cid:any)
  {
    this.router.navigate(['teamleader/update-email-signature/',cid]);
  }
  delEmailsig(list:any)
  {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Remove Signature',
        message: 'Are you sure, you want to remove a Signature: ' + list.name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
		   this.emailtservice.delOneEmailsignature(list.id).subscribe((data)=>{
      this.getEmailsignature(this.user.data.id)
    
		})
	}
});
  }

  ExportEmailSignatureDataView(){
    this.emailtservice.ExportEmailSignatureDataView(this.user.data.id).subscribe((result:any)=>{
      const worksheet = XLSX.utils.json_to_sheet(result.message);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, 'table-data.xlsx');
    
    if(result?.status==false){
      this.toaster.error(result.message,'error')
    }
    })
  }
}
