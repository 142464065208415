<div class="max-w w-full space-y-8  p-10 " style="height: 86vh !important; overflow-y: auto;">
  <div class="flex flex-col sm:flex-row items-center mb-6">
    <h2 class="font-semibold text-lg mr-auto">Quotation</h2>
    <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>
  
    <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
      <button (click)="captureScreen()" class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
        <span>Download</span>
        <svg class="fill-current ml-2 -mr- w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
      
      </button>
      <button type="button" (click)="sendnow(list.id)" class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
        <span>Send</span>
        <svg aria-hidden="true" class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
       
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="mx-20 my-20 p-4 bg-white-100 border border-gray-200" style="max-width: 1000px;" id="contentToConvert" >
      
      <div class="flex justify-center">
      <h1 class="text-2xl text-left px-2 font-medium"> {{list?.name}}</h1>
    </div>
    <div class="flex justify-between ">
      <div>
        {{configlist?.name}}<br>
      {{configlist?.address}}<br>
      {{configlist?.email}}<br />
      {{configlist?.phone}}<br>
      GST No. {{configlist?.gst_no}}
      </div>
      <div>
        Ref No: REFQC{{list?.id}}<br>
        Date:  {{date}}<br>
        Expiry Date: {{expiration}}
        {{list?.contact?.name}}<br />
        {{list?.contact?.address1}}<br />
       {{list?.contact?.city}}{{list?.contact?.state}}{{list?.contact?.country}}<br />
        {{list?.contact?.email}}<br />
        {{list?.contact?.phoneNumber}}<br>

      </div>
      </div>
      
    
  
      <div class="border border-t-2 border-gray-200 mb-4 px-2"></div>
      <!-- <h1 class="text-2xl text-left px-3 font-medium flex justify-center"> {{list?.name}}</h1> -->
      <!-- <div class="mb-8 px-3 flex justify-center">
        <p> {{list?.product_details}}</p>
      </div> -->
      <span class="text-xl mt-3">Account Details : </span><br>
      <div class="mb-8 py-4">
        <div class="text-right leading-tight ">
          <div class="flex justify-between mb-4 mt-2 px-3 py-2 bg-gray-200 border-gray-200 " >
            <div>Account Name</div>
            <div class="text-left font-medium"> {{configlist?.account_name}}<br> </div>
          </div>
          <div class="flex justify-between mb-4  px-3 py-2 bg-gray-200 border-gray-200" >
            <div>Account Number</div>
            <div class="text-left font-medium"> {{configlist?.account_number}}<br> </div>
          </div>
          <div class="flex justify-between mb-4  px-3 py-2 bg-gray-200 border-gray-200">
            <div>IFSC Code</div>
            <div class="text-left font-medium"> {{configlist?.ifsc_code}}<br> </div>
          </div>
        </div>
      </div>
      <!-- <div class="flex justify-between mb-4  px-2 py-2"> -->
        <!-- <div>Payment Terms:</div> -->
        <!-- <div class="text-left font-medium">{{list?.paymentterms}} </div> -->
      <!-- </div> -->
      

      <span class="text-xl ">Product Details : </span><br>
      <div class='overflow-x-auto overflow-y-auto  w-full mb-8 mt-5'>
        <table class="min-w-full table-auto border-collapse border border-gray-300">
            <thead class="bg-gray-200 text-gray-600 text-sm">
                <tr class="bg-gray-200 text-gray-600 text-sm ">
                    <th class="py-3 px-6  border-r border-gray-300">Name</th>
                    <th class="py-3 px-6 text-center border-r border-gray-300">Description</th>
                    <th class="py-3 px-6 text-center border-r border-gray-300">Price</th>
                    <th class="py-3 px-6 border border-gray-300 text-center hide-in-pdf">Manage</th>
                    
                </tr>
            </thead>
         
            <tbody class="text-gray-600 text-sm font-light" *ngIf="list?.product_or_service?.length > 0; else noData">
                <tr *ngFor="let list of list?.product_or_service" class="border-b border-gray-200 hover:bg-gray-100" >
                    <td class="py-3 px-6 text-center whitespace-nowrap border border-gray-300">
                        {{list?.product}}
                      </td>
                    <td class="py-3 px-6 text-center border border-gray-300">
                        {{list?.product_description}}
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300">
                        {{list?.product_price}}
                    </td>
                    <td class="py-3 px-6 border border-gray-300 text-center hide-in-pdf" >
                      <div class="flex justify-center">
                         <span class="bg-red-200 mr-2 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer"><a (click)="Editproduct(list.id)"><i class="fa fa-pencil"></i></a></span>
                         
                 
             </div>
                 </td>
                  </tr>
            </tbody>
        </table>
        <ng-template #noData><tr><td colspan="3" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
        </div>

      
        
        <div class="flex justify-between mb-4 bg-gray-200 px-3 py-2">
          <div>Subtotal (in ₹)</div>
          <div class="text-right font-medium">₹{{list?.approved_amount ? list?.approved_amount : list?.quotation_revenue}} </div>
        </div>
        <div class="flex justify-between mb-4 bg-gray-200 px-3 py-2">
          <div>GST</div>
          <div class="text-right font-medium">₹{{list?.gst_amount ? list?.gst_amount : 0}} </div>
        </div>
      <div class="flex justify-between items-center mb-2 px-3">
        <div class="text-2xl leading-none"><span class="">Total</span>:</div>
        <div class="text-2xl text-right font-medium">₹{{grandtotal ? grandtotal : 0}}<br>
          <span class="text-sm text-right font-medium">{{grandtotal_word}}</span>
        </div>
      </div><br>
     
     
  
      <div class="mb-8 px-3 text-center">
        <span>To be paid before</span> {{expiration}} on <b class="underline font-bold">REFQC{{list?.id}}</b> specifying the quotation 
      </div>
  
      <div class="mb-8 text-4xl text-center px-3">
        <span>Thank you!</span>
      </div>
  
    </div>
  </div>