import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { ExecutiveComponent } from './executive/executive.component';
import { AuthGuard } from './auth.guard';
import { DragDropComponent } from './executive/drag-drop/drag-drop.component';
import { LeadComponent } from './executive/lead/lead.component';
import { EditLeadComponent } from './executive/edit-lead/edit-lead.component';
import { LeadDialogComponent } from './executive/lead-dialog/lead-dialog.component';
import { ContactFormComponent } from './executive/contact-form/contact-form.component';
import { ContactViewComponent } from './executive/contact-view/contact-view.component';
import { ContactCustomerComponent } from './executive/contact-customer/contact-customer.component';
import { CompanyComponent } from './executive/company/company.component';
import { CustomerListComponent } from './executive/customer-list/customer-list.component';
import { CustomerlistComponent } from './executive/customerlist/customerlist.component';

import { ActivitiesComponent } from './executive/activities/activities.component';
import { AddEditActivityComponent } from './executive/add-edit-activity/add-edit-activity.component';
import { ActivityReportComponent } from './executive/activity-report/activity-report.component';
import { LastUpdatedReportComponent } from './executive/last-updated-report/last-updated-report.component';
import { HotLeadReportComponent } from './executive/hot-lead-report/hot-lead-report.component';

import { ScheduleComponent } from './executive/schedule/schedule.component';

import { ReportComponent } from './executive/report/report.component';
import { QuotationComponent } from './executive/quotation/quotation.component';
import { QuotationListComponent } from './executive/quotation-list/quotation-list.component';
import { QuotationGenerationComponent } from './executive/quotation-generation/quotation-generation.component';
import { OpportunityListComponent } from './executive/opportunity-list/opportunity-list.component';
import { OpportunityAddEditComponent } from './executive/opportunity-add-edit/opportunity-add-edit.component';

import { ProductComponent } from './executive/product/product.component';

import { AdminComponent } from './admin/admin/admin.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';

import { AddMembersComponent } from './admin/add-members/add-members.component';
import { TargetComponent } from './admin/target/target.component';
import { AddTargetComponent } from './admin/add-target/add-target.component';
import { TeamComponent } from './admin/team/team.component';
import { AddTeamComponent } from './admin/add-team/add-team.component';
import { TeamMembersDetailsComponent } from './admin/team-members-details/team-members-details.component';
import { TeamReportComponent } from './admin/team-report/team-report.component';
import { TeamMemberReportComponent } from './admin/team-member-report/team-member-report.component';
import { AddTeamMembersComponent } from './admin/add-team-members/add-team-members.component';
import { MemberLeadsComponent } from './admin/member-leads/member-leads.component';
import { ConfigurationComponent } from './admin/configuration/configuration.component';
import { AddConfigurationComponent } from './admin/add-configuration/add-configuration.component';
import { SuperAdminComponent } from './super-admin/super-admin/super-admin.component';
import { AdminReportComponent } from './super-admin/admin-report/admin-report.component';
import { TeamMembersReportComponent } from './super-admin/team-members-report/team-members-report.component';

import { AddMemberComponent } from './super-admin/add-member/add-member.component';
import { MemberComponent} from './super-admin/member/member.component';
import { MembersLeadComponent } from './super-admin/members-lead/members-lead.component';
import { DashboardsComponent } from './super-admin/dashboards/dashboards.component';
import { TargetsComponent } from './super-admin/targets/targets.component';
import { AddTargetsComponent } from './super-admin/add-targets/add-targets.component';
import { TeamleaderComponent } from './teamleader/teamleader/teamleader.component';
import { ContactFormViewComponent } from './teamleader/contact-form-view/contact-form-view.component';
import { AddSalesComponent } from './teamleader/add-sales/add-sales.component';
import { AssignedContactsComponent } from './teamleader/assigned-contacts/assigned-contacts.component';
import { AllreportComponent } from './teamleader/allreport/allreport.component';
import { ChangePasswordComponent } from './teamleader/change-password/change-password.component';
import { DragDropsComponent } from './teamleader/drag-drops/drag-drops.component';
import { EditLeadsComponent } from './teamleader/edit-leads/edit-leads.component';
import { ContactFormsComponent } from './teamleader/contact-forms/contact-forms.component';
import { ContactViewsComponent } from './teamleader/contact-views/contact-views.component';
import { ActivityComponent } from './teamleader/activity/activity.component';
import { SchedulesComponent } from './teamleader/schedules/schedules.component';
import { EmailsComponent } from './teamleader/emails/emails.component';
import { ScheduleEmailsComponent } from './teamleader/schedule-emails/schedule-emails.component';
import { EventViewsComponent } from './teamleader/event-views/event-views.component';
import { ContactgroupsComponent } from './teamleader/contactgroups/contactgroups.component';
import { ContactGroupMemberComponent } from './teamleader/contact-group-member/contact-group-member.component';
import { AddContactGroupMemberComponent } from './teamleader/add-contact-group-member/add-contact-group-member.component';
import { AddContactgroupsComponent } from './teamleader/add-contactgroups/add-contactgroups.component';
import { AddMeberssComponent } from './teamleader/add-meberss/add-meberss.component';
import { AddEmailTemplatesComponent } from './teamleader/add-email-templates/add-email-templates.component';
import { EmailTemplatesComponent } from './teamleader/email-templates/email-templates.component';
import { EmailSignaturesComponent } from './teamleader/email-signatures/email-signatures.component';
import { AddEmailSignaturesComponent } from './teamleader/add-email-signatures/add-email-signatures.component';
import { ReportsComponent } from './teamleader/reports/reports.component';
import { MembersComponent } from './admin/members/members.component';
import { MembersesComponent } from './teamleader/memberses/memberses.component';
import { TargetssComponent } from './teamleader/targetss/targetss.component';
import { AddingTargetComponent } from './teamleader/adding-target/adding-target.component';
import { QuotationsComponent } from './teamleader/quotations/quotations.component';
import { QuotationListsComponent } from './teamleader/quotation-lists/quotation-lists.component';
import { QuotationGenerationsComponent } from './teamleader/quotation-generations/quotation-generations.component';
import { InvoicesComponent } from './teamleader/invoices/invoices.component';
import { InvoiceGeneratesComponent } from './teamleader/invoice-generates/invoice-generates.component';
import { ProductsComponent } from './teamleader/products/products.component';
import { AddProductsComponent } from './teamleader/add-products/add-products.component';
import { FullCalendersComponent } from './teamleader/full-calenders/full-calenders.component';
import { ChangePasswordsComponent } from './admin/change-passwords/change-passwords.component';
import { ChangePasswordesComponent } from './executive/change-passwordes/change-passwordes.component';
import { ChangePasswordessComponent } from './super-admin/change-passwordess/change-passwordess.component';
import { NotificationSidebarComponent } from './executive/notification-sidebar/notification-sidebar.component';
import { NotificationAdminComponent } from './admin/notification-admin/notification-admin.component';


export const routes: Routes = [
  {path:"",redirectTo:"",pathMatch:"full"},

    {
        path: "",
        loadComponent: () =>
          import(`./login/login/login.component`).then((m) => m.LoginComponent),
      },
      {
        path: "forgotpassword",
        loadComponent: () =>
          import(`./login/forgot-password/forgot-password.component`).then((m) => m.ForgotPasswordComponent),
      },
      
      {path:'executive', component:ExecutiveComponent,
      children:[

        { path: '', component: DragDropComponent , canActivate: [AuthGuard],
        data: {
          role: 'salesperson'
        },
    },
    { path: 'lead', component: LeadComponent , canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }
    },
    { path: 'add-edit-lead/:id1/:id2', component: EditLeadComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    } 
    },
    { path: 'add-lead', component: LeadDialogComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }  },
    { path: 'add-contact/:id/:id1', component: ContactFormComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    } },
    { path: 'add-contact', component: ContactFormComponent, canActivate: [AuthGuard],
      data: {
        role: 'salesperson'
      } },
    { path: 'contact', component:ContactViewComponent , canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    { path: 'contact/:id', component:ContactViewComponent , canActivate: [AuthGuard],
      data: {
        role: 'salesperson'
      }},
    { path: 'customer-contact/:id', component:ContactCustomerComponent , canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    { path: 'customer-add', component:CompanyComponent , canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    { path: 'customer-edit/:id', component:CompanyComponent , canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    { path: 'customer-list', component:CustomerListComponent , canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    
    { path: 'customerlist', component:CustomerlistComponent , canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    { path: 'notification-list', component:NotificationSidebarComponent , canActivate: [AuthGuard],
      data: {
        role: 'salesperson'
      }},
      
    
    { path: 'activities', component: ActivitiesComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    { path: 'add-activities', component: AddEditActivityComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    { path: 'edit-activities/:id', component: AddEditActivityComponent, canActivate: [AuthGuard],
      data: {
        role: 'salesperson'
      }},
    { path: 'open-activity-report', component: ActivityReportComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    { path: 'last-updated-report', component: LastUpdatedReportComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    { path: 'hot-lead-report', component: HotLeadReportComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
   
    { path: 'schedule', component:ScheduleComponent , canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    } },
    { path: 'update-activities/:id', component:ScheduleComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }  },
   
    { path: 'pipeline', component: DragDropComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }  },
  
   
    { path: 'schedule/:id', component: ScheduleComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    { path: 'schedule/:id/:str', component: ScheduleComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    
    {path:'revenue-report',component:ReportComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
   
    {path:'change-password/:id',component:ChangePasswordesComponent, canActivate: [AuthGuard],
      data: {
        role: 'salesperson'
      }},
    {path:'quotation',component:QuotationListComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    {path:'quotation-generate/:id',component:QuotationGenerationComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    {path:'quotation-generate',component:QuotationGenerationComponent, canActivate: [AuthGuard],
      data: {
        role: 'salesperson'
      }},
    {path:'opportunity_list',component:OpportunityListComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    {path:'add-edit-opportunity/:id/:id1',component:OpportunityAddEditComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
    {path:'add-edit-opportunity/:id',component:OpportunityAddEditComponent, canActivate: [AuthGuard],
      data: {
        role: 'salesperson'
      }},
    
  
    
    {path:'product',component:ProductComponent, canActivate: [AuthGuard],
    data: {
      role: 'salesperson'
    }},
   
    
    // {path:'change-password/:id',component:ChangePasswordComponent, canActivate: [AuthGuard],
    // data: {
    //   role: 'salesperson'
    // }}
      ]},
      
      { path: 'admin', component: AdminComponent,children:[
        { path: '', component: DashboardComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }},
        { path: 'team-members', component:MembersComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }  },
        { path: 'add-team-member', component:AddMembersComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }    },
        { path: 'notifications-admin', component:NotificationAdminComponent,canActivate: [AuthGuard],
          data: {
            role: 'admin'
          }    },
        { path: 'add-team-member/:id', component:AddMembersComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }    },
        { path: 'target', component:TargetComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }    },
        { path: 'add-target', component:AddTargetComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }
          },
        { path: 'team', component:TeamComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }
        },
        { path: 'add-team', component:AddTeamComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }  },
        { path: 'add-team/:id', component:AddTeamComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }  },
        { path: 'team-members-details/:id', component:TeamMembersDetailsComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }  },
        { path: 'team-report', component:TeamReportComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }  },
        { path: 'team-member-report/:id', component:TeamMemberReportComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }  },
        { path: 'add-team-members/:id', component:AddTeamMembersComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }  },
        { path: 'add-team-members/:id/:idd/:uid', component:AddTeamMembersComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }  },
        { path: 'members-leads/:id/:cid', component:MemberLeadsComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }  },
        { path: 'configuration', component:ConfigurationComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }  },
        {path:'add-configuration',component:AddConfigurationComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }  },
        {path:'change-password/:id',component:ChangePasswordsComponent, canActivate: [AuthGuard],
          data: {
            role: 'admin'
          }},
        {path:'add-configuration/:id',component:AddConfigurationComponent,canActivate: [AuthGuard],
        data: {
          role: 'admin'
        }  }
      ]},




      
      { path: 'superAdmin', component: SuperAdminComponent,children:[
        { path: '', component: DashboardsComponent,canActivate: [AuthGuard],
        data: {
          role: 'superadmin'
        } },
        { path: 'admins', component:MemberComponent,canActivate: [AuthGuard],
        data: {
          role: 'superadmin'
        }  },
        { path: 'add-admin', component:AddMemberComponent,canActivate: [AuthGuard],
        data: {
          role: 'superadmin'
        }  },
        { path: 'add-admin/:id', component:AddMemberComponent,canActivate: [AuthGuard],
        data: {
          role: 'superadmin'
        }  },
        { path: 'target', component:TargetsComponent,canActivate: [AuthGuard],
        data: {
          role: 'superadmin'
        }  },
        { path: 'add-target', component:AddTargetsComponent,canActivate: [AuthGuard],
        data: {
          role: 'superadmin'
        } },
        { path: 'admin-report/:id', component:AdminReportComponent,canActivate: [AuthGuard],
        data: {
          role: 'superadmin'
        }},
        { path: 'team-member-report/:id/:cid', component:TeamMembersReportComponent,canActivate: [AuthGuard],
        data: {
          role: 'superadmin'
        }},
        {path:'change-passwordes/:id',component:ChangePasswordessComponent, canActivate: [AuthGuard],
          data: {
            role: 'superadmin'
          }},
        { path: 'member-leads/:ids/:id/:cid', component:MembersLeadComponent,canActivate: [AuthGuard],
        data: {
          role: 'superadmin'
        }},
      ]},




      { path: 'teamleader', component: TeamleaderComponent,children:[

        { path: '', component:DragDropsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }, },
        
        { path: 'edit-lead/:id', component: EditLeadsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }  },
        { path: 'add-contact', component: ContactFormsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        } },
        { path: 'add-contact/:id', component: ContactFormsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        } },
        { path: 'contact-view/:id', component:ContactFormViewComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        } },
        { path: 'contact', component:ContactViewsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        } },
        { path: 'activities', component: ActivityComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        { path: 'calendar', component:FullCalendersComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }  },
        { path: 'add-salesteam', component:AddSalesComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }  },
        { path: 'schedule', component:SchedulesComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }  },
        { path: 'update-activities/:id', component:SchedulesComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }  },
        { path: 'email', component:EmailsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }  },
        { path: 'scheduleemail', component:ScheduleEmailsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }  },
        { path: 'update-scheduleemail/:id', component:ScheduleEmailsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }  },
        { path: 'pipeline', component: DragDropsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }  },
       
        { path: 'event', component:EventViewsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        } },
        { path: 'schedule/', component: SchedulesComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        { path: 'schedule/:id/:str', component: SchedulesComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'contact-group',component:ContactgroupsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'add-contactgroup',component:AddContactgroupsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'update-contactgroup/:id',component:AddContactgroupsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'contact-group-member/:id',component:ContactGroupMemberComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        // {path:'notifications-teamleader',component:,canActivate: [AuthGuard],
        //   data: {
        //     role: 'teamleader'
        //   }},
        {path:'add-contactgroup-member/:id/:gid',component:AddContactGroupMemberComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'update-contactgroup-member/:id',component:AddContactGroupMemberComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'add-email-template',component:AddEmailTemplatesComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'update-email-template/:id',component:AddEmailTemplatesComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'email-template',component:EmailTemplatesComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'email-signature',component:EmailSignaturesComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'add-email-signature',component:AddEmailSignaturesComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'update-email-signature/:id',component:AddEmailSignaturesComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'report',component:ReportsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'add-member',component:AddMeberssComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'add-member/:id',component:AddMeberssComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'members',component:MembersesComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'target',component:TargetssComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'add-target/:id/:mid',component:AddingTargetComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'allreport/:id/:mid',component:AllreportComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'contacts-list',component:AssignedContactsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'add-quotation',component:QuotationsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'update-quotation/:id',component:QuotationsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'quotation',component:QuotationListsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'quotation-generate/:id',component:QuotationGenerationsComponent,canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'invoice/:id',component:InvoicesComponent, canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'invoice-generate/:id',component:InvoiceGeneratesComponent, canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'product',component:ProductsComponent, canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'add-product',component:AddProductsComponent, canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'edit-product/:id',component:AddProductsComponent, canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }},
        {path:'change-password/:id',component:ChangePasswordComponent, canActivate: [AuthGuard],
        data: {
          role: 'teamleader'
        }}
      ]},
      
                 
];

