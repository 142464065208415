import { Component, OnInit,ViewEncapsulation,Output,EventEmitter, viewChild, ViewChild } from '@angular/core';
import {FormGroup,FormControl,Validators,FormArray, FormBuilder,NgForm, FormsModule} from '@angular/forms';
import { ContactFormService } from '../../services/contact-form.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { PipelineService } from '../../services/pipeline.service';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { apiurl } from '../../global';
import { SuccessComponent } from '../../success/success.component';
import { FailComponent } from '../../fail/fail.component';
import { title } from 'process';
import { CommonServiceService } from '../../services/common-service.service';
import { CompanyservService } from '../../services/companyserv.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { response } from 'express';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { log } from 'console';
import { imgurl } from '../../global';
@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [ReactiveFormsModule,MatDividerModule,CommonModule,NgxFileDragDropModule,FormsModule],
  providers : [ContactFormService,LoginService,PipelineService,CommonServiceService,CompanyservService],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss'
})
export class ContactFormComponent {
  singlecontact:any[] =[];
    contactForm!: FormGroup;
    submitted = false;
    salesteam: any[] = [];
    contact:any[] =[];
    users:any=[];
    selectedImages: any;
    public contactid:any[]=[];
    private cusId:any
    public user: any;
    imagesview:any
    ischecked:boolean=false;
    public isEdit:boolean = false;
    @Output() private ontaskcomplete = new EventEmitter();
    public images: any[]=[];
    imgname: any;
    contactdetails:any;
    contactId:any;
    countryList:any
    stateList:any
    cityList:any
    imgurl=imgurl
    organizationlist:any;
    userid:any;
    selectedOption1: string = '';
    selectedOption2: string = '';
    phoneCodes:string[] = [];
    @ViewChild('contactaddress') contactaddress:any;
    constructor(private dialog: MatDialog, private toaster: ToastrService,private formBuilder: FormBuilder,private contactservice: ContactFormService,private loginservice: LoginService,
    private router: Router,private servdata:CommonServiceService,private companyserv:CompanyservService,private location: Location,private pservice: PipelineService,private route: ActivatedRoute) { }
    

    ngOnInit() {
      console.log( this.contactForm,'contactformmm')
      this. phoneCodes = [
        '+93', '+355', '+213', '+376', '+244', '+1-268', '+54', '+374', '+61', '+43', 
        '+994', '+1-242', '+973', '+880', '+1-246', '+375', '+32', '+501', '+229', '+975', 
        '+591', '+387', '+267', '+55', '+673', '+359', '+226', '+257', '+238', '+855', 
        '+237', '+1', '+236', '+235', '+56', '+86', '+57', '+269', '+242', '+243', '+506', 
        '+385', '+53', '+357', '+420', '+45', '+253', '+1-767', '+1-809', '+593', '+20', 
        '+503', '+240', '+291', '+372', '+268', '+251', '+679', '+358', '+33', '+241', 
        '+220', '+995', '+49', '+233', '+30', '+1-473', '+502', '+224', '+245', '+592', 
        '+509', '+504', '+36', '+354', '+91', '+62', '+98', '+964', '+353', '+972', 
        '+39', '+1-876', '+81', '+962', '+7', '+254', '+686', '+850', '+82', '+965', 
        '+996', '+856', '+371', '+961', '+266', '+231', '+218', '+423', '+370', '+352', 
        '+261', '+265', '+60', '+960', '+223', '+356', '+692', '+222', '+230', '+52', 
        '+691', '+373', '+377', '+976', '+382', '+212', '+258', '+95', '+264', '+674', 
        '+977', '+31', '+64', '+505', '+227', '+234', '+389', '+47', '+968', '+92', 
        '+680', '+507', '+675', '+595', '+51', '+63', '+48', '+351', '+974', '+40', 
         '+250', '+1-869', '+1-758', '+1-784', '+685', '+378', '+239', '+966', 
        '+221', '+381', '+248', '+232', '+65', '+421', '+386', '+677', '+252', '+27', 
        '+211', '+34', '+94', '+249', '+597', '+46', '+41', '+963', '+886', '+992', 
        '+255', '+66', '+670', '+228', '+676', '+1-868', '+216', '+90', '+993', '+688', 
        '+256', '+380', '+971', '+44',  '+598'
      ];

      this.phoneCodes.sort((a, b) => {
        // Remove '+' and '-' for proper numeric sorting
        const numA = parseInt(a.replace(/[^\d]/g, ''), 10);
        const numB = parseInt(b.replace(/[^\d]/g, ''), 10);
        return numA - numB;
      });

      this.route.params.subscribe((params:any)=>{
        if (params['id']!=undefined){
          this.cusId=params['id'];
        }
      })
      this.route.params.subscribe((params:any)=>{
        if (params['id1']!=undefined){
          this.contactId=params['id1'];
        }
      })


      this.user = this.loginservice.getUserdata()
      this.userid=this.user.data.id
      this.setForm()
    
      if(this.contactId){
        this.isEdit = true;
        this.getcontact()
      }

      this.getCountry()
      this.getState()
      this.getCity()
      this.getOrganisation()
    }

    getOrganisation(){
      this.companyserv.getOrganization(this.userid,'').subscribe(
        (organizationlist: any) =>{ 

          this.organizationlist = organizationlist.company_list;
          
         }
        );    
   }
   organizations(event:any){
    let value = this.contactaddress.nativeElement.value
   
    if(event.target.checked){

      this.organizationlist.forEach((item:any)=>{

  
        if(value==item?.id){
        
          this.contactForm.patchValue({ address_line_1:item?.address1})
          this.contactForm.patchValue({ address_line_2:item?.address2})
          this.contactForm.patchValue({mobile_phone:item?.phoneNumber})
          this.contactForm.patchValue({zipcode:item?.zipcode})
          this.contactForm.patchValue({fax:item?.fax})
          this.contactForm.patchValue({state:item?.state})
          this.contactForm.patchValue({city:item?.city})
        }
      })
    }
else{
  this.contactForm.get('city')?.setValue('')
  this.contactForm.get('address_line_1')?.setValue('')
  this.contactForm.get('address_line_2')?.setValue('')
  this.contactForm.get('mobile_phone')?.setValue('')
  this.contactForm.get('zipcode')?.setValue('')
  this.contactForm.get('fax')?.setValue('')
  this.contactForm.get('state')?.setValue('')
}

   }

    getCountry(){
      this.servdata.getCountry(this.userid).subscribe((response:any)=>{
        this.countryList=response.data
     })
    }
  
    getState(){
      this.servdata.getState(this.userid).subscribe((response:any)=>{
        this.stateList=response.data
     })
    }
  
    getCity(){
      this.servdata.getCity(this.userid).subscribe((response:any)=>{
        this.cityList=response.data
     })
    }

    setForm(){
      
      this.contactForm = this.formBuilder.group({
        primary: [this.contactdetails?.primary ||false],
        customer_name: [this.contactdetails?.customer ||'', [Validators.required]],
        first_name: [this.contactdetails?.first_name ||'', [Validators.required,Validators.pattern(/^[a-zA-Z ]+$/)]],
        last_name: [this.contactdetails?.last_name ||'', [Validators.required,Validators.pattern(/^[a-zA-Z ]+$/)]],
        designation: [this.contactdetails?.designation ||''],
        email: [this.contactdetails?.email ||'', [ Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
        country_code: [this.contactdetails?.country_code || '+91', Validators.required],
        office_phone:[this.contactdetails?.office_phone ||'', [Validators.required,Validators.pattern(/^[0-9]{10}$/)]],
        mobile_phone:[this.contactdetails?.mobile_phone ||''],
        image:[this.contactdetails?.image || null],
        address_line_1: [this.contactdetails?.address_line_1 ||'',[Validators.required]],
        address_line_2: [this.contactdetails?.address_line_2 ||''],
        city: [this.contactdetails?.city ||'',[Validators.required]],
        comments:[this.contactdetails?.comments ||''],
        state: [this.contactdetails?.state ||'',[Validators.required]],
        zipcode: [this.contactdetails?.zipcode ||'', [Validators.required, Validators.pattern('[0-9]{6}')]],
        fax: [this.contactdetails?.fax ||'', [Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)]],  
    }, );
    this.selectedOption1 = '+91'
    }


    onSubmit(){
    
      this.contactForm.markAllAsTouched();
    
      if(this.contactForm.valid) {
       
      const formData = new FormData();
      formData.append('primary', this.contactForm.value.primary);
      formData.append('first_name', this.contactForm.value.first_name);
      formData.append('email', this.contactForm.value.email);
      formData.append('last_name', this.contactForm.value.last_name);
      formData.append('designation', this.contactForm.value.designation);
      formData.append('country_code', this.contactForm.value.country_code);
      formData.append(' office_phone', this.contactForm.value.office_phone);
      formData.append(' mobile_phone', this.contactForm.value.mobile_phone);
      formData.append(' address_line_1', this.contactForm.value.address_line_1);
       formData.append('address_line_2',this.contactForm.value.address_line_2);
       formData.append('city',this.contactForm.value.city);
       formData.append('state',this.contactForm.value.state);
       formData.append('zipcode',this.contactForm.value.zipcode)
       formData.append('fax',this.contactForm.value.fax)
       formData.append('salesperson',this.user.data.id)
       formData.append('creator',this.user.data.id)
       formData.append('comments',this.contactForm.value.comments)
       formData.append('customer',this.contactForm.value.customer_name)
       console.log('ooooooooooooooooo', formData)
      if (this.selectedImages) {
        formData.append('image', this.selectedImages);
      }

      this.contactservice.createContact(formData).subscribe(
        (response: any) => {
          if (response.status_code === 201) {
            this.toaster.success(response.message, 'Success');
            this.router.navigate(['/executive/contact']);
          } 
           else {
            this.toaster.error('Unexpected error occurred.', 'Error');
          }
        },
        (error: any) => {
          if (error.status === 400) {
           
            this.toaster.error(error.error.message || 'Bad request', 'Error');
          } else {
            this.toaster.error('An unexpected error occurred. Please try again later.', 'Error');
          }
        }
      );
    } else {
     console.log(this.contactForm, "formmmmmmmmmmmmmmmmmmm");
     
      this.toaster.error('Please fill out the form correctly.', 'Validation Error');
    }
    
  }

    getcontact(){
      this.contactservice.getContactdetail(this.contactId).subscribe((response:any)=>{
        this.contactdetails=response?.data?.contact
        this.setForm()
      })

    }

  editContact(){
    this.contactForm.get('image')?.setValue(this.selectedImages)
    this.contactForm.markAllAsTouched();
    // const postData={
    //   primary: this.contactForm.value.primary,
    //   // customer_name: this.contactForm.value.customer_name,
    //   first_name:this.contactForm.value.first_name,
    //   last_name: this.contactForm.value.last_name,
    //   designation:this.contactForm.value.designation,
    //   email:this.contactForm.value.email,
    //   country_code:this.contactForm.value.country_code,
    //   office_phone: this.contactForm.value.office_phone,
    //   mobile_phone: this.contactForm.value.mobile_phone,
      
    //   address_line_1: this.contactForm.value.address_line_1,
    //   address_line_2: this.contactForm.value.address_line_2,
    //   city:this.contactForm.value.city,
    //   state:this.contactForm.value.state,
    //   zipcode:this.contactForm.value.zipcode,
    //   fax:this.contactForm.value.fax,
      
    //   salesperson:this.user.data.id,
    //   creator:this.user.data.id,
    //   customer: this.contactForm.value.customer_name, 
    // }
    if (this.contactForm.valid){
      console.log('contactformmmmmmmm')
      const formData = new FormData();
      formData.append('primary', this.contactForm.value.primary);
      formData.append('first_name', this.contactForm.value.first_name);
      formData.append('email', this.contactForm.value.email);
      formData.append(' last_name', this.contactForm.value.last_name);
      formData.append('designation', this.contactForm.value.designation);
      formData.append('country_code', this.contactForm.value.country_code);
      formData.append(' office_phone', this.contactForm.value.office_phone);
      formData.append(' mobile_phone', this.contactForm.value.mobile_phone);
      formData.append(' address_line_1', this.contactForm.value.address_line_1);
       formData.append('address_line_2',this.contactForm.value.address_line_2);
       formData.append('city',this.contactForm.value.city);
       formData.append('state',this.contactForm.value.state);
       formData.append('zipcode',this.contactForm.value.zipcode)
       formData.append('fax',this.contactForm.value.fax)
       formData.append('salesperson',this.user.data.id)
       formData.append('creator',this.user.data.id)
       formData.append('customer',this.contactForm.value.customer_name)
      formData.append('comments',this.contactForm.value.comments)
      if (this.selectedImages) {
        console.log(this.selectedImages,'imageeeeee')
        formData.append('image', this.selectedImages);
      }
    this.contactservice.editcontact(this.contactId,formData).subscribe((response:any)=>{
      if(response.status_code === 200){
        this.toaster.success(response.message,'success')
        this.router.navigate(['/executive/contact']);
      }
      

    },
    
    (error:any) => {
      if(error.status === 400){
        this.toaster.error(error.error.message,'error');

      }
       
    })
    }
   }

   cancel(){
    this.location.back(); 
   }

   onFileChanges(event: any) {
    if(event != null && event.length > 0){
    const files: FileList = event;
    const file: File = files[0]; 
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.selectedImages = e.target.result;
    };
    reader.readAsDataURL(file);
  
   
    }
    }
}
