import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [MatMenuModule,RouterModule,CommonModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {
  activeLink: any;

  constructor(	private router:Router, private loginService : LoginService) { }

  ngOnInit(): void {
    if(this.router.url == '/teamleader'){
      this.activeLink = 'dashboard' 

    }
    else if(this.router.url=='/teamleader/contact-group')
    {
      this.activeLink = 'logs'
    }
    else if(this.router.url=='/teamleader/email')
    {
      this.activeLink = 'cronjobs'
    }
    else if(this.router.url=='/teamleader/email-template')
   {
       this.activeLink = 'email'
   }
  else if(this.router.url== '/teamleader/email-signature')
  {
       this.activeLink='database'
  }
  else if(this.router.url== '/teamleader/report')
  {
       this.activeLink='report'
  }
  else if(this.router.url== '/teamleader/members')
  {
      this.activeLink='members'
  }
  else if(this.router.url== '/teamleader/activities')
  {
      this.activeLink='Activities'
  }
  else if(this.router.url== '/teamleader/quotation')
  {
      this.activeLink='Quotation'
  } 
  else if(this.router.url== '/teamleader/calendar')
  {
      this.activeLink='Calender'
  }  
  else if(this.router.url== '/teamleader/products')
  {
      this.activeLink='Products'
  } 
  
 

  }

  logout()
  {
    console.log('dbd')
    localStorage.removeItem("usertoken")
    localStorage.removeItem("userdata")
    this.router.navigate([''])
    
  
  
  }

  setActiveLink(link: string) {
    this.activeLink = link;
  }
}