import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { provideToastr } from 'ngx-toastr';
import { provideRouter } from '@angular/router';
import { routes } from './app/app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient } from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';
import { importProvidersFrom } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
bootstrapApplication(AppComponent, {
  providers:[provideToastr(),
  provideRouter(routes), provideAnimationsAsync(), provideAnimationsAsync(),provideHttpClient(), importProvidersFrom(MatNativeDateModule),]
})
  .catch((err) => console.error(err));