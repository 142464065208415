import { Component } from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import { SuccessComponent } from '../../success/success.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';
 
 
 
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [MatMenuModule,RouterModule,CommonModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
 
  constructor(private router:Router,private dialog: MatDialog) { }
  isSubMenuVisible: boolean = false;  
  activeLink: string = '';  
 
 
  toggleSubMenu() {
    this.isSubMenuVisible = !this.isSubMenuVisible;
  }
  onSubMenuClick(event: MouseEvent) {
    event.stopPropagation();
  }
 
  ngOnInit(): void {
    if(this.router.url == '/executive'){
      this.activeLink = 'dashboard'
 
    }
    else if(this.router.url=='/executive/activities')
    {
      this.activeLink = 'Activities'
    }
    else if(this.router.url=='/executive/quotation')
    {
      this.activeLink = 'Quotation'
    }
    else if(this.router.url=='/teamleader/customerlist')
   {
       this.activeLink = 'Customer'
   }
  else if(this.router.url== '/executive/contact')
  {
       this.activeLink='Contact'
  }
  else if(this.router.url== '/executive/lead')
  {
       this.activeLink='leads'
  }
  else if(this.router.url== '/executive/opportunity_list')
  {
      this.activeLink='Opportunity'
  }
  else if(this.router.url== '/executive/Report')
  {
      this.activeLink='Report'
  }
 
  }
  logout()
  {
    localStorage.removeItem("usertoken")
    localStorage.removeItem("userdata")
 
  const confirmDialog = this.dialog.open(SuccessComponent, {
    data: {
      title: 'Success',
      message: 'Logout Successfully!'
    }
    });
    confirmDialog.afterClosed().subscribe(result => {
    window.location.href="login"
  });
  }
 
  showMenu: boolean = false;
 
 
 
  setActiveLink(link: string) {
    this.activeLink = link;
    if (this.activeLink !== 'Report') {
      this.isSubMenuVisible = false;
    } else {
     
      this.isSubMenuVisible = !this.isSubMenuVisible;
    }
  }}