import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { MenutoggleService } from '../../services/menutoggle.service';

@Component({
  selector: 'app-admin',
  standalone: true,
  providers:[MenutoggleService],
  imports: [RouterOutlet, RouterModule, CommonModule, SidebarComponent, NavbarComponent],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent implements OnInit {
	public col2width:string = "col-span-2"
	public col10width:string = "col-span-10"

	constructor(
		private menuservice:MenutoggleService,
		private router:Router
	) { }

    ngOnInit(): void {
	  this.menuservice.onGetnav.subscribe((data:any)=>{
		  if (data == false){
			  this.col2width = "hidden"
			  this.col10width = "col-span-12 animate__fadeOutLeftBig"

		  }else{
			  this.col2width = "col-span-2"
			  this.col10width = "col-span-10 animate__fadeInLeftBig"
		  }
		});
  }

	closemenu(){
		this.menuservice.onSetnav(false);
	}

	refresh() {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(['/admin']);
		});
	 
	}
	
}

