<div class="max-w w-full  space-y-8 p-10 bg-white rounded-xl shadow-lg z-10  overflow-y-auto "style="height:85vh !important;margin-left:0vh !important">
  <div class="grid gap-8 grid-cols-1 ">
      <form class="" [formGroup]="registerForm" >
      <div class="flex flex-col ">
              <div class="flex flex-col sm:flex-row items-center mb-6">
                  <h2 class="font-semibold text-lg mr-auto">Send Mail</h2>
                  <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
                    
                    <button  class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 border-blue-800"  routerLink="/teamleader/email"  (click)="cancel()">Cancel</button>

                    <button class="mb-2 md:mb-0  px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg "  style="background-color: #2a303c;" (click)="onSubmit()">Send</button>
                </div>
              </div>
              <mat-divider></mat-divider>
            <div class="mt-3 bg-white rounded border border-gray-100  px-20">
              <div class="flex flex-wrap -mx-3 ">
                <div class="w-full md:w-1/2 mt-5 px-3 mb-2 ">
                  <label class="flex  tracking-wide text-gray-700 text-sm font-bold" for="grid-first-name">
                  <input type="radio"  value="Individual" (change)="groups('individual')" formControlName="mailtype" [checked]="true"  class="form-radio focus:outline-none focus:shadow-outline cursor-pointer" />
                  <div class="select-none text-gray-700 px-2">Individual</div> </label>
                </div>
                <div class="w-full md:w-1/2 mt-5 px-3 mb-2">
                  <label class="flex  tracking-wide text-gray-700 text-sm font-bold" for="grid-first-name">
                    <input type="radio" value="Group" (change)="groups('group')" formControlName="mailtype" class="form-radio focus:outline-none focus:shadow-outline cursor-pointer" />
                    <div class="select-none text-gray-700 px-2"> Group</div> 
                  </label>
                </div>
                <div *ngIf="submitted && f['mailtype'].errors" class="invalid-feedback text-sm">
                  <div *ngIf="f['mailtype'].errors['required']">Please fill out this field.</div>
                </div>
                <div class="w-full px-3 mb-2" *ngIf="isGroup">
                  <label class="block  tracking-wide text-gray-700   mb-2" for="grid-first-name">Group</label>
                  <select *ngIf="isGroup" #group (change)="groupmembers(group.value)"  formControlName="groupid" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                    <option [value]=null selected disabled >Select </option>
                    <option *ngFor="let t of group_list" value="{{t.id}}">{{t.name}}</option>
                  </select>
                </div>
              </div>
              <div class="flex row  mb-2">
                <div class="col-lg-6  w-full">
                  <label class="block  tracking-wide text-gray-700" for="grid-first-name">Template</label>
                    <select  #emailtemp (change)="emailtemplate(emailtemp.value)" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm" formControlName="template">
                      <option  [value]=null selected disabled >Select</option>
                      <option *ngFor="let t of template" value="{{t.id}}">{{t.name}}</option>
                    </select>
                </div>
                <div class="col-lg-6 mx-2 w-full">
                  <label class="block  tracking-wide text-gray-700" for="grid-first-name">Signature</label>
                  <select  #emailsign (change)="emailsignature(emailsign.value)" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm" formControlName="signature">
                    <option  [value]=null selected disabled  >Select</option>
                    <option *ngFor="let s of esignature" value="{{s.id}}">{{s.name}}</option>
                  </select>
                </div>
              </div>
              <div class="flex flex-wrap">
                <div class="w-full  "  *ngIf="isGroup">
                  <label class="block  tracking-wide text-gray-700  mb-2" for="grid-first-name">Recipients</label>
                  <input type="text"  formControlName="contacts"  [ngClass]="{ 'is-invalid': submitted && f['contacts'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                  <div *ngIf="submitted && f['contacts'].errors" class="invalid-feedback text-sm">
                    <div *ngIf="f['contacts'].errors['required']">Please fill out this field.</div>
                  </div>
                </div>
                <div class="w-full "  *ngIf="!isGroup">
                  <label class="block  tracking-wide text-gray-700 mb-2" for="grid-first-name">Recipients</label>
                  <select  formControlName="contacts"  [ngClass]="{ 'is-invalid': submitted && f['contacts'] .errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                    <option  [value]=null selected disabled >Select</option>
                    <option *ngFor="let list of contact" value="{{list.email}}">{{list.email}}</option>
                  </select>
                  <div *ngIf="submitted && f['contacts'].errors" class="invalid-feedback text-sm">
                    <div *ngIf="f['contacts'].errors['required']">Please fill out this field.</div>
                  </div>
                </div>
                <div class="w-full mb-2">
                  <label class="block  tracking-wide text-gray-700   mb-2" for="grid-last-name">cc</label>
                  <input type="text"  formControlName="cc" [ngClass]="{ 'is-invalid': submitted && f['cc'] .errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                  <div *ngIf="submitted && f['cc'].errors" class="invalid-feedback text-sm">
                    <div *ngIf="f['cc'].errors['email']">Please enter a valid email address.</div>
                  </div>
                </div>
                <div class="w-full mb-2">
                  <label class="block  tracking-wide text-gray-700   mb-2" for="grid-last-name">bcc</label>
                  <input type="text"  formControlName="bcc" [ngClass]="{ 'is-invalid': submitted && f['bcc'] .errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                  <div *ngIf="submitted && f['bcc'].errors" class="invalid-feedback text-sm">
                    <div *ngIf="f['bcc'].errors['email']">Please enter a valid email address.</div>
                  </div>
                </div>    
                <div class="w-full mb-2">
                  <label class="block  tracking-wide text-gray-700   mb-2" for="grid-last-name">Subject</label>
                  <input type="text"  formControlName="subject" [ngClass]="{ 'is-invalid': submitted && f['subject'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm">
                  <div *ngIf="submitted && f['subject'].errors" class="invalid-feedback text-sm">
                    <div *ngIf="f['subject'].errors['required']">Please fill out this field.</div>
                  </div>
                </div>
              </div> 
              <div class="flex flex-wrap -mx-3 mt-3 mb-4">
                <div class="w-full mx-3">
                  <div class=" justify-center">
                      <!-- <angular-editor formControlName="message" [ngClass]="{ 'is-invalid': submitted && f['message'].errors }" class="h-10 justify-center" [(ngModel)]="htmlContent" [config]="config"></angular-editor> -->
                      <!-- <textarea 
                      formControlName="message"  [(ngModel)]="htmlContent"
                      [ngClass]="{ 'is-invalid': submitted && f['message'].errors }" 
                      id="editor" [disabled]="false"
                      #editor 
                     >
                    </textarea> -->
                    <textarea id="editor"  formControlName="message"  
                    [ngClass]="{ 'is-invalid': submitted && f['message'].errors }" ></textarea> <!-- CKEditor will be initialized here -->
                  </div>
                  <div *ngIf="submitted && f['message'].errors" class="invalid-feedback text-sm">
                      <div *ngIf="f['message'].errors['required']">Please fill out this field.</div>
                  </div>
                 
                </div>
              </div>
              </div>
           </div>
        </form>
    </div>
</div>