import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { MainService } from '../../main-service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
declare var echarts: any;

@Component({
  selector: 'app-dashboards',
  standalone: true,
  imports: [CommonModule,NgxPaginationModule,RouterModule,FormsModule], 
  providers: [LoginService, MainService],
  templateUrl: './dashboards.component.html',
  styleUrl: './dashboards.component.scss'
})
export class DashboardsComponent implements OnInit {
  public user:any
  adminList:any[]=[]
  admintarget:any
  adminachievemnet:any
  t_percentage:any
  userid: any;
  typeArr : any =  ['Monthly','Yearly','Quaterly'];
  subTypeArr : any[] = [{id:1,month:'January'},{id:2,month:'February'},{id:3,month:'March'},{id:4,month:'April'},{id:5,month:'May'},{id:6,month:'June'},{id:7,month:'July'},{id:8,month:'August'},{id:9,month:'September'},{id:10,month:'October'},{id:11,month:'November'},{id:12,month:'December'}]
  typeModel : any = 'Monthly';
  subType : any = null;
  originalSubArr :any =[];
  adminListArr : any [] = [];
  dashboardData : any;
  totalChartData : any [] = [];
  adminChartData : any [] = [];
  adminModel : any = null;
  xLabelArr : any[] = [];
  yLabelArr : any [] = [];
  achievementArr : any[] = [];
  targetArr : any[] = [];
  percentageArr : any[] = [];
  yearFilter : any[] = [];
  yearModel : any = '';


  constructor(private loginservice:LoginService,private mservice:MainService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata();
    // Get the current month name
    const currentMonthIndex = new Date().getMonth() + 1; // 0-indexed
    this.subType = currentMonthIndex;
    this.originalSubArr = this.subTypeArr;
    // const currentYear = new Date().getFullYear();
    this.yearModel = new Date().getFullYear();
    const lastTenYear: any = this.yearModel - 10
    for(let i = this.yearModel;i >= lastTenYear; i-- ){
      this.yearFilter.push({id:i,year:i})
    }
    this.getDashboardData()
  }

  onFilterChange(event:any){
    this.subTypeArr = [];
    this.subType = null;
    if(event?.target?.value == 'Yearly'){
      const currentYear = new Date().getFullYear();
      const lastTenYear: any = currentYear - 10
      for(let i = currentYear;i >= lastTenYear; i-- ){
        this.subTypeArr.push({id:i,month:i})
      }
    }else if(event?.target?.value == 'Quaterly'){
      this.subTypeArr = [{id:'1-3',month:'January - March'},{id:'4-6',month:'April - June'},{id:'7-9',month:'July - September'},{id:'10-12',month:'October - December'}]
    }else{
      this.subTypeArr = this.originalSubArr;
    }
  }

  onSubFilterChange(event:any){
    this.getDashboardData()
  }

  getDashboardData(){
    this.adminListArr = [];
    this.totalChartData = [];
    this.adminChartData = []
    this.mservice.getSuperAdminDashboard(this.typeModel,this.subType).subscribe((res:any)=>{
      if(res?.data){
        this.dashboardData = res?.data;
        if(this.dashboardData?.total_target_achivements){
          this.totalChartData = [
            { "value": this.dashboardData?.total_target_achivements?.target, "name": "Target" },
            { "value": this.dashboardData?.total_target_achivements?.achieved, "name": "Achieved" }
          ]
          this.getPieGraph();
        }
        if(this.dashboardData?.admin_achivement_percentage){
          this.dashboardData?.admin_achivement_percentage.forEach((item:any)=>{
            this.adminChartData.push({value: item?.achievement_percentage, name: item?.admin_name})
          })
          this.getPieChartAdmin();
        }
        if(res?.data?.admins){
          res?.data?.admins.forEach((item:any)=>{
            this.adminListArr.push({
              id : item?.id,
              name : item?.first_name + ' ' + item?.last_name
            })
          })
          this.adminModel = this.adminListArr[0]?.id;
          this.getBarChartData()
        }
      }
     
    })
  }

  getBarChartData(){
    this.xLabelArr = [];
    this.yLabelArr = [];
    this.achievementArr = [];
    this.targetArr = [];
    this.percentageArr = []
    this.mservice.getSuperAdminBarGraph(this.yearModel,this.adminModel).subscribe((res:any)=>{
      if(res){
        if(res?.admins_monthly_achievements){
          this.xLabelArr = res?.admins_monthly_achievements[0]?.xlabel;
          this.yLabelArr = res?.admins_monthly_achievements[0]?.ylabel;
          res?.admins_monthly_achievements[0]?.monthly_data.forEach((item:any)=>{
            this.targetArr.push(item?.target)
            this.achievementArr.push(item?.achievement);
            this.percentageArr.push(item?.percentage.toFixed(1))
          })
          this.getBarChart()
        }
      }
    })
  }

  getPieGraph() {
    const chartDom = document.getElementById('pieChart')!;
    if (chartDom) {
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          left: 'center'
        },
        color: ['#7d92fe', '#a6baf6'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '75%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 10,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.totalChartData // Use updated data here
          }
        ]
      };
      myChart.setOption(option);
    }
  }

  getPieChartAdmin(){
    const chartDom = document.getElementById(`pieChartAdmin`)!;
    if (chartDom) {
      const myChart = echarts.init(chartDom);
      const option ={
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '75%'],
            center: ['50%', '60%'],
            data: this.adminChartData,
            label: {
              show: true, // Ensure labels are visible
              color: '#000', // Set label text color
              fontSize: 10, // Set font size
              fontWeight: 'bold', // Make labels bold
              formatter: '{b}: {d}%', // Customize label format (name: value (percentage))
            },
            labelLine: {
              show: true, // Display lines connecting labels to the chart
              length: 10, // Length of the line
              length2: 10, // Length of the second segment
              smooth: true, // Smooth the label lines
            },
          }
        ]
      }
      myChart.setOption(option);
    }
  }

  getBarChart() {
    const chartDom = document.getElementById('barChartAdmin')!;
    if (chartDom) {
      const myChart = echarts.init(chartDom);
      // Fix remaining data calculation
      const remainingData = this.targetArr.map((target, index) => 
        Math.max(0, target - this.achievementArr[index])
      );
      // Define the series for the bar chart
      const series = [
        {
          name: 'Achieved',
          type: 'bar',
          stack: 'total',
          barWidth: '53%',
          label: {
            show: true,
            position: 'inside', // Display percentage inside the bar
            fontSize: 9,
            color: '#ffffff',
            rotate: 90, // Rotate the label 
            formatter: (params: any) => {
              const percentage = this.percentageArr[params.dataIndex];
              return percentage > 0 ? `${percentage}%` : ''; // Only show if > 0
            },
          },
          itemStyle: {
            color: '#7d92fe', // Achieved bar color
          },
          data: this.achievementArr, // Achieved values
        },
        {
          name: 'Remaining',
          type: 'bar',
          stack: 'total',
          itemStyle: {
            color: '#a6baf6'
          },
          data: remainingData, // Remaining values
        },
      ];
  
      // Define chart options
      const option: any = {
        grid: {
          left: 50,
          right: 50,
          top: 50,
          bottom: 50,
        },
        xAxis: {
          type: 'category',
          data: this.xLabelArr, // Use X-axis data from API
        },
        yAxis: {
          type: 'value',
          max: Math.max(...this.yLabelArr), // Y-axis max from provided labels
          axisLabel: {
            formatter: (value: number) => `${value}`, // Display values with $ prefix
          },
        },
        series,
      };
      // Render the chart
      myChart.setOption(option);
    }
  }
  
  onAdminChange(){
    this.getBarChartData()
  }
}

  

