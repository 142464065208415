<div class="w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10" style="min-height: 85vh !important; max-height: 600px; overflow-y: auto;">
    <!-- Header Section -->
    <div class="flex flex-col sm:flex-row items-center mb-4">
        <h2 class="font-semibold text-lg mr-auto">Quotations</h2>
        <div class="ml-auto flex space-x-3 mt-2 sm:mt-0">
            <a routerLink="/teamleader/add-quotation" 
               class="bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 border-blue-800"> 
               Create
            </a>
            <button class="bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg"  style="background-color: #2a303c;" 
                    (click)="ExportQuotationDataView()">
                Export
            </button>
        </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Table Section -->
    <div class="w-full overflow-x-auto">
        <table class="min-w-max w-full table-auto border-collapse border border-gray-300">
            <thead class="bg-gray-200 text-gray-600 text-sm">
                <tr class="border-b border-gray-300">
                    <th class="py-3 px-6 text-center border border-gray-300">Quotation unique id  </th>
                    <th class="py-3 px-6 text-center border border-gray-300">Quotation Name </th>
                    <th class="py-3 px-6 text-center border border-gray-300">Customer</th>
                    <th class="py-3 px-6 text-center border border-gray-300">Type</th>
                    <th class="py-3 px-6 text-center border border-gray-300">Quote Revenue</th>
                    <th class="py-3 px-6 text-center border border-gray-300">Approved Amount</th>
                    <th class="py-3 px-6 text-center border border-gray-300">Contact</th>
                    <th class="py-3 px-6 text-center border border-gray-300">Quote Stage</th>
                    <th class="py-3 px-6 text-center border border-gray-300">Sales Rep</th>
                    <th class="py-3 px-6 text-center border-r border-gray-300">Manage</th>
                    <th class="py-3 px-6 text-center border-r border-gray-300">Status</th>
                    <th class="py-3 px-6 text-center">Generate</th>
                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm " *ngIf="data && data.length > 0; else noData">
                <tr *ngFor="let item of data | paginate: { itemsPerPage: 4, currentPage: p }" 
                    class="border-b border-gray-300 hover:bg-gray-100">
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        {{item.quote_unique_id}}
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        {{item.oppertunity_name_label}}
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        {{item.customer_name}}
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                         {{item.type}}
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        ₹{{item.quotation_revenue ? item.quotation_revenue : 0}}
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        ₹{{item.approved_amount ? item.approved_amount : 0 }}
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        {{item.contact_name}}
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        {{item.quotation_stage}}
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        {{item.cloudvice_sales_rep_label}}
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        <div class="flex justify-center space-x-2">
                            <span class="bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer">
                                <a (click)="Editquotation(item.id)">
                                    <i class="fa fa-pencil"></i>
                                </a>
                            </span>
                            <span class="bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs cursor-pointer">
                                <a (click)="deletequotation(item)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </span>
                        </div>
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        <div class="flex justify-center">
                            <span class="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs">
                                <button type="button" (click)="statusupdate(item.id, item.status)">
                                    {{item.status ? item.status : 'Generate'}}
                                </button>
                            </span>
                        </div>
                    </td>
                    <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                        <div class="flex justify-center space-x-2">
                            <span class="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs">
                                <a *ngIf="item.status != 'Accept' && item.status != 'Completed' && item.status != 'Invoice'" 
                                   (click)="generate(item.id)" 
                                   class="cursor-pointer">
                                    Quotation Generate
                                </a>
                                <a *ngIf="item.status == 'Accept' || item.status == 'Invoice'" 
                                   (click)="generateinvoice(item.id)" 
                                   class="cursor-pointer">
                                    Invoice Generate
                                </a>
                                <a *ngIf="item.status == 'Completed'" class="cursor-default">
                                    Completed
                                </a>
                            </span>
                        </div>
                    </td>
                </tr>
                <!-- <tr *ngIf="data?.length === 0">
                    <td colspan="6" class="text-center  font-semibold text-lg pt-9">No Data Found</td>
                  </tr> -->
            </tbody>
        </table>
        <ng-template #noData><tr><td colspan="7" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
    </div>
    <pagination-controls (pageChange)="p = $event" class="float-right pagination mt-1"></pagination-controls>

</div>
