<div class="max-w w-full space-y-8 mt-3  h-screen p-10 bg-white rounded-xl shadow-lg z-10 " style="height: 85vh !important;">
    <div class="flex flex-col">
        <div class="flex flex-col sm:flex-row items-center mb-4">
            <h2 class="font-semibold text-lg mr-auto">Contacts</h2>
            <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>
    
            <div class="my-2 flex sm:flex-row flex-col pr-4">
               
                <div class="block ">
                    <input placeholder="Search"  id="name" [(ngModel)]="searchControl"  (input)="searchCall()"
                        class="appearance-none rounded-full  border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm
                         placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" autocomplete="off" />
                </div>
            </div>
            <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
             <a  [routerLink]="['/executive/add-contact']" class="mb-2 md:mb-0 px-5 bg-green-400 text-white py-2 text-sm shadow-sm font-medium tracking-wider border rounded-full hover:shadow-lg" style="background-color: #2a303c;"> Create </a>
               </div>
      
        </div>
    </div>

        <mat-divider></mat-divider>
    <div class=" w-full overflow-x-auto">
      <table class="min-w-max w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr class="bg-gray-200 text-gray-600 text-sm">
              <th class="py-3 px-6 border border-gray-300 text-center" >Name</th>
              <th class="py-3 px-6 border border-gray-300 text-center" >Organization</th>
              <th class="py-3 px-6 border border-gray-300 text-center" >Designation</th>
              <th class="py-3 px-6 border border-gray-300 text-center" >Is Primary</th>
              <th class="py-3 px-6 border border-gray-300 text-center" >Email</th>
              <th class="py-3 px-6 border border-gray-300 text-center" >Phone Number</th>
              <th class="py-3 px-6 border border-gray-300 text-center" >Action</th>

  
            </tr>
          </thead>
          <tbody class="text-gray-600 text-sm" *ngIf="dataSource && dataSource.length > 0; else noData">
            <tr *ngFor="let element of dataSource | paginate: { itemsPerPage: 5, currentPage: p }" class="border-b border-gray-300 hover:bg-gray-100">
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{element?.first_name}} {{element.last_name}}</td>
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.customer_name }}</td>
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.designation }}</td>
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap"><mat-icon>{{element?.primary ? 'done' : 'close'  }}</mat-icon> </td>
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.email }}</td>
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">{{ element?.mobile_phone }}</td>
              <td class="py-3 px-6 text-center border border-gray-300 whitespace-nowrap">
                <mat-icon [routerLink]="['/executive/add-contact',cid,element.id]" class="icon-pointer">edit</mat-icon> 
                <mat-icon (click)="deleteContact(element)" class="icon-pointer">delete</mat-icon>
              </td>
            </tr>
            <!-- <tr *ngIf="dataSource?.length === 0">
              <td colspan="7" class="text-center  font-semibold text-lg pt-9">No Data Found</td>
            </tr> -->
          </tbody>
        </table>
        <ng-template #noData><tr><td colspan="7" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
    </div>
    <pagination-controls (pageChange)="p = $event" class="float-right mt-1 pagination"></pagination-controls>

    </div>
