import { Component,OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { QuotationListService } from '../../services/quotation-list.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CompanyservService } from '../../services/companyserv.service';
import { ContactFormService } from '../../services/contact-form.service';
// import { Options } from 'ng5-slider';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTableModule } from '@angular/material/table';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxPaginationModule } from 'ngx-pagination';


@Component({
  selector: 'app-opportunity-list',
  standalone: true,
  imports: [ReactiveFormsModule,FormsModule,MatDividerModule,CommonModule,MatIconModule,RouterModule,MatMenuModule,NgSelectModule,MatTableModule,NgxSliderModule,NgxPaginationModule],
  providers : [LoginService,QuotationListService,CompanyservService,ContactFormService],
  templateUrl: './opportunity-list.component.html',
  styleUrl: './opportunity-list.component.scss'
})
export class OpportunityListComponent {
  constructor(private loginservice: LoginService,private contactservice: ContactFormService,private companyserv:CompanyservService,private quotationserv:QuotationListService,private dialog: MatDialog) { }
  user:any;
  userid:any;
  p:number = 1;
  opportunitylist:any[]=[]
  organizationlist:any;
  contactlist:any[] = [];
  customerFilter: any = null;
  contactFilter=null;
  stageFilter=null;
  rangeFilter1=""
  rangeFilter2=""
  searchControl=""
  advfilter : any

  isLoading: boolean = true; 
  noDataMessageShown: boolean = false;
  displayedColumns: string[] = ['id','name', 'customer','type','forecast_probability','forecast_period','contact','created_date','created_by','opportunity_revenue','opportunity_stage','action' ];


  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.userid=this.user.data.id
  this.getOpportunity("")
  this. getOrganisationList()
  // this.getcontactwithoutCustomer(this.user.data.id,'')

  }

  // minValue: number = 40;
  // maxValue: number = 100;
  // options: Options = {
  //   floor: 40, // Start the slider at 10
  //   ceil: 100, // End the slider at 40
  //   step: 10,
  //   tickStep: 1, // Show a tick for every step
  //   ticksArray: [40,50, 60, 70, 80,90,100], // Array of tick values
  //   showTicks: true, // Show ticks on the slider
  //   showTicksValues: true,
   
  // }

  // minVal: number = 1;
  // maxVal: number = 5;
  // opt: Options = {
  //   floor: 1, // Start the slider at 1
  //   ceil: 5, // End the slider at 12
  //   step: 1,
  //   tickStep: 1, // Show a tick for every step
  //   ticksArray: [1, 2, 3, 4,5], // Array of tick values
  //   showTicks: true, // Show ticks on the slider
  //   showTicksValues: true,
   
  // }

  minValue: number = 40;
  maxValue: number = 100;
  options: any = {
    floor: 40,
    ceil: 100,
    step: 10,
    showTicks: true
  };

  minVal: number = 1;
  maxVal: number = 5;
  Foroptions: any = {
    floor: 1,
    ceil: 5,
    step: 1,
    showTicks: true
  };

  getOpportunity(filter:any){
    this.quotationserv.getOpportunity(this.userid,filter).subscribe(
      (opportunitylist: any) =>{ 
        this.opportunitylist = opportunitylist?.data;
        this.isLoading=false;

        if(this.opportunitylist.length===0){
          this.noDataMessageShown=true;

        }
        else{
          this.noDataMessageShown=false;
        }
        
        
       }
      );    
  }

  getOrganisationList(){
    this.companyserv.getCompany(this.user.data.id,'').subscribe(
      (organizationlist: any) =>{ 
        // console.log(organizationlist.company_list,'organizationlist.company_list')
        this.organizationlist = organizationlist.company_list
       });    
  }

  getcontactwithoutCustomer(userid: number,search:any){
    this.contactlist = []
    this.contactservice.getContactwithoutCus(userid,search).subscribe(
      (contactlist: any) => {
        contactlist.data.forEach((item:any)=>{
          item.name = item?.first_name + ' ' + item?.last_name
        })
        this.contactlist = contactlist.data
      });   
  }

  filter(){
    // if(this.searchControl !=""){
    this.advfilter={
        customer:this.customerFilter,
        contact:this.contactFilter,
        oppertunity_stage:this.stageFilter,
        search:this.searchControl,
        forcast_percentage_max : Number(this.maxValue),
        forcast_percentage_min : Number(this.minValue),
        forcast_period_max : Number(this.maxVal),
        forcast_period_min : Number(this.minVal)
      }
      this.getOpportunity(this.advfilter)
    // }else{
    //   this.clearfilter()
    // }
    
 }

 clearfilter(){
  this.customerFilter=null
  this.contactFilter=null
  this.stageFilter=null
  this.rangeFilter1=""
  this.rangeFilter2=""
  this.maxValue = 100
  this.minValue = 50
  this.minVal = 1
  this.maxVal = 5
  this.advfilter={
    customer:"",
    contact:"",
    oppertunity_stage:"",
   
    search:this.searchControl,
    forcast_percentage_max:"",
    forcast_percentage_min : "",
    forcast_period_max : "",
    forcast_period_min : ""
  }
 
  this.getOpportunity(this.advfilter)
}


  deleteOpportunity(list:any){

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Remove Opportunity',
        message: 'Are you sure, you want to remove a Opportunity: ' + list.oppertunity_name_label
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
		this.quotationserv.delopportunity(list.id).subscribe((data:any)=>{
      this.getOpportunity('')
    
		})
	}
});

}

onProbabilitySliderChange(event: any) {
  // The event object contains the new values of the slider
  this.minValue = event.value;
  this.maxValue = event.highValue;
  // Now you can use this.minValue and this.maxValue as needed
  
}

onPeriodSliderChange(event:any){
  this.minVal = event.value;
  this.maxVal = event.highValue;
  // Now you can use this.minValue and this.maxValue as needed
}

customSearchFn(term: string, item:any) {
  item.name = item.name.replace(',','');
  term = term.toLocaleLowerCase();
  return item.name.toLocaleLowerCase().indexOf(term) > -1;
}

onCustomerFilterChange(value: any) {
  this.customerFilter = value // Output the selected value to the console;
  this.getcontactlist(this.user?.data?.id,value)

 }
 
 getcontactlist(uid : any,cuid :any){
  this.contactlist = [];
  this.contactservice.getContact(uid,cuid).subscribe(
    (contactdetails: any) => {
      // console.log(contactdetails.data,'contactdetails.data')
      contactdetails.data.forEach((item:any)=>{
        item.name = item?.first_name + ' ' + item?.last_name
      })
      this.contactlist = contactdetails.data;
      // console.log(contactdetails.data,this.contactlist,'contactdetails.data')

  }
    );  

}

// getStatusClass(status: string): string[] {
//   switch (status) {
//     case 'new':
//       return ['bg-blue-100', 'text-blue-800'];
//     case 'open':
//       return ['bg-green-100', 'text-green-800'];
//     case 'closed':
//       return ['bg-red-100', 'text-red-800'];
//     default:
//       return ['bg-gray-200', 'text-gray-800']; // or any default classes
//   }
// }


}
