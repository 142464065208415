<div class="max-w h-screen w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 mt-5"
  style="height:83vh !important">
  <form class="form-control" [formGroup]="activityForm">
    <div class="flex sm:flex-row items-center mb-6">
      <h2 class="font-semibold text-lg mr-auto">
        Activity / {{id?'Edit':'New'}}</h2>
      <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>

      <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
        <a [routerLink]="['/executive/activities/']"
          class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 cursor-pointer">
          Cancel </a>
        <button
          class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg color"
          (click)="onSubmit()" type="submit">Save</button>
      </div>

    </div>
    <mat-divider></mat-divider>
    <div class="mt-3 bg-white  w-full rounded border border-gray-100" style="height:56vh !important; overflow-y: auto;">
      <div class="row" style="display: flex;">
        <div class="col-lg-6 w-full mt-3" style="padding-left: 5vh !important;">
          <div class="row mb-2 mt-2 w-full">
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              Name :</label>
            <input type="text" formControlName="eventname"
              class="focus:ring-white h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm"
              style="width: 50vh !important;" />
            <div *ngIf="activityForm.get('eventname')?.touched && activityForm.get('eventname')?.hasError('required')"
              class="error-msg">
              Activity Name is required
            </div>
          </div>
          <div class="row mb-2  mt-2  w-full">
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              Type :</label>
            <select formControlName="type"
              class="focus:ring-white h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm"
              (ngModelChange)="getTypeChange($event)" style="width: 50vh !important;">
              <option value="">Select</option>
              <option value="lead">Lead</option>
              <option value="opportunity">Opportunity</option>
              <option value="quotation">Quotation</option>
            </select>
            <div *ngIf="activityForm.get('type')?.touched && activityForm.get('type')?.hasError('required')"
              class="error-msg">
              Type is required
            </div>
          </div>
          <div class="row mb-2  mt-2  w-full">
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              Type value :</label>
            <select formControlName="task"
              class="focus:ring-white h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm"
              style="width: 50vh !important;">
              <option *ngFor="let list of typeList" [value]="list.id">{{list.name}} - {{list.unique_id}}</option>
            </select>
            <div *ngIf="activityForm.get('task')?.touched && activityForm.get('task')?.hasError('required')"
              class="error-msg">
              Type value is required
            </div>
          </div>
          <div class="row mb-2  mt-2  w-full">
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              Due Date :</label>
            <mat-form-field style="width:50vh !important;height: 13vh !important;">
              <input formControlName="edate" matInput [matDatepicker]="picker1" class="datepicker">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker1">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <div *ngIf="activityForm.get('edate')?.touched && activityForm.get('edate')?.hasError('required')"
              class="error-msg">
              Due Date is required
            </div>
          </div>
        </div>
        <div class="col-lg-6 w-full mt-3" style="padding-left: 5vh !important;">
          <div class="row mb-2   mt-2 w-full">
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              Activity Type :</label>
            <select formControlName="activity_type"
              class="focus:ring-white h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm"
              style="width: 50vh !important;">
              <option value="events">Events</option>
              <option value="Meetings">Meetings</option>

            </select>
            <div
              *ngIf="activityForm.get('activity_type')?.touched && activityForm.get('activity_type')?.hasError('required')"
              class="error-msg">
              Activity Type is required
            </div>
          </div>
          <div class="row mb-2   mt-2 w-full">
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              Hour :</label>
            <input type="number" formControlName="hour"
              class="focus:ring-white h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm"
              style="width: 50vh !important;" />
            <div *ngIf="activityForm.get('hour')?.touched && activityForm.get('hour')?.hasError('required')"
              class="error-msg">
              Hour is required
            </div>
          </div>
          <div class="row mb-2  mt-2  w-full">
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              Minute :</label>
            <input type="number" formControlName="minute"
              class="focus:ring-white h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm"
              style="width: 50vh !important;" />
            <div *ngIf="activityForm.get('minute')?.touched && activityForm.get('minute')?.hasError('required')"
              class="error-msg">
              Minute is required
            </div>
          </div>
          <div class="row mb-2  mt-2  w-full">
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              Description :</label>
            <textarea formControlName="description" type="text"
              class="focus:ring-white h-15 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm"
              style="width: 50vh !important;"></textarea>
            <div
              *ngIf="activityForm.get('description')?.touched && activityForm.get('description')?.hasError('required')"
              class="error-msg">
              description is required
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>