import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from '../global';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenutoggleService {

  private menustatus = new BehaviorSubject<boolean>(true);
  private progressupdate = new BehaviorSubject<boolean>(false);
  private update = new BehaviorSubject<boolean>(false);
  onGetnav = this.menustatus.asObservable();


  constructor(private http: HttpClient) { }

	onSetnav(data:boolean) {
		this.menustatus.next(data);
	}
	// onGetnav(){
	// 	return this.menustatus.asObservable();
	// }

	setprogress(data:any) 
	{
		this.progressupdate.next(data);
	}
	getProgress(){
		return this.progressupdate.asObservable()
	}


	setupdate(data:any) 
	{
		this.update.next(data);
	}
	getupdate(){
		return this.update.asObservable()
	}

	getNotificationList(id:number){
		return this.http.get(`${apiurl}pipeline/NewNotification/?user_id=${id}`);             
	}
}
