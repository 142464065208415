import { Component } from '@angular/core';
import { SuccessComponent } from '../../success/success.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MenutoggleService } from '../../services/menutoggle.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TeamComponent } from '../../admin/team/team.component';
import { MatCardModule } from '@angular/material/card';
import { LoginService } from '../../services/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule,RouterModule,MatCardModule,TeamComponent,RouterOutlet],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  public menuclass:string = "hidden";
  user: any;
  uid: any;
  uname: any;
  role: any;
  isDropdownVisible: boolean = false;
  
  constructor(
    private breakpointObserver: BreakpointObserver,
    private menuservice:MenutoggleService,
    private router:Router,private dialog: MatDialog,
    private loginservice:LoginService,
    private toaster :ToastrService
  ) {}

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
	  
      this.uid=this.user.data.id
      this.uname=this.user.data.username
      this.role=this.user.data.groups[0].name
    

    this.menuservice.onGetnav.subscribe((data:any)=>{
 
      if(data==true){
      
      this.menuclass = 'hidden';
    
      } else{
      
      this.menuclass = 'block';
      }
  });
  }
  openmenu(){
    this.menuservice.onSetnav(true);
  }
  logout()
    {
  
    localStorage.removeItem("usertoken")
    localStorage.removeItem("userdata")
    this.toaster.success('Logout Sucessfully','success')
		this.router.navigate(['/'])
  
    }
    onChangePassword(){
      this.isDropdownVisible = false;
      }
    
      onProfileClick(){
      this.isDropdownVisible = !this.isDropdownVisible
      }
}





