import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { MainService } from '../../services/main-service';
import { CommonModule } from '@angular/common';
import {NgxPaginationModule} from 'ngx-pagination';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var XLSX: any;

@Component({
  selector: 'app-members',
  standalone: true,
  imports: [CommonModule,NgxPaginationModule,MatDialogModule,MatDividerModule,RouterModule],
  templateUrl: './members.component.html',
  styleUrl: './members.component.scss'
})
export class MembersComponent implements OnInit {
  public user:any
  membrList:any[]=[]
 p:number=1;
  lists: any=[];
  constructor(private loginservice:LoginService,private mservice:MainService, private toaster:ToastrService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.listMember()
  }

  listMember(){
    
    this.loginservice.listMembers(this.user.data.id).subscribe((response:any)=>{
      this.membrList=response;
      console.log(this.membrList,'memberlistassss')
      
    })
    
  }
  
  exportmembers(){
    this.loginservice.exportmembers(this.user.data.id).subscribe((response:any)=>{
      this.lists=response;
      const worksheet = XLSX.utils.json_to_sheet(response.message);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, 'table-data.xlsx');
    
    if(response?.status==false){
      this.toaster.error(response.message,'error')
    }

    
    })
  }

  
  
  removeuser(userid:any,status:any)
  {
    if(status==true){

      status='Active'
    }
    else{
      status='Deactive'
    }
    this.loginservice.statusactivate(userid,status).subscribe((result:any)=>{
     this.listMember();
      
     
    })

    // this.mservice.removemember(id).subscribe((response:any)=>{
     
    //   this.listMember();
    // })
  }
  changeRole(id:any){
    let data={
      "creator_id":this.user.data.id,
      "role":id
    }
    
    
  }
}
