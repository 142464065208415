
import { Component, OnInit,ViewEncapsulation } from '@angular/core';
// import jspdf from "jspdf";
// import html2canvas from "html2canvas";
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { QuotationListService } from '../quotation-lists/quotation-list.service';
import { LoginService } from '../../services/login.service';
import { SuccessComponent } from '../../success/success.component';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { ReactiveFormsModule } from '@angular/forms';
declare var jspdf: any;
declare const html2canvas: any; 
declare const numberToWords: any; 


@Component({
  selector: 'app-invoice-generates',
  standalone: true,
  imports: [CommonModule,RouterModule,MatDividerModule,ReactiveFormsModule],
  templateUrl: './invoice-generates.component.html',
  styleUrl: './invoice-generates.component.scss',
  
})
export class InvoiceGeneratesComponent implements OnInit {
  showTable:boolean=false
  data:any
  configlist:any
  list:any;
  private cid:any
  public user:any
  public grandtotal:any
  public grandtotal_word:any
  expiration: any;
  date: any;
  constructor( private dialog: MatDialog,private router: Router,private route: ActivatedRoute,private qservice:QuotationListService,private loginservice:LoginService) { }

  ngOnInit(): void {
    this.user=this.loginservice.getUserdata()
     
    this.route.paramMap.subscribe(params => {
      this.cid = params.get('id')
      this.getquotation(this.cid)
    })
    this.configurationlist()
  }
  getquotation(id:any){
    this.qservice.getSingleQuotation(id).subscribe(
      (data: any) =>{
         this.list = data

         this. expiration=this.list?.expiration
         this.expiration= new Date(this.expiration).toISOString().split('T')[0]
         this. date=this.list?.date
         this.date= new Date(this.date).toISOString().split('T')[0]
         console.log(this.expiration,'expppppp')
         let s_amount=parseInt(data.amount)
         let g_amount=parseInt(data.gst_amount)
         this.grandtotal=s_amount+g_amount
        //  var converter = require('number-to-words');
        this.grandtotal_word= numberToWords.toWords(this.grandtotal); // => “thirteen”
        
  });
  }
  configurationlist()

  {
    
    this.qservice.configurationlist(this.user.teamid).subscribe((response:any)=>{
      this.configlist=response[0]
    console.log(this.configlist.name)
      
    })
  }
  public captureScreen() {
    this.showTable=true
    this.data = document.getElementById("contentToConvert");
    html2canvas(this.data).then((canvas:any) => {
      // Few necessary setting options
      var imgWidth = 210;
      var pageHeight = 297;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var position = 1;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save("MYPdf.pdf"); // Generated PDF
    });
  }
  sendnow(id:any)
  {
    
    let data_val1={
      "status":'Completed',
      
    }
    this.qservice.Editquotation(data_val1,id).subscribe((reponse:any) => {
      this.router.navigate(['teamleader/quotation/']);
     });
     let type='invoice'
  //   this.qservice.getquotesend(id,type).subscribe(
  //     (data: any) =>{
  //      console.log(data)
        
  // });
  const confirmDialog = this.dialog.open(SuccessComponent, {

    data: {
      title: 'Success',
      message: 'Send Succesfully!'
    }
  })
  confirmDialog.afterClosed().subscribe(result => {

   if (result == true) {
     this.router.navigate(['teamleader/quotation/']);
       
  }


});
  }
}
