<div class="grid grid-cols-12 gap-0 h-screen">
	<div class="h-full bg-gray-200 animate__animated animate__delay-5s" [ngClass]="col2width" style="background: #2a303c;">
		<h3  class="title text-white font-bold pl-7 py-4" style="
    font-size: 25px;
"><span (click)="refresh()" class="cursor-pointer">CRM</span>
			<svg xmlns="http://www.w3.org/2000/svg" (click)="closemenu()" class="h-6 w-6 float-right mr-2 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
			</svg>
		</h3>
		<app-sidebar style="position: sticky;"> </app-sidebar>
	</div>
	<div  [ngClass]="col10width" style="background: #f6f8f9">
		<app-navbar class="bg-gray-100 mb-3" style="background-color: #fff position-sticky"> </app-navbar>
		<div class=" px-2 "style="padding-top:3vh !important">
			<router-outlet class="hidden"></router-outlet>
		</div>
	</div>
</div>