import { Component, OnInit } from '@angular/core';
import { ActivitiesService } from '../../services/activities.service';
import { LoginService } from '../../services/login.service';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-activity-report',
  standalone: true,
  imports: [MatDividerModule,CommonModule,NgxPaginationModule],
  providers : [ActivitiesService,LoginService],
  templateUrl: './activity-report.component.html',
  styleUrl: './activity-report.component.scss'
})
export class ActivityReportComponent {
  constructor(private activityserve:ActivitiesService,private loginservice: LoginService) { }

  activityReportlist:any[] = [];
  p:number=1;
  user:any;
  userid:any;
  isLoading: boolean = true; 
  noDataMessageShown :boolean =false;

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.userid=this.user.data.id 
    this.getActivityReport()
  }

  getActivityReport(){
    this.activityserve.activityReport().subscribe(
      (result: any) =>{ 
        this.activityReportlist = result.data;
        this.isLoading = false;

        if(this.activityReportlist.length===0){
          this.noDataMessageShown=true;
        }
        else{
          this.noDataMessageShown=false;
        }
       }
      );    
  }

}
