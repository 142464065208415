import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from '../global';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  
  user = this.loginservice.getUserdata();
  private update = new BehaviorSubject<boolean>(false);
  private socket: WebSocket | any;
  public notificationUpdate$ = new BehaviorSubject<any>('');
    constructor(private http: HttpClient, private loginservice  : LoginService) { }
  
  getreport(userid:number){
    return this.http.get(apiurl + 'createteammembers/'+userid+"/");             
    }
    getprog(userid:number){
      return this.http.get(apiurl + 'rud_team/'+userid+"/");             
      }
      getprogressreport(id:number){
        return this.http.get(apiurl + 'retriveteammembers/'+id+"/");             
        }
  gettarget(userid:number){
      return this.http.get(apiurl + 'target/'+userid+"/");             
      }
      getalleport(userid:number){
        return this.http.get(apiurl + 'pipeline/tasklist/'+userid+"/"+"role"+"/");             
     
    }
    getnotificationList(user_id:any) 
    {
      return this.http.get(`${apiurl}pipeline/UnreadNotificationList/?user_id=${user_id}`);             
    }
    getnotification(id:any) 
    {
      return this.http.get(apiurl + 'pipeline/notificationcount/'+id+"/");             
    }
    updatenotification(id:any,res:any) 
    {
      return this.http.get(apiurl + 'pipeline/update_notificationcount/'+id+"/"+res+"/");             
    }
    setupdate(data:any) 
    {
      this.update.next(data);
    }
    getupdate(){
      return this.update.asObservable();
    }
    connect(userId: string): void {
      const socketUrl = `ws://${apiurl}/ws/notifications/${userId}/`;
      this.socket = new WebSocket(socketUrl);
  
      // Handle incoming WebSocket messages
      this.socket.onmessage = (event: any) => {
        const data = JSON.parse(event.data);
        if (data?.notification_count !== undefined) {
          this.notificationUpdate$.next(data); // Emit the updated count
        }
      };
  
      this.socket.onerror = (error: any) => {
        console.error('WebSocket error:', error);
      };
  
      this.socket.onclose = () => {
        setTimeout(() => this.connect(this.user?.data?.id), 5000); // Retry after 5 seconds
      };
    }

}
