import { Component, OnInit } from '@angular/core';
import { SuccessComponent } from '../../success/success.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-add-team',
  standalone: true,
  imports: [ReactiveFormsModule,MatDividerModule,CommonModule,MatFormFieldModule,MatDatepickerModule,MatNativeDateModule,RouterModule,MatInputModule,MatIconModule,FormsModule],
  templateUrl: './add-team.component.html',
  styleUrl: './add-team.component.scss'
})
export class AddTeamComponent implements OnInit {
  addteamForm!: FormGroup;
  public user:any
  submitted = false;
  private cid:any
  public isEdit:boolean = false;
  
  Tl:any[]=[]
  exc:any[]=[]
  


  constructor(private formBuilder: FormBuilder,private route: ActivatedRoute,private router: Router,private loginservice:LoginService,private dialog: MatDialog) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.form()
    this.route.paramMap.subscribe(params => {
      this.cid = params.get('id')
     
      if(this.cid != null){
        
          this.isEdit = true;
      this.loginservice.getOneTeam(this.cid).subscribe((data:any)=>{
          this.addteamForm.setValue({
            teamname:data.teamname,
            target:data.target
            // salesperson:data.salesexecutive,
            // teamlead:data.teamled
          })
      })
    
  }
  else
  {
      this.isEdit = false;
  this.addteamForm.reset();
  }
    });

    this.listExc()
    this.listTl()
  }
  form() {
	  this.addteamForm = this.formBuilder.group({
		  teamname: ['', Validators.required],
		  target: ['', Validators.required]
  }
	   );
	  
  }
  get f() { return this.addteamForm.controls; }
  onSubmit(colid:any) {
    this.submitted = true;
      if (this.addteamForm.invalid) {
          return;
          
      }
      
      
      let data_r={
        "teamname":this.addteamForm.value.teamname,
        "target":this.addteamForm.value.target,
        "creator_id":this.user.data.id

       }
       this.loginservice.addTeam(data_r,0).subscribe((response:any)=>{
        const confirmDialog = this.dialog.open(SuccessComponent, {
          data: {
            title: 'Success',
            message: 'Added Succesfully!'
          }
          })
          confirmDialog.afterClosed().subscribe(result => {
            if (result == true) {
              this.router.navigate(['admin/team'])
            }
  
  
          });

       })
       
  }
  listTl(){
    this.loginservice.listrTl(this.user.data.id).subscribe((response:any)=>{
      this.Tl=response 
  })
  }
  listExc(){
    this.loginservice.listrExc(this.user.data.id).subscribe((response:any)=>{
      this.exc=response 
  })
  }

  edit(){
    let data_r={
      "teamname":this.addteamForm.value.teamname,
      "target":this.addteamForm.value.target,
      "creator_id":this.user.data.id
     }
      
     this.loginservice.editteam(data_r,this.cid).subscribe((response:any) => {
      const confirmDialog = this.dialog.open(SuccessComponent, {

				data: {
				  title: 'Success',
				  message: 'Edited Succesfully!'
				}
			  })
			  confirmDialog.afterClosed().subscribe(result => {
          if (result == true) {
            this.router.navigate(['admin/team'])
          }


		    });
          
          
    });

  }

  
}
