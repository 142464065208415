
  import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
  // import { AngularEditorConfig, AngularEditorModule } from '@kolkov/angular-editor';
  import {FormGroup,FormControl,Validators,FormArray, FormBuilder, ReactiveFormsModule} from '@angular/forms';
  import { EmailService } from '../emails/email.service';
  import { AddContactgroupService } from '../add-contactgroups/add-contactgroups.service';
  import { EmailtemplateService } from '../add-email-templates/email-template.service';
  import { ContactFormService } from '../../services/contact-form.service';
  import { LoginService } from '../../services/login.service';
  import { Router, ActivatedRoute, RouterModule } from '@angular/router';
  import { MatDialog } from '@angular/material/dialog';
  import { SuccessComponent } from '../../success/success.component';
  import { FailComponent } from '../../fail/fail.component';
  import { ScheduleService } from '../schedules/schedule.service';
  import { CommonModule } from '@angular/common';
  import { MatDividerModule } from '@angular/material/divider';
  declare var ClassicEditor: any;



  @Component({
    selector: 'app-schedule-emails',
    standalone: true,
  imports: [CommonModule,RouterModule,MatDividerModule,ReactiveFormsModule],
    templateUrl: './schedule-emails.component.html',
    styleUrl: './schedule-emails.component.scss',
    encapsulation: ViewEncapsulation.None,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
  })
  export class ScheduleEmailsComponent implements OnInit,AfterViewInit {
    public user: any;
    contact:any[] =[];
    template:any[]=[]
    esignature:any[]=[]
    dmessage:any
    recepientlist:any
    group_list:any[]=[]
    contact_list:any[]=[]
    dsignature:any
    registerForm!: FormGroup;
    submitted = false;
    isGroup:boolean=false
    cid:any
    emailid:any
    grpid:any
    ctid:any
    public isEdit: boolean=false;
    customer: any;
    id: any;
    editorInstance  : any ;

    constructor(private scheduleservice:ScheduleService,private dialog: MatDialog,private formBuilder: FormBuilder,private etempservice:EmailtemplateService,private cgroupservice:AddContactgroupService,
      private emailservice: EmailService,private contactservice: ContactFormService,private loginservice: LoginService,
      private router: Router,private route: ActivatedRoute) { }
  
    ngOnInit() {
      
      this.user = this.loginservice.getUserdata()
        this.registerForm = this.formBuilder.group({
          
            contacts: [null, Validators.required],
            mailtype:['',Validators.required],
            subject:['',Validators.required],
            cc:[''],
            bcc:[''],
            message:['', Validators.required],
            signature:['',Validators.required],
            hour:['', Validators.required],
            minute:['', Validators.required],
            edate:['',Validators.required],
            groupid:['']
        }, );
        
        this.getcontacts(this.user.data.id)
        this.getEmailtemplate(this.user.data.id)
        
        this.getEmailsignature(this.user.data.id)
        this.route.paramMap.subscribe(params => {
          this.cid = params.get('id')
         
          if(this.cid != null){
            
              this.isEdit = true;
          this.scheduleservice.getOneActivity(this.cid).subscribe((data:any)=>{
              console.log(data.edate,'edit')
              console.log('fsdf',data.emails.to)
              if(data.emails.mailtype==='Group')
              {
                console.log('testramcy')
                this.isGroup=true
                this.grpid=data.emails.contact_group.id
                this.ctid=data.emails.to
              }
              else if(data.emails.mailtype==='Individual'){
                console.log('testram')
                this.isGroup=false
                this.grpid=''
                this.ctid=data.emails.to
              }
              this.registerForm.setValue({
                edate:data.edate,
                hour:data.hour,
                minute:data.minute,
                mailtype:data.emails.mailtype,
                subject:data.emails.subject,
                cc:data.emails.cc,
                bcc:data.emails.bcc,
                contacts:this.ctid,
                message:data.emails.message,
                signature:data.emails.signatures,
                groupid:this.grpid
                // mailtype:['',Validators.required],
                // subject:[''],
                // cc:[''],
                // bcc:[''],
                // message:['', Validators.required],
                // signature:['',Validators.required],
                // user:this.user.data.id,
                // groupid:[''],
                // hour:['', Validators.required],
                // minute:['', Validators.required],
                // edate:['',Validators.required]
              })
            
              this.emailid=data.emails_id
          })
       this.groups() 
      }
      else
      {
          this.isEdit = false;
      this.registerForm.reset();
      }
        });
      }

      ngAfterViewInit() {
        setTimeout(() => {

            // Initialize CKEditor after the view is loaded
         const editorContainer = document.querySelector('#editor'); // Target div
         if (editorContainer) {
           ClassicEditor.create(editorContainer)
             .then((editor: any) => {
               this.editorInstance = editor;
               editor.setData(''); // Set initial content
               editor.model.document.on('change:data', () => {
                 this.htmlContent = editor.getData(); // Update content on changes
               });
             })
             .catch((error: any) => {
               console.error('There was a problem initializing CKEditor:', error);
             });
         }
           
           }, 0);
    
      }
      
     
      getEmailsignature(userid :number){
  
      this.etempservice.getEmailsignature(userid).subscribe(
        (sdata: any) => this.esignature = sdata
  
        );
    }
      getEmailtemplate(userid :number){
  
      this.etempservice.getEmailtemplate(userid).subscribe(
        (tdata: any) => this.template = tdata
  
        );
    }
      getcontacts(userid: number){
      
         this.scheduleservice.gettasklist(userid).subscribe(
          (contact: any) =>{this.contact = contact
            console.log('customer111111111111111111',this.contact)
          } 
          
          );
      }
      
    htmlContent = '';
  
    config: any = {
    editable: true,
    spellcheck: true,
    height: '35rem',
    minHeight: '15rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  
  groupsind()
  {
    this.isGroup=false
  }
  groups()
  {
    
  this.isGroup=true
  console.log(this.isGroup)
    this.cgroupservice.getContactgroup(this.user.data.id).subscribe(
      (data: any) => {
        this.group_list = data;
        console.log(this.group_list,'groupppppppp')
      }

  
      );
  } 
  groupmembers(val:any)
  {
   let group_members_list:any
    let  contact_email_list:  any = [];
    let email_arr:any[]=[]
    this.cgroupservice.getContactgroupMembers(val).subscribe(
      (data: any) =>{ 
      
        this.contact_list = data[0].contact
        console.log(this.contact_list,'lstt')
        for(let res of this.contact_list)
        {
        //  let data_r={
        //    "email":res.contact.email
        //  }
        console.log(res.email,'emailll')
          contact_email_list.push(res.email)
          const email_li = contact_email_list
         group_members_list = email_li.toString();
         console.log(group_members_list)
         this.registerForm.controls['contacts'].setValue(group_members_list)
       
        }
       
  });
  
  
  
  
  }
  emailtemplate(val:any)
  {
   this.etempservice.getOneEmailtemplate(val).subscribe((data:any)=>{
    this.dmessage=data.message
    this.registerForm.controls['message'].setValue(this.dmessage)
    this.registerForm.controls['subject'].setValue(data.subject)
    this.editorInstance.setData(data?.message)

  })
  
  
  }
  emailsignature(val:any)
  {
    
    this.etempservice.getOneEmailsignature(val).subscribe((data:any)=>{
      this.dsignature=data.signature                
      console.log(this.dsignature)
      this.registerForm.controls['signature'].setValue(this.dsignature)
     
    })
  }
  get f() { return this.registerForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
  }
  if (this.editorInstance) {
    const val = this.editorInstance.getData()
    let cleanedContent = val.replace(/<p>/g, '');   // Remove opening <p> tags
    cleanedContent = cleanedContent.replace(/<\/p>/g, '');
    this.registerForm.controls['message'].setValue(cleanedContent)
  }
  let data_ema={
    
    "contact_group_id":this.registerForm.value.groupid,
    "user_id":this.user.data.id,
    "to":this.registerForm.value.contacts,
    "mailtype":this.registerForm.value.mailtype,
    "subject":this.registerForm.value.subject,
    "message":this.registerForm.value.message,
    "signature":this.registerForm.value.signature,
    "cc":this.registerForm.value.cc,
    "bcc":this.registerForm.value.bcc
   }
   console.log(data_ema)
    this.emailservice.addEmail(data_ema).subscribe((reponse:any)=>{
      console.log(reponse);
  let data_val = {
    "emails_id":reponse.id,
    "eventname":"Emails",
    "edate":this.registerForm.value.edate,
    "task_id":this.id,
    "customer_id":this.customer,
    "activity_type":'Emails',
    "hour":this.registerForm.value.hour,
    "minute":this.registerForm.value.minute,
    "user_id":this.user.data.id
  }
  this.scheduleservice.addSchedule(data_val,this.user.data.id).subscribe((reponse_event)=>{

    if(reponse_event)
    {
     const confirmDialog = this.dialog.open(SuccessComponent, {

       data: {
         title: 'Success',
         message: 'Add Send Succesfully!'
       }
     })
     confirmDialog.afterClosed().subscribe(result => {
      if (result == true) {
        this.router.navigate(['/teamleader/activities']);
     }


   });
}
else{
   const confirmDialog = this.dialog.open(FailComponent, {

       data: {
         title: 'Failed',
         message: 'Email Add Failed!'
       }
     })
     confirmDialog.afterClosed().subscribe(result => {
 
      if (result == true) {
          this.router.navigate(['teamleader/email'])
     }


   });
}
  });
     });
   
  }
  editemailactivity(cid:number,eid:any){
    if (this.registerForm.invalid) {
      return;
  }
    let data_ema={
    
      "contact_group_id":this.registerForm.value.groupid,
      "user_id":this.user.data.id,
      "to":this.registerForm.value.contacts,
      "mailtype":this.registerForm.value.mailtype,
      "subject":this.registerForm.value.subject,
      "message":this.registerForm.value.message,
      "signature":this.registerForm.value.signature,
      "cc":this.registerForm.value.cc,
      "bcc":this.registerForm.value.bcc,
      "send_now":false
     }
     console.log(data_ema)
      this.emailservice.editEmail(data_ema,eid).subscribe((reponse:any)=>{
        console.log(reponse);
      });
    let data_val = {
      "emails_id":eid,
      "eventname":"Emails",
      "edate":this.registerForm.value.edate,
      "task_id":null,
      "activity_type":'Emails',
      "hour":this.registerForm.value.hour,
      "minute":this.registerForm.value.minute,
      "user_id":this.user.data.id
    }
    this.scheduleservice.editschedule(data_val,cid).subscribe((reponse:any) => {
      this.router.navigate(['teamleader/activities/']);
      });
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  onContactChange(event:any){
		this.contact.forEach((item:any)=>{
      console.log(item,'itemssssssssss')
			if(item.email==event.target.value){
				this.id = item?.id,
				this.customer = item?.customer_id
        
			}
			
		})
	
	}
  }