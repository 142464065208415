import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProductService } from './product.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { SuccessComponent } from '../../success/success.component';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-add-products',
  standalone: true,
  imports: [CommonModule,RouterModule,ReactiveFormsModule,MatDividerModule],
  templateUrl: './add-products.component.html',
  styleUrl: './add-products.component.scss'
})
export class AddProductsComponent implements OnInit {

  //   registerForm!: FormGroup;
    public user: any;
    submitted = false;
    users:any=[];
    Acivity:any=[];
    isEdit: boolean=false;
    cid:any
    productForm!: FormGroup;
    constructor(private dialog: MatDialog,private formBuilder: FormBuilder,
      private pservice: ProductService,
      private router: Router,private loginservice: LoginService,private route: ActivatedRoute
      ) { }
  
    ngOnInit() {
        this.productForm = this.formBuilder.group({
          name: ['',[Validators.required,Validators.pattern(/^[a-zA-Z\s]*$/)]],                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
          price:['', [Validators.required,Validators.pattern(/^\d*\.?\d*$/)]],
          description:['',Validators.required]
        }, );
        this.user = this.loginservice.getUserdata()
        this.getuser()
        this.route.paramMap.subscribe(params => {
          this.cid = params.get('id')
         
          if(this.cid != null){
            
              this.isEdit = true;
          this.pservice.getOneProduct(this.cid).subscribe((data:any)=>{
                      
              console.log(data)
            
              this.productForm.setValue({
                  name:data.product_name,
                  price:data.product_price,
                  description:data.product_description
                  
              
              })
          })
        
      }
      else
      {
          this.isEdit = false;
      this.productForm.reset();
      }
        });
    }
    getuser(){
    
      this.pservice.getUser().subscribe(
        (users: any) => this.users = users
        )
      }
      editproduct(cid:number){
        let data_val1={
          "product_name":this.productForm.value.name,
          "product_price":this.productForm.value.price,
          "product_description":this.productForm.value.description,
          "creator_id_id":this.user.data.id
        }
    this.pservice.editProduct(data_val1,cid).subscribe((reponse:any) => {
      //console.log(reponse);
      this.router.navigate(['teamleader/product/']);
    });
    }
    // convenience getter for easy access to form fields
    get f() { return this.productForm.controls; }
  
    onSubmit() {
      this.submitted = true;
      if (this.productForm.invalid) {
        return;
    }
        //console.log(this.registerForm.value);
        let data_val={
          "product_name":this.productForm.value.name,
          "product_price":this.productForm.value.price,
          "product_description":this.productForm.value.description,
          "creator_id_id":this.user.data.id
        }
       
        this.pservice.addProduct(data_val).subscribe((reponse:any)=>{
      //   //
       const confirmDialog = this.dialog.open(SuccessComponent, {
  
         data: {
           title: 'Success',
           message: 'Added Succesfully!'
         }
       })
       confirmDialog.afterClosed().subscribe(result => {
   
        if (result == true) {
          this.router.navigate(['teamleader/product/']);
       }
  
  
     });
  //}
  // else{
  //    const confirmDialog = this.dialog.open(FailComponent, {
  
  //        data: {
  //          title: 'Failed',
  //          message: 'Added Failed!'
  //        }
  //      })
  //      confirmDialog.afterClosed().subscribe(result => {
   
  //       if (result == true) {
  //           this.router.navigate(['teamleader/add-product'])
  //      }
  
  
  //    });
  // }
        
       });  
    }
  
    onReset() {
        this.submitted = false;
        this.productForm.reset();
    }
  }
  