<div class="max-w w-full h-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 mt-5 mb-5">
    <div class="grid  gap-8 grid-cols-1 ">
        <form class="" [formGroup]="cgroupMForm" (ngSubmit)="onSubmit()">
        <div class="flex flex-col ">
                <div class="flex flex-col sm:flex-row items-center mb-2">
                    <h2 class="font-semibold text-lg mr-auto"> Add Contact group</h2>
                    <div class="w-full sm:w-auto sm:ml-auto sm:mt-0"></div>
  
                    <div class=" text-right md:space-x-3 md:block flex flex-col-reverse" >
                      <a routerLink="/teamleader/contact-group-member/{{gid}}" class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
                        <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg color">Save</button>
                    </div>
                
                </div>
                <mat-divider></mat-divider>
             
                <div class="mt-3 bg-white rounded border border-gray-100  px-20">
                    <div class="form mt-3">
                 
             
                      <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3 py-3 mb-6 md:mb-0" >
                          <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                            Name</label>
                            <select [disabled]="isDisabled" formControlName="contact_group" [ngClass]="{ 'is-invalid': submitted && f['contact_group'].errors }" class="relative w-full bg-white text-sm text-gray-700 placeholder-gray-400 border h-5/6 border-gray-400">
                              <option value="" selected>Select</option>
                              <option *ngFor="let list of contacts_g" value="{{list.id}}">{{list.name}}</option>
                       </select>
                        <div *ngIf="submitted && f['contact_group'].errors" class="invalid-feedback text-sm">
                         <div *ngIf="f['contact_group'].errors['required']">Please fill out this field.</div>
                        </div>
                     </div>
                    
                        <div class="w-full py-3 px-3 mt-4 mb-6">
                          <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                         Contacts
                          </label>
                      
                   <select multiple formControlName="contact" [ngClass]="{ 'is-invalid': submitted && f['contact'].errors }" class="relative w-full bg-white text-sm text-gray-700 placeholder-gray-400 border h-5/6 border-gray-400">
                    
                    <option *ngFor="let list of contactgroup_list" value="{{list.id}}">{{list.first_name}}</option>
                  </select>
                   <div *ngIf="submitted && f['contact'].errors" class="invalid-feedback text-sm">
                    <div *ngIf="f['contact'].errors['required']">Please fill out this field.</div>
                  </div>
                        </div>
                 
                        </div>
                        
                               
                    </div>      
                    
                  
                </div>
        </div>
        </form>
    </div>
  </div>
  
  