import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { SuccessComponent } from '../../success/success.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [MatMenuModule,RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {

 
  constructor(private router:Router,private dialog: MatDialog) { }
  activeLink: string = '';
  ngOnInit(): void 
  {
  if(this.router.url == '/superAdmin'){
    this.activeLink='dashboard'
  }
  else if(this.router.url =='/superAdmin/admins'){
     this.activeLink='logs'
  }
 
  }
	logout()
  {
    localStorage.removeItem("usertoken")
    localStorage.removeItem("userdata")

	const confirmDialog = this.dialog.open(SuccessComponent, {
		data: {
		  title: 'Success',
		  message: 'Logout Successfully!'
		}
	  });
	  confirmDialog.afterClosed().subscribe(result => {
	  window.location.href="login"
  });
  }

  showMenu: boolean = false;
  toggleMenu() {
    
    this.showMenu = !this.showMenu;
  }
 

  setActiveLink(link: string) {
    this.activeLink = link;
  }

}
