<div class="max-w w-full space-y-8  p-10 bg-white rounded-xl shadow-lg z-10 mb-15">
    <div class="flex flex-col sm:flex-row items-center mb-2">
        <h2 class="font-semibold  text-lg mr-auto">Team Members Report - {{teamname}}</h2>
        <a routerLink="/admin/team-report" class="justify-start text-blue link-hover">Report</a>/ Team members
    </div>
    <mat-divider></mat-divider>
    
    <div class='mt-4'>
        <table class="min-w-full table-auto border-collapse border border-gray-300">
            <thead>
                <tr class="bg-gray-200 text-gray-600 text-sm">
                    <th class="py-3 px-6 border border-gray-300 text-center">Members</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Role</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Target</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Progress</th>
                    <th class="py-3 px-6 border border-gray-300 text-center">Manage</th>
                </tr>
            </thead>
         
            <tbody class="text-gray-600 text-sm" *ngIf="members && members.length > 0; else noData">
                <tr  class="border-b border-gray-200 hover:bg-gray-100" *ngFor='let item of members  | paginate: { itemsPerPage: 10, currentPage: p }' >
                    <td class="py-3 px-6 border border-gray-300 text-center">
                        {{item.team_member.first_name}}
                    </td>
                    <td class="py-3 px-6 border border-gray-300 text-center">
                        {{item.team_member.groups[0].name}}
                    </td>
                    <td class="py-3 px-6 border border-gray-300 text-center">
                        {{item.target ? item.target : 0}}
                    </td>
                    <td class="py-3 px-6 border border-gray-300 text-center">
                        {{item.member_progress ? item.member_progress : 0 }} %
                    </td>
                    <td class="">
                        <div class="iconAlign">
                            <span routerLink="/admin/members-leads/{{item.team_id}}/{{item.team_member.id}}"  class="cursor-pointer bg-red-200 text-purple-600 py-1 px-3 rounded-full text-xs">View Leads</span>
                        </div>
                    </td>
                </tr>
            
            </tbody>
        </table>
        <ng-template #noData><tr><td colspan="5" class="text-center py-4"><span class="text-gray-600 font-semibold">No data available</span></td></tr></ng-template>
        <pagination-controls (pageChange)="p = $event" class="float-right mt-1"></pagination-controls>
    </div>        
    
</div>
