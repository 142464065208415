<div class="max-w w-full space-y-8 h-screen p-10 bg-white rounded-xl shadow-lg z-10 " style="height:85vh !important;overflow-y: auto;">
    <div class="flex flex-col">
        <div class="flex flex-col sm:flex-row items-center mb-4">
            <h2 class="font-semibold text-lg mr-auto">Contacts</h2>
            <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>
    
            <div class="my-2 flex sm:flex-row flex-col pr-2">
                <div class="flex flex-row mb-1 sm:mb-0">
                    <div class="relative">
                        <select
                        [formControl]="first_name" id="name" class="dropdown-container h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500btn-underline-primary h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                        <option value="" >All</option>
                        <option *ngFor="let list of contact" value="{{list?.first_name}}">{{list?.first_name}}</option>
                    </select>
                        <div
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filter" viewBox="0 0 16 16">
                                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                              </svg>
                        </div>
                    </div>
              </div>
                <div class="block relative">
                    <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                        <svg viewBox="0 0 24 24" class="h-4 w-4 fill-current text-gray-500">
                            <path
                                d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z">
                            </path>
                        </svg>
                    </span>
                    <input placeholder="Search"  [formControl]="first_name" id="name1"
                        class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" />
                </div>
            </div>
         
            <div class="text-right md:space-x-3 flex items-center">
                <a routerLink="/teamleader/add-contact/" class="bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 border-blue-800 cursor-pointer"> Create </a>
            
               
               
                <a class="bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 border-blue-800 cursor-pointer"
                (click)="triggerFileInput()"> 
                Import 
             </a>
             
             <input #fileInput type="file" (change)="onFileSelected($event)" style="display: none;">
             
            
                <a class="px-1 py-2 text-xsm  font-medium text-gray-600 cursor-pointer flex items-center" title="download sample file" (click)="downloadSampleFile()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24"><path fill="currentColor" d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-6 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z"/></svg>
                    <span class="sample-file">Sample File</span>
                </a>
            </div>
            
      
        </div>
        <mat-divider></mat-divider>
    <div class="grid grid-cols-4 gap-4 mt-4">
    <div class="bg-white border-solid border-2 px-2 py-2 border-light-blue-500 h-28 shadow-xl lg:flex lg:max-w-xs bordersize rounded-sm" 
    *ngFor="let list of contactlist | search: [first_name.value, category.value,email.value,type.value]: ['first_name', 'phoneNumber','email','contacttype'] ;" style="border-radius: 10px !important;">

        <img src="{{list.image}}"
            class="w-1/1 lg:w-1/3 px-2 py-2 rounded-xl">
        <div class="px-2 py-2">
            <p class="mb-2 text-sm text-gray-900">{{list.first_name}}</p>
            <p class="text-gray-600">{{list.email}}</p>
            <div class="flex mt-2 mb-2">
            <a routerLink="/teamleader/contact-view/{{list.id}}" class="text-gray-500  menu-btn p-0 m-0 hover:text-gray-900 focus:text-gray-900 focus:outline-none transition-all ease-in-out duration-300" title="
            view">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path  stroke-linecap="round" stroke-linejoin="round"  d="M10,6.978c-1.666,0-3.022,1.356-3.022,3.022S8.334,13.022,10,13.022s3.022-1.356,3.022-3.022S11.666,6.978,10,6.978M10,12.267c-1.25,0-2.267-1.017-2.267-2.267c0-1.25,1.016-2.267,2.267-2.267c1.251,0,2.267,1.016,2.267,2.267C12.267,11.25,11.251,12.267,10,12.267 M18.391,9.733l-1.624-1.639C14.966,6.279,12.563,5.278,10,5.278S5.034,6.279,3.234,8.094L1.609,9.733c-0.146,0.147-0.146,0.386,0,0.533l1.625,1.639c1.8,1.815,4.203,2.816,6.766,2.816s4.966-1.001,6.767-2.816l1.624-1.639C18.536,10.119,18.536,9.881,18.391,9.733 M16.229,11.373c-1.656,1.672-3.868,2.594-6.229,2.594s-4.573-0.922-6.23-2.594L2.41,10l1.36-1.374C5.427,6.955,7.639,6.033,10,6.033s4.573,0.922,6.229,2.593L17.59,10L16.229,11.373z"></path>
            </svg>       
           </a>
           <a (click)="EditContact(list.id)" class="text-gray-500 pl-4  menu-btn p-0 m-0 hover:text-gray-900 focus:text-gray-900 focus:outline-none transition-all ease-in-out duration-300 cursor-pointer" title="
           edit">
           <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
          </svg>          
        </a>
          <a (click)="delContact(list)" class="text-gray-500 pl-4  menu-btn p-0 m-0 hover:text-gray-900 focus:text-gray-900 focus:outline-none transition-all ease-in-out duration-300 cursor-pointer" title="
          delete">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path   stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>               
    </a>
    <a (click)="addtask(list)" class="text-gray-500 pl-4  menu-btn p-0 m-0 hover:text-gray-900 focus:text-gray-900 focus:outline-none transition-all ease-in-out duration-300 cursor-pointer" title="
    convert to  lead" *ngIf="list.salesperson_id==this.user.data.id">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12.522,10.4l-3.559,3.562c-0.172,0.173-0.451,0.176-0.625,0c-0.173-0.173-0.173-0.451,0-0.624l3.248-3.25L8.161,6.662c-0.173-0.173-0.173-0.452,0-0.624c0.172-0.175,0.451-0.175,0.624,0l3.738,3.736C12.695,9.947,12.695,10.228,12.522,10.4 M18.406,10c0,4.644-3.764,8.406-8.406,8.406c-4.644,0-8.406-3.763-8.406-8.406S5.356,1.594,10,1.594C14.643,1.594,18.406,5.356,18.406,10M17.521,10c0-4.148-3.374-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.147,17.521,17.521,14.147,17.521,10"></path>
    </svg>               
    </a>
    <a routerLink="/teamleader/contacts-list" class="bg-red-200 mr-2 ml-2 text-purple-600 py-1 px-3 rounded-full text-xs" title="
    Assigned to {{list.salesperson.first_name}}" *ngIf="list.salesperson_id!=this.user.data.id">
    E           
    </a>
        </div>
        </div>
        
    
    </div>
    
    </div>
    <div class="flex justify-center pt-9" *ngIf="(contactlist | search: [first_name.value, category.value,email.value,type.value]: ['first_name', 'phoneNumber','email','contacttype']).length === 0">
        <h1 class="font-semibold text-lg">No Data Found</h1>
    </div>
    </div>
    </div>