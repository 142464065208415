import { Injectable } from '@angular/core';
//import { baseurl} from './../global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiurl } from '../../global';

@Injectable({
  providedIn: 'root'
})
export class AddEventService {

 
  constructor(
    private http:HttpClient,
  ) { }

  // getEvents(){
  //   return this.http.get(apiurl+"/getallevent")
  // }
  // addEvents(name:string ,edate:Date){
  //   var data= { "name":name ,"edate":edate};
  //   const headers = new HttpHeaders();
	// 	headers.append('Content-Type','application/json');

  //   return this.http.post(apiurl+"/event",data,{headers:headers})
    
  // }
  
  getEvents(userid:number){
    return this.http.get(apiurl+"events/"+userid+"/")
  }
  addEvents(data:any,id:number,res:any){
   
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');

    return this.http.post(apiurl+"pipeline/events/"+id+"/"+res+"/",data,{headers:headers})
    
  }
  gettasklist(userid:number){
    return this.http.get(apiurl + 'pipeline/tasklist/'+userid+"/"+"role"+"/");             
    }
}
