import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoginService } from '../../services/login.service';
import { ToastrService } from 'ngx-toastr';
import { TargetService } from '../../teamleader/targetss/target.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
declare var XLSX: any;



@Component({
  selector: 'app-team-members-details',
  standalone: true,
  imports: [CommonModule,RouterModule,NgxPaginationModule,MatDividerModule],
  templateUrl: './team-members-details.component.html',
  styleUrl: './team-members-details.component.scss'
})
export class TeamMembersDetailsComponent implements OnInit {
  public user:any
  members:any[]=[]
  teamid:any
  p: number = 1;
   cid:any
  teams: any;
  

  constructor(private loginservice:LoginService, private target: TargetService, private route: ActivatedRoute,private toaster:ToastrService, private dialog : MatDialog) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.route.paramMap.subscribe(params => {
      this.cid = params.get('id')
    })
    this.listSigTeam(this.cid)
  }
  listSigTeam(cid:number){
    this.loginservice.singleTeamMem(cid).subscribe((response:any)=>{  
    this.members=response
  })
}
  deltemember(id:any,userid:any,name:any){
  
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: 'Confirm Remove Team-member',
            message: 'Are you sure, you want to remove a team-member: ' + name
          }
        });
        confirmDialog.afterClosed().subscribe((result: any) => {
          if (result === true) {
            this.loginservice.statusactivate(userid,'Deactive').subscribe((result:any)=>{ 
              
            })
        this.loginservice.removeteammember(id).subscribe((result:any)=>{
        this.listSigTeam(this.cid);
        
        })
      }
      });
 


//  


  }

  exportTeammember(){
    this.target.ExportTeamMembersDataView(this.cid).subscribe((response:any)=>{
      this.teams=response;

      const worksheet = XLSX.utils.json_to_sheet(response.message);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, 'table-data.xlsx');
    
    if(response?.status==false){
      this.toaster.error(response.message,'error')
    }

})
  }

}
