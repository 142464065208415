
<div class="max-w h-content w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 mt-5 mb-5" style="height:85vh !important;overflow-y: auto !important;">
    <div class="grid ">
      <form class="form-control" [formGroup]="leadForm">
        <div class="flex flex-col ">
          <div class="flex flex-col sm:flex-row items-center mb-6">
            <h2 class="font-semibold text-lg mr-auto">
              Lead / {{lid?'Edit':'New'}}</h2>
            <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>
            <div class=" text-right md:space-x-3 md:block flex flex-col-reverse">
              <a routerLink="/executive/lead"
                class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">
                Cancel </a>
              <button 
                class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg " style="background-color: #2a303c;"
               (click)="lid? editLead() :onSubmit()"  type="submit">Save</button>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="mt-3 bg-white overflow-y-auto h-screen rounded border border-gray-100">
            <div class="form mt-3">
              <div>
  
                <div class="mt-4">
                  <div>
                    <div class="flex justify-between">
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Customer :</label>
                          <mat-form-field appearance="fill" class="custom-width" >
                          <mat-select formControlName="customer"  (selectionChange)="getCustomerData($event.value)"  [disabled]="true">
                            <input matInput [formControl]="filter" [(ngModel)]="searchControl" (input)="searchCall()"  placeholder="Search..." [readonly]="true">
                            <mat-option *ngFor="let list of organizationlist" [value]="list.id">
                              {{ list.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field> 
                      </div>
  
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Contact :</label>
                        <select formControlName="contact"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="">Select Contact</option>
                          <option *ngFor="let list of contactdetails" [value]="list.id">{{list.first_name}} {{list.last_name}}</option>
                        </select>
                        <div
                          *ngIf="leadForm.get('contact')?.touched && leadForm.get('contact')?.hasError('required')"
                          class="error-msg">
                          Contact is required
                        </div>
                       
                      </div>
  
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Lead Name :</label>
                        <input type="text" formControlName="lead_name"
                         
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                        <div
                          *ngIf="leadForm.get('lead_name')?.touched && leadForm.get('lead_name')?.hasError('required')"
                          class="error-msg">
                          Lead Name is required
                        </div>
                        <div *ngIf="leadForm.get('lead_name')?.hasError('pattern')" class="error-msg">
                          Invalid format
                        </div>
                      </div>
                    </div>
  
                    <div class="flex justify-between">
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Lead Short Description :</label>
                        <input type="text" formControlName="lead_description"
                         
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                        
                      </div><div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Lead Revenue :</label>
                        <input type="text" formControlName="budget"
                         
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                        <div
                          *ngIf="leadForm.get('budget')?.touched && leadForm.get('budget')?.hasError('required')"
                          class="error-msg">
                          Lead Revenue is required
                        </div>
                        <div *ngIf="leadForm.get('budget')?.hasError('pattern')" class="error-msg">
                          Invalid format
                        </div>
                      </div>
  
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Currency :</label>
                <select formControlName="currency"
							  class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
							  <option value="usd">USD</option>
							  <option value="inr">INR</option>
							  <option value="aed">AED</option>
							</select>
                        <div
                          *ngIf="leadForm.get('currency')?.touched && leadForm.get('currency')?.hasError('required')"
                          class="error-msg">
                          Currency is required
                        </div>
                       
                      </div>
  
                    </div>
  
                    <div class="flex justify-between">
                     
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Type :</label>
                        <select formControlName="type"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="" disabled="true">Select</option>
                          <option value="HCM">HCM</option>
                          <option value="ERP">ERP</option>
                          <option value="OCI">OCI</option>
                        </select>
                        <div *ngIf="leadForm.get('type')?.touched && leadForm.get('type')?.hasError('required')"
                          class="error-msg">
                          Type is required
                        </div>
                        
                      </div>
  
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Lead Stage :</label>
                        <select formControlName="lead_stage"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="">Select</option>
                          <option *ngFor="let list of leadstatus" [value]="list?.name">{{list?.name}}</option>
                          
                        </select>
                        <div
                          *ngIf="leadForm.get('lead_stage')?.touched && leadForm.get('lead_stage')?.hasError('required')"
                          class="error-msg">
                          Lead Stage is required
                        </div>
                       
                      </div>
  
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Forecast Period :</label>
                          <select formControlName="forecast_period"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="1">1 Month</option>
                          <option value="2">2 Months</option>
                          <option value="3">3 Months</option>
                          <option value="4">4 Months</option>
                          <option value="5">5 Months</option>
                          <option value="6">6 Months</option>
                          <option value="7">7 Months</option>
                          <option value="8">8 Months</option>
                          <option value="9">9 Months</option>
                          <option value="10">10 Months</option>
                          <option value="11">11 Months</option>
                          <option value="12">12 Months</option>
                        </select>
                        <div *ngIf="leadForm.get('forecast_period')?.touched && leadForm.get('forecast_period')?.hasError('required')"
                          class="error-msg">
                          Forecast Period is required
                        </div>
                        
                      </div>
  
                     
                    </div>
  
                     
                     <div class="flex justify-between">
                     
  
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Forecast Probability (Percentage) :</label>
                          <select formControlName="forecast_percentage"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" (change)="changePercentage($event)">
                          <option value="10">10%</option>
                          <option value="20">20%</option>
                          <option value="30">30%</option>
                          
                        </select>
                        <div *ngIf="leadForm.get('forecast_percentage')?.touched && leadForm.get('forecast_percentage')?.hasError('required')"
                          class="error-msg">
                          Forecast Probability is required
                        </div>
                       
                      </div>
  
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Hot Lead :</label>
                        <select formControlName="hot_lead"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="" disabled="true">Select</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
  
                        </select>
                        <div
                          *ngIf="leadForm.get('hot_lead')?.touched && leadForm.get('hot_lead')?.hasError('required')"
                          class="error-msg">
                          Hot Lead is required
                        </div>
                       
                      </div>
  
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Cloudvice Sales Rep :</label>
                          <select formControlName="cloudvice_sales_rep"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="" disabled="true">Select</option>
                          <option *ngFor="let list of salesReplist" [value]="list.value">{{list.label}}</option>
                        </select>
                        <div *ngIf="leadForm.get('cloudvice_sales_rep')?.touched && leadForm.get('cloudvice_sales_rep')?.hasError('required')"
                          class="error-msg">
                          Cloudvice Sales Rep is required
                        </div>
                       
                      </div>
  
                      </div>
   
                    
  
                    <div class="flex justify-between">
                     
  
                     
                    </div>
  
                    <div class="flex justify-between">
  
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Comments :</label>
                          <textarea formControlName="comments" rows="4" cols="50" 
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm"></textarea>
                        <div *ngIf="leadForm.get('comments')?.touched && leadForm.get('comments')?.hasError('required')"
                          class="error-msg">
                          Comments is required
                        </div>
                       
                      </div>
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Partner Sales Rep :</label>
                          <select formControlName="partner_sales_rep"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="" disabled="true">Select</option>
                          <option *ngFor="let list of partnerSalesReplist" [value]="list.value">{{list.label}}</option>
                        </select>
                        <div *ngIf="leadForm.get('partner_sales_rep')?.touched && leadForm.get('partner_sales_rep')?.hasError('required')"
                          class="error-msg">
                          Partner Sales Rep is required
                        </div>
                       
                      </div>
  
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          Referal / Campain :</label>
                          <select formControlName="referal"
                          class="focus:ring-white w-64 h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                          <option value="" disabled="true">Select</option>
                          <option value="web">Web</option>
                          <option value="email">Email</option>
                        </select>
                        <div *ngIf="leadForm.get('referal')?.touched && leadForm.get('referal')?.hasError('required')"
                          class="error-msg">
                          Refferal / Campaign is required
                        </div>
                        
                      </div>
                     </div>
                    <div class="flex justify-between">
                      <div class="p-2">
                        <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                          File :</label>
                          
                        <ngx-file-drag-drop
							formControlName="file"
						  activeBorderColor="#3F51B5" class="focus:ring-white w-64 file-uploader"
						  [multiple]="false"
						  (valueChanged)="onFileChange($event)"></ngx-file-drag-drop>
							<div class="mt-2 cursor-pointer" *ngIf="this.leadDetails?.file != null" (click)="downloadFile()"> <mat-icon class="icon " >insert_drive_file</mat-icon>{{fileName}}</div>
                        </div>
                      </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
