import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { ContactFormService } from '../../services/contact-form.service';
import { EditLeadService } from '../../services/edit-lead.service';
import { ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CompanyservService } from '../../services/companyserv.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';

@Component({
  selector: 'app-edit-lead',
  standalone: true,
  imports: [ReactiveFormsModule,MatDividerModule,MatFormFieldModule,MatDatepickerModule,MatNativeDateModule,RouterModule,MatInputModule,MatSelectModule,CommonModule,MatIconModule,NgxFileDragDropModule],
  providers : [LoginService,ContactFormService,EditLeadService,CompanyservService],
  templateUrl: './edit-lead.component.html',
  styleUrl: './edit-lead.component.scss'
})
export class EditLeadComponent {

  public user: any;
  // leadForm!: FormGroup;
  cid:any;
  lid:any;
  contactdetails:any;
  leadDetails:any;
  cstDetails:any;
  organizationlist:any;
  customerDetails:any;
  salesReplist:any;
  partnerSalesReplist:any;
  searchControl:any
  filter: FormControl = new FormControl('');
	selectedImages:any;
  fileName : any
  leadstatus: any=[];


  constructor(private leadserv: EditLeadService,private companyserv:CompanyservService,private router :Router,private contactservice:ContactFormService,private formBuilder: FormBuilder,private route: ActivatedRoute,private loginservice: LoginService) { }


ngOnInit() {
  this.user = this.loginservice.getUserdata()
  this.route.params.subscribe((params:any)=>{
    if (params['id1']!=undefined){
      this.cid=params['id1']
    }
  })
  this.route.params.subscribe((params:any)=>{
    if (params['id2']!=undefined){
      this.lid=params['id2']
    }
  })
  this.user = this.loginservice.getUserdata()
  if(this.lid !==''){
    this.getLeadDetails()
  }
 
  this.getcontactlist(this.user.data.id,this.cid)
  this.getOrg()
  this.getLeadstatus('Lead')
  this.getOrganisationList("")
  this.salesrepList()
  this.getpartnerSalesrepList()
}

getcontactlist(userid: number,cid:number){
  this.contactservice.getContact(userid,cid).subscribe(
    (contactdetails: any) => {this.contactdetails = contactdetails.data
    }
    );   
}

getOrg(){
  this.companyserv.getorg(this.cid).subscribe((response:any)=>{
    this.cstDetails=response?.company_data
    
  })
}
getOrganisationList(search:any){
  this.companyserv.getCompany(this.user.data.id,search).subscribe(
    (organizationlist: any) =>{ 
      this.organizationlist = organizationlist.company_list
      
     });    
}

getCustomerData(customerId:any){
  // this.getcontactlist(this.user?.data?.id,customerId)
  // console.log(customerId,'clickeddd')
  // this.companyserv.getorg(customerId).subscribe((response:any)=>{
  //   console.log(response,'customerDetailssssssssss')
  //   this.customerDetails=response?.company_data
  //   console.log(this.customerDetails?.id,'titleeee')
  //   this.setForm()
  //   this.leadForm.patchValue({contact:this.customerDetails.id})
  // })
}

salesrepList(){
  this.leadserv.salesRepList().subscribe(
    (salesReplist: any) =>{ 
      this.salesReplist = salesReplist.data
    });  
}

getpartnerSalesrepList(){
  this.leadserv.partnersalesRepList().subscribe(
    (partnerSalesReplist: any) =>{ 
      this.partnerSalesReplist = partnerSalesReplist.data
    });  
}

searchCall(){
 this.getOrganisationList(this.searchControl)
}

  leadForm : FormGroup = this.formBuilder.group({
    customer: ['', [Validators.required]],
    contact: ['', [Validators.required]],
    lead_name: ['', [Validators.required,Validators.pattern(/^[a-zA-Z ]+$/)]],
    lead_description: [''],
    budget: ['',[Validators.required,Validators.pattern("^[0-9]*$")]],
    currency: ['', [Validators.required]],
    type: ['', [Validators.required]],
    lead_stage: ['New', Validators.required],  
    forecast_period: ['', [Validators.required]],
    forecast_percentage: ['', [Validators.required]],
    hot_lead: [ '', [Validators.required]],
    cloudvice_sales_rep: ['', [Validators.required]],  
    partner_sales_rep: ['', [Validators.required]],
    comments: [ '', [Validators.required]],
    referal: ['', [Validators.required]],
	  file: [null],
  },);



onSubmit(){
  this.leadForm.markAllAsTouched();
   const postData={
  customer: this.cid,
  contact:this.leadForm.value.contact,
  name: this.leadForm.value.lead_name,
  lead_description: this.leadForm.value.lead_description,
  // created_date:this.leadForm.value.created_date,
  budget:this.leadForm.value.budget,
  currency: this.leadForm.value.currency,
  type: this.leadForm.value.type,
  // created_by: this.leadForm.value.created_by,
  lead_stage:this.leadForm.value.lead_stage,
  status:this.leadForm.value.lead_stage,
  forecast_period:this.leadForm.value.forecast_period,
  forecast_percentage: this.leadForm.value.forecast_percentage,
  hot_lead: this.leadForm.value.hot_lead,
  cloudvice_sales_rep:this.leadForm.value.cloudvice_sales_rep,
  partner_sales_rep: this.leadForm.value.partner_sales_rep,
  comments:this.leadForm.value.comments,
  referal:this.leadForm.value.referal,
  creator_id:this.user.data.id,
  file:this.selectedImages,
  }
  if (this.leadForm.valid){
    this.leadserv.addLead(postData).subscribe((response:any)=>{
    if(response.status_code=== 201){
      this.router.navigate(['/executive/lead'])
  }
  })
  }
}

getLeadDetails(){
  this.contactservice.getleaddetails(this.lid).subscribe((response:any)=>{
    this.leadDetails=response?.data?.lead;
    // Split the URL by '/'
    if(this.leadDetails?.file != null){
    const parts = this.leadDetails?.file?.split('/');
    // Get the last part which is the file name
    this.fileName = parts[parts?.length - 1]
    }
    
    this.editForm()
  })
}
 
editForm(){
  this.leadForm.reset({
    customer : this.cstDetails?.id  || this.leadDetails?.customer,
    contact : this.leadDetails?.contact,
    lead_name : this.leadDetails?.name,
    lead_description : this.leadDetails?.lead_description,
    budget : this.leadDetails?.budget ,
    currency : this.leadDetails?.currency,
    type : this.leadDetails?.type,
    lead_stage : this.leadDetails?.status ,
    forecast_period : this.leadDetails?.forecast_period ,
    forecast_percentage : this.leadDetails?.forecast_percentage,
    hot_lead : this.leadDetails?.hot_lead ,
    cloudvice_sales_rep : this.leadDetails?.cloudvice_sales_rep ,
    partner_sales_rep : this.leadDetails?.partner_sales_rep,
    comments : this.leadDetails?.comments,
    referal : this.leadDetails?.referal
  })
}


editLead(){
  this.leadForm.markAllAsTouched();
  const postData={
    customer: +this.cid || this.leadDetails?.customer,
    contact:this.leadForm.value.contact,
    name: this.leadForm.value.lead_name,
    lead_description: this.leadForm.value.lead_description,
   
    budget:this.leadForm.value.budget,
    currency: this.leadForm.value.currency,
    type: this.leadForm.value.type,

    status:this.leadForm.value.lead_stage,
    forecast_period:this.leadForm.value.forecast_period,
    forecast_percentage: this.leadForm.value.forecast_percentage,
    hot_lead: this.leadForm.value.hot_lead,

    cloudvice_sales_rep:this.leadForm.value.cloudvice_sales_rep,
    partner_sales_rep: this.leadForm.value.partner_sales_rep,
    
    comments:this.leadForm.value.comments,
    referal:this.leadForm.value.referal,
   
    creator_id:this.user.data.id,
    file:this.selectedImages,
  }

this.contactservice.editlead(this.lid,postData).subscribe((response:any)=>{
  if(response.status_code=== 200){
   this.router.navigate(['/executive/lead'])
}
})
// }
}
getLeadstatus(status:any){
  this.contactservice.getQuatationstatus(status,this.user.data.id).subscribe((result:any)=>{
    this.leadstatus=result?.data;
    console.log('result lead is',this.leadstatus)
  })
}
changePercentage(event: any){
  if(event.target.value == 30){
    this.leadForm?.get('hot_lead')?.setValue(true);
  }else{
    this.leadForm?.get('hot_lead')?.setValue(false);
  }
}

onFileChange(event: any) {
  // console.log(event,this.quotationDetails?.file,'hhiik')
  if(event != null && event.length > 0){
  const files: FileList = event;
  const file: File = files[0]; // Access the file object directly
  const reader = new FileReader();
  reader.onload = (e: any) => {
    this.selectedImages = e.target.result;
  };
  reader.readAsDataURL(file);

  if(this.leadDetails?.file != undefined && this.leadDetails?.file !== null){
    // Log the file name
    // console.log('hai wwww')
    this.fileName = '';
    this.leadDetails.file = null;
  }
  }
}


  downloadFile() {
		if (this.leadDetails?.file != null) {
			const fileUrl = this.leadDetails?.file // Change this to your file URL
			const a = document.createElement('a');
			a.href = fileUrl;
			a.download = fileUrl; // Change the file name if needed
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	}

}
