<div class="max-w w-full h-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 mt-5 mb-5" style="height: 86vh !important;overflow-y: auto !important;">
  <div class="grid gap-8 grid-cols-1">
    <form [formGroup]="configForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-col">
        <div class="flex flex-col sm:flex-row items-center mb-2">
          <h2 class="font-semibold text-lg mr-auto">
            Configuration / {{ isEdit ? 'Edit' : 'New' }}
          </h2>
          <div class="w-full sm:w-auto sm:ml-auto sm:mt-0"></div>
          <div class="text-right md:space-x-3 md:block flex flex-col-reverse mb-2">
            <a routerLink="/admin/configuration" class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </a>
            <button class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm 
            font-medium tracking-wider text-white rounded-full hover:shadow-lg color" type="submit">
              {{ isEdit ? 'Save' : 'Save' }}
            </button>
          </div>
         
        </div>
        <mat-divider></mat-divider>

        <div class="mt-3 bg-white rounded border border-gray-100 px-20">
          <div class="form mt-3">
            <div class="flex flex-wrap -mx-3 mb-6">
              
              <div class="w-full md:w-1/2 px-3">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2">Company Name</label>
                <input type="text" formControlName="cname" [ngClass]="{ 'is-invalid': submitted && f['cname'].errors }" class="focus:ring-white w-full focus:border-gray-200 border-gray-400 sm:text-sm" placeholder="Name" />
                <div *ngIf="submitted && f['cname'].errors" class="invalid-feedback text-sm">
                  <div *ngIf="f['cname'].errors['required']">Please fill out this field.</div>
                </div>
              </div>
              <div class="w-full md:w-1/2 px-3">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2">Email</label>
                <input type="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" class="focus:ring-white w-full focus:border-gray-200 border-gray-400 sm:text-sm" placeholder=" Email" />
                <div *ngIf="submitted && f['email'].errors" class="invalid-feedback text-sm">
                  <div *ngIf="f['email'].errors['required']">Please fill out this field.</div>
                  <div *ngIf="f['email'].errors['email']">Invalid Format.</div>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2">Phone Number</label>
                <input type="text" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f['phone'].errors }" class="focus:ring-white w-full focus:border-gray-200 border-gray-400 sm:text-sm" placeholder="Phone Number" />
                <div *ngIf="submitted && f['phone'].errors" class="invalid-feedback text-sm">
                  <div *ngIf="f['phone'].errors['required']">Please fill out this field.</div>
                  <div *ngIf="f['phone'].errors['pattern']">Invalid Format.</div>
                </div>
              </div>
              <div class="w-full md:w-1/2 px-3">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2">Gst Number</label>
                <input type="text" formControlName="gstno" [ngClass]="{ 'is-invalid': submitted && f['gstno'].errors }" class="focus:ring-white w-full focus:border-gray-200 border-gray-400 sm:text-sm" placeholder="Gst Number" />
                <div *ngIf="submitted && f['gstno'].errors" class="invalid-feedback text-sm">
                  <div *ngIf="f['gstno'].errors['required']">Please fill out this field.</div>
                </div>
              </div>
             
            </div>

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2">Address</label>
                <input type="text" formControlName="address" [ngClass]="{ 'is-invalid': submitted && f['address'].errors }" class="focus:ring-white w-full focus:border-gray-200 border-gray-400 sm:text-sm" />
                <div *ngIf="submitted && f['address'].errors" class="invalid-feedback text-sm">
                  <div *ngIf="f['address'].errors['required']">Please fill out this field.</div>
                  <div *ngIf="f['address'].errors['pattern']">Invalid Format.</div>
                </div>
              </div>
              <div class="w-full md:w-1/2 px-3">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2">Account Name</label>
                <input type="text" formControlName="accountname" [ngClass]="{ 'is-invalid': submitted && f['accountname'].errors }" class="focus:ring-white w-full focus:border-gray-200 border-gray-400 sm:text-sm" placeholder="Eg. John123" />
                <div *ngIf="submitted && f['accountname'].errors" class="invalid-feedback text-sm">
                  <div *ngIf="f['accountname'].errors['required']">Please fill out this field.</div>
                </div>
              </div>
             
            </div>
              <div class="flex flex-wrap -mx-3 mb-6">
             
              <div class="w-full md:w-1/2 px-3">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2">Account Number</label>
                <input type="text" formControlName="accountnumber" [ngClass]="{ 'is-invalid': submitted && f['accountnumber'].errors }" class="focus:ring-white w-full focus:border-gray-200 border-gray-400 sm:text-sm" placeholder=" Account Number" />
                <div *ngIf="submitted && f['accountnumber'].errors" class="invalid-feedback text-sm">
                  <div *ngIf="f['accountnumber'].errors['required']">Please fill out this field.</div>
                </div>
              </div>
              <div class="w-full md:w-1/2 px-3">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2">Bank Name</label>
                <input type="text" formControlName="bankname" [ngClass]="{ 'is-invalid': submitted && f['bankname'].errors }" class="focus:ring-white w-full focus:border-gray-200 border-gray-400 sm:text-sm" placeholder=" eg:Bank of America" />
                <div *ngIf="submitted && f['bankname'].errors" class="invalid-feedback text-sm">
                  <div *ngIf="f['bankname'].errors['required']">Please fill out this field.</div>
                  <div *ngIf="f['bankname'].errors['pattern']">Invalid Format.</div>
                </div>
              </div></div>
              <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                 IFSC Code</label>
              <input type="text" formControlName="ifsc" [ngClass]="{ 'is-invalid': submitted && f['ifsc'].errors }" class="focus:ring-white w-full focus:border-gray-200  border-gray-400  sm:text-sm " placeholder="eg.HDFC0001234"/>
              <div *ngIf="submitted && f['ifsc'].errors" class="invalid-feedback text-sm">
               <div *ngIf="f['ifsc'].errors['required']">Please fill out this field.</div>
               <div *ngIf="f['ifsc'].errors['pattern']">Invalid Format.</div>
           </div>
              </div>
              <div class="w-full md:w-1/2 px-3" *ngIf="!isEdit">
                <label class="block  tracking-wide text-gray-700 text-xs font-bold mb-2">Image</label>
                <input type="file" formControlName="files" (change)="onImageSelect($event)" [ngClass]="{ 'is-invalid': submitted && f['files'].errors }" class="focus:ring-white w-full focus:border-gray-200 border-gray-400 sm:text-sm" />
                <div *ngIf="submitted && f['files'].errors" class="invalid-feedback text-sm">
                  <div *ngIf="f['files'].errors['required']">Please fill out this field.</div>
                </div>
              </div>
              <div class="w-full md:w-1/2 px-3" *ngIf="isEdit">
                <label class="font-bold tracking-wide text-sm">Change Photo</label>
                <img src="{{ imagesview }}" alt="" style="width:50px;height:50px">{{ imgname }}<br>
                <input formControlName="files" type="file" (change)="onImageSelect($event)" class="form-control" />
              </div>
            
            
            
            </div>
          
    </div>      
    
  
</div>
</div>
</form>
</div>
</div>

