<div class="max-w h-screen w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10 mt-5"
  style="overflow-y: auto !important; height:83vh !important;">
  <div class="grid">
    <form class="form-control" [formGroup]="quotationForm">
      <div class="flex flex-col">
        <div class="flex flex-col sm:flex-row items-center mb-6">
          <h2 class="font-semibold text-lg mr-auto">Quotation / {{Qid ? 'Edit' : 'New'}}</h2>
          <div class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>
          <div class="text-right md:space-x-3 md:block flex flex-col-reverse">
            <a routerLink="/executive/quotation"
              class="mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 cursor-pointer">
              Cancel
            </a>
            <button type="submit"
              class="mb-2 md:mb-0 bg-green-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg color"
              (click)="onSubmit()">
              Save
            </button>
          </div>
        </div>

        <mat-divider></mat-divider>

        <!-- GRID CONTAINER -->
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">

          <!-- Customer Field -->
          <div>
            <label class="block text-gray-400 text-sm mb-2">
              <span class="text-red-600">*</span> Customer:
            </label>
            <select formControlName="customer"
              class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm"
              (change)="customerChange($event)">
              <option value="">Select</option>
              <option *ngFor="let list of organizationlist" [value]="list.id">{{list.name}}</option>
            </select>
            <div *ngIf="quotationForm.get('customer')?.touched && quotationForm.get('customer')?.hasError('required')"
              class="error-msg">
              Customer is Required
            </div>
          </div>

          <!-- Opportunity ID Field -->
          <div>
            <label class="block text-gray-400 text-sm mb-2">
              <span class="text-red-600">*</span> Opportunity ID:
            </label>
            <select formControlName="oppertunity_unique_id" (change)="getLeadData($event)"
              class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
              <option value="">Select</option>
              <option *ngFor="let list of opplist" [value]="list.oppertunity_unique_id">{{list?.oppertunity_unique_id}}
                -
                {{list?.oppertunity_name_label}}</option>
            </select>
            <div
              *ngIf="quotationForm.get('oppertunity_unique_id')?.touched && quotationForm.get('oppertunity_unique_id')?.hasError('required')"
              class="error-msg">
              Opportunity ID is Required
            </div>

          </div>

          <!-- Quotation Name -->
          <div>
            <label class="block text-gray-400 text-sm mb-2">
              <span class="text-red-600">*</span> Quotation Name:
            </label>
            <select formControlName="quote_name"
                        class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                        <option value="">Select</option>
                        <option *ngFor="let list of opplist" [value]="list.id">{{list.oppertunity_name_label}}</option>
                      </select>
                      <div
                        *ngIf="quotationForm.get('quote_name')?.touched && quotationForm.get('quote_name')?.hasError('required')"
                        class="error-msg">
                        Quotation Name is Required
                      </div>
          </div>

          <!-- Contact Field -->
          <div>
            <label class="block text-gray-400 text-sm mb-2">
              <span class="text-red-600">*</span> Contact:
            </label>
            <select formControlName="contact"
            class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
            <option value="">Select</option>
            <option *ngFor="let list of contactlist" [value]="list.id">{{list.first_name}}</option>
          </select>
          <div
            *ngIf="quotationForm.get('contact')?.touched && quotationForm.get('contact')?.hasError('required')"
            class="error-msg">
            Contact is Required
          </div>

          </div>
          <div>
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              Description :</label>
            <input type="text" formControlName="description"
              class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
            <div
              *ngIf="quotationForm.get('description')?.touched && quotationForm.get('description')?.hasError('required')"
              class="error-msg">
              Description is Required
            </div>

          </div>


          <!-- Quotation Revenue -->
          <div>
            <label class="block text-gray-400 text-sm mb-2">
              <span class="text-red-600">*</span> Quotation Revenue:
            </label>
            <input type="text" formControlName="quotation_revenue"
                        class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
                      <div
                        *ngIf="quotationForm.get('quotation_revenue')?.touched && quotationForm.get('quotation_revenue')?.hasError('required')"
                        class="error-msg">
                        Quotation Revenue is Required
                      </div>

          </div>

          <!-- Currency Field -->
          <div>
            <label class="block text-gray-400 text-sm mb-2">
              <span class="text-red-600">*</span> Currency:
            </label>
            <select formControlName="currency"
                        class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                        <option value="">Select</option>
                        <option value="usd">USD</option>
                        <option value="inr">INR</option>
                        <option value="aed">AED</option>
                      </select>
                      <div
                        *ngIf="quotationForm.get('currency')?.touched && quotationForm.get('currency')?.hasError('required')"
                        class="error-msg">
                        Currency is Required
                      </div>

          </div>

          <!-- Type Field -->
          <div>
            <label class="block text-gray-400 text-sm mb-2">
              <span class="text-red-600">*</span> Type:
            </label>
            <select formControlName="type"
                        class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
                        <option value="">Select</option>
                        <!-- <option value="HCM">HCM</option>
                        <option value="ERP">ERP</option>
                        <option value="OCI">OCI</option> -->
						              <option [value]="item" *ngFor="let item of typeArr">{{item}}</option>

                      </select>
                      <div *ngIf="quotationForm.get('type')?.touched && quotationForm.get('type')?.hasError('required')"
                        class="error-msg">
                        Type is Required
                      </div>

          </div>
          <div>
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              <span class="text-red-600">*</span> Product :</label>
            <!-- <select formControlName="type_product"
              class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
              <option value="" disabled="true">Select</option>
              <option [value]="item" *ngFor="let item of productArr">{{item}}</option>
              
            </select> -->
            <mat-select #cat placeholder="Select" formControlName="products_id"   class="multiple-select focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" multiple>
              <mat-option class="select-checkbox">select</mat-option>
              <mat-option class="select-option" *ngFor="let item of productArr" 
              [value]="item.id">{{item.product_name}}</mat-option>
              </mat-select>
            <div *ngIf="quotationForm?.get('products_id')?.touched && quotationForm?.get('products_id')?.hasError('required')"
              class="error-msg">
              Product is Required
            </div>

          </div>
          <div>
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              Quotation Stage :</label>
            <select formControlName="quotation_stage"
              class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
              <option value="">Select</option>
              <option *ngFor="let list of quatationstatus" [value]="list?.name">{{list?.name}}</option>

            </select>

          </div>
          <div>
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              Cloudvice Sales Rep :</label>
            <!-- <select formControlName="cloudvice_sales_rep"
              class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
              <option value="" disabled="true">Select</option>
              <option *ngFor="let list of salesReplist" [value]="list.value">{{list.label}}</option>
            </select> -->
            <input type="text" formControlName="cloudvice_sales_rep"

             class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
            <!-- <div
              *ngIf="quotationForm.get('cloudvice_sales_rep')?.touched && quotationForm.get('cloudvice_sales_rep')?.hasError('required')"
              class="error-msg">
              Cloudvice Sales Rep is Required
            </div> -->

          </div>
          <div>
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              Partner Company Name :</label>
           <!-- <select formControlName="partner_sales_rep"
             class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm">
             <option value="" disabled="true">Select</option>
             <option *ngFor="let list of partnerSalesReplist" [value]="list.value">{{list.label}}</option>
           </select> -->
           <input type="text" formControlName="partner_sales_rep"
           class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
           <!-- <div
             *ngIf="quotationForm.get('partner_sales_rep')?.touched && quotationForm.get('partner_sales_rep')?.hasError('required')"
             class="error-msg">
             Partner Sales Rep is Required
           </div> -->

          </div>
          <div>
            <div class="flex items-center">
              <input type="checkbox" formControlName="is_approved"
                class="focus:ring-white w-6 h-6 rounded-md border-gray-300 sm:text-sm" [(ngModel)]="isChecked" />
              <label class="pl-2 text-gray-400 text-sm">
                Is Approved?
              </label>
            </div>
          </div>

          <div *ngIf="isChecked == true">
            <!-- <div class="p-2" *ngIf="isChecked == true"> -->
              <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
                Approved Amount :</label>
              <input type="text" formControlName="approved_amount"
                class="focus:ring-white w-full h-9 rounded-md focus:border-gray-200  border-gray-300  sm:text-sm" />
              <!-- <div
                *ngIf="quotationForm.get('approved_amount')?.touched && quotationForm.get('approved_amount')?.hasError('required')"
                class="error-msg">
                Price is Required
              </div> -->

          </div>
          <div>
            <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
              File :</label>
            <ngx-file-drag-drop formControlName="file" activeBorderColor="#3F51B5"
              class="form-control focus:ring-white w-full file-uploader uploader" [multiple]="false"
              (valueChanged)="onFileChange($event)" >
            </ngx-file-drag-drop>
            <div class="mt-2 cursor-pointer" *ngIf="this.quotationDetails?.file != null"
              (click)="downloadFile()"> <mat-icon class="icon ">insert_drive_file</mat-icon>{{fileName}}</div>
        </div>
        <!-- <div class="col-span-1 sm:col-span-2 md:col-span-3">
          <label class="block text-gray-400 text-sm mb-2">Comments:</label>
          <textarea class="focus:ring-white w-full rounded-md border-gray-300 sm:text-sm" rows="5"></textarea>
        </div> -->
          </div>
        <div class="grid grid-cols-12">
          <div class="col-span-12">
          <label class="block  tracking-wide text-gray-400 text-sm mb-2" for="grid-password">
            Comments :</label>
            <textarea formControlName="comments" rows="5" 
            class="focus:ring-white w-full rounded-md focus:border-gray-200  border-gray-300  sm:text-sm"></textarea>
          </div>
          </div>
          

      </div>
    </form>
  </div>
</div>