import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MainService } from '../../main-service';
import { LoginService } from '../../services/login.service';
import { PipelineService } from '../../services/pipeline.service';
import { ConfirmedValidator } from '../../confirmed.validator';
import { SuccessComponent } from '../../success/success.component';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-change-passwordess',
  standalone: true,
  imports: [CommonModule,RouterModule,MatDividerModule,ReactiveFormsModule],
  templateUrl: './change-passwordess.component.html',
  styleUrl: './change-passwordess.component.scss'
})
export class ChangePasswordessComponent implements OnInit {
  registerForm!: FormGroup;
  submitted = false;
  public user:any
  public cid:any
  public isEdit:boolean = false;
  public isUserExist:boolean = false;
  mainservice: any;
  roles:any[]=[]
  constructor(private formBuilder: FormBuilder,private dialog: MatDialog, private router: Router,private route: ActivatedRoute,private mservice:MainService, private loginservice:LoginService,private pservice:PipelineService) { }

  ngOnInit(): void {
    this.user = this.loginservice.getUserdata()
    this.form()
    this.route.paramMap.subscribe(params => {
      this.cid = params.get('id')
     
   
    });

  this.listRole()
  }
  form() {
	  this.registerForm = this.formBuilder.group({
		 
		  password: ['', [Validators.required,Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$')]],
		  confirm_password: ['', [Validators.required]]
	  
  }, { 
	validator: ConfirmedValidator('password', 'confirm_password')
  }
	   );
	  
  }
  userexist(e:any)
{
  this.mservice.userexist(e).subscribe((reponse:any) => {
  if(reponse=="User Exists")
  {
    this.isUserExist=true
  }
  else{
    this.isUserExist=false
  }
      
});
}
  get f() { return this.registerForm.controls; }


  editcontact(){
   this.submitted=true
   
   if(this.registerForm.invalid){
      return
   }
    let data_r={
      
      "userid":this.cid,
      "password":this.registerForm.value.password
     
      
     }
this.loginservice.changepswd(data_r).subscribe((reponse:any) => {
  const confirmDialog = this.dialog.open(SuccessComponent, {
		data: {
		  title: 'Success',
		  message: 'Change Password Successfully!'
		}
	  });
	  confirmDialog.afterClosed().subscribe(result => {
	window.location.href=""
  });
      
});
}
    onReset() {
      this.submitted = false;
      this.registerForm.reset();
      this.router.navigate(['/superAdmin'])
  }
   

  listRole(){
    this.loginservice.listrRole(this.user.data.id).subscribe((response:any)=>{
      this.roles=response 
  })
  }



}
