import { Component, OnInit,Input, Inject,Output,EventEmitter} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { PipelineService } from '../drag-drops/pipeline.service';
import { Board } from '../../admin/admin-global';
import { FailComponent } from '../../fail/fail.component';
import { Router, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { ContactFormService } from '../../services/contact-form.service';
import { ProductService } from '../add-products/product.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { EditLeadService } from '../edit-leads/edit-lead.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lead-dialog',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatDialogModule,MatInputModule, MatIconModule, RouterModule, MatSelectModule, NgxFileDragDropModule],
  templateUrl: './lead-dialog.component.html',
  styleUrl: './lead-dialog.component.scss'
})
export class LeadDialogComponent implements OnInit {
  isDisabled: boolean = false;
  submitted = false;
  user:any;
  contact:any[] =[];
  col:any
  public board: any = Board;
  isCatpro:boolean = false;
  isCatser:boolean = false;
  public boardid: any;
  productlist:any[]=[]
  servicetitle:any;
  servicedes:any
  productid : any[] = []
  @Output() private ontaskcomplete = new EventEmitter();
	customer: any;
  constructor( public dialogRef: MatDialogRef<LeadDialogComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,private toaster:ToastrService, private router: Router,private loginservice: LoginService,private contactservice: ContactFormService,private pservice:PipelineService,private formBuilder: FormBuilder,private proservice:ProductService) { 
	this.user = this.loginservice.getUserdata();
	this.getcontacts(this.user.data.id)
	this.getBoards(this.user.data.id)
 }


 registerForm : FormGroup = this.formBuilder.group({
	contact_id: ['', Validators.required],
	// column_id: ['', Validators.required],
	name: ['', Validators.required],
	budget: [''],
	email: [''],
	phone: [''],
	color: [''],
	category:[null,Validators.required],
	product: [null ,Validators.required],
	servicetitle: [''],
	servicedes:['']
});
  ngOnInit(): void {
	this.registerForm.controls["contact_id"].setValue(this.data.id);
	this.registerForm.controls["email"].setValue(this.data.email);
	this.registerForm.controls["phone"].setValue(this.data.phone);
	
	}
getcontacts(userid: number){
  
    this.contactservice.contacts(userid).subscribe(
      (contact: any) => {this.contact = contact
	  }
      );
  }
  getBoards(userid: number) {
	this.pservice.getBoard(userid).subscribe(
		(data: any) => {
			this.board = new Board(data[0]['id'], data[0]['name'], [])
			this.boardid = data[0]['id'];
			this.getColumns(this.boardid);
		}
	)
}
getColumns(boardid: number) {
	this.pservice.getColumns(boardid).subscribe(
		(cdata: any) => {
    this.col=cdata[0]['id']

		}
	)

}
get f() { return this.registerForm.controls; }

onSubmit(colid:any) {
	console.log('submittttttttttttttttt')
	this.submitted = true;
		if (this.registerForm.invalid) {
			console.log('retn',this.registerForm.controls)
			return;
        }
	

	
	if(this.registerForm.value.category=='product')
		{
        console.log(this.registerForm.value.product,'this.registerForm.value.product')
		this.registerForm.value.product.forEach((item:any)=>{
			this.productid.push(Number(item))
		})
		this.servicetitle = ''
		this.servicedes = ''
		}
		if(this.registerForm.value.category=='service')
		{
		this.productid = []
		this.servicetitle = this.registerForm.value.servicetitle
		this.servicedes= this.registerForm.value.servicedes
		}
  let data=
  {
	'contact_id': this.registerForm.value.contact_id,
	'creator_id': this.user.data.id,
	'column_id':this.col,
	'phone':this.registerForm.value.phone,
	'email': this.registerForm.value.email,
	'name':this.registerForm.value.name,
	'budget':this.registerForm.value.budget,
	'color':'white',
	'category':this.registerForm.value.category,
	'status':"Lead",
	"products_id":this.productid,
    "service_title":this.servicetitle,
	"service_description":this.servicedes,
	"team_id" : this.user.teamid,
	"customer_id":this.data.customer,
	"task_stage":""
  }
  this.pservice.addTask(data,this.col).subscribe((reponse:any) => {
  if(reponse){
	this.pservice.ContactleadCountCreateView(reponse.contact_id,reponse.id).subscribe((result:any)=>{
	})
	this.dialogRef.close();
	this.toaster.success('task created Sucessfully')

	this.router.navigate(['teamleader/']);
  }

	  });
	}
	onReset() {
        this.submitted = false;
        this.registerForm.reset();
    }
	
	Onchangecat(res:any){
       if(res=='product')
		{
        this.isCatpro=true
		this.isCatser=false
		this.proservice.getProduct(this.user?.data?.id).subscribe(
			(res: any) => {
				this.productlist = res
			})
		
		}
		if(res=='service')
		{
			this.isCatser=true
			this.isCatpro=false
		}
	}
}