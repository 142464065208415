import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule,DatePipe } from '@angular/common';
import { ReportService } from '../../teamleader/reports/report.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-notification-admin',
  standalone: true,
  imports: [MatIconModule,CommonModule],
  providers: [DatePipe],
  templateUrl: './notification-admin.component.html',
  styleUrl: './notification-admin.component.scss'
})
export class NotificationAdminComponent implements OnInit {

  notificationListArr : any[] = [];
  user : any = '';
  loading = false;
  @Output()private notifyCloseEvent = new EventEmitter();
  @Output()private notifySingleCloseEvent = new EventEmitter();


  constructor(private reportService : ReportService, private loginservice : LoginService){}

  ngOnInit() {
    this.user = this.loginservice.getUserdata()
    this.getNotificationList()
  }

  getNotificationList(){
    this.notificationListArr = []
    this.reportService.connect(this.user?.data?.id)
    this.reportService.notificationUpdate$.subscribe((data:any) => {
      if(data?.message?.length){
          this.loading = false;
          this.notificationListArr = data?.message
      }else{
        this.loading = true;
      }
    });
  //   .subscribe({
  //     next: (data: any) => {
  //       if(data?.message?.length){
  //         this.loading = false;
  //         this.notificationListArr = data?.message;
  //       }else{
  //         this.loading = true;
  //       }
  //   },
  // })
  }

  closeNotification(){
    this.notifyCloseEvent.emit()
  }

  closeSingleNotification(notify_id:any){
    this.reportService.clearSingleNotification(this.user?.data?.id,notify_id).subscribe((res:any)=>{
      if(res?.message == "Notification readed successfully"){
        this.notifySingleCloseEvent.emit();
        this.getNotificationList()
      }
    })
  }

  clearAllNotification(){
    this.reportService.markAllNotification(this.user?.data?.id).subscribe((res:any)=>{
      if(res){
        this.notifySingleCloseEvent.emit();
        this.getNotificationList()
      }
    })
  }


}
