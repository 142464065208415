import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from '../global';
@Injectable({
  providedIn: 'root'
})
export class EditLeadService {

  constructor(private http: HttpClient) { }

  public performanceUpdate:BehaviorSubject<any> = new BehaviorSubject<any>('');

  
  addLead(data:any){
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json');  
    return this.http.post(apiurl + 'pipeline/leads/create/',data,{headers:headers});             
    }

    salesRepList(){
      return this.http.get(apiurl + 'pipeline/cloudvise-members/list/');             

    }

    partnersalesRepList(){
      return this.http.get(apiurl + 'pipeline/partner-sales-members/list/');             

    }

    editLead(id:any,data:any){
      const headers = new HttpHeaders();
      headers.append('Content-Type','application/json');  
      return this.http.put(`${apiurl}pipeline/opportunity-generator/update/${id}/`,data,{headers:headers});             
      }
    

      editQuotation(id:any,data:any){
        const headers = new HttpHeaders();
        headers.append('Content-Type','application/json');  
        return this.http.put(`${apiurl}pipeline/quotation/update/${id}/`,data,{headers:headers});             
        }

        getUpdate(data:any){
          this.performanceUpdate.next(data)
          
        }
      
        setUpdate() {
          return this.performanceUpdate.asObservable();
        }

        getProductList(){
          return this.http.get(apiurl + 'ProductList/');             
    
        }
}
